import React from 'react';
import ReactDOM from 'react-dom';

import 'reflect-metadata';

import 'src/bootstrap';
import * as serviceWorker from 'src/serviceWorker';

import Providers from 'src/Providers';

const render = () => {
  const App = require('src/App').default;

  ReactDOM.render(
    <React.StrictMode>
      <Providers>
        <App />
      </Providers>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('src/App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
