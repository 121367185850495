import { IllusEscalier } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { FETCH_STATUS } from 'src/Redux/Types';
import MissionCreationLoader from '../MissionCreationLoader';
import styles from './MissionCreationSuccess.module.scss';
import { Props } from './MissionCreationSuccess.types';

const MissionCreationSuccess = (props: Props) => {
  return (
    <div className={styles.container}>
      {props.missionCreationFetchStatus === FETCH_STATUS.PENDING && <MissionCreationLoader />}
      {props.missionCreationFetchStatus === FETCH_STATUS.FULFILLED && (
        <p>Votre commande a bien été créée</p>
      )}
      {props.missionCreationFetchStatus === FETCH_STATUS.REJECTED && (
        <div className={styles.noResult}>
          <IllusEscalier />
          <div className={styles.noResultText}>Une erreur est survenue</div>
        </div>
      )}
    </div>
  );
};

export default MissionCreationSuccess;
