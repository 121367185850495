import styles from './TalentPhoneNumber.module.scss';
import { Props } from './TalentPhoneNumber.types';

export const TalentPhoneNumber = ({ phoneNumber1 }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>numéro de téléphone</div>
      <div className={styles.text}>{phoneNumber1 ?? 'non renseigné'}</div>
    </div>
  );
};

export default TalentPhoneNumber;
