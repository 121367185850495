import { useController } from 'react-hook-form';

import { Props } from './EnvironmentRisksSelection.types';
import {
  DefaultIcon,
  RiskPontRoulant,
  RiskStockageChimique,
  RiskStockageMateriaux,
  RiskTravailEnginsChantier,
  RiskTravailEnginsMouvement,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import SelectionIconButtonsForm from '../SelectionIconButtonsForm/SelectionIconButtonsForm.component';
import { addOrRemove } from 'src/Utils/addOrRemove';
import { Risk } from 'src/Services/API';
import React from 'react';
import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import styles from './EnvironmentRisksSelection.module.scss';

const iconMapping: { [id: string]: JSX.Element } = {
  1: <RiskTravailEnginsChantier />,
  2: <RiskTravailEnginsMouvement />,
  3: <RiskPontRoulant />,
  4: <RiskStockageChimique />,
  5: <RiskStockageMateriaux />,
};

const EnvironmentRisksSelection = ({
  isEditable,
  control,
  submit,
  ...selectionIconButtonsFormProps
}: Props) => {
  const risks = [
    { id: '1', label: 'Présence d’engin de chantier' },
    { id: '2', label: 'Présence de chariot automoteur' },
    { id: '3', label: 'Présence de pont roulant' },
    { id: '4', label: 'Stockage de produits chimiques' },
    { id: '5', label: 'Stockage de matériaux' },
  ];

  const { field } = useController({
    name: 'environmentRisks',
    control,
  });

  return isEditable ? (
    <SelectionIconButtonsForm
      {...selectionIconButtonsFormProps}
      isLoading={false}
      loadingItems={20}
      isError={false}
      error="une erreur est survenue lors de la récupération du référentiel des risques"
      selectIconButtonClassName={styles.riskCard}
      selectIconButtonLoaderClassName={styles.riskCardLoader}
      isSuccess={true}
      data={
        risks?.map(risk => ({
          key: risk.id,
          label: risk.label,
          icon: iconMapping[risk.id] ?? <DefaultIcon />,
          selected: field.value?.find((selected: Risk) => selected.id === risk.id) !== undefined,
          onClick: () => {
            field.onChange(addOrRemove(field.value, risk ?? []));
            submit();
          },
        })) ?? []
      }
    />
  ) : (
    <WithLightTitle
      className={styles.lightTitle}
      title={
        selectionIconButtonsFormProps.title ??
        "sélectionner parmi ces risques liés à l'environnement de travail"
      }
    >
      {field.value.map(risk => (
        <div>{risk.label}</div>
      ))}
      {field.value.length === 0 && <div>aucun renseigné</div>}
    </WithLightTitle>
  );
};

export default EnvironmentRisksSelection;
