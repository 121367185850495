import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';

import styles from './FileInput.module.scss';
import { Props } from './FileInput.types';

const FileInput = ({ selectedFile, name, titleNode, onChangeData }: Props) => {
  if (!selectedFile) {
    return (
      <div>
        <div className={styles.input_file_wrap}>
          <div className={styles.input_file_label}>{titleNode}</div>
          <input
            id={name}
            name={name}
            type="file"
            accept={'.csv'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (!event.target.files) return;
              onChangeData(event.target.files[0]);
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.previewFile}>
        <div className={styles.previewFileName}>
          <div className={styles.fileName}>{selectedFile.name}</div>
          <div className={styles.fileDate}>{moment(selectedFile.lastModified).toString()}</div>
        </div>

        <div className={styles.previewFileDeleteButton}>
          <button
            onClick={() => {
              onChangeData(undefined);
            }}
          >
            <Trashcan></Trashcan>supprimer
          </button>
        </div>
      </div>
    );
  }
};

FileInput.displayName = 'FileInput';
export default FileInput;
