import { TypeAndEnter } from "@randstad-lean-mobile-factory/react-components-core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { youplanCompaniesActions } from "src/Redux/YouplanCompanies/Slice";

const TemporaryWorkersTopBarComponent = ()=>{
    const [searchValue,setSearchValue] = useState('')
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(youplanCompaniesActions.setYouplanSearchInput(searchValue));
      }, [dispatch, searchValue]);
    return <TypeAndEnter
    selectedValue={searchValue}
    setSelectedValue={newValue => setSearchValue(newValue)}
    placeholder="rechercher"
  />
}
export default TemporaryWorkersTopBarComponent;