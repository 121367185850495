import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { YouplanMissionsService } from 'src/Services/API';

export const useCancelYouplanRelatedMission = (
  opts?: UseMutationOptions<unknown, unknown, { id?: string }>
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  return useMutation(async ({ id }: { id?: string }) => {
    if (!brandCode || !id) {
      throw new Error('Invalid values');
    }
    return YouplanMissionsService.youplanMissionsControllerCancelRelatedMission({
      id,
      brandCode,
    });
  }, opts);
};
