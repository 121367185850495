import { useContext, useRef } from 'react';

import {
  Button,
  LetterBadge,
  ModalDeprecated,
  PopupActions,
  PopupMenu,
  PopupMenuContext,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  Email,
  Info,
  PinLocate,
  Telephone,
  User,
  Visite,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import ContentLoader from 'react-content-loader';
import { useFetchCandidateDetails } from 'src/Hooks/Candidate/useFetchCandidateDetails';
import { useFetchCandidateQualifications } from 'src/Hooks/Candidate/useFetchCandidateQualifications';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeFetchStatus, toFetchStatus } from 'src/Utils/fetch-status';
import styles from './CandidateDetailsModal.module.scss';
import { Props } from './CandidateDetailsModal.types';

const CandidateDetailsModal = ({ candidateId }: Props) => {
  const ref = useRef<PopupActions>(null);
  const candidateDetailsQuery = useFetchCandidateDetails(candidateId);
  const candidateQualifications = useFetchCandidateQualifications(candidateId);
  const fetchStatus = mergeFetchStatus(
    toFetchStatus(candidateDetailsQuery),
    toFetchStatus(candidateQualifications)
  );
  const popupContext = useContext(PopupMenuContext);
  return (
    <ModalDeprecated
      ref={ref}
      icon={
        fetchStatus === FETCH_STATUS.FULFILLED ? (
          <LetterBadge
            className={styles.letterBadge}
            text={`${candidateDetailsQuery.data?.firstName[0]}${candidateDetailsQuery.data?.name[0]}`}
          />
        ) : (
          <ContentLoader height="8rem" width="90%" uniqueKey={'candidateLetterBadge'}>
            <rect x="1%" y="10" rx="24" ry="4" width="10%" height="18" />
          </ContentLoader>
        )
      }
      title="détails sur le candidat"
      trigger={<PopupMenu.Item text="voir détails" icon={<Info />} keepOpenOnClick />}
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_PUBLIC_RL_URL}/candidate/${candidateId}/file/synthese`
            );
            ref.current?.close();
          }}
        >
          consulter son dossier
        </Button.Secondary>,
        <Button.Primary onClick={() => ref.current?.close()}>fermer</Button.Primary>,
      ]}
      onClose={() => {
        popupContext?.().actions?.close();
      }}
    >
      {fetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="8rem" width="90%" uniqueKey={'candidateDetails'}>
          <rect x="1%" y="10" rx="4" ry="4" width="50%" height="18" />
          <rect x="1%" y="30" rx="4" ry="4" width="80%" height="18" />
          <rect x="1%" y="50" rx="4" ry="4" width="80%" height="18" />
          <rect x="1%" y="70" rx="4" ry="4" width="80%" height="18" />
        </ContentLoader>
      )}
      {fetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          <div className={styles.elementWithIcon}>
            <div className={styles.dot}>
              <User />
            </div>
            <WithLightTitle title="prénom et nom">{`${candidateDetailsQuery.data?.firstName} ${candidateDetailsQuery.data?.name}`}</WithLightTitle>
          </div>
          <div className={styles.elementWithIcon}>
            <div className={styles.dot}>
              <PinLocate />
            </div>
            <WithLightTitle className={styles.cityName} title="ville">
              {candidateDetailsQuery.data?.city}
            </WithLightTitle>
            <WithLightTitle title="code postal">
              {candidateDetailsQuery.data?.zipCode}
            </WithLightTitle>
          </div>
          <div className={styles.elementWithIcon}>
            <div className={styles.dot}>
              <Telephone />
            </div>
            <WithLightTitle title="numéro de téléphone">
              {candidateDetailsQuery.data?.phone1 ??
                candidateDetailsQuery.data?.phone2 ??
                'non renseigné'}
            </WithLightTitle>
          </div>
          <div className={styles.elementWithIcon}>
            <div className={styles.dot}>
              <Email />
            </div>
            <WithLightTitle title="email">
              {candidateDetailsQuery.data?.email ?? 'non renseigné'}
            </WithLightTitle>
          </div>
          <div className={styles.separator} />
          <div className={styles.elementWithIcon}>
            <div className={styles.dot}>
              <Visite />
            </div>
            <WithLightTitle title="qualifications" className={styles.qualifications}>
              <ul>
                {candidateQualifications.data?.map(qualification => (
                  <li>{qualification.label?.toLocaleLowerCase()}</li>
                ))}
              </ul>
            </WithLightTitle>
          </div>
        </>
      )}
    </ModalDeprecated>
  );
};

export default CandidateDetailsModal;
