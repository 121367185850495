import { useMutation } from '@tanstack/react-query';
import { CompaniesService } from 'src/Services/API';

export const usePatchCompanyQualification = () => {
  return useMutation(
    async ({
      companyId,
      potentialQualificationId,
    }: {
      companyId: string;
      potentialQualificationId: string;
    }) => {
      return CompaniesService.companiesControllerAddNonExistentCompanyQualification({
        companyId,
        body: { potentialQualificationId },
      });
    }
  );
};
