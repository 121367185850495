import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { QueryKeys } from 'src/Hooks/types';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService } from 'src/Services/API';

export const useFetchCurrentContractOfCandidate = ({ candidateId }: { candidateId?: string }) => {
  const brandCode = useSelector(getCurrentBrandCode);
  return useQuery(
    [QueryKeys.fetchCurrentContractOfCandidate, candidateId, brandCode],
    async () => {
      return CandidatesService.candidatesControllerGetCandidateCurrentContract({
        id: candidateId!,
        brandCode: brandCode ?? '',
      });
    },
    { enabled: candidateId !== undefined }
  );
};
