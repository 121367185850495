import moment from 'moment';
import styles from './TalentLastMedicalVisit.module.scss';
import { Props } from './TalentLastMedicalVisit.types';
import {
  CircleCheckGreen,
  DangerFillRed,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';

const TalentLastMedicalVisit = ({ lastMedicalVisitDate }: Props) => {
  const today = moment().startOf('day');
  const formattedDate = moment(lastMedicalVisitDate).format('DD/MM/YYYY');
  const isFutureDate = moment(lastMedicalVisitDate).isAfter(today);

  return (
    <div className={styles.container}>
      <div className={styles.title}>dernière visite médicale</div>
      <div className={styles.detailsContainer}>
        <div>{lastMedicalVisitDate !== undefined ? <CircleCheckGreen /> : <DangerFillRed />}</div>
        <div className={styles.lastMedicalVisitDetails}>
          {lastMedicalVisitDate !== undefined && lastMedicalVisitDate !== null
            ? isFutureDate
              ? `prévue le ${formattedDate}`
              : `effectuée le ${formattedDate}`
            : 'aucune visite détectée'}
        </div>
      </div>
    </div>
  );
};

export default TalentLastMedicalVisit;
