import React from 'react';

import styles from './ParametersPopOverMenuButton.module.scss';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Gear } from '@randstad-lean-mobile-factory/react-components-ui-shared';

const ParametersPopOverMenuButton = () => (
  <Button.Tertiary className={styles.gearButton}>
    <Gear />
  </Button.Tertiary>
);

export default ParametersPopOverMenuButton;
