import { useSearchContractsWithReset } from 'src/Redux/Search/Hooks';
import SingleDatePickerPopOver from '../SingleDatePickerPopOver';
import styles from './ContractsTopBarComponent.module.scss';

export const ContractsTopBarComponent = () => {
  const searchContractsWithReset = useSearchContractsWithReset();

  return (
    <div className={styles.container}>
      de mon agence en cours le
      <div className={styles.datePickerContainer}>
        <SingleDatePickerPopOver calendar={false} onChange={searchContractsWithReset} />
      </div>
    </div>
  );
};
