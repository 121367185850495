import classnames from 'classnames';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTypedState } from 'react-router-typesafe-routes/dom';
import { useFetchDomains } from 'src/Hooks/Domains/useFetchDomains';
import { useFetchQualifications } from 'src/Hooks/Qualifications/useFetchQualifications';
import { getSearchInput } from 'src/Redux/ExternalDemandPreferences/Selectors';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { AGENCY_PARAM, DEFAULT_SOURCE_VALUE } from 'src/Utils/constants';
import styles from './MailParametersList.module.scss';
import { GroupedMailParameters, Props } from './MailParametersList.types';
import MailParametersTable from './MailParameterTable/MailParameterTable.component';

const MailParametersList = ({ mailParameters, source }: Props) => {
  const initialSiret = useTypedState(ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES).siret;

  const brandCode = useSelector(getCurrentBrandCode);
  const searchInput = useSelector(getSearchInput);
  const [openSection, setOpenSection] = useState<string | undefined>(
    initialSiret ? initialSiret + source : undefined
  );

  const data = useMemo(
    () =>
      Object.entries(_.groupBy(mailParameters, mailParameter => mailParameter.siret)).map(
        ([siret, mailParameters]): GroupedMailParameters => ({
          clientName:
            siret === DEFAULT_SOURCE_VALUE
              ? AGENCY_PARAM
              : mailParameters[0].clientName ?? AGENCY_PARAM,
          siret: siret,
          companyId: mailParameters[0].companyId,
          mailParameters: mailParameters.map(mailParameter => ({
            ...mailParameter,
            updatedOsmoseLabel: mailParameter.osmoseLabel,
          })),
        })
      ),
    [mailParameters]
  );

  const { data: qualificationData } = useFetchQualifications({ brandCode: brandCode });
  const qualifications = useMemo(
    () => qualificationData?.map(qualifications => qualifications.shortLabel).filter(Boolean) ?? [],
    [qualificationData]
  );
  const { data: domainsData } = useFetchDomains({ brandCode: brandCode });
  const domains = useMemo(
    () => domainsData?.map(domain => domain.shortLabel).filter(Boolean) ?? [],
    [domainsData]
  );

  const handleToggleSection = useCallback((siret: string) => {
    setOpenSection(prevOpenSection => (prevOpenSection === siret ? undefined : siret));
  }, []);

  return (
    <div className={classnames(styles.container)}>
      {data.filter(group =>
        group.clientName.toLowerCase().trim().includes(searchInput.toLowerCase().trim())
      ).length === 0 && (
        <div className={styles.noResult}>aucun résultat ne correspond à votre recherche</div>
      )}
      {data.map(group => {
        return (
          <MailParametersTable
            key={group.siret}
            group={group}
            domains={domains}
            qualifications={qualifications}
            source={source}
            isOpen={openSection === group.siret + source}
            onToggle={() => handleToggleSection(`${group.siret}${source}`)}
            defaultOpenState={false}
            searchInput={searchInput}
          />
        );
      })}
    </div>
  );
};

export default MailParametersList;
