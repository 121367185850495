import {
  Button,
  ComboBox,
  ModalDeprecated,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusTdpUnite } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchPerimeter } from 'src/Hooks/Perimeter';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';

import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/Hooks/types';
import ErrorFormField from './ErrorFormField';
import styles from './PerimeterSelectionModal.module.scss';
import { PerimeterSelectionModalProps, Schema } from './PerimeterSelectionModal.types';

const PerimeterSelectionModal = ({ open, onClose }: PerimeterSelectionModalProps) => {
  const dispatch = useDispatch();
  const { data } = useFetchPerimeter({});
  const queryClient = useQueryClient();

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  const isClosable = useRef(!!currentBrand && !!currentAgency).current;

  const [brandSearchValue, setBrandSearchValue] = useState('');
  const [agencySearchValue, setAgencySearchValue] = useState('');

  const formMethods = useForm<Schema>({
    defaultValues: { brand: currentBrand, agency: currentAgency },
  });
  const { control, getValues, handleSubmit, reset, setValue, watch } = formMethods;
  const innerRef = React.useRef<PopupActions>(null);
  const watchBrand = watch('brand');
  useEffect(() => {
    if (watchBrand?.brand?.code !== currentBrand?.brand?.code)
      setValue('agency', watchBrand?.agencies?.[0] ?? undefined, {
        shouldValidate: true,
      });
  }, [currentBrand, setValue, watchBrand]);

  return (
    <FormProvider {...formMethods}>
      <ModalDeprecated
        ref={innerRef}
        open={open}
        onOpen={() => {
          setValue('agency', currentAgency);
          setValue('brand', currentBrand);
        }}
        onClose={() => {
          if (onClose !== undefined) {
            onClose();
          }
        }}
        title={`choisissez vos unités`}
        hideCloseAction={!isClosable}
        closeOnDocumentClick={isClosable}
        icon={<IllusTdpUnite />}
        footerActionsLeft={[
          <Button.Tertiary
            onClick={() => {
              reset();
            }}
          >
            tout réinitialiser
          </Button.Tertiary>,
        ]}
        footerActionsRight={[
          isClosable && (
            <Button.Secondary
              onClick={() => {
                innerRef.current?.close();
              }}
            >
              annuler
            </Button.Secondary>
          ),
          <Button
            onClick={handleSubmit(({ brand, agency }) => {
              dispatch(perimeterActions.setCurrentBrand(brand));
              dispatch(perimeterActions.setCurrentAgency(agency));
              queryClient.invalidateQueries({ queryKey: [QueryKeys.isCGC] });
              setTimeout(() => window.location.reload());
              innerRef.current?.close();
            })}
          >
            valider
          </Button>,
        ]}
      >
        <div className={styles.title}>
          Sélectionnez parmi votre périmètre, la marque et l'unité voulue
        </div>
        <WithLightTitle title="périmètre">
          <div className={styles.perimeterSelectionRow}>
            <Controller
              control={control}
              name="brand"
              rules={{ required: 'Sélectionnez une marque' }}
              render={({ field: { value, name, onChange } }) => {
                return (
                  <div className={styles.comboBoxFormField}>
                    <ComboBox
                      id={name}
                      value={value}
                      displayValue={'Aucune marque'}
                      onSearch={setBrandSearchValue}
                      searchResults={
                        data?.companies?.filter(({ brand }) =>
                          brandSearchValue
                            ? new RegExp(brandSearchValue, 'i').test(brand?.name ?? '')
                            : true
                        ) ?? []
                      }
                      onChange={onChange}
                      keyValueExtractor={({ brand, brandCodeApiHeader }) => ({
                        key: `${brand?.code}${brandCodeApiHeader}`,
                        value: brand?.name ?? 'Marque inconnue',
                      })}
                    />
                    <ErrorFormField<Schema> name={name} />
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name="agency"
              rules={{ required: 'Sélectionnez une agence' }}
              render={({ field: { value, name, onChange } }) => {
                return (
                  <div className={styles.comboBoxFormField}>
                    <ComboBox
                      id={name}
                      value={value}
                      displayValue="Aucune agence"
                      onSearch={setAgencySearchValue}
                      searchResults={(getValues('brand')?.agencies ?? []).filter((agency: string) =>
                        agencySearchValue ? new RegExp(agencySearchValue, 'i').test(agency) : true
                      )}
                      onChange={onChange}
                      keyValueExtractor={agency => ({ key: agency, value: agency })}
                    />
                    <ErrorFormField<Schema> name={name} />
                  </div>
                );
              }}
            />
          </div>
        </WithLightTitle>
      </ModalDeprecated>
    </FormProvider>
  );
};

export default PerimeterSelectionModal;
