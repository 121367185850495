import { TopBar } from 'src/Components/TopBar';
import { ContractsTopBarComponent } from 'src/Components/TopBar/ContractsTopBarComponent';
import ContractDetail from './ContractDetail';
import styles from './Contracts.module.scss';
import ListContracts from './ListContracts';

export const ContractsRoute = () => {
  return (
    <>
      <TopBar title="contrats">
        <ContractsTopBarComponent />
      </TopBar>
      <div className={styles.content}>
        <ListContracts />
        <ContractDetail />
      </div>
    </>
  );
};
