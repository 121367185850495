import { Props } from './ResidencePermit.types';
import styles from './ResidencePermit.module.scss';
import moment from 'moment';
import {
  CircleCheckGreen,
  DangerFillRed,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';

export const ResidencePermit = ({ residencePermit }: Props) => {
  const formattedDate = moment(residencePermit.expirationDate).format('DD/MM/YYYY');
  const expirationDate = moment(residencePermit.expirationDate);
  const today = moment().startOf('day');

  const isFutureDate = expirationDate.isAfter(today);

  return (
    <div className={styles.container}>
      <div className={styles.title}>titre de séjour</div>
      <div className={styles.detailsContainer}>
        <div>{isFutureDate ? <CircleCheckGreen /> : <DangerFillRed />}</div>
        <div
          className={styles.residencePermitDetails}
        >{`type : ${residencePermit.residencePermitTypes?.label?.toLocaleLowerCase()} - exp : ${formattedDate}`}</div>
      </div>
    </div>
  );
};

export default ResidencePermit;
