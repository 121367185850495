export interface Props {
  candidateId: string;
  onClose: () => void;
}

export enum REPOSITIONING_TAB {
  COMMANDES = 'commandes',
  QUALIFICATION = 'qualifications potentielles',
  MATCHING = 'matching de compétences',
}
