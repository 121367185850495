import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../types';
import { CandidateMemo, CandidatesService } from 'src/Services/API';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';

interface DeleteMemoParams {
  memoId: string;
  candidateId: string;
}

export const useDeleteCandidateMemo = (
  extraOptions?: UseMutationOptions<void, unknown, DeleteMemoParams, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.deleteCandidateMemo],
    async (params: DeleteMemoParams) => {
      await CandidatesService.candidatesControllerDeleteCandidateMemo({
        memoId: params.memoId,
        candidateId: params.candidateId,
      });
    },
    {
      ...extraOptions,
      onMutate: async deletedMemo => {
        await queryClient.cancelQueries({
          queryKey: [QueryKeys.fetchCandidateMemos, deletedMemo.candidateId],
        });

        const previousMemos = queryClient.getQueryData<CandidateMemo[]>([
          QueryKeys.fetchCandidateMemos,
          deletedMemo.candidateId,
        ]);

        queryClient.setQueryData<CandidateMemo[]>(
          [QueryKeys.fetchCandidateMemos, deletedMemo.candidateId],
          old => old?.filter(memo => memo.id !== deletedMemo.memoId)
        );

        return { previousMemos };
      },
      onError: (err, deletedMemo, context) => {
        queryClient.setQueryData(
          [QueryKeys.fetchCandidateMemos, deletedMemo.candidateId],
          context?.previousMemos
        );
        toast.error('Une erreur est survenue lors de la suooression du mémo, veuillez réessayer.');
      },
      onSettled: (data, error, deletedMemo) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchCandidateMemos, deletedMemo.candidateId],
        });
      },
    }
  );
};
