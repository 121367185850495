import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CompaniesService, CompanyAction } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanyActions = (
  companyId: string,
  agencyId: string,
  extraOptions?: UseQueryOptions<CompanyAction[], unknown, CompanyAction[], string[]>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchCompanyActions, companyId, agencyId],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return CompaniesService.companiesControllerGetCompanyLastActions({
        companyId,
        body: { agency: agencyId, brandCode: currentBrand.brandCodeApiHeader },
      });
    },
    { ...extraOptions, enabled: !!companyId }
  );
};
