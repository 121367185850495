import { useEffect, useMemo, useRef, useState } from 'react';

import { Button, DataTable } from '@randstad-lean-mobile-factory/react-components-core';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import { binocular, redCloud } from 'src/Assets_DEPRECATED';
import { useFetchYouplanMissions } from 'src/Hooks/Youplan/useFetchYouplanMissions';
import { useMarkYouplanMissionsAsRead } from 'src/Hooks/Youplan/useMarkYouplanMissionsAsRead';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { fetchYouplanMissionForNewMission } from 'src/Redux/MissionCreation/Thunks';
import { RootState } from 'src/Redux/RootReducer';
import {
  getCurrentYouplanCompany,
  getYouplanSearchInput,
} from 'src/Redux/YouplanCompanies/Selectors';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import {
  EnumCoreMissionCandidateCandidateAdvancement,
  EnumCoreMissionCandidateCompanyAdvancement,
  EnumYouplanMissionActionSummaryAction,
  YouplanMission,
} from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { MISSION_CREATION_STEP } from 'src/ModalRoutes_DEPRECATED/MissionCreationModal/MissionCreationModal.types';
import styles from './YouplanMissionDetail.module.scss';
import YouplanMissionsFooter from './YouplanMissionsFooter';
import YouplanMissionActions from './YouplanMissionActions';
import { ColumnDef, Table, ColumnFiltersState } from '@tanstack/react-table';
import YouplanMissionBadge from 'src/Components/MissionCard/YouplanMissionBadge';
import moment from 'moment';

const YouplanMissions = () => {
  const currentCompany = useSelector(getCurrentYouplanCompany);
  const {
    data: missionPages,
    fetchNextPage,
    hasNextPage,
    isError,
    isSuccess,
    isFetchingNextPage,
    isLoading,
  } = useFetchYouplanMissions({
    companyId: currentCompany?.companyId,
  });
  const location = useLocation();
  const locationState = useMemo(
    () => location.state as { isCreationSuccess?: boolean } | undefined,
    [location]
  );
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const { mutate: markMissionAsRead } = useMarkYouplanMissionsAsRead();
  const missions = (missionPages?.pages ?? []).flat();
  const searchInput = useSelector(getYouplanSearchInput);
  const filteredMissions = (missionPages?.pages ?? [])
    .flat()
    .filter(mission =>
      `${mission?.candidates?.[0].firstName} ${mission?.candidates?.[0].lastName}`
        .toLowerCase()
        .includes(searchInput.toLowerCase() ?? '')
    );
  const [selectedMissions, setSelectedMissions] = useState<string[]>([]);
  const [firstSelectedMission, setFirstSelectedMission] = useState<YouplanMission>();
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const tableRef = useRef<Table<YouplanMission>>(null);

  const fullSelectedMissions = missions.filter(mission =>
    selectedMissions.includes(mission.missionId ?? '')
  );
  const selectedMissionsCandidatesIds = fullSelectedMissions
    .flatMap(item => item.candidates)
    .map(item => item?.candidateId);
  const navigate = useNavigate();
  useEffect(() => {
    if (locationState?.isCreationSuccess) {
      setFirstSelectedMission(undefined);
      setSelectedMissions([]);
    }
  }, [locationState]);
  useEffect(() => {
    setFirstSelectedMission(undefined);
    setSelectedMissions([]);
  }, [currentCompany]);
  const displayGroupCommandButton =
    selectedMissionsCandidatesIds.filter(
      (item, index) => selectedMissionsCandidatesIds.indexOf(item) !== index
    ).length === 0 &&
    fullSelectedMissions.filter(
      mission =>
        mission.salesPhase?.id === 'AN' ||
        mission.youplanStatus?.action === EnumYouplanMissionActionSummaryAction.MISSION_CREATION
    ).length === 0;

  useEffect(() => {
    if ((tableRef.current?.getSelectedRowModel().rows ?? []).length > 0) {
      tableRef.current?.getSelectedRowModel().rows.forEach(row => {
        const isSelected = selectedMissions.includes(row.original.missionId ?? '');
        if (isSelected) {
          row.original.missionId === firstSelectedMission?.missionId &&
            setFirstSelectedMission(
              missions.find(mission => mission.missionId === selectedMissions?.[1])
            );
          setSelectedMissions(
            selectedMissions.filter(missionItem => missionItem !== row.original.missionId)
          );
        } else {
          !firstSelectedMission && setFirstSelectedMission(row.original);
          setSelectedMissions([...selectedMissions, row.original.missionId ?? '']);
        }
      });
    } else {
      setSelectedMissions([]);
      setFirstSelectedMission(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  const YouPlanMissionsHeaders = useMemo(
    (): ColumnDef<YouplanMission>[] => [
      {
        id: 'name',
        header: 'nom intérimaire',
        accessorFn: mission => {
          const isCancelled = mission?.salesPhase?.id === 'AN';
          const isOkOk =
            mission?.candidates?.some(
              candidate =>
                candidate.candidateAdvancement ===
                  EnumCoreMissionCandidateCandidateAdvancement.accepté &&
                candidate.companyAdvancement === EnumCoreMissionCandidateCompanyAdvancement.accepté
            ) ?? false;

          return (
            <div className={styles.name}>
              <div>
                {mission?.candidates?.[0]
                  ? `${mission?.candidates?.[0].firstName} ${mission?.candidates?.[0].lastName}`
                  : "pas d'intérimaire sur cette mission"}
              </div>
              <YouplanMissionBadge
                status={mission?.youplanStatus}
                isCancelled={isCancelled}
                isOkOk={isOkOk}
              />
            </div>
          );
        },
        cell: ({ getValue }) => {
          const missionAndQualification = getValue() as string | undefined;
          return <>{<>{missionAndQualification}</>}</>;
        },
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: 'period',
        header: 'période',
        accessorFn: mission => {
          return mission?.startDate ? moment(mission.startDate, 'DD/MM/YYYY').valueOf() : undefined;
        },
        cell: ({ row }) => {
          const momentStartDate = moment(row.original?.startDate);
          const momentEndDate = moment(row.original?.endDate);
          return (
            <div className={styles.durationContainer}>
              {momentStartDate.isSame(momentEndDate) ? (
                <div className={styles.date}>{`le ${momentStartDate.format('L')}`}</div>
              ) : (
                <>
                  <div className={styles.date}>{`du ${momentStartDate.format('L')}`}</div>
                  <div className={styles.date}> {`au ${momentEndDate.format('L')}`}</div>
                </>
              )}{' '}
            </div>
          );
        },
        sortUndefined: false,
        enableColumnFilter: true,
        enableSorting: true,
        meta: {
          isDate: true,
          columnSortingLabel: 'par ordre de date de début',
        },
        filterFn: 'inNumberRange',
      },
      {
        id: 'qualification',
        header: 'qualification',
        accessorFn: mission => {
          return mission?.qualification?.label ?? '';
        },
        cell: ({ getValue }) => {
          const missionAndQualification = getValue() as string | undefined;
          return <>{<>{missionAndQualification}</>}</>;
        },
        enableColumnFilter: true,
        enableSorting: true,
        filterFn: 'arrIncludes',
      },
      // To be uncommented when managing customer service
      // {
      //   id: 'service',
      //   header: 'service',
      //   accessorFn: mission => {
      //     return mission?.service?.name;
      //   },
      //   cell: ({ row }) => {
      //     const isCancelled = row.original?.salesPhase?.id === 'AN';
      //     const isUnhandledCancel = isCancelled && row.original.youplanStatus?.action !== undefined;
      //     const isConflictingRenewal =
      //       (row.original?.youplanStatus?.isInConflict || isUnhandledCancel) &&
      //       row.original?.youplanStatus?.action ===
      //         EnumYouplanMissionActionSummaryAction.CONTRACT_RENEWAL;
      //     return (
      //       <div>
      //         <div
      //           className={classNames(styles.service, { [styles.warning]: isConflictingRenewal })}
      //         >
      //           {row.original?.service?.name
      //             ? isConflictingRenewal
      //               ? 'assurez vous que la prolongation de contrat initiale a été supprimée avant de marquer le besoin comme résolu'
      //               : row.original?.service?.name
      //             : ''}
      //         </div>
      //       </div>
      //     );
      //   },
      //   sortUndefined: false,
      //   enableColumnFilter: true,
      //   enableSorting: true,
      //   filterFn: 'arrIncludes',
      // },
      {
        id: 'traitement',
        header: 'date de traitement',
        accessorFn: mission => {
          return mission?.processingDate
            ? moment(mission.processingDate, 'DD/MM/YYYY').valueOf()
            : undefined;
        },
        cell: ({ row }) => {
          return (
            <div className={styles.qualification}>
              {row.original?.processingDate
                ? moment(row.original?.processingDate as Date).format('DD/MM - HH:mm')
                : ''}
            </div>
          );
        },
        sortUndefined: false,
        enableColumnFilter: true,
        enableSorting: true,
        meta: {
          isDate: true,
        },
        filterFn: 'inNumberRange',
      },
      {
        id: 'action',
        header: 'actions',
        cell: ({ row }) => {
          return (
            <div className={styles.buttonContainer}>
              <YouplanMissionActions
                mission={row.original!}
                candidateId={row.original?.candidates?.[0]?.candidateId!}
              />
            </div>
          );
        },
        size: 20,
      },
    ],
    []
  );

  return (
    <div className={styles.container}>
      {isSuccess && missions.length !== 0 && (
        <>
          <div className={styles.companyContainer}>
            <div className={styles.title}>{currentCompany?.companyName}</div>
            <div className={styles.siret}>{currentCompany?.siret}</div>
          </div>
          <div className={styles.buttonBarContainer}>
            {selectedMissions.length > 0 && (
              <div className={styles.buttonBarLine}>
                <div className={styles.buttonBar}>
                  {displayGroupCommandButton && (
                    <Button.Secondary
                      onClick={() => {
                        dispatch(missionCreationActions.setYouplanMissionIds(selectedMissions));

                        dispatch(
                          fetchYouplanMissionForNewMission(firstSelectedMission?.missionId ?? '')
                        );
                        dispatch(
                          missionCreationActions.setNumberPositions(selectedMissions.length)
                        );
                        dispatch(
                          missionCreationActions.setYouPlanMissionCandidates(
                            missions
                              .filter(mission => selectedMissions.includes(mission.missionId ?? ''))
                              .flatMap(missionItem => missionItem.candidates ?? [])
                          )
                        );
                        navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
                          state: {
                            modalParameters: {
                              openingStep: MISSION_CREATION_STEP.FROM_YOUPLAN_ENTRY,
                            },
                            background: location,
                          },
                        });
                      }}
                      className={styles.smallButton}
                    >
                      commande groupée
                    </Button.Secondary>
                  )}
                  <Button.Secondary
                    onClick={() => {
                      setFirstSelectedMission(undefined);
                      setSelectedMissions([]);
                    }}
                    className={styles.smallButton}
                  >
                    tout désélectionner
                  </Button.Secondary>
                  {!fullSelectedMissions
                    .map(missionItem => missionItem.youplanStatus?.action)
                    .includes(EnumYouplanMissionActionSummaryAction.MISSION_CREATION) && (
                    <Button.Secondary
                      onClick={() => {
                        markMissionAsRead({
                          youplanMissionIds: selectedMissions,
                          startDate: firstSelectedMission?.startDate!,
                          endDate: firstSelectedMission?.endDate!,
                          companyId: firstSelectedMission?.company?.id!,
                          dpaeAgencyId: firstSelectedMission?.dPAEAgencyId!,
                        });
                        setFirstSelectedMission(undefined);
                        setSelectedMissions([]);
                      }}
                      className={styles.smallButton}
                    >
                      marquer traité
                    </Button.Secondary>
                  )}
                </div>
                <div className={styles.selectedMissionsNumber}>
                  {pluralFormat(selectedMissions.length, 'commande sélectionnée')}
                </div>
              </div>
            )}
          </div>
          {filteredMissions && (
            <DataTable<YouplanMission, unknown>
              size="small"
              columns={YouPlanMissionsHeaders}
              data={filteredMissions ?? []}
              enableRowSelection
              stickyHeader
              containerClassName={styles.table}
              state={{ rowSelection, columnFilters }}
              onColumnFiltersChange={setColumnFilters}
              onRowSelectionChange={setRowSelection}
              ref={tableRef}
            />
          )}
        </>
      )}
      <div className={styles.resultcontainer}>
        {isSuccess && !isLoading && missions.length === 0 && (
          <div className={styles.noResult}>
            <img src={binocular} alt="glasses" />
            <div className={styles.noResultText}>Pas de résultats</div>
          </div>
        )}
        {isError && (
          <div className={styles.noResult}>
            <img src={redCloud} alt="illusEscalier" />
            <div className={styles.noResultText}>Une erreur est survenue</div>
          </div>
        )}
        <YouplanMissionsFooter
          canLoadMore={hasNextPage}
          onLoadMore={fetchNextPage}
          isFetching={isLoading || isFetchingNextPage}
        />
      </div>
    </div>
  );
};

export default YouplanMissions;
