import { Props } from './EpisSelection.types';
import styles from './EpisSelection.module.scss';
import { EpiButton } from './EpiButton';
import { useState } from 'react';
import { WhoGaveEpi, YesNoUnknown } from 'src/Services/API';
import EpiEditionModal from './EpiEditionModal/EpiEditionModal.component';
import { WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';

const EpisSelection = ({
  isEditable,
  allEPIs,
  selectedEpis,
  onEpiUpdate,
  onEpiDelete,
  isError,
  isSuccess,
}: Props) => {
  const [openEditModal, setOpenEditModal] = useState<string | null>(null);
  return (
    <div>
      {!isEditable && <WithLightTitle title="EPI sélectionnés" children={<></>} />}
      <div className={styles.container}>
        {isSuccess &&
          allEPIs.map(epi => (
            <div key={`${epi.id} container`} className={styles.epiCardContainer}>
              <EpiButton
                key={`${epi.id} button`}
                isEditable={isEditable}
                epi={
                  selectedEpis.find(selected => selected.id === epi.id) ?? {
                    id: epi.id,
                    label: epi.label ?? epi.id,
                    hasBeenWorn: false,
                    whoGaveIt: WhoGaveEpi.UNKNOWN,
                    inContract: YesNoUnknown.Unknown,
                  }
                }
                selected={selectedEpis.find(selected => selected.id === epi.id) !== undefined}
                onClick={() => {
                  isEditable && setOpenEditModal(epi.id);
                }}
              />
              {isEditable && (
                <EpiEditionModal
                  key={`${epi.id} ModalDeprecated`}
                  epi={
                    selectedEpis.find(selected => selected.id === epi.id) ?? {
                      id: epi.id,
                      label: epi.label,
                      hasBeenWorn: false,
                      whoGaveIt: WhoGaveEpi.UNKNOWN,
                      inContract: YesNoUnknown.Unknown,
                    }
                  }
                  onEpiUpdate={onEpiUpdate}
                  onEpiDelete={onEpiDelete}
                  onClose={() => setOpenEditModal(null)}
                  open={openEditModal === epi.id}
                />
              )}
            </div>
          ))}

        {isError && (
          <div>
            une erreur est survenue lors de la récupération du référentiel des équipements de
            protection
          </div>
        )}
      </div>
    </div>
  );
};

export default EpisSelection;
