import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CompaniesService, CompanyContact } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanyContacts = (
  id: string,
  extraOptions?: UseQueryOptions<CompanyContact[], unknown, CompanyContact[], string[]>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchCompanyContacts, id],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return CompaniesService.companiesControllerGetContacts({
        id,
        brandCode: currentBrand.brandCodeApiHeader,
      });
    },
    { ...extraOptions, enabled: !!id }
  );
};
