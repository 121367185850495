import { TextInput, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import styles from './NewContactSelection.module.scss';
import classNames from 'classnames';
import { Props } from './NewContactSelection.types';

const NewContactSelection = ({ selectedContact, setSelectedContact }: Props) => {
  return (
    <div>
      <div className={classNames(styles.row, styles.marginTop)}>
        <WithLightTitle title="nom de la personne" className={styles.rowElement}>
          <TextInput
            value={selectedContact.name}
            onChange={event =>
              setSelectedContact({
                ...selectedContact,
                name: (event.target as HTMLInputElement).value,
              })
            }
          />
        </WithLightTitle>
        <WithLightTitle title="prénom de la personne" className={styles.rowElement}>
          <TextInput
            value={selectedContact.firstName}
            onChange={event =>
              setSelectedContact({
                ...selectedContact,
                firstName: (event.target as HTMLInputElement).value,
              })
            }
          />
        </WithLightTitle>
      </div>
      <WithLightTitle title="position de la personne" className={styles.marginTop}>
        <TextInput
          value={selectedContact.position}
          onChange={event =>
            setSelectedContact({
              ...selectedContact,
              position: (event.target as HTMLInputElement).value,
            })
          }
        />
      </WithLightTitle>
      {'societyName' in selectedContact && (
        <div className={classNames(styles.row, styles.marginTop)}>
          <WithLightTitle title="nom de la société" className={styles.rowElement}>
            <TextInput
              value={selectedContact.societyName}
              onChange={event =>
                setSelectedContact({
                  ...selectedContact,
                  societyName: (event.target as HTMLInputElement).value,
                })
              }
            />
          </WithLightTitle>
          <WithLightTitle title="numéro de la personne" className={styles.rowElement}>
            <TextInput
              value={selectedContact.phoneNumber}
              onChange={event =>
                setSelectedContact({
                  ...selectedContact,
                  phoneNumber: (event.target as HTMLInputElement).value,
                })
              }
            />
          </WithLightTitle>
        </div>
      )}
    </div>
  );
};

export default NewContactSelection;
