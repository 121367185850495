import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VariableSimulatorState } from './Types';
import { fetchVariableSimulator } from './Thunks';
import { FETCH_STATUS } from 'src/Redux/Types';
import moment from 'moment';
import { EnumConsultantInformationDataStatus } from 'src/Services/API';

const initialState: VariableSimulatorState = {
  consultantInformation: undefined,
  fetchStatus: FETCH_STATUS.FULFILLED,
};

export const { reducer: variableSimulatorReducer, actions: variableSimulatorActions } = createSlice(
  {
    name: 'variableSimulator',
    initialState,
    reducers: {
      reset: () => initialState,
      setLastSyncDate: (state, action: PayloadAction<string>) => {
        state.lastSyncDate = action.payload;
      },
    },
    extraReducers: builder => {
      builder.addCase(fetchVariableSimulator.pending, state => {
        state.fetchStatus = FETCH_STATUS.PENDING;
      });
      builder.addCase(fetchVariableSimulator.fulfilled, (state, action) => {
        const newState = { ...state };
        newState.fetchStatus = FETCH_STATUS.FULFILLED;
        newState.consultantInformation = action.payload.consultantInformation;

        newState.lastSyncDate =
          newState.consultantInformation.dataStatus ===
          EnumConsultantInformationDataStatus.CONSULTANT_FOUND
            ? moment().toISOString()
            : undefined;
        return newState;
      });
      builder.addCase(fetchVariableSimulator.rejected, state => {
        state.fetchStatus = FETCH_STATUS.REJECTED;
      });
    },
  }
);
