import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QueryKeys } from '../types';
import { CandidatesService, FetchRegionalTemporaryWorkersParams } from 'src/Services/API';

export const useFetchRegionalTemporaryWorkers = (
  params: Omit<FetchRegionalTemporaryWorkersParams, 'page' | 'brandCode' | 'agency'>
) => {
  const brandCode = useSelector(getCurrentBrandCode) ?? '';
  const agency = useSelector(getCurrentAgency) ?? '';

  return useInfiniteQuery(
    [QueryKeys.fetchRegionalTemporaryWorkers, brandCode, agency, params],
    async ({ pageParam = 0 }) => {
      return await CandidatesService.candidatesControllerFetchRegionalTemporaryWorkers({
        body: { brandCode, agency, page: pageParam, ...params },
      });
    },
    {
      getNextPageParam: page => page.nextPage,
    }
  );
};
