import { useMutation, useQueryClient } from '@tanstack/react-query';

import { MutationKeys } from '../types';
import { WorkAccidentAnalysisService } from 'src/Services/API';

export const useDeleteWorkAccidentFile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.deleteWorkAccidentFiles],
    async (params: { id: string }) => {
      const deletedWorkAccidentFile = await WorkAccidentAnalysisService.workAccidentAnalysisControllerDeleteWorkAccidentFiles(
        {
          id: params.id,
        }
      );
      if (deletedWorkAccidentFile.valueOf())
        queryClient.invalidateQueries({ queryKey: ['fetchWorkAccidentFiles'] });
      return deletedWorkAccidentFile;
    },
    {}
  );
};
