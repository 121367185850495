import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Props } from './RecrutLiveProfile.types';
import styles from './RecrutLiveProfile.module.scss';
import { Folder } from '@randstad-lean-mobile-factory/react-assets/dist/icons';

const RecrutLiveProfile = ({ candidateId, text }: Props) => {
  return (
    <div>
      <Button.Secondary
        className={styles.RLButton}
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_PUBLIC_RL_URL}/candidate/${candidateId}/file/synthese`
          );
        }}
        leftIcon={<Folder />}
        text={text}
      />
    </div>
  );
};

export default RecrutLiveProfile;
