import styles from './CDITopBarComponent.module.scss';
import Dot from 'src/Components/Dot';

const CDITopBarComponent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>légende</div>
      <div className={styles.content}>
        <Dot className={styles.inMissionDot} />
        en mission
        <Dot className={styles.outOfContractDot} />
        hors contrat
        <Dot className={styles.absentDot} />
        absent
      </div>
    </div>
  );
};
export default CDITopBarComponent;
