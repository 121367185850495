import {
  AgencyManagementActions,
  AgencyManagementActionsModalHeader,
  AgencyOrganizationActions,
  AgencyOrganizationActionsModalHeader,
  AgencyTechnicalActions,
  AgencyTechnicalActionsModalHeader,
  ClientManagementActions,
  ClientManagementActionsModalHeader,
  ClientOrganizationActions,
  ClientOrganizationActionsModalHeader,
  ClientTechnicalActions,
  ClientTechnicalActionsModalHeader,
} from 'src/Assets_DEPRECATED';
import {
  WhyAction,
  WhyActionActorsEnum,
  WhyActionCategoriesEnum,
  WhyActionDelayEnum,
  WhyActionsEnum,
} from 'src/Services/API';

export const WhyActionDelayTranslation = {
  [WhyActionDelayEnum.DONE]: 'réalisée',
  [WhyActionDelayEnum['1DAY']]: '1 jour',
  [WhyActionDelayEnum['3DAYS']]: '3 jours',
  [WhyActionDelayEnum['1WEEK']]: '1 semaine',
  [WhyActionDelayEnum['2WEEKS']]: '2 semaines',
  [WhyActionDelayEnum['1MONTH']]: '1 mois',
  [WhyActionDelayEnum['3MONTHS']]: '3 mois',
};

export const WhyActionsActorsTranslation = {
  [WhyActionActorsEnum.CLIENT]: 'client',
  [WhyActionActorsEnum.AGENCY]: 'agence',
};

export const WhyActionsCategoriesTranslation = {
  [WhyActionCategoriesEnum.ORGANIZATION]: 'organisation',
  [WhyActionCategoriesEnum.TECHNICAL]: 'technique (achat – maintenance)',
  [WhyActionCategoriesEnum.MANAGEMENT]: 'humain/management',
};

export const WhyActionTranslation = {
  [WhyActionsEnum.LIST_RISKY_POSITIONS]: 'élaboration ou modification liste des postes à risque',
  [WhyActionsEnum.SET_POSITION_BRIEF]: 'mise en place fiche de poste',
  [WhyActionsEnum.REVIEW_OPERATING_MODE]: 'révision / actualisation mode opératoire',
  [WhyActionsEnum.ADD_COLLECTIVE_EPI]:
    'équipement de protection collective (ex : carter sécurité, garde corps, ...)',
  [WhyActionsEnum.ADD_PERSONAL_EPI]: 'équipement de protection individuelle (adapté aux risques)',
  [WhyActionsEnum.FORMALIZE_ON_BOARDING]: 'accueil et formation au poste formalisés',
  [WhyActionsEnum.SET_TUTOR]: 'mise en place de tuteur ou référent',
  [WhyActionsEnum.REVIEW_HABILITATIONS]: 'autorisation de conduite / Habilitation électrique',
  [WhyActionsEnum.TRAIN_TO_SECURITY]: 'formation renforcée à la sécurité formalisée',
  [WhyActionsEnum.REMIND_SECURITY_INSTRUCTIONS]: 'consignes de sécurité / EPI à rappeler',
  [WhyActionsEnum.GET_PREVENTION_PROCESS]:
    'RV point prévention (Connaissance démarche prévention du client)',
  [WhyActionsEnum.REVIEW_POSITION_BRIEF]: 'réalisation ou Mise à jour de l’EDP',
  [WhyActionsEnum.GET_RISKY_POSITIONS_LIST]:
    'demande liste des postes à risques < à 1 an (à vérifier auprès des autres clients)',
  [WhyActionsEnum.FOLLOW_MEDICAL_MONITORING]: 'gestion du suivi médical des TT',
  [WhyActionsEnum.CHECK_OTHERS_SENSITIZATION]: 'vérification des sensibilisations des autres TT',
  [WhyActionsEnum.SET_QUITUS_EPI]: 'mise en place du Quitus EPI et enregistrement Osmose',
  [WhyActionsEnum.DIPLOMAS_AND_HABILITATIONS]: 'diplôme / CACES / Habilitations',
  [WhyActionsEnum.UPDATE_CONTRACTS_DATA]: 'mise à jour des données dans les contrats',
  [WhyActionsEnum.REMIND_OTHERS_ALERT_RIGHT]:
    'rappel sur le droit d’alerte et de retrait (aux TT exposés au même risque)',
  [WhyActionsEnum.RAISE_CANDIDATE_AWARENESS]: 'entretien et Sensibilisation du TT avant reprise',
  [WhyActionsEnum.SEND_SENSITIZATION_MESSAGES_TO_OTHERS]:
    'envoi SMS de sensibilisation aux TT exposés à la même situation',
  [WhyActionsEnum.SUBSTITUTE_CANDIDATE]: 'VM de reprise du TT si AT > à 30 jours',
};

export const whyActionsIcons = {
  [WhyActionActorsEnum.CLIENT]: {
    [WhyActionCategoriesEnum.ORGANIZATION]: <ClientOrganizationActions />,
    [WhyActionCategoriesEnum.TECHNICAL]: <ClientTechnicalActions />,
    [WhyActionCategoriesEnum.MANAGEMENT]: <ClientManagementActions />,
  },
  [WhyActionActorsEnum.AGENCY]: {
    [WhyActionCategoriesEnum.ORGANIZATION]: <AgencyOrganizationActions />,
    [WhyActionCategoriesEnum.TECHNICAL]: <AgencyTechnicalActions />,
    [WhyActionCategoriesEnum.MANAGEMENT]: <AgencyManagementActions />,
  },
};

export const whyActionsModalIcons = {
  [WhyActionActorsEnum.CLIENT]: {
    [WhyActionCategoriesEnum.ORGANIZATION]: <ClientOrganizationActionsModalHeader />,
    [WhyActionCategoriesEnum.TECHNICAL]: <ClientTechnicalActionsModalHeader />,
    [WhyActionCategoriesEnum.MANAGEMENT]: <ClientManagementActionsModalHeader />,
  },
  [WhyActionActorsEnum.AGENCY]: {
    [WhyActionCategoriesEnum.ORGANIZATION]: <AgencyOrganizationActionsModalHeader />,
    [WhyActionCategoriesEnum.TECHNICAL]: <AgencyTechnicalActionsModalHeader />,
    [WhyActionCategoriesEnum.MANAGEMENT]: <AgencyManagementActionsModalHeader />,
  },
};

export const whyActionsTree = {
  [WhyActionActorsEnum.CLIENT]: {
    [WhyActionCategoriesEnum.ORGANIZATION]: [
      WhyActionsEnum.LIST_RISKY_POSITIONS,
      WhyActionsEnum.SET_POSITION_BRIEF,
      WhyActionsEnum.REVIEW_OPERATING_MODE,
    ],
    [WhyActionCategoriesEnum.TECHNICAL]: [
      WhyActionsEnum.ADD_COLLECTIVE_EPI,
      WhyActionsEnum.ADD_PERSONAL_EPI,
    ],
    [WhyActionCategoriesEnum.MANAGEMENT]: [
      WhyActionsEnum.FORMALIZE_ON_BOARDING,
      WhyActionsEnum.SET_TUTOR,
      WhyActionsEnum.REVIEW_HABILITATIONS,
      WhyActionsEnum.TRAIN_TO_SECURITY,
      WhyActionsEnum.REMIND_SECURITY_INSTRUCTIONS,
    ],
  },
  [WhyActionActorsEnum.AGENCY]: {
    [WhyActionCategoriesEnum.ORGANIZATION]: [
      WhyActionsEnum.GET_PREVENTION_PROCESS,
      WhyActionsEnum.REVIEW_POSITION_BRIEF,
      WhyActionsEnum.GET_RISKY_POSITIONS_LIST,
      WhyActionsEnum.FOLLOW_MEDICAL_MONITORING,
      WhyActionsEnum.CHECK_OTHERS_SENSITIZATION,
    ],
    [WhyActionCategoriesEnum.TECHNICAL]: [
      WhyActionsEnum.SET_QUITUS_EPI,
      WhyActionsEnum.DIPLOMAS_AND_HABILITATIONS,
      WhyActionsEnum.UPDATE_CONTRACTS_DATA,
    ],
    [WhyActionCategoriesEnum.MANAGEMENT]: [
      WhyActionsEnum.REMIND_OTHERS_ALERT_RIGHT,
      WhyActionsEnum.RAISE_CANDIDATE_AWARENESS,
      WhyActionsEnum.SEND_SENSITIZATION_MESSAGES_TO_OTHERS,
      WhyActionsEnum.SUBSTITUTE_CANDIDATE,
    ],
  },
};

export interface UpdateActionParams {
  newAction?: WhyAction;
  actionKeyName?: string;
}
