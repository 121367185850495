import { ModalDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import QRCode from 'qrcode.react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getContactDeepLink, getImage, getLabel } from 'src/Services/Actions';
import { ContactLocationState } from 'src/Services/Routing';
import { Colors } from 'src/Utils/constants';
import styles from './ContactModal.module.scss';

const ContactModal = () => {
  const navigate = useNavigate();
  const modalParameters = (useLocation().state as ContactLocationState | undefined)
    ?.modalParameters;
  const Icon = modalParameters && getImage(modalParameters.type);

  return (
    <ModalDeprecated
      onClose={() => navigate(-1)}
      key={'key'}
      nested
      open
      icon={Icon ? <Icon /> : undefined}
      title={
        <p>{`contact avec ${modalParameters?.contactName} par ${
          modalParameters && getLabel(modalParameters.type)
        }`}</p>
      }
    >
      <div className={styles.content}>
        {modalParameters?.contactInfo.map((elem, idx) => (
          <div style={{ display: 'flex' }}>
            {idx > 0 && <div style={{ width: '2rem' }} />}
            <div className={styles.infoContainer}>
              <div className={styles.qrcodeContainer}>
                <QRCode
                  value={getContactDeepLink(modalParameters.type, elem.info)}
                  fgColor={Colors.white}
                  bgColor={Colors.darkBlue}
                />
              </div>
              <p className={styles.infoType}>{elem.infoType}</p>
              <a href={getContactDeepLink(modalParameters.type, elem.info)} className={styles.info}>
                {elem.info}
              </a>
            </div>
          </div>
        ))}
      </div>
    </ModalDeprecated>
  );
};

export default ContactModal;
