import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';

import { Props } from './SelectService.types';

const SelectService = ({
  services,
  selectedService,
  fetchStatus,
  searchServices,
  setSelectedService,
  disabled,
}: Props) => (
  <ComboBox
    id="SelectService"
    value={selectedService ?? null}
    onSearch={searchServices}
    searchResults={services}
    keyValueExtractor={({ serviceId, name }) => ({
      key: serviceId ?? 'unknown',
      value: name!,
    })}
    fetchStatus={fetchStatus}
    minLengthToSearch={0}
    disabled={disabled}
    onChange={searchResult => setSelectedService(searchResult ?? undefined)}
  />
);

export default SelectService;
