import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';
import { ContractsState } from './Types';
import {
  CandidateWithMoment,
  ContactWithMoment,
  ContactingInfoWithMoment,
  WithMoment,
} from 'src/Redux/Types';
import { convertPropertyToMoment } from 'src/Utils/moment';
import { ContractDetail } from 'src/Services/API';

const getContractsState = (state: RootState) => state.contracts;

export const getContracts = createSelector(
  [getContractsState],
  (state: ContractsState) => state.contracts
);

export const getRawCurrentContract = createSelector(
  [getContractsState],
  (state: ContractsState): ContractsState['currentContract'] | null => state.currentContract
);

export const getCurrentContract = createSelector(
  [getContractsState],
  (state: ContractsState): ContractDetailWithMoment | null =>
    state.currentContract !== null
      ? {
          ...convertPropertyToMoment(
            state.currentContract,
            'effectiveStartDate',
            'effectiveEndDate'
          ),
          candidate: state.currentContract.candidate && {
            ...state.currentContract.candidate,
            lastContact:
              state.currentContract.candidate.lastContact !== undefined
                ? convertPropertyToMoment(state.currentContract.candidate.lastContact, 'at')
                : undefined,
          },
          contact: {
            ...state.currentContract.contact,
            lastContact:
              state.currentContract.contact.lastContact &&
              convertPropertyToMoment(state.currentContract.contact.lastContact, 'at'),
          },
          lastClientContact:
            state.currentContract.lastClientContact &&
            convertPropertyToMoment(state.currentContract.lastClientContact, 'at'),
        }
      : null
);

export const getCanLoadMore = createSelector(
  [getContractsState],
  (state: ContractsState) => state.canLoadMore
);

export const getContractsFetchStatus = createSelector(
  [getContractsState],
  (state: ContractsState) => state.contractsFetchStatus
);

export const getCurrentContractFetchStatus = createSelector(
  [getContractsState],
  (state: ContractsState) => state.currentContractFetchStatus
);

export interface ContractDetailWithMoment
  extends Omit<
    WithMoment<ContractDetail, 'effectiveStartDate' | 'effectiveEndDate'>,
    'candidate' | 'contact' | 'lastClientContact'
  > {
  candidate?: CandidateWithMoment;
  contact?: ContactWithMoment;
  lastClientContact?: ContactingInfoWithMoment;
}
