import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';
import { WithMoment } from 'src/Redux/Types';
import { MissionDetail } from 'src/Services/API';
import { CandidateWithMoment, ContactingInfoWithMoment, ContactWithMoment } from '../Types';
import { MissionsState } from './Types';

const getMissionsState = (state: RootState) => state.missions;

export const getMissions = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.missions
);

export const getCurrentMissionId = createSelector(
  [getMissionsState],
  (state: MissionsState): string => state.currentMissionId
);

export const getCanLoadMore = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.canLoadMore
);

export const getMissionsFetchStatus = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.missionsFetchStatus
);

export const getMissionSortBy = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.sortBy
);

export const getMissionSortOrder = createSelector(
  [getMissionsState],
  (state: MissionsState) => state.sortOrder
);

export interface MissionDetailWithMoment
  extends Omit<
    WithMoment<MissionDetail, 'startDate' | 'endDate'>,
    'candidates' | 'contact' | 'lastClientContact'
  > {
  candidates?: CandidateWithMoment[];
  contact: ContactWithMoment;
  lastClientContact?: ContactingInfoWithMoment;
}
