import {
  Button,
  DatePickerInput,
  DropDown,
  FetchButton,
  ModalDeprecated,
  PopupActions,
  TextArea,
  ToggleSwitch,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { TickCircleGreen } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import {
  whyActionsModalIcons,
  WhyActionTranslation,
} from 'src/Routes/Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Actions/Actions.types';
import { useFetchAgencyConsultants } from 'src/Hooks/Agencies/useFetchAgencyConsultants';
import { useFetchCompanyContacts } from 'src/Hooks/Companies/useFetchCompanyContacts';
import { useCloseWorkAccidentActionPlan } from 'src/Hooks/WorkAccidentAnalysis/useCloseWorkAccidentActionPlan';
import { useFetchWorkAccidentActionPlanById } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentActionPlanById';
import { useUpdateWorkAccidentAction } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentAction';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import {
  CompanyContact,
  WhyAction,
  WhyActionActorsEnum,
  WhyActionCategoriesEnum,
  WhyActionsStatusEnum,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import styles from './ActionPlansListModal.module.scss';
import { Props } from './ActionPlansListModal.types';

const ActionPlansListModal = ({ actionId, workAccidentId, onClose, isEditable }: Props) => {
  const innerRef = useRef<PopupActions>(null);
  const brandCode = useSelector(getCurrentBrandCode);

  const updateWorkAccidentActionMutation = useUpdateWorkAccidentAction();
  const updateWorkAccidentActionMutationFetchStatus = toFetchStatus(
    updateWorkAccidentActionMutation
  );
  const fetchWorkAccidentActionPlan = useFetchWorkAccidentActionPlanById({
    workAccidentId: workAccidentId ?? '',
  });
  const fetchWorkAccidentActionPlanFetchStatus = toFetchStatus(fetchWorkAccidentActionPlan);
  const closeWorkAccidentActionPlanMutation = useCloseWorkAccidentActionPlan();
  const fetchAgencyConsultants = useFetchAgencyConsultants(
    fetchWorkAccidentActionPlan.data?.agencyId ?? '',
    brandCode
  );
  const fetchCompanyContacts = useFetchCompanyContacts(
    fetchWorkAccidentActionPlan.data?.companyId ?? ''
  );

  const initialAction = useMemo(() => {
    return fetchWorkAccidentActionPlan.data?.whyActions?.find(
      action => action.osmoseActionId === actionId
    );
  }, [fetchWorkAccidentActionPlan, actionId]);

  useEffect(() => {
    if (fetchWorkAccidentActionPlanFetchStatus === FETCH_STATUS.FULFILLED) {
      if (initialAction) {
        setAction(initialAction);
        setIsFinalised(initialAction.status === WhyActionsStatusEnum.DONE);
      }
    }
  }, [
    actionId,
    fetchWorkAccidentActionPlan.data?.whyActions,
    fetchWorkAccidentActionPlanFetchStatus,
    initialAction,
  ]);

  const [action, setAction] = useState<WhyAction>({} as WhyAction);
  const [isFinalised, setIsFinalised] = useState(false);
  const agencyConsultantsContacts = useMemo(
    () =>
      fetchAgencyConsultants.data?.map(
        consultant =>
          ({
            contactId: consultant.id,
            firstName: consultant.firstName,
            name: consultant.name,
          } as CompanyContact)
      ),
    [fetchAgencyConsultants]
  );
  const possibleActors = (
    (action.actorType === WhyActionActorsEnum.CLIENT
      ? fetchCompanyContacts.data
      : agencyConsultantsContacts) ?? []
  ).map(contact => `${contact.firstName} ${contact.name} `);

  const resetValues = () => {
    setAction(initialAction ?? ({} as WhyAction));
    setIsFinalised(initialAction?.status === WhyActionsStatusEnum.DONE);
  };

  const handleChangeActor = useCallback(
    ({ newActorNameValue }: { newActorNameValue: string }) => {
      const actors =
        (action.actorType === WhyActionActorsEnum.CLIENT
          ? fetchCompanyContacts.data
          : agencyConsultantsContacts) ?? [];
      const contact = actors.find(
        contact => `${contact.firstName} ${contact.name} ` === newActorNameValue
      );

      setAction({
        ...action,
        actorName: `${contact?.firstName} ${contact?.name} `,
        actor: contact?.contactId ?? '',
      });
    },
    [action, agencyConsultantsContacts, fetchCompanyContacts.data]
  );

  return (
    <ModalDeprecated
      key={'key'}
      icon={
        fetchWorkAccidentActionPlanFetchStatus === FETCH_STATUS.FULFILLED &&
        whyActionsModalIcons[initialAction?.actorType as WhyActionActorsEnum][
          initialAction?.category as WhyActionCategoriesEnum
        ]
      }
      nested
      open
      className={styles.modalContainer}
      title={
        fetchWorkAccidentActionPlanFetchStatus === FETCH_STATUS.FULFILLED &&
        `détails ${WhyActionTranslation[action.name]}`
      }
      ref={innerRef}
      onClose={() => {
        onClose?.();
      }}
      children={
        <>
          {fetchWorkAccidentActionPlanFetchStatus === FETCH_STATUS.PENDING && (
            <ContentLoader className={styles.contentLoader} />
          )}
          {fetchWorkAccidentActionPlanFetchStatus === FETCH_STATUS.FULFILLED && action && (
            <>
              <WithLightTitle
                title="précisez"
                className={styles.lightTitle}
                rightTitleComponent={
                  <>
                    {isEditable && (
                      <div className={styles.numberOfChar}>
                        {(action.description ?? '').length}/{REPORT_MAX_LENGTH} caractères
                      </div>
                    )}
                  </>
                }
                children={
                  <div className={styles.itemBox}>
                    {isEditable && (
                      <TextArea
                        value={action.description}
                        className={styles.textArea}
                        placeholder={'en quelques mots…'}
                        onChange={(event: any) =>
                          setAction({
                            ...action,
                            description: (event.target as HTMLTextAreaElement).value,
                          })
                        }
                      />
                    )}
                    {!isEditable && <div className={styles.textArea}>{action.description}</div>}

                    <div className={styles.itemRow}>
                      <WithLightTitle
                        title="par qui ?"
                        children={
                          <>
                            {isEditable && (
                              <DropDown
                                className={styles.namePicker}
                                childrenClassName={styles.dropdownChildren}
                                items={possibleActors}
                                placeholder="choisir l'interlocuteur…"
                                canBeReset={false}
                                keyValueExtractor={(searchResult: string) => ({
                                  key: searchResult,
                                  value: searchResult,
                                })}
                                selectedItem={action.actorName}
                                onSelectItem={(value?: string) => {
                                  if (value) handleChangeActor({ newActorNameValue: value });
                                }}
                              />
                            )}
                            {!isEditable && (
                              <div className={styles.namePicker}> {action.actorName}</div>
                            )}
                          </>
                        }
                      />
                      <WithLightTitle
                        title="date"
                        children={
                          <>
                            {isEditable && (
                              <DatePickerInput
                                className={styles.datePicker}
                                minDate={moment().toDate()}
                                date={
                                  moment(action.realisationDate).toDate() ?? action?.realisationDate
                                }
                                onSelectDate={date =>
                                  setAction({
                                    ...action,
                                    realisationDate: date ?? action?.realisationDate,
                                  })
                                }
                              />
                            )}
                            {!isEditable && (
                              <div className={styles.datePicker}>
                                {moment(action.realisationDate).format('DD/MM/YYYY')}
                              </div>
                            )}
                          </>
                        }
                      />
                    </div>
                    <div className={styles.itemRow}>
                      {action?.status === WhyActionsStatusEnum.IN_PROGRESS && (
                        <>
                          <ToggleSwitch
                            checked={isFinalised}
                            onCheckStatusChange={() => setIsFinalised(prevState => !prevState)}
                          />
                          <p>l'action est finalisée</p>
                        </>
                      )}
                      {action?.status === WhyActionsStatusEnum.DONE && (
                        <>
                          <TickCircleGreen />
                          <p>l'action est clôturée</p>
                        </>
                      )}
                    </div>
                  </div>
                }
              />
            </>
          )}
        </>
      }
      footerActionsLeft={[
        <>
          {isEditable && (
            <Button.Tertiary
              onClick={() => {
                resetValues();
                setIsFinalised(false);
              }}
            >
              tout réinitialiser
            </Button.Tertiary>
          )}
        </>,
      ]}
      footerActionsRight={[
        <>
          {isEditable && (
            <Button.Secondary
              onClick={() => {
                onClose?.();
              }}
            >
              annuler
            </Button.Secondary>
          )}
        </>,
        <>
          {!isEditable && (
            <Button.Primary
              onClick={() => {
                onClose?.();
              }}
            >
              fermer
            </Button.Primary>
          )}
        </>,
        <>
          {isEditable && (
            <FetchButton
              title="valider"
              fetchStatus={updateWorkAccidentActionMutationFetchStatus}
              onSuccess={() => {
                if (!(isFinalised && action?.status !== WhyActionsStatusEnum.DONE)) {
                  onClose?.();
                }
              }}
              onClick={() => {
                updateWorkAccidentActionMutation.mutate(
                  {
                    osmoseActionId: action.osmoseActionId ?? '',
                    realisationDate: moment(action.realisationDate).toDate(),
                    actor: action.actor,
                    actorName: action.actorName,
                    description: action.description,
                    workAccidentId: workAccidentId ?? '',
                    companyId:
                      action.actorType !== WhyActionActorsEnum.CLIENT
                        ? fetchWorkAccidentActionPlan.data?.companyId
                        : undefined,
                  },
                  {
                    onSuccess: () => {
                      if (isFinalised && action?.status !== WhyActionsStatusEnum.DONE) {
                        closeWorkAccidentActionPlanMutation.mutate(
                          {
                            actionId: action.osmoseActionId ?? '',
                            workAccidentId,
                          },
                          {
                            onError: () => {
                              setIsFinalised(false);
                            },
                            onSuccess: () => {
                              onClose?.();
                            },
                          }
                        );
                      }
                    },
                  }
                );
              }}
            />
          )}
        </>,
      ]}
    />
  );
};

export default ActionPlansListModal;
