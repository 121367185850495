import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { CandidatesService, ChangeRepoStatusParams } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

export const useChangeManuallyCandidateRepoStatus = (
  extraOptions?: UseMutationOptions<void, unknown, ChangeRepoStatusParams, MutationKeys[]>
) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MutationKeys.changeManuallyRepoStatus],
    async (params: ChangeRepoStatusParams) => {
      return await CandidatesService.candidatesControllerChangeManuallyRepoStatus({
        body: params,
      });
    },
    {
      ...extraOptions,
      onSuccess: () => {
        queryClient.resetQueries({ queryKey: [QueryKeys.fetchCandidatesActivityPlanning] });
        queryClient.resetQueries({ queryKey: [QueryKeys.fetchCandidateActivityPlanning] });
      },
    }
  );
};
