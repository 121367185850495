import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { MissionsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchMissionDetails = ({ missionId }: { missionId?: string }) => {
  const brandCode = useSelector(getCurrentBrandCode)!;

  return useQuery(
    [QueryKeys.fetchMissionDetails, missionId],

    () => {
      return MissionsService.missionsControllerGetMission({ id: missionId!, brandCode });
    },
    { enabled: missionId !== '' && missionId !== undefined, refetchOnWindowFocus: false }
  );
};
