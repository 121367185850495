import { departments } from './departments';

export const formatDepartments = (codes?: string[]) => {
  if (codes !== undefined) {
    return codes
      .map(code => `${departments[code as keyof typeof departments]} (${code})`)
      .join(', ');
  } else {
    return null;
  }
};
