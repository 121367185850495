import React from 'react';
import { AuthProvider } from './Auth';
import { ReactQueryProvider } from './ReactQuery';
import { ReduxProvider } from './Redux';

const Provider = ({ children }: { children: React.ReactElement }) => (
  <AuthProvider>
    <ReactQueryProvider>
      <ReduxProvider>{children}</ReduxProvider>
    </ReactQueryProvider>
  </AuthProvider>
);

export default Provider;
