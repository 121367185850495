import {
  Button,
  Checkbox,
  FetchButton,
  ModalDeprecated,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LoadingCard } from 'src/Components/Card';
import { useFetchRelatedCGC } from 'src/Hooks/Agencies/useFetchRelatedCGC';
import { useChangeManuallyCandidateRepoStatus } from 'src/Hooks/Candidate/useChangeManuallyCandidateRepoStatus';
import { useFetchMissionsDetails } from 'src/Hooks/Missions/useFetchMissionsDetails';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { MissionDetail } from 'src/Services/API';
import { AttachSuggestedCandidateState } from 'src/Services/Routing';
import { mergeFetchStatus, toFetchStatus } from 'src/Utils/fetch-status';
import styles from './AttachSuggestedCandidateModal.module.scss';

const AttachSuggestedCandidateModal = () => {
  const navigate = useNavigate();
  const modalParameters = (useLocation().state as AttachSuggestedCandidateState).modalParameters;
  const { data: isCGC } = useIsCGC();
  const { data: missions, isSuccess, isLoading, isError } = useFetchMissionsDetails({
    missionIds: modalParameters?.missionIds,
  });
  const fetchStatus = mergeFetchStatus(toFetchStatus({ isError, isLoading, isSuccess }));
  const [selectedMissions, setSelectedMissions] = useState<MissionDetail[]>([]);
  const relatedCGCQuery = useFetchRelatedCGC();
  const currentAgency = useSelector(getCurrentAgency);
  const changeManuallyCandidateRepoStatusMutation = useChangeManuallyCandidateRepoStatus();
  const changeManuallyCandidateRepoStatusMutationFetchStatus = toFetchStatus(
    changeManuallyCandidateRepoStatusMutation
  );

  return (
    <ModalDeprecated
      key="attachCandidateModal"
      nested
      title="Mission(s) suggérée(s) à passer en rattachée(s)"
      open
      onClose={() => navigate(-1)}
      footerActionsRight={[
        <Button.Secondary
          key="attachedSuggestedModalcancelButton"
          onClick={() => {
            navigate(-1);
          }}
        >
          annuler
        </Button.Secondary>,
        <FetchButton
          key="attachedSuggestedModalvalidateButton"
          title="valider"
          onClick={() =>
            changeManuallyCandidateRepoStatusMutation.mutate({
              candidateId: modalParameters?.candidate.id ?? '',
              firstName: modalParameters?.candidate.firstName ?? '',
              lastName: modalParameters?.candidate.name,
              cgcId: isCGC ? currentAgency ?? '' : relatedCGCQuery.data?.id ?? '',
              comment: '',
              agencyId: currentAgency ?? '',
              suggestedMissionsIds: modalParameters?.candidate.suggestedMissionIds ?? [],
              suggestedMissionsToAttach: selectedMissions.map(mission => {
                return mission.id ?? '';
              }),
              oldStatus: 'suggéré',
              newStatus: 'en traitement',
            })
          }
          fetchStatus={changeManuallyCandidateRepoStatusMutationFetchStatus}
        />,
      ]}
    >
      {fetchStatus === FETCH_STATUS.PENDING ? (
        <div className={styles.loadingContainer}>
          {Array.from({ length: 2 }).map((_, idx) => (
            <div className={styles.loadingCard} key={`${idx} repositioningCard`}>
              <LoadingCard />
            </div>
          ))}
        </div>
      ) : fetchStatus === FETCH_STATUS.FULFILLED ? (
        <div key="suggestedMissionsTab">
          {missions?.map(mission => {
            const selectedMissionIds = selectedMissions.map(missionItem => missionItem.id);
            const isSelected = selectedMissionIds.includes(mission?.id ?? '');
            return (
              <div
                className={classnames(styles.container, {
                  [styles.isSelected]: isSelected,
                })}
              >
                <Checkbox
                  checked={isSelected}
                  onClick={() => {
                    isSelected
                      ? setSelectedMissions(
                          selectedMissions.filter(missionItem => mission.id !== missionItem.id)
                        )
                      : setSelectedMissions([...selectedMissions, mission]);
                  }}
                />

                <div className={styles.infoContainer}>
                  <p className={styles.companyName}>{mission.company?.name}</p>
                  <p className={styles.otherInformation}>{`${mission.qualification?.label} ${
                    mission.company?.location?.cityName || mission.company?.location?.zipCode
                      ? `| ${mission.company?.location?.cityName} ${mission.company?.location?.zipCode}`
                      : ''
                  }`}</p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <div className={styles.noMission}>Il n'y a aucune mission suggérée</div>
        </div>
      )}
    </ModalDeprecated>
  );
};

export default AttachSuggestedCandidateModal;
