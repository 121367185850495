import { Button, DataTable, Pagination } from '@randstad-lean-mobile-factory/react-components-core';
import {
  CheckmarkCircleOutline,
  DuplicataLine,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ColumnDef, ColumnFiltersState, PaginationState, Row, Table } from '@tanstack/react-table';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useUpdateExternalDemands } from 'src/Hooks/ExternalDemands/useUpdateExternalDemands';
import { ExternalDemandListActions } from 'src/Redux/ExternalDemandList/Slice';
import { ROUTES } from 'src/Routes/Routes.types';
import { DisplayStatus, ExternalDemandDto } from 'src/Services/API';
import ExternalDemandsListMenu from '../ExternalDemandsListMenu';
import styles from './ExternalDemandsList.module.scss';
import {
  ExternalDemandElement,
  isDemandDto,
  isDemandDtoWithChildren,
  isDemandItem,
  Props,
} from './ExternalDemandsList.types';

const ExternalDemandsList = ({ data, searchInput, isFetching, source }: Props) => {
  const showArchivedDemands = !!useMatch(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.ARCHIVED.path);

  const { mutate: updateExternalDemands, isLoading } = useUpdateExternalDemands();
  const dispatch = useDispatch();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const tableRef = useRef<Table<ExternalDemandElement>>(null);

  const getSubRows = useCallback((element: ExternalDemandElement) => {
    return isDemandDtoWithChildren(element)
      ? element.items.map(item => ({ ...item, parent: element }))
      : undefined;
  }, []);

  const isRowSelectable = useCallback((row: Row<ExternalDemandElement>) => row.depth === 0, []);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: 50, pageIndex: 0 });

  const hubloColumns = useMemo(
    (): ColumnDef<ExternalDemandElement>[] => [
      {
        id: 'missionDate',
        header: 'date mission',
        accessorFn: element => {
          let item: Date | number | string | undefined = element.dateMission;
          if (typeof item === 'number' || typeof item === 'string') item = new Date(item);
          return item?.valueOf();
        },
        cell: ({ row, getValue }) => {
          const date = getValue() as number | undefined;
          return (
            <>
              {date !== undefined && moment(date).format('DD/MM/YYYY')}
              {row.depth === 0 && (
                <>
                  <br />
                  {(row.original as ExternalDemandDto).items.length} roulements
                </>
              )}
            </>
          );
        },
        meta: {
          isDate: true,
        },
        filterFn: 'inNumberRange',
      },
      {
        id: 'clientName',
        header: 'nom client',
        accessorFn: element =>
          isDemandDto(element) ? element.clientName : element.parent.clientName,
        filterFn: 'arrIncludes',
      },
      {
        id: 'service',
        header: 'service',
        accessorFn: element => (isDemandDto(element) ? element.service : element.parent.service),
        filterFn: 'arrIncludes',
      },
      {
        id: 'qualification',
        header: 'qualifi\u00adcation',
        accessorFn: element =>
          isDemandDto(element) ? element.qualification : element.parent.qualification,
        filterFn: 'arrIncludes',
      },
      {
        id: 'numOsmose',
        header: 'num osmose',
        accessorFn: element =>
          element.osmoseId ?? (isDemandItem(element) ? element.parent.osmoseId : undefined),
        cell: ({ getValue }) => {
          const osmoseId = getValue() as string | undefined;
          return (
            osmoseId && (
              <Button.Tertiary.XSmall
                data-full-width
                data-full-height
                onClick={event => {
                  event.stopPropagation();
                  navigator.clipboard.writeText(osmoseId);
                }}
                className={styles.copyButton}
                text={osmoseId}
                rightIcon={<DuplicataLine />}
              />
            )
          );
        },
        filterFn: 'arrIncludes',
      },
      {
        id: 'numSource',
        header: `num ${source.toLowerCase()}`,
        accessorFn: element =>
          isDemandItem(element)
            ? element.id
            : element.items.length === 1
            ? element.items[0].id
            : undefined,
        cell: ({ getValue }) => {
          const numSource = getValue() as string | undefined;
          return (
            numSource && (
              <Button.Tertiary.XSmall
                data-full-width
                data-full-height
                onClick={event => {
                  event.stopPropagation();
                  navigator.clipboard.writeText(numSource);
                }}
                className={styles.copyButton}
                text={numSource}
                rightIcon={<DuplicataLine />}
              />
            )
          );
        },
        filterFn: 'arrIncludes',
      },
      {
        id: 'endDate',
        header: 'date limite',
        accessorFn: element => {
          let item: Date | number | string | undefined = isDemandItem(element)
            ? element.limitDate
            : element.items.length === 1
            ? element.items[0].limitDate
            : undefined;
          if (typeof item === 'number' || typeof item === 'string') item = new Date(item);
          return item?.valueOf();
        },
        cell: ({ getValue }) => {
          const date = getValue() as number | undefined;
          return date !== undefined && moment(date).format('DD/MM/YYYY');
        },
        sortUndefined: false,
        meta: {
          isDate: true,
        },
        filterFn: 'inNumberRange',
      },
      {
        id: 'action',
        header: () => <div style={{ textAlign: 'center' }}>Actions</div>,
        cell: ({ row }) => {
          return (
            <ExternalDemandsListMenu
              data-no-padding-left
              element={row.original}
              showArchivedDemands={showArchivedDemands}
            />
          );
        },
        size: 184,
      },
    ],
    [showArchivedDemands, source]
  );

  useEffect(() => tableRef.current?.setGlobalFilter(searchInput), [searchInput]);
  useEffect(() => {
    dispatch(
      ExternalDemandListActions.setFilteredColumns(
        (tableRef.current?.getRowModel().rows ?? [])
          .filter(row => row.depth === 0)
          .map(row => row.original.osmoseId ?? '') ?? []
      )
    );
  }, [dispatch, columnFilters]);

  return (
    <div className={styles.container}>
      <div className={styles.actionRow}>
        {!showArchivedDemands && (
          <Button.Primary.XSmall
            className={styles.demandsButtonContainer}
            onClick={() => {
              const commandStatusToUpdate =
                tableRef.current
                  ?.getSelectedRowModel()
                  .rows.map(row =>
                    isDemandDto(row.original)
                      ? { commandId: row.original.commandId, displayStatus: DisplayStatus.archived }
                      : undefined
                  )
                  .filter(Boolean) ?? [];
              if (!commandStatusToUpdate.length) return;

              updateExternalDemands(
                { commandStatusToUpdate },
                {
                  onSuccess: () => {
                    tableRef.current?.toggleAllRowsSelected(false);
                  },
                }
              );
            }}
            disabled={Object.keys(rowSelection).length === 0 || isFetching || isLoading}
            leftIcon={<CheckmarkCircleOutline />}
            text="archiver"
          />
        )}
        <div className={styles.spacer} />
        <Pagination
          pageCount={tableRef.current?.getPageCount() ?? 1}
          currentPage={pagination.pageIndex}
          setCurrentPage={page => tableRef.current?.setPageIndex(page)}
        />
      </div>
      <DataTable<ExternalDemandElement, unknown>
        size="medium"
        columns={hubloColumns}
        data={data}
        enableExpanding
        enableRowSelection={!showArchivedDemands && isRowSelectable}
        state={{ rowSelection, columnFilters }}
        onRowSelectionChange={setRowSelection}
        onColumnFiltersChange={setColumnFilters}
        getSubRows={getSubRows}
        stickyHeader
        disableMultiExpansion
        enablePagination
        onPaginationChange={setPagination}
        initialState={{ pagination }}
        enableAlternateBackgrounds
        filterFromLeafRows
        enableMultiSort={false}
        empty="aucun résultat ne correspond à votre recherche"
        ref={tableRef}
      />
    </div>
  );
};
export default ExternalDemandsList;
