import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../types';
import { AgencyDetails, CandidatesService } from 'src/Services/API';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { isInternEmails } from 'src/Utils/emails';

export interface suggestCandidateToAgenciesParams {
  suggestedAgencies: AgencyDetails[];
  cc: string[];
  cgcId: string;
  candidateFullName: string;
  candidateMainQualification: string;
  comment: string;
  lastContractEndDate: string;
  lastMedicalVisit: string;
}
export const useSuggestCandidateToAgencies = (
  extraOptions?: UseMutationOptions<void, unknown, suggestCandidateToAgenciesParams, MutationKeys[]>
) => {
  const queryContext = useQueryClient();

  return useMutation(
    [MutationKeys.suggestCandidateToAgencies],
    async ({
      candidateId,
      cc,
      suggestedAgencies,
      cgcId,
      candidateFullName,
      candidateMainQualification,
      comment,
      lastContractEndDate,
      lastMedicalVisit,
    }: suggestCandidateToAgenciesParams & { candidateId: string }) => {
      if (cc[0] !== '' && !isInternEmails(cc)) {
        throw new Error('Invalid CC email address');
      }
      return await CandidatesService.candidatesControllerSuggestCandidateToAgencies({
        id: candidateId,
        body: {
          suggestedAgencies,
          cc,
          cgcId,
          candidateFullName,
          candidateMainQualification,
          comment,
          lastContractEndDate,
          lastMedicalVisit,
        },
      });
    },
    {
      onSuccess: () => {
        queryContext.invalidateQueries({ queryKey: [QueryKeys.fetchCandidatesActivityPlanning] });
        queryContext.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateActivityPlanning] });
      },
      onError: (error: any, ...args) => {
        if (error.message === 'Invalid CC email address') {
          toast.error('Certains mails en cc ne sont pas valides');
          return;
        }

        toast.error('une erreur est survenue');
      },
      ...extraOptions,
    }
  );
};
