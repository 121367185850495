import styles from './../Context.module.scss';
import { ExclusiveSelectionGroup } from '@randstad-lean-mobile-factory/react-form-fields';
import { useEffect } from 'react';
import { TextInput, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { FirstAidPersonType } from 'src/Services/API';
import { FirstAidProps } from '../Context.types';
import { useController } from 'react-hook-form';

const personTypeValues = [
  { key: FirstAidPersonType.FIRST_AID_RESCUE_WORKER, label: 'SST' },
  { key: FirstAidPersonType.NURSE, label: 'infirmier(e)' },
  { key: FirstAidPersonType.EMERGENCY_SERVICES, label: 'SAMU' },
  { key: FirstAidPersonType.FIREFIGHTER, label: 'pompier' },
  { key: FirstAidPersonType.OTHER, label: 'autre' },
];

const FirstAid = ({ isEditable, control, handleSubmit }: FirstAidProps) => {
  const firstAidPersonType = useController({ control, name: 'firstAidPersonType' });
  const otherFirstAidPerson = useController({ control, name: 'otherFirstAidPerson' });

  useEffect(() => {
    window.addEventListener('beforeunload', handleSubmit);
    return () => {
      window.removeEventListener('beforeunload', handleSubmit);
    };
  }, [handleSubmit]);

  return (
    <div>
      <div className={styles.subTitle}>premiers secours, prise en charge de la victime</div>
      <div className={styles.firstHelp}>
        <WithLightTitle
          title="par quel type de personne ?"
          className={styles.lightTitle}
          children={
            isEditable ? (
              <ExclusiveSelectionGroup
                name="firstAidPersonType"
                className={styles.multipleChoices}
                getSelectedFormValue={value => value?.key}
                getKey={({ key }) => key}
                getStringValue={({ label }) => label}
                values={personTypeValues}
                control={control}
                getSelectedValue={value => personTypeValues.find(({ key }) => key === value)}
              />
            ) : (
              <div>
                {personTypeValues.find(value => value.key === firstAidPersonType.field.value)
                  ?.label ?? 'non renseigné'}
              </div>
            )
          }
        />

        {firstAidPersonType.field.value === FirstAidPersonType.OTHER && (
          <WithLightTitle
            title="autre type de personne"
            className={styles.lightTitle}
            children={
              isEditable ? (
                <div onBlur={handleSubmit()}>
                  <TextInput
                    type="text"
                    value={otherFirstAidPerson.field.value}
                    onChange={(event: any) =>
                      otherFirstAidPerson.field.onChange(
                        (event.target as HTMLTextAreaElement).value
                      )
                    }
                  />
                </div>
              ) : (
                <div>{otherFirstAidPerson.field.value ?? 'non renseigné'}</div>
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default FirstAid;
