import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import useInitializeAnalytics from 'src/Hooks/Analytics/useInitializeAnalytics';
import useAuth from 'src/Hooks/Authentication/useAuth';
import { Root } from 'src/Routes';
import { setupAPI } from 'src/Services/API/APIConfigurator';
import { security } from 'src/Services/API/Interceptors/security';

const App = () => {
  const { getAccessTokenSilently, getIdTokenClaims, logout } = useAuth();
  security.setIdTokenClaims(getIdTokenClaims);
  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setLogout(logout);
  useInitializeAnalytics();
  useEffect(() => setupAPI(), []);
  return (
    <div className="App">
      <BrowserRouter>
        <Root />
      </BrowserRouter>
    </div>
  );
};

export default App;
