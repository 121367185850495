import { useInfiniteQuery } from '@tanstack/react-query';
import { QueryKeys } from '../types';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { EnumSearchMissionsBodySortBy, YouplanMissionsService } from 'src/Services/API';
import { SEARCH_LIMIT } from 'src/Redux/Search/Types';

export const useFetchYouplanMissions = ({ companyId }: { companyId?: string }) => {
  const agencyId = useSelector(getCurrentAgency) ?? '';
  const brandCode = useSelector(getCurrentBrand) ?? '';
  return useInfiniteQuery(
    [QueryKeys.fetchYouplanMissions, companyId],
    ({ pageParam: offset = 0 }) => {
      if (!brandCode || !brandCode.brandCodeApiHeader || !agencyId) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return YouplanMissionsService.youplanMissionsControllerGetYouplanMissions({
        agencyId,
        companyId: companyId!,
        sortBy: EnumSearchMissionsBodySortBy['date de début'],
        limit: SEARCH_LIMIT,
        offset,
        brandCode: brandCode.brandCodeApiHeader,
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.length === SEARCH_LIMIT ? pages.length * SEARCH_LIMIT : undefined;
      },

      enabled: companyId !== undefined,
    }
  );
};
