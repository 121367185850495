import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServiceState } from './Types';
import { ThunkAPI } from 'src/Redux/RootReducer';
import { CompaniesService } from 'src/Services/API';

export interface FetchServicesParams {
  id: string;
  brandCode: string;
}

export const fetchServices = createAsyncThunk<
  ServiceState['services'],
  FetchServicesParams,
  ThunkAPI
>('services/fetch', async params => {
  return CompaniesService.companiesControllerGetServices(params);
});
