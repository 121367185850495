import {
  BigClock,
  BigGroup,
  BigPencil,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import styles from './TalentStatistics.module.scss';
import { Props } from './TalentStatistics.types';
import { pluralFormat } from 'src/Utils/pluralFormat';

export const TalentStatistics = ({ nbHours, nbCompanies, nbMissions }: Props) => {
  return (
    <div className={styles.container}>
      <div>
        <div>
          <div className={styles.hoursIndicator}>
            <BigClock />
            <div className={styles.column}>
              <div className={styles.indicatorValue}>{Math.round(nbHours ?? 0)}</div>
              <div className={styles.indicatorUnits}>
                {pluralFormat(nbHours ?? 0, 'heure', false)}
              </div>
            </div>
          </div>
          <div className={styles.separator} />
          <div className={styles.missionsIndicator}>
            <BigPencil />
            <div className={styles.column}>
              <div className={styles.indicatorValue}>{nbMissions}</div>
              <div className={styles.indicatorUnits}>
                {pluralFormat(nbMissions ?? 0, 'contrat', false)}
              </div>
            </div>
          </div>
          <div className={styles.separator} />
          <div className={styles.clientsIndicator}>
            <BigGroup />
            <div className={styles.column}>
              <div className={styles.indicatorValue}>{nbCompanies}</div>
              <div className={styles.indicatorUnits}>
                {pluralFormat(nbCompanies ?? 0, 'client', false)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentStatistics;
