import { useEffect, useRef } from 'react';

import { Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import ContentLoader from 'react-content-loader';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { redCloud } from 'src/Assets_DEPRECATED';
import { useFetchPotentialQualificationsClientsForRepositioning } from 'src/Hooks/Qualifications/useFetchPotentialQualificationsClientsForRepositioning';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import DepartmentsPopupMenu from '../DepartmentsPopupMenu';
import QualificationsPopupMenu from '../QualificationsPopupMenu';
import PotentialQualificationClientCard, { LoadingCard } from './PotentialQualificationClientCard';
import styles from './PotentialQualificationsClients.module.scss';
import { Props } from './PotentialQualificationsClients.types';

const PotentialQualificationsClients = ({
  candidateQualifications,
  selectedPotentialQualificationsClients,
  setSelectedPotentialQualificationsClients,
  selectedQualifications,
  setSelectedQualifications,
  allDepartments,
  departmentsFetchStatus,
  selectedDepartments,
  setSelectedDepartments,
  setScrollTop,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView();
  const brandCode = useSelector(getCurrentBrandCode)!;

  const {
    data: potentialQualificationsClientsPages,
    fetchNextPage,
    isSuccess,
    isError,
    isFetchingNextPage,
    isLoading,
  } = useFetchPotentialQualificationsClientsForRepositioning({
    mainQualifications: selectedQualifications,
    departmentIds: selectedDepartments.map(item => item.id ?? ''),
    brandCode,
    isCGC: true,
  });
  const potentialQualificationsClients = (potentialQualificationsClientsPages?.pages ?? []).flat(1);

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, potentialQualificationsClients.length]);
  return (
    <div
      className={styles.container}
      ref={containerRef}
      onScroll={() => setScrollTop(containerRef?.current?.scrollTop ?? 0)}
    >
      <div className={styles.filters}>
        <QualificationsPopupMenu
          allQualifications={candidateQualifications}
          setSelectedQualifications={setSelectedQualifications}
          selectedQualifications={selectedQualifications}
        />
        <div className={styles.departmentFilter}>
          <DepartmentsPopupMenu
            allDepartments={allDepartments}
            departmentsFetchStatus={departmentsFetchStatus}
            selectedDepartments={selectedDepartments}
            setSelectedDepartments={setSelectedDepartments}
          />
        </div>
      </div>
      {isSuccess && (
        <>
          <p className={styles.title}>
            {potentialQualificationsClients.length > 0
              ? `${potentialQualificationsClients.length} qualifications potentielles`
              : 'aucune qualification potentielle'}
          </p>
          <div className={styles.checkboxesContainer}>
            {potentialQualificationsClients.map(potentialQualificationClient => {
              const isSelected =
                selectedPotentialQualificationsClients.find(
                  selected =>
                    selected.client.companyId === potentialQualificationClient.client.companyId &&
                    selected.potentialQualification.id ===
                      potentialQualificationClient.potentialQualification.id
                ) !== undefined;
              return (
                <div className={styles.checkboxContainer}>
                  <Checkbox
                    children={
                      <PotentialQualificationClientCard
                        potentialQualificationClient={potentialQualificationClient}
                      />
                    }
                    onClick={() =>
                      isSelected
                        ? setSelectedPotentialQualificationsClients(
                            selectedPotentialQualificationsClients.filter(
                              selectedItem => potentialQualificationClient !== selectedItem
                            )
                          )
                        : setSelectedPotentialQualificationsClients([
                            ...selectedPotentialQualificationsClients,
                            potentialQualificationClient,
                          ])
                    }
                    checked={isSelected}
                  />
                </div>
              );
            })}
            <div ref={ref}>
              {isFetchingNextPage && (
                <ContentLoader
                  height="3.5rem"
                  width="100%"
                  uniqueKey={'potentialQualificationClientCard'}
                >
                  <rect x="1%" y="10" rx="4" ry="4" width="80%" height="16" />
                  <rect x="1%" y="30" rx="4" ry="4" width="80%" height="16" />
                </ContentLoader>
              )}
            </div>
          </div>
        </>
      )}
      {isLoading && (
        <>
          <div className={styles.title}></div>
          {Array.from({ length: 2 }).map((_, idx) => (
            <div
              className={styles.checkboxContainer}
              key={`${idx} potentialQualificationClientCard`}
            >
              <LoadingCard />
            </div>
          ))}
        </>
      )}
      {isError && (
        <div className={styles.title}>
          <img src={redCloud} alt="illusEscalier" />
          <div>Une erreur est survenue</div>
        </div>
      )}
    </div>
  );
};

export default PotentialQualificationsClients;
