import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Bubble,
  DocumentPDF,
  Interversion,
  Refresh,
  ThreeDots,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGeneratePreventionSheet } from 'src/Hooks/Candidate/useGeneratePreventionSheet';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { FETCH_STATUS } from 'src/Redux/Types';
import { EnumCreateActionBodyActionTarget } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { onFileOpen } from 'src/Utils/onFileOpen';
import CandidateContactPopup from '../CandidateContactPopup';
import styles from './PopOverContact.module.scss';
import { Props } from './PopOverContact.types';

const PopOverContact = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const generatePreventionSheet = useGeneratePreventionSheet();
  const [open, setOpen] = useState(false);

  let smsAllowed = true;
  let callsAllowed = true;
  let emailsAllowed = true;
  if (props.target === EnumCreateActionBodyActionTarget.CONTACT) {
    smsAllowed = !props.contact.dontCallMe && !!props.contact.mobilePhoneNumber;
    callsAllowed =
      !props.contact.dontCallMe && (!!props.contact.phone || !!props.contact.mobilePhoneNumber);
    emailsAllowed = !props.contact.dontMailMe && !!props.contact.email;
  }
  if (props.target === EnumCreateActionBodyActionTarget.CANDIDATE) {
    smsAllowed = (props.contact.flagAcceptSMS && !!props.contact.phone2) ?? false;
    callsAllowed =
      (props.contact.flagAcceptSMS && (!!props.contact.phone2 || !!props.contact.phone1)) ?? false;
    emailsAllowed = (props.contact.flagAcceptEmailMission && !!props.contact.email) ?? false;
  }
  const notDisabled = smsAllowed || callsAllowed || emailsAllowed;

  return props.target === EnumCreateActionBodyActionTarget.CANDIDATE ? (
    <PopupMenu
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      position={props.position ?? 'left top'}
      trigger={
        props.triggerButton ?? (
          <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: open })}>
            <ThreeDots className={styles.menuIcon} />
          </Button.Tertiary>
        )
      }
    >
      {notDisabled && (
        <PopupMenu
          position={'left top'}
          arrow={false}
          trigger={
            <>
              {props.enableEditMissionStatus && (
                <PopupMenu.Item
                  icon={<Interversion />}
                  text="changer le statut commande"
                  onClick={() =>
                    navigate(MODAL_ROUTES.STATUS_MODAL_CONTENT.route.buildPath({}), {
                      state: {
                        candidateId: props.contact.candidateId,
                        candidateStatus: props.contact.candidateAdvancement,
                        companyStatus: props.contact.companyAdvancement,
                        background: location,
                      },
                    })
                  }
                />
              )}
              <PopupMenu.Item icon={<Bubble />} text="contacter" keepOpenOnClick hasSubMenu />
            </>
          }
        >
          <CandidateContactPopup
            contact={props.contact}
            target={props.target}
            smsAllowed={smsAllowed}
            callsAllowed={callsAllowed}
            emailsAllowed={emailsAllowed}
          />
        </PopupMenu>
      )}
      {props.positionStudyId && (
        <PopupMenu.Item
          icon={
            toFetchStatus(generatePreventionSheet) === FETCH_STATUS.PENDING ? (
              <Refresh className={styles.loading} />
            ) : (
              <DocumentPDF />
            )
          }
          keepOpenOnClick
          text="PDF fiche prévention"
          onClick={() => {
            generatePreventionSheet.mutate(props.positionStudyId ?? '', {
              onSuccess: (buffer: Buffer) => onFileOpen(buffer, 'application/pdf'),
            });
          }}
        />
      )}
    </PopupMenu>
  ) : (
    <PopupMenu
      position={'left top'}
      trigger={
        props.triggerButton ?? <Button.Tertiary disabled={!notDisabled}>contacter</Button.Tertiary>
      }
    >
      <CandidateContactPopup
        contact={props.contact}
        target={props.target}
        smsAllowed={smsAllowed}
        callsAllowed={callsAllowed}
        emailsAllowed={emailsAllowed}
      />
    </PopupMenu>
  );
};

export default PopOverContact;
