import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { Sort } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchMissionsWithReset } from 'src/Redux/Search/Hooks';
import { getSortMode } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { EnumSearchMissionsBodySortBy } from 'src/Services/API';
import styles from './SortMissionsPopOverMenu.module.scss';

const sortLabelTansformer = (sortBy: EnumSearchMissionsBodySortBy): string => {
  switch (sortBy) {
    case EnumSearchMissionsBodySortBy['date de début']:
      return 'date début';
    case EnumSearchMissionsBodySortBy['date de fin']:
      return 'date fin';
    case EnumSearchMissionsBodySortBy['nombre de postes']:
      return 'nombre postes';
  }
};

const SortMissionsPopOverMenu = () => {
  const sortBy = useSelector(getSortMode);
  const searchWithReset = useSearchMissionsWithReset();

  const dispatch = useDispatch();
  const setSortMode = useCallback(
    (sortBy: EnumSearchMissionsBodySortBy) => {
      dispatch(searchActions.setSortMode(sortBy));
      searchWithReset();
    },
    [dispatch, searchWithReset]
  );
  return (
    <PopupMenu
      selectedIds={[sortBy.toString()]}
      trigger={
        <Button.Secondary
          leftIcon={<Sort />}
          text={sortLabelTansformer(sortBy)}
          className={styles.filterButton}
        />
      }
      position="bottom center"
    >
      {Object.values(EnumSearchMissionsBodySortBy).map(sortByItem => (
        <PopupMenu.Item
          text={sortByItem.toString()}
          id={sortByItem.toString()}
          key={sortByItem.toString()}
          onClick={() => setSortMode(sortByItem)}
          keepOpenOnClick
        />
      ))}
    </PopupMenu>
  );
};
export default SortMissionsPopOverMenu;
