import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import useAuth from 'src/Hooks/Authentication/useAuth';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { ConsultantPerimeter, ConsultantsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchPerimeter = (
  extraOptions?: Omit<
    UseQueryOptions<ConsultantPerimeter, unknown, ConsultantPerimeter, [QueryKeys]>,
    'queryKey' | 'queryFn'
  >
) => {
  const currentAgency = useSelector(getCurrentAgency);
  const user = useAuth();
  const dispatch = useDispatch();
  const { onSuccess, ...rest } = extraOptions ?? {};

  return useQuery({
    queryKey: [QueryKeys.fetchPerimeter],
    queryFn: () => ConsultantsService.consultantsControllerGetPerimeter(),
    enabled: user.isAuthenticated,
    onSuccess: (...args) => {
      const [data] = args;
      !currentAgency && dispatch(perimeterActions.setCompanies(data));
      dispatch(perimeterActions.setUserInfo(data));
      onSuccess?.(...args);
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
};
