import { useEffect, useRef } from 'react';

import { Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import ContentLoader from 'react-content-loader';
import { useInView } from 'react-intersection-observer';
import Card, { LoadingCard } from 'src/Components/Card';
import { useFetchMissionsForRepositioning } from 'src/Hooks/Missions/useFetchMissionsForRepositioning';
import { Mission } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';
import DepartmentsPopupMenu from '../DepartmentsPopupMenu';
import QualificationsPopupMenu from '../QualificationsPopupMenu';
import styles from './Missions.module.scss';
import { Props } from './Missions.types';

const Missions = ({
  selectedMissions,
  onMissionClick,
  selectedQualifications,
  setSelectedQualifications,
  allQualifications,
  allDepartments,
  departmentsFetchStatus,
  selectedDepartments,
  setSelectedDepartments,
  setScrollTop,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView();
  const {
    data: missionsWithCount,
    fetchNextPage,
    isSuccess,
    isFetchingNextPage,
    isLoading,
  } = useFetchMissionsForRepositioning({
    qualificationIds: selectedQualifications,
    departmentIds: selectedDepartments.map(item => item.id ?? ''),
  });
  const missions: Mission[] = (missionsWithCount?.pages.map(page => page.missions!) ?? []).flat(1);
  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, missions.length]);
  return (
    <div
      className={styles.container}
      ref={containerRef}
      onScroll={() => setScrollTop(containerRef?.current?.scrollTop ?? 0)}
    >
      <div className={styles.filters}>
        <QualificationsPopupMenu
          allQualifications={allQualifications}
          setSelectedQualifications={setSelectedQualifications}
          selectedQualifications={selectedQualifications}
        />
        <div className={styles.departmentFilter}>
          <DepartmentsPopupMenu
            allDepartments={allDepartments}
            departmentsFetchStatus={departmentsFetchStatus}
            selectedDepartments={selectedDepartments}
            setSelectedDepartments={setSelectedDepartments}
          />
        </div>
      </div>
      {isSuccess && (
        <div>
          <p className={styles.title}>
            {`${pluralFormat(
              missionsWithCount && missionsWithCount?.pages.length > 0
                ? missionsWithCount?.pages[0].count ?? 0
                : 0,
              'commande'
            )} correspondant à votre recherche`}
          </p>
          <div className={styles.gridContainer}>
            {missions.map(mission => {
              const selectedMissionIds = selectedMissions.map(mission => mission.missionId);
              const isSelected = selectedMissionIds.includes(mission?.missionId ?? '');
              return (
                <Checkbox
                  children={
                    <Card
                      disabled={false}
                      className={styles.card}
                      firstSubtitle={mission.service?.name ?? 'service non renseigné'}
                      secondSubtitle={mission.qualification?.label}
                      title={mission.company?.name ?? ''}
                      description={`du ${moment(mission.startDate).format('L')} au ${moment(
                        mission.endDate
                      ).format('L')} | ${mission.numberPositions} postes à pourvoir`}
                    />
                  }
                  onClick={() =>
                    isSelected
                      ? onMissionClick(
                          selectedMissions.filter(
                            missionItem => mission.missionId !== missionItem.missionId
                          )
                        )
                      : onMissionClick([...selectedMissions, mission])
                  }
                  checked={isSelected}
                />
              );
            })}
            {isFetchingNextPage && (
              <div ref={ref}>
                <ContentLoader height="3.5rem" width="100%" uniqueKey={'missionCard'}>
                  <rect x="1%" y="10" rx="4" ry="4" width="80%" height="16" />
                  <rect x="1%" y="30" rx="4" ry="4" width="80%" height="16" />
                </ContentLoader>
              </div>
            )}
          </div>
        </div>
      )}
      {isLoading && (
        <div className={styles.loadingContainer}>
          {Array.from({ length: 10 }).map((_, idx) => (
            <div className={styles.loadingCard} key={`${idx} missionCard`}>
              <LoadingCard />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Missions;
