import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import {
  getCurrentMissionId,
  getMissions,
  getMissionsFetchStatus,
} from 'src/Redux/Missions/Selectors';
import { missionsActions } from 'src/Redux/Missions/Slice';
import { RootState } from 'src/Redux/RootReducer';
import { useSearchMissionsWithReset } from 'src/Redux/Search/Hooks';
import { searchActions } from 'src/Redux/Search/Slice';
import { ROUTES } from 'src/Routes/Routes.types';
import { EnumSearchMissionsBodySortBy } from 'src/Services/API';
import ListMissions from './ListMissions.component';
import styles from './ListMissions.module.scss';

const MemoListMissions = React.memo(ListMissions);

const EnhancedListMissions = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const searchWithReset = useSearchMissionsWithReset();

  const navigate = useNavigate();
  const params = useMatch(ROUTES.ACTIVITY.MISSIONS.MISSION_DETAIL)?.params;
  const missions = useSelector(getMissions);
  const missionsFetchStatus = useSelector(getMissionsFetchStatus);
  const currentMissionId = useSelector(getCurrentMissionId);

  useEffect(() => {
    searchWithReset();
    dispatch(searchActions.setSortMode(EnumSearchMissionsBodySortBy['date de début']));
  }, [dispatch, searchWithReset]);

  const onLoadMore = useCallback(() => {
    dispatch(searchActions.incrementOffset());
    searchWithReset();
  }, [dispatch, searchWithReset]);

  useEffect(() => {
    if (missions.length && currentMissionId === '') {
      dispatch(missionsActions.setCurrentMissionId(missions[0].missionId ?? ''));
    }
  }, [currentMissionId, dispatch, missions]);

  const urlMissionId = missions.map(mission => mission.missionId).includes(params?.missionId)
    ? missions.find(mission => mission.missionId === params?.missionId)?.missionId ?? null
    : null;
  const listSectionProps = {
    fetchStatus: missionsFetchStatus,
    selectedId: params?.missionId ? urlMissionId : currentMissionId,
    cards: missions.map(mission => ({
      id: mission.missionId ?? '',
      title: mission.company?.name ?? '',
      firstSubtitle: mission.service?.name || 'aucun service renseigné',
      secondSubtitle: mission.qualification?.label ?? '',
      description: `nombre de poste(s): ${mission.numberPositions}`, // missionId
      flag: {
        count: mission.suggestedCandidateCount,
        label: 'cdii',
        className: styles.cdiiFlag,
      },
      onClick: () => {
        dispatch(missionsActions.setCurrentMissionId(mission.missionId ?? ''));
        navigate(ROUTES.ACTIVITY.MISSIONS.buildPath({}));
      },
    })),
    onLoadMore: onLoadMore,
  };

  return <MemoListMissions {...listSectionProps} />;
};

export default EnhancedListMissions;
