import {
  Badge,
  Button,
  HorizontalCardWithTitle,
  Loader,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Clock, Group, Stylo } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import { IllusEdpFormationHabilitation } from 'src/Assets_DEPRECATED';
import HabilitationCard from 'src/Components/HabilitationCard';
import RecrutLiveProfile from 'src/Components/RecrutLiveProfile/RecrutLiveProfile.component';
import { useFetchLastYearCandidateContracts } from 'src/Hooks/Candidate/useFetchCandidate';
import { useFetchCandidateCurrentCDIContract } from 'src/Hooks/Candidate/useFetchCandidateCurrentCDIContract';
import { useFetchCandidateDetails } from 'src/Hooks/Candidate/useFetchCandidateDetails';
import { useFetchCandidateHabilitations } from 'src/Hooks/Candidate/useFetchCandidateHabilitations';
import { useFetchCandidateLastMedicalVisit } from 'src/Hooks/Candidate/useFetchCandidateLastMedicalVisit';
import { useFetchCandidateMemos } from 'src/Hooks/Candidate/useFetchCandidateMemos';
import { useFetchCandidateQualifications } from 'src/Hooks/Candidate/useFetchCandidateQualifications';
import { useOpenOSMResume } from 'src/Hooks/Candidate/useOpenOSMResume';
import MemoModal from 'src/Modals/MemoModal';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { OSMCommonModelDateDtoToDate } from 'src/Routes/Talents/Cdii/utils';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Utils/fetch-status';
import styles from './CdiiDetailSection.module.scss';
import { CdiiDetailSectionProps } from './CdiiDetailSection.types';

export const CdiiDetailSection = ({ candidateId, lastContractEndDate }: CdiiDetailSectionProps) => {
  const qualificationQuery = useFetchCandidateQualifications(candidateId);
  const habilitationQuery = useFetchCandidateHabilitations(candidateId);

  const detailsQuery = useFetchCandidateDetails(candidateId);
  const firstContractDate = useFetchLastYearCandidateContracts(candidateId);
  const { data: lastMedicalVisit } = useFetchCandidateLastMedicalVisit(candidateId);
  const qualificationfetchStatus = toFetchStatus(qualificationQuery);
  const detailsFetchStatus = toFetchStatus(detailsQuery);
  const firstContractFetchStatus = toFetchStatus(firstContractDate);
  const fetchStatus = mergeSeveralFetchStatus([
    qualificationfetchStatus,
    detailsFetchStatus,
    firstContractFetchStatus,
  ]);
  const contractDetails = useFetchCandidateCurrentCDIContract(candidateId);
  const currentBrand = useSelector(getCurrentBrand);
  const { data: candidateMemos, isSuccess, isLoading, isError } = useFetchCandidateMemos(
    candidateId
  );
  const memosFetchStatus = toFetchStatus({ isError, isLoading, isSuccess });
  const { mutate: mutateCV, isLoading: isLoadingCV } = useOpenOSMResume();
  const [hoverCv, setHoverCv] = useState<Boolean>(false);

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <div className={styles.title}>en détails</div>
        <div className={styles.buttonsTopBar}>
          <div className={styles.buttons}>
            <RecrutLiveProfile candidateId={candidateId} text={'consulter dossier cdii'} />
            <Button.Secondary
              className={styles.CVButton}
              onClick={() =>
                mutateCV({
                  candidateId,
                  candidateName: `${detailsQuery.data?.firstName} ${detailsQuery.data?.name}`,
                })
              }
              onMouseEnter={() => setHoverCv(true)}
              onMouseLeave={() => setHoverCv(false)}
            >
              {isLoadingCV ? <Loader heightInRem={1.2} darkMode={!hoverCv} /> : 'afficher le CV'}
            </Button.Secondary>
            <MemoModal
              memos={candidateMemos}
              fetchStatus={memosFetchStatus}
              candidateId={candidateId}
            />
          </div>
        </div>
      </div>
      {fetchStatus === FETCH_STATUS.FULFILLED && (
        <div className={styles.child}>
          <div className={styles.informationContainer}>
            <div className={styles.informationSubContainer}>
              <WithLightTitle
                title="qualifications principales et secondaires"
                className={styles.list}
              >
                <p key="main"># principale :</p>
                <p>{detailsQuery.data?.qualification.label}</p>
                <br />
                <p key="secondary"># secondaire :</p>
                {qualificationQuery.data
                  ?.filter(
                    qualification =>
                      qualification.id !== detailsQuery.data?.qualification.qualificationId
                  )
                  .map(qualification => (
                    <p key={qualification.id}>{qualification.label}</p>
                  ))}
              </WithLightTitle>
            </div>
            <div className={styles.informationSubContainer}>
              <WithLightTitle title="métiers">
                {contractDetails.isSuccess &&
                  contractDetails.data.jobList?.map(job => <p key={job.id}>• {job.label}</p>)}
              </WithLightTitle>
              <WithLightTitle title="date de fin du dernier contrat">
                {OSMCommonModelDateDtoToDate(lastContractEndDate)}
              </WithLightTitle>
            </div>
            <div className={styles.informationSubContainer}>
              <div className={styles.bottomLineContainer}>
                <div className={styles.imageIndicator}>
                  <Clock />
                  <div className={styles.column}>
                    <div className={styles.indicatorValue}>
                      {Math.round(detailsQuery.data?.nbHoursWorked ?? 0)}
                    </div>
                    <div className={styles.indicatorUnits}>hrs</div>
                  </div>
                </div>
                <div className={styles.separator} />

                <div className={styles.imageIndicator}>
                  <Group />
                  <div className={styles.column}>
                    <div className={styles.indicatorValue}>{detailsQuery.data?.nbCompanies}</div>
                    <div className={styles.indicatorUnits}>clients</div>
                  </div>
                </div>
                <div className={styles.separator} />

                <div className={styles.imageIndicator}>
                  <Stylo />
                  <div className={styles.column}>
                    <div className={styles.indicatorValue}>{detailsQuery.data?.nbMissions}</div>
                    <div className={styles.indicatorUnits}>contrats</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bottomInformationContainer}>
            <div className={styles.informationSubContainer}>
              <WithLightTitle title="mobilité acceptée sur le contrat">
                {contractDetails.isSuccess && contractDetails.data.mobility && (
                  <div>{`${contractDetails.data.mobility.distance} KM`}</div>
                )}
              </WithLightTitle>
              <WithLightTitle title="ville de résidence">
                {detailsQuery.isSuccess && detailsQuery.data.city && (
                  <div>{detailsQuery.data.city}</div>
                )}
              </WithLightTitle>
            </div>
            <div className={styles.informationSubContainer}>
              <WithLightTitle title="dernière visite médicale">
                {lastMedicalVisit
                  ? moment(lastMedicalVisit).isAfter(moment())
                    ? `prévue le ${moment(lastMedicalVisit).format('L')}`
                    : `${moment(lastMedicalVisit).format('L')}`
                  : 'aucune visite détectée'}
              </WithLightTitle>
              <WithLightTitle title="code postal de résidence">
                {detailsQuery.isSuccess && detailsQuery.data.zipCode && (
                  <div>{detailsQuery.data.zipCode}</div>
                )}
              </WithLightTitle>
            </div>
            <div className={styles.informationSubContainer}>
              <WithLightTitle title={`date de 1er contrat chez ${currentBrand?.brand?.name}`}>
                {moment(firstContractDate.data?.firstContractStartDate).format('L')}
              </WithLightTitle>
              <WithLightTitle title="numéro de téléphone">
                {detailsQuery.data?.phone1 ?? 'non renseigné'}
              </WithLightTitle>
            </div>
          </div>
          <div className={styles.habilitationsContainer}>
            <div className={styles.habilitationsTitle}>
              habilitations
              <Badge
                className={styles.habilitationsBadge}
                value={`${habilitationQuery.isSuccess ? habilitationQuery.data.length : 0}`}
              />
            </div>
            <div>
              {habilitationQuery.isSuccess && habilitationQuery.data.length !== 0
                ? habilitationQuery.data.map((habilitation, index) => (
                    <HabilitationCard
                      key={habilitation.id}
                      className={index === 0 ? styles.first_habilitation : styles.multiCriteriaCard}
                      icon={<IllusEdpFormationHabilitation />}
                      title={habilitation.label ?? ''}
                    />
                  ))
                : "aucune habilitation n'a été renseignée"}
            </div>
          </div>

          <WithLightTitle title="horaires acceptés">
            <div className={styles.columnList}>
              {contractDetails.isSuccess &&
                contractDetails.data.workingConditions &&
                contractDetails.data.workingConditions.map(condition => (
                  <HorizontalCardWithTitle
                    title={condition.label ?? ''}
                    className={styles.conditionCardContainer}
                    titleClassname={styles.conditionCardTitle}
                    key={condition.id}
                  />
                ))}
            </div>
          </WithLightTitle>
        </div>
      )}
      {fetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="20rem" width="100%" uniqueKey="cdiiDetails">
          <rect x="1%" y="10" rx="4" ry="4" width="50%" height="10" />
          <rect x="1%" y="30" rx="4" ry="4" width="100%" height="10" />
          <rect x="1%" y="50" rx="4" ry="4" width="100%" height="10" />
          <rect x="1%" y="70" rx="4" ry="4" width="100%" height="10" />
          <rect x="1%" y="90" rx="4" ry="4" width="100%" height="10" />
        </ContentLoader>
      )}
    </div>
  );
};
