import { useMatch } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';
import FiltersMenu from 'src/Routes/Talents/Cdii/FiltersMenu/FiltersMenu.component';
import styles from './CDIITopBarComponent.module.scss';

const CDIITopBarComponent = () => {
  const isRepositioning = !!useMatch(ROUTES.TALENTS.CDII.CDII_DETAIL.REPOSITIONING.path);
  return (
    <div className={styles.container}>
      {!isRepositioning && <FiltersMenu isRepositioning={false} />}
    </div>
  );
};
export default CDIITopBarComponent;
