import {
  Button,
  Checkbox,
  FetchButton,
  ModalDeprecated,
  PopupActions,
  TextArea,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BinocularFullCut } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useRef } from 'react';
import ContentLoader from 'react-content-loader';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFetchRelatedCGC } from 'src/Hooks/Agencies/useFetchRelatedCGC';
import { useSendToCGC } from 'src/Hooks/Candidate/useSendToCGC';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { ROUTES } from 'src/Routes/Routes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { toFetchStatus } from 'src/Services/Async';
import styles from './CGCModal.module.scss';
import { Props } from './CGCModal.types';
import { useForm, Controller } from 'react-hook-form';
import classnames from 'classnames';

const CGCModal = ({ candidateId, candidateFirstName, candidateLastName, statut }: Props) => {
  const ref = useRef<PopupActions>(null);
  const navigate = useNavigate();
  const userInfo = useSelector(getAnalyticsUserInfo);
  const relatedCGCQuery = useFetchRelatedCGC();
  const sendToCGCMutation = useSendToCGC();
  const fetchStatus = toFetchStatus(sendToCGCMutation);
  const relatedCGCFetchStatus = toFetchStatus(relatedCGCQuery);
  const text = statut.includes('available') ? 'relancer cgc' : 'SOS cgc';

  interface FormData {
    firstRequirementCheck: boolean;
    secondRequirementCheck: boolean;
    thirdRequirementCheck: boolean;
    comment: string;
  }

  const { control, watch } = useForm<FormData>({
    defaultValues: {
      firstRequirementCheck: false,
      secondRequirementCheck: false,
      thirdRequirementCheck: false,
      comment: '',
    },
    mode: 'onChange',
  });

  const isFirstRequirementCheck = watch('firstRequirementCheck');
  const isSecondRequirementCheck = watch('secondRequirementCheck');
  const isThirdRequirementCheck = watch('thirdRequirementCheck');
  const comment = watch('comment');

  return (
    <ModalDeprecated
      ref={ref}
      title="SOS CGC"
      footerActionsRight={[
        <Button.Secondary key="cgcModalCancelButton" onClick={() => ref.current?.close()}>
          annuler
        </Button.Secondary>,
        <FetchButton
          key="cgcModalValidateButton"
          disabled={
            !isFirstRequirementCheck ||
            !isSecondRequirementCheck ||
            !isThirdRequirementCheck ||
            comment.trim().length < 10 ||
            relatedCGCFetchStatus !== FETCH_STATUS.FULFILLED
          }
          title="j'envoie au CGC"
          fetchStatus={fetchStatus}
          onClick={() => {
            sendToCGCMutation.mutate({
              candidateId,
              candidateFirstName,
              candidateLastName,
              cgcId: relatedCGCQuery.data?.id ?? '',
              comment,
            });
          }}
          onSuccess={() => {
            ref.current?.close();
            navigate(ROUTES.TALENTS.CDII.buildPath({}));
          }}
        />,
      ]}
      trigger={
        <Button.Primary
          text={text}
          className={styles.cgcButton}
          onClick={() =>
            ReactGA.event(ANALYTICS_EVENT.CLICK_SOS, {
              ...userInfo,
              statut,
            })
          }
        />
      }
      icon={<BinocularFullCut />}
    >
      {relatedCGCQuery.isSuccess && relatedCGCQuery.data !== undefined && (
        <p className={styles.text}>
          je m’apprête à confier le talent auprès du {relatedCGCQuery.data.label} -{' '}
          {relatedCGCQuery.data.id} car je remplis les 3 conditions :
        </p>
      )}
      {(relatedCGCQuery.isFetching || relatedCGCQuery.isLoading) && (
        <ContentLoader key="cgc-help-loader" width="100%">
          <rect x="1%" y="10" rx="4" ry="4" width="80%" height="16" />
          <rect x="1%" y="30" rx="4" ry="4" width="80%" height="16" />
        </ContentLoader>
      )}

      <form>
        <div
          className={classnames(styles.checkText, {
            [styles.errorCheckbox]: !isFirstRequirementCheck,
          })}
        >
          <Controller
            name="firstRequirementCheck"
            control={control}
            render={({ field }) => (
              <Checkbox
                label="je n'ai pas de commandes pour ses qualifications"
                checked={field.value}
                onClick={() => field.onChange(!field.value)}
              />
            )}
          />
        </div>

        <div
          className={classnames(styles.checkText, {
            [styles.errorCheckbox]: !isSecondRequirementCheck,
          })}
        >
          <Controller
            name="secondRequirementCheck"
            control={control}
            render={({ field }) => (
              <Checkbox
                label="j'ai réalisé une PPA sans succès ou je n'ai pas le temps de faire une PPA dans le temps imparti"
                checked={field.value}
                onClick={() => field.onChange(!field.value)}
              />
            )}
          />
        </div>

        <div
          className={classnames(styles.checkText, {
            [styles.errorCheckbox]: !isThirdRequirementCheck,
          })}
        >
          <Controller
            name="thirdRequirementCheck"
            control={control}
            render={({ field }) => (
              <Checkbox
                label="je n'ai pas de commandes sur ses qualifications passerelles"
                checked={field.value}
                onClick={() => field.onChange(!field.value)}
              />
            )}
          />
        </div>

        <WithLightTitle title="commentaire" className={styles.textAreaTitle}>
          <Controller
            name="comment"
            control={control}
            render={({ field }) => (
              <TextArea
                placeholder="Décrivez votre talent en quelques mots et précisez la raison du repositionnement."
                className={classnames(styles.textArea, {
                  [styles.errorTextArea]: field.value.trim().length < 10,
                })}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {comment.trim().length < 10 && (
            <div className={styles.warningMessage}>10 caractères minimum</div>
          )}
        </WithLightTitle>
      </form>
    </ModalDeprecated>
  );
};

export default CGCModal;
