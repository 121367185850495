import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { WorkAccidentAnalysisService } from 'src/Services/API';

import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import { MutationKeys } from '../types';

export const useGenerateATPdf = (extraOptions?: UseMutationOptions<Buffer, unknown, unknown>) => {
  const currentBrand = useSelector(getCurrentBrand);
  const user = useAuthenticatedUser();
  return useMutation(
    [MutationKeys.generateATPdf],
    async (analyseId: string) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const analyse = await WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkingAnalysePdf(
        { id: analyseId, body: { brandCode: currentBrand?.brandCodeApiHeader, user } },
        { responseType: 'arraybuffer' }
      );
      return analyse;
    },
    {
      ...extraOptions,
    }
  );
};
