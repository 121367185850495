import { EnumSearchMissionsBodySortBy, Salesphase } from 'src/Services/API';

export const SEARCH_LIMIT = 100;

export interface SearchState {
  startDate: string;
  salesphases: Salesphase[];
  endDate: string;
  singleDate: string;
  sortBy: EnumSearchMissionsBodySortBy;
  offset: number;
}
