import { WorkAccidentActionPlan } from 'src/Services/API';

export const sortWorkAccidentAction = (
  actions: WorkAccidentActionPlan[]
): WorkAccidentActionPlan[] => {
  return actions.sort((actionPlanA, actionPlanB) => {
    const AMinActionDate = actionPlanA.whyActions
      ?.map(action => action.realisationDate ?? new Date())
      .sort(function (a: Date, b: Date) {
        return new Date(a).valueOf() - new Date(b).valueOf();
      })[0];
    const BMinActionDate = actionPlanB.whyActions
      ?.map(action => action.realisationDate ?? new Date())
      .sort(function (a: Date, b: Date) {
        return new Date(a).valueOf() - new Date(b).valueOf();
      })[0];
    return (
      (AMinActionDate ? new Date(AMinActionDate)?.valueOf() : new Date().valueOf()) -
      (BMinActionDate ? new Date(BMinActionDate)?.valueOf() : new Date().valueOf())
    );
  });
};
