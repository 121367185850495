import { getAnalyticsUserInfo, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import {
  ArchiveATReason,
  EnumCreateActionBodyBrandCode,
  WorkAccidentAnalysisService,
} from 'src/Services/API';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { MutationKeys, QueryKeys } from '../types';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';

interface WorkAccidentAnalysisCreationParams {
  analyseId: string;
  lastName: string;
  firstName: string;
  startDate?: Date;
  endDate?: Date;
  candidateId: string;
  accidentId: string;
  stoppageDuration: number;
  archivedReason?: ArchiveATReason;
  archiveComment?: string;
}

export const useCreateWorkAccidentAnalysis = (
  extraOptions?: UseMutationOptions<string, unknown, WorkAccidentAnalysisCreationParams, unknown>
) => {
  const queryClient = useQueryClient();

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgencyId = useSelector(getCurrentAgency);
  const userInfo = useSelector(getAnalyticsUserInfo);

  return useMutation(
    [MutationKeys.createWorkAccidentAnalysis],
    async (params: WorkAccidentAnalysisCreationParams) => {
      if (!currentBrand?.brandCodeApiHeader || !currentAgencyId) {
        throw new Error('No current brand or has an invalid current brand');
      }
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerCreateWorkAccident({
        id: params.analyseId,
        body: {
          brandCode: currentBrand.brandCodeApiHeader as EnumCreateActionBodyBrandCode,
          agencyId: currentAgencyId,
          lastName: params.lastName,
          firstName: params.firstName,
          startDate: params.startDate ?? new Date(),
          endDate: params.endDate ?? new Date(),
          candidateId: params.candidateId,
          accidentId: params.accidentId,
          stoppageDuration: params.stoppageDuration,
          archivedComment: params.archiveComment,
          archivedReason: params.archivedReason,
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkAccidents] });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchAccidentsUnderMinStoppageDuration],
        });
        ReactGA.event(ANALYTICS_EVENT.CREATE_WORK_ACCIDENT_ANALYSIS, { ...userInfo });
      },
    }
  );
};
