import { ExternalDemandElement } from '../ExternalDemandsList/ExternalDemandsList.types';
import { AppelMedicalEventStatus, ExternalDemandStatus } from 'src/Services/API';

export interface Props {
  element: ExternalDemandElement;
  showArchivedDemands: boolean;
}

export const errorEnums = [
  AppelMedicalEventStatus.Erreur,
  AppelMedicalEventStatus['Erreur annulation'],
  ExternalDemandStatus.ERROR,
];
export const completeEnums = [
  AppelMedicalEventStatus['Commande créée'],
  AppelMedicalEventStatus['Commande mise à jour'],
  AppelMedicalEventStatus['Commande Annulée'],
  ExternalDemandStatus.COMPLETE,
];

export const ROULEMENT_ERROR = 'Erreur: un roulement est déjà positionné pour ce jour';

export const TECHNICAL_ERROR = 'Erreur technique :';

export const CANCEL_ERROR = 'demande annulée par le client mais des Talents y sont rattachés';

export const ANNULATION_ERROR = 'Candidats non M présents';
