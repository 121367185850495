import {
  Badge,
  HorizontalCardWithTitle,
  Loader,
  SegmentedControl,
  Table,
  TypeAndEnter,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTypedHash } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import CDIITopBarComponent from 'src/Components/TopBar/CDIITopBarComponent/CDIITopBarComponent.component';
import { useFetchCandidatesActivityPlanning } from 'src/Hooks/Candidate/useFetchCandidatesActivityPlanning';
import { useFetchSuggestedCandidates } from 'src/Hooks/Candidate/useFetchSuggestedCandidates';
import { useFetchPerimeter } from 'src/Hooks/Perimeter';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { getCDISortBy, getCDISortOrder } from 'src/Redux/CDICandidates/Selectors';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { CdiiDetailScreen } from 'src/Screens/CdiiDetail';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { DBCDIIStatus, EnumActivityPlanningDayStatus } from 'src/Services/API';
import styles from './Cdii.module.scss';
import { CDII_STATUS, DASHBOARD_MENU } from './Cdii.types';
import { filterCdi, getEndMission, getRepositionedStatus, sortCdi } from './utils';

export const dashboardMenuLabel = {
  [DASHBOARD_MENU.REPOSITIONING]: 'mes CDII',
  [DASHBOARD_MENU.REPOSITIONED]: 'en repositionnement',
  [DASHBOARD_MENU.REGIONAL]: 'de ma région',
};

const repositioningHeaders = [
  { label: 'nom' },
  { label: 'unité du dernier contrat' },
  { label: 'qualification du dernier contrat' },
  { label: 'fin de contrat le' },
  { label: 'prochaine disponibilité' },
  { label: 'lieu de résidence' },
  { label: 'statut' },
];

const repositionedHeaders = [...repositioningHeaders, { label: 'étape repositionnement' }];

export const CDIIRoute = () => {
  const navigate = useNavigate();
  const [initialSection, initialSelected] = (useTypedHash(ROUTES.TALENTS.CDII) ?? '').split(
    '/'
  ) as [DASHBOARD_MENU | '' | undefined, string | undefined];

  const { data: isCGC } = useIsCGC();
  const userInfo = useSelector(getAnalyticsUserInfo);
  const {
    data: cdii,
    isSuccess: cdiiSuccess,
    isLoading: cdiiLoading,
  } = useFetchCandidatesActivityPlanning();
  const {
    data: regionCdii,
    isLoading: regionCdiiLoading,
    isSuccess: regionCdiiSuccess,
  } = useFetchSuggestedCandidates({});
  const perimeterData = useFetchPerimeter({});
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [segmentedControlKey, setSegmentedControlKey] = useState<DASHBOARD_MENU>(
    initialSection || DASHBOARD_MENU.REPOSITIONING
  );

  const [selectedCdi, setSelectedCdi] = useState<string | undefined>(initialSelected);

  useEffect(
    () =>
      ReactGA.event(ANALYTICS_EVENT.CLICK_CDII_SCREEN, {
        ...userInfo,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    navigate(
      ROUTES.TALENTS.CDII.buildPath(
        {},
        {},
        [segmentedControlKey, selectedCdi].filter(Boolean).join('/')
      ),
      { replace: true }
    );
  }, [selectedCdi, segmentedControlKey, navigate]);

  const sortBy = useSelector(getCDISortBy);
  const sortOrder = useSelector(getCDISortOrder);

  const selectedCDIData = useMemo(
    () =>
      cdii?.find(cdii => cdii.id === selectedCdi) ??
      regionCdii?.find(cdii => cdii.id === selectedCdi),
    [cdii, selectedCdi, regionCdii]
  );

  const dataSources = useMemo(() => {
    return {
      repositioning: cdii?.filter(({ cdiiStatus }) => !cdiiStatus) ?? [],
      repositioned: cdii?.filter(({ cdiiStatus }) => cdiiStatus) ?? [],
      regional: regionCdii ?? [],
    };
  }, [cdii, regionCdii]);

  const filteredData = useMemo(() => {
    return {
      repositioning: filterCdi(dataSources.repositioning, searchValue),
      repositioned: filterCdi(dataSources.repositioned, searchValue),
      regional: filterCdi(dataSources.regional, searchValue),
    };
  }, [dataSources, searchValue]);

  const filteredAndSortedData = useMemo(() => {
    return {
      repositioning: sortCdi(filteredData.repositioning, sortBy, sortOrder),
      repositioned: sortCdi(filteredData.repositioned, sortBy, sortOrder),
      regional: sortCdi(filteredData.regional, sortBy, sortOrder),
    };
  }, [filteredData, sortBy, sortOrder]);

  const selectedDataSource = useMemo(() => {
    if (segmentedControlKey === DASHBOARD_MENU.REPOSITIONING)
      return filteredAndSortedData.repositioning;
    if (segmentedControlKey === DASHBOARD_MENU.REPOSITIONED)
      return filteredAndSortedData.repositioned;
    return filteredAndSortedData.regional;
  }, [filteredAndSortedData, segmentedControlKey]);

  useEffect(() => {
    if (
      searchValue &&
      selectedCdi &&
      selectedDataSource &&
      !selectedDataSource.find(candidate => candidate.id === selectedCdi)
    )
      setSelectedCdi(undefined);
  }, [selectedDataSource, selectedCdi, searchValue]);

  const getMissionStatus = useCallback(
    (
      missionEnd: { year?: number; month?: number; day?: number } | undefined,
      statut?: EnumActivityPlanningDayStatus
    ) => getEndMission(missionEnd, statut),
    []
  );

  const isLoading =
    (segmentedControlKey === 'regional' && regionCdiiLoading) ||
    (segmentedControlKey !== 'regional' && cdiiLoading);
  const isSuccess =
    (segmentedControlKey === 'regional' && regionCdiiSuccess) ||
    (segmentedControlKey !== 'regional' && cdiiSuccess);

  return (
    <>
      <TopBar title="cdii">
        <CDIITopBarComponent />
      </TopBar>
      <div className={styles.content}>
        <div className={styles.containerWithModal} ref={containerRef}>
          <div
            className={
              !!selectedCDIData ? styles.segmentedControlOnItemSelected : styles.segmentedControl
            }
          >
            <SegmentedControl
              controls={Object.values(DASHBOARD_MENU).filter(
                item => !(isCGC && item === DASHBOARD_MENU.REGIONAL)
              )}
              selected={segmentedControlKey}
              getValue={dashboardMenuItem => {
                const lengths = {
                  repositioning: filteredAndSortedData.repositioning.length,
                  repositioned: filteredAndSortedData.repositioned.length,
                  regional: filteredAndSortedData.regional.length,
                };
                return `${dashboardMenuLabel[dashboardMenuItem]} (${lengths[dashboardMenuItem]})`;
              }}
              onSelectionChange={dashboardMenuItem => {
                setSegmentedControlKey(dashboardMenuItem);

                if (segmentedControlKey === DASHBOARD_MENU.REPOSITIONING)
                  ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_REPOSITIONING, {
                    ...userInfo,
                  });
                if (segmentedControlKey === DASHBOARD_MENU.REPOSITIONED)
                  ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_REPOSITIONED, {
                    ...userInfo,
                  });
                if (segmentedControlKey === DASHBOARD_MENU.REGIONAL)
                  ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_FROM_MY_REGION, {
                    ...userInfo,
                  });
              }}
            />
          </div>
          <div className={styles.searchBar}>
            <TypeAndEnter
              selectedValue={searchValue}
              setSelectedValue={newValue => setSearchValue(newValue)}
              placeholder="rechercher"
            />
          </div>
          {isLoading ? (
            <div className={styles.loadingScreen}>
              <Loader heightInRem={4} className={styles.loader} />
              <p>chargement des CDII-TT, cela peut prendre du temps</p>
            </div>
          ) : isSuccess && selectedDataSource && selectedDataSource.length > 0 ? (
            <div className={styles.mainContainer}>
              <div className={styles.cdiiContainer}>
                {!!selectedCDIData ? (
                  <div className={styles.detailsContainer}>
                    <div className={styles.cardsContainer}>
                      {selectedDataSource.map(candidate => {
                        const cdiiStatus = getMissionStatus(
                          candidate.lastContract?.endDate,
                          candidate.activityPlanning?.find(
                            day =>
                              moment(day.date)?.format('DD/MM/YYYY') ===
                              moment().format('DD/MM/YYYY')
                          )?.status
                        );
                        const repositionedCdiiStatus = getRepositionedStatus(
                          candidate.cdiiStatus ?? DBCDIIStatus.available
                        );

                        return (
                          <HorizontalCardWithTitle
                            key={candidate.id}
                            title={`${candidate.firstName ?? ''} ${candidate.name ?? ''}`}
                            subtitles={[
                              candidate.lastContract?.qualification?.label ??
                                'pas de contrat en cours pour ce CDII',
                              candidate.lastContract?.endDate?.year
                                ? `date de fin 
                              ${moment(
                                `${candidate.lastContract?.endDate?.year}/${candidate.lastContract?.endDate?.month}/${candidate.lastContract?.endDate?.day}`,
                                'YYYY/MM/DD'
                              ).format('DD/MM/YYYY')}`
                                : '',
                            ]}
                            leftIcon={
                              <>
                                {!cdii || !regionCdii || isLoading ? (
                                  <ContentLoader
                                    height="2rem"
                                    width="100%"
                                    uniqueKey={`${candidate.id}calendarBadge`}
                                  >
                                    <rect x="1%" y="10" rx="4" ry="4" width="50%" height="10" />
                                  </ContentLoader>
                                ) : (
                                  <div className={styles.badgeContainer}>
                                    <Badge
                                      value={cdiiStatus}
                                      className={classnames({
                                        [styles.badgeInMission]: cdiiStatus === CDII_STATUS.MISSION,
                                        [styles.badgeSoonAvailable]:
                                          cdiiStatus === CDII_STATUS.SOON_AVAILABLE,
                                        [styles.badgeIntermission]:
                                          cdiiStatus === CDII_STATUS.INTERMISSION,
                                        [styles.badgeAbsent]: cdiiStatus === CDII_STATUS.ABSENT,
                                      })}
                                    />
                                    {segmentedControlKey !== 'repositioning' &&
                                      candidate.cdiiStatus !== undefined && (
                                        <div
                                          className={styles.repositionedUpperBadge}
                                          key={`${candidate.id}8`}
                                        >
                                          <Badge
                                            value={repositionedCdiiStatus ?? CDII_STATUS.ABSENT}
                                            className={classnames({
                                              [styles.badgeSuggested]:
                                                candidate.cdiiStatus === DBCDIIStatus.suggested,
                                              [styles.badgeAvailable]:
                                                candidate.cdiiStatus === DBCDIIStatus.available,
                                              [styles.badgeProcessing]:
                                                candidate.cdiiStatus === DBCDIIStatus.processing,
                                            })}
                                          />
                                        </div>
                                      )}
                                  </div>
                                )}
                              </>
                            }
                            className={
                              candidate.id === selectedCdi ? styles.selectedRow : styles.card
                            }
                            classNameLeftIcon={styles.badgeInCard}
                            onClick={() => {
                              setSelectedCdi(candidate.id);
                            }}
                          />
                        );
                      })}
                    </div>
                    <div className={styles.sliderOpen}>
                      <CdiiDetailScreen
                        candidateId={selectedCDIData.id ?? ''}
                        showCloseButton
                        onClose={() => setSelectedCdi(undefined)}
                        statut={getEndMission(
                          selectedCDIData?.lastContract?.endDate,
                          selectedCDIData?.activityPlanning?.find(
                            day =>
                              moment(day.date)?.format('DD/MM/YYYY') ===
                              moment().format('DD/MM/YYYY')
                          )?.status
                        )}
                        dashboardLabel={segmentedControlKey}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {selectedCDIData === undefined && (
                      <div className={styles.tableOverflow}>
                        <Table
                          hasFixedHeaders={true}
                          headers={
                            segmentedControlKey !== 'repositioning'
                              ? repositionedHeaders
                              : repositioningHeaders
                          }
                          lineActions={index => setSelectedCdi(selectedDataSource[index ?? 0].id)}
                          data={selectedDataSource.map(candidate => {
                            const brand = perimeterData.data?.companies?.find(company =>
                              company.agencies?.some(
                                agency => agency === candidate.lastContract?.agency?.id
                              )
                            );

                            const cdiiStatus = getMissionStatus(
                              candidate.lastContract?.endDate,
                              candidate.activityPlanning?.find(
                                day =>
                                  moment(day.date)?.format('DD/MM/YYYY') ===
                                  moment().format('DD/MM/YYYY')
                              )?.status
                            );

                            const repositionedCdiiStatus = getRepositionedStatus(
                              candidate.cdiiStatus ?? DBCDIIStatus.available
                            );

                            const nextAvailabilityDate = candidate.activityPlanning?.find(
                              day =>
                                moment(day.date).isSameOrAfter(moment(), 'day') &&
                                day.status === 'Intermission'
                            )?.date;

                            return [
                              <p className={styles.nameTextContainer} key={`${candidate.id}1`}>
                                {`${candidate.firstName ?? ''} ${
                                  candidate.name ?? ''
                                }`.toLocaleLowerCase()}
                              </p>,
                              <p className={styles.textContainer} key={`${candidate.id}2`}>
                                {brand &&
                                  `${brand.brand?.name} - ${
                                    candidate.lastContract?.agency?.id ?? 'non précisé'
                                  }`}
                              </p>,
                              <p
                                className={styles.textContainer}
                                onClick={() => {
                                  setSelectedCdi(candidate.id);
                                }}
                                key={`${candidate.id}3`}
                              >
                                {candidate.lastContract?.qualification?.label?.toLocaleLowerCase() ??
                                  'pas de contrat en cours pour ce CDII'}
                              </p>,
                              <p className={styles.textContainer} key={`${candidate.id}4`}>
                                {candidate.lastContract?.endDate?.year
                                  ? moment(
                                      `${candidate.lastContract?.endDate?.year}/${candidate.lastContract?.endDate?.month}/${candidate.lastContract?.endDate?.day}`,
                                      'YYYY/MM/DD'
                                    ).format('DD/MM/YYYY')
                                  : ''}
                              </p>,
                              <p className={styles.nextAvailability} key={`${candidate.id}5`}>
                                {nextAvailabilityDate
                                  ? moment(nextAvailabilityDate).format('DD/MM/YYYY')
                                  : 'dans + de 4 semaines'}
                              </p>,
                              <div className={styles.columnCell} key={`${candidate.id}6`}>
                                <p className={styles.textContainer}>
                                  {`${
                                    candidate.candidateAddress?.candidateCityName ??
                                    'ville non renseignée'
                                  }`.toLocaleLowerCase()}
                                </p>
                                <p className={styles.textContainer}>
                                  {`CP ${
                                    candidate.candidateAddress?.candidatePostalCode ??
                                    'non renseigné'
                                  }`}
                                </p>
                              </div>,
                              <div className={styles.badgeContainer} key={`${candidate.id}7`}>
                                {!cdii || cdiiLoading ? (
                                  <ContentLoader
                                    height="2rem"
                                    width="100%"
                                    uniqueKey={`${candidate.id}secondcalendarBadge`}
                                  >
                                    <rect x="1%" y="10" rx="4" ry="4" width="50%" height="10" />
                                  </ContentLoader>
                                ) : (
                                  <Badge
                                    value={cdiiStatus}
                                    className={classnames({
                                      [styles.badgeInMission]: cdiiStatus === CDII_STATUS.MISSION,
                                      [styles.badgeSoonAvailable]:
                                        cdiiStatus === CDII_STATUS.SOON_AVAILABLE,
                                      [styles.badgeIntermission]:
                                        cdiiStatus === CDII_STATUS.INTERMISSION,
                                      [styles.badgeAbsent]: cdiiStatus === CDII_STATUS.ABSENT,
                                    })}
                                  />
                                )}
                              </div>,
                              segmentedControlKey !== 'repositioning' && (
                                <div className={styles.badgeContainer} key={`${candidate.id}8`}>
                                  {candidate.cdiiStatus !== undefined && (
                                    <Badge
                                      value={repositionedCdiiStatus ?? CDII_STATUS.ABSENT}
                                      className={classnames({
                                        [styles.badgeSuggested]:
                                          candidate.cdiiStatus === DBCDIIStatus.suggested,
                                        [styles.badgeAvailable]:
                                          candidate.cdiiStatus === DBCDIIStatus.available,
                                        [styles.badgeProcessing]:
                                          candidate.cdiiStatus === DBCDIIStatus.processing,
                                      })}
                                    />
                                  )}
                                </div>
                              ),
                            ];
                          })}
                          className={classnames(styles.table, {
                            [styles.repositionedTable]: segmentedControlKey === 'repositioned',
                          })}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : selectedDataSource.length === 0 ? (
            <div className={styles.noResult}>aucun résultat ne correspond à votre recherche</div>
          ) : (
            <>Une erreur s'est produite</> // TODO plus beau message d'erreur
          )}
        </div>
      </div>
    </>
  );
};
