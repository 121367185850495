import { TopBar } from 'src/Components/TopBar';
import { MissionsTopBarComponent } from 'src/Components/TopBar/MissionsTopBarComponent';
import ListMissions from './ListMissions';
import MissionDetail from './MissionDetail';
import styles from './Missions.module.scss';

export const MissionsRoute = () => {
  return (
    <>
      <TopBar title="commandes">
        <MissionsTopBarComponent />
      </TopBar>
      <div className={styles.content}>
        <ListMissions />
        <MissionDetail />
      </div>
    </>
  );
};
