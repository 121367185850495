import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  EnumCandidateCandidateAdvancement,
  EnumCandidateCompanyAdvancement,
} from 'src/Services/API';
import StatusModalContent from './StatusModal.component';

const EnhancedStatusModal = () => {
  const navigate = useNavigate();
  const state = useLocation().state as {
    candidateId: string;
    candidateStatus: EnumCandidateCandidateAdvancement;
    companyStatus: EnumCandidateCompanyAdvancement;
  };

  const onClose: () => void = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <StatusModalContent
      candidateId={state.candidateId}
      candidateStatus={state.candidateStatus}
      companyStatus={state.companyStatus}
      onClose={onClose}
    />
  );
};

export default EnhancedStatusModal;
