import React from 'react';

import { CircleAdd } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  ComboBox,
  FetchButton,
  ModalDeprecated,
  PopupActions,
  RadioButtonGroup,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { workAccidentAnalysisCreation } from 'src/Assets_DEPRECATED';
import { useCreateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useCreateWorkAccidentAnalysis';
import { useFetchAccidentsUnderMinStoppageDuration } from 'src/Hooks/WorkAccidentAnalysis/useFetchAccidentsUnderMinStoppageDuration';
import { useFetchWorkAccidentsByAgencies } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentsByAgencies';
import { ROUTES } from 'src/Routes/Routes.types';
import {
  AgencyWorkAccidents,
  CandidatesWorkAccidents,
  DateFilterStrategy,
  OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { pluralFormat } from 'src/Utils/pluralFormat';
import Styles from './CreateWorkAccidentAnalysisModal.module.scss';
import { toDate } from 'src/Utils/datetime';

const CreateWorkAccidentAnalysisModal = () => {
  const navigate = useNavigate();
  const ref = useRef<PopupActions>(null);

  const { data: allWorkStoppages, isSuccess } = useFetchAccidentsUnderMinStoppageDuration();
  const createWorkAccidentAnalysis = useCreateWorkAccidentAnalysis();
  const creationFetchStatus = toFetchStatus(createWorkAccidentAnalysis);

  const [keyword, setKeyword] = useState('');
  const [allCandidates, setAllCandidates] = useState<
    OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto[]
  >([]);
  const [selectedCandidate, setSelectedCandidate] = useState<string | null>(null);

  const [selectedCandidateWorkStoppages, setSelectedCandidateWorkStoppages] = useState<
    CandidatesWorkAccidents[]
  >([]);
  const [
    selectedCandidateWithoutWorkStoppages,
    setSelectedCandidateWithoutWorkStoppages,
  ] = useState<AgencyWorkAccidents[]>([]);
  const [selectedWorkStoppage, setSelectedWorkStoppage] = useState<string | undefined>(undefined);
  const [selectedAccident, setSelectedAccident] = useState<string | undefined>(undefined);

  const fetchAllAccidents = useFetchWorkAccidentsByAgencies({
    startDate: moment().subtract(2, 'years').toDate(),
    dateFilterStrategy: DateFilterStrategy.STARTAFTERSTARTDATE,
  });

  const reset = () => {
    setKeyword('');
    setSelectedCandidate(null);
    setSelectedWorkStoppage(undefined);
    setSelectedAccident(undefined);
  };

  const onClose = () => {
    ref.current?.close();
    reset();
  };

  useEffect(() => {
    const newAllCandidates: OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto[] = [];
    allWorkStoppages?.workStoppages.forEach(workStoppage => {
      if (
        workStoppage.candidate &&
        !newAllCandidates.find(candidate => candidate.id === workStoppage.candidate?.id)
      ) {
        newAllCandidates.push(workStoppage.candidate);
      }
    });
    const accidentIdsWithStoppage = allWorkStoppages?.workStoppages.map(
      stoppage => stoppage.workAccidentId
    );
    if (accidentIdsWithStoppage && fetchAllAccidents.data) {
      const accidentsWithoutStoppage = fetchAllAccidents.data.filter(acc => {
        return !accidentIdsWithStoppage.includes(acc.accidentId);
      });
      const candidatesWithoutStoppage = accidentsWithoutStoppage.map(acc => {
        return {
          id: acc.candidate?.id ?? '',
          lastName: acc.candidate?.name ?? '',
          name: acc.candidate?.firstName ?? '',
        };
      });
      newAllCandidates.push(...candidatesWithoutStoppage);
    }
    setAllCandidates(newAllCandidates);
  }, [allWorkStoppages, fetchAllAccidents.data]);

  useEffect(() => {
    setSelectedCandidateWorkStoppages(
      allWorkStoppages?.workStoppages.filter(
        workStoppage => workStoppage.candidate?.id === selectedCandidate
      ) ?? []
    );
  }, [allWorkStoppages?.workStoppages, selectedCandidate]);

  useEffect(() => {
    setSelectedCandidateWithoutWorkStoppages(
      fetchAllAccidents.data?.filter(acc => acc.candidate?.id === selectedCandidate) ?? []
    );
  }, [fetchAllAccidents.data, selectedCandidate]);

  return (
    <ModalDeprecated
      ref={ref}
      trigger={
        <Button.Primary.XSmall
          disabled={!allCandidates.length}
          leftIcon={<CircleAdd />}
          text="ajouter"
        />
      }
      onOpen={reset}
      onClose={onClose}
      title={`ajouter une analyse AT`}
      icon={<img src={workAccidentAnalysisCreation} alt="work accident analysis creation" />}
      footerActionsLeft={[<Button.Tertiary onClick={reset}>tout réinitialiser</Button.Tertiary>]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={!selectedWorkStoppage && !selectedAccident}
          onClick={() => {
            const fullSelectedWorkStoppage = allWorkStoppages?.workStoppages.find(
              workStoppage => workStoppage.id === selectedWorkStoppage
            );
            if (fullSelectedWorkStoppage && fullSelectedWorkStoppage?.workAccidentId) {
              createWorkAccidentAnalysis.mutate(
                {
                  accidentId: fullSelectedWorkStoppage.workAccidentId,
                  candidateId: fullSelectedWorkStoppage.candidate?.id ?? '',
                  firstName: fullSelectedWorkStoppage.candidate?.name ?? '',
                  lastName: fullSelectedWorkStoppage.candidate?.lastName ?? '',
                  analyseId: fullSelectedWorkStoppage.id ?? '',
                  startDate: fullSelectedWorkStoppage.startDate,
                  endDate: fullSelectedWorkStoppage.endDate,
                  stoppageDuration: fullSelectedWorkStoppage.stoppageDuration ?? 0,
                },
                {
                  onSuccess: data => {
                    onClose();
                    navigate(
                      ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({
                        analysisId: data,
                      })
                    );
                  },
                }
              );
            }
            const fullSelectedAccident = fetchAllAccidents.data?.find(
              accident => accident.accidentId === selectedAccident
            );
            if (fullSelectedAccident) {
              const { year, month, day } = fullSelectedAccident.date ?? {};
              createWorkAccidentAnalysis.mutate(
                {
                  accidentId: fullSelectedAccident.accidentId ?? '',
                  candidateId: fullSelectedAccident.candidate?.id ?? '',
                  firstName: fullSelectedAccident.candidate?.firstName ?? '',
                  lastName: fullSelectedAccident.candidate?.name ?? '',
                  analyseId: fullSelectedAccident.accidentId ?? '',
                  startDate: year && month && day ? new Date(year, month - 1, day) : undefined,
                  endDate: undefined,
                  stoppageDuration: 0,
                },
                {
                  onSuccess: data => {
                    onClose();
                    navigate(
                      ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({
                        analysisId: data,
                      })
                    );
                  },
                }
              );
            }
          }}
          fetchStatus={creationFetchStatus}
        />,
      ]}
    >
      <>
        <WithLightTitle
          title="choisir le nom de l'intérimaire concerné"
          className={Styles.CandidateSelection}
        >
          <ComboBox
            id="SelectCandidateName"
            value={selectedCandidate}
            onSearch={keyword => setKeyword(keyword)}
            searchResults={
              keyword.length > 0
                ? allCandidates
                    .filter(candidate =>
                      `${candidate?.name} ${candidate?.lastName}`
                        .toLowerCase()
                        .includes(keyword.toLowerCase())
                    )
                    .sort((a, b) =>
                      `${a.name} ${a.lastName}` < `${b.name} ${b.lastName}` ? -1 : 1
                    )
                    .sort((a, b) =>
                      `${a.name} ${a.lastName}`.toLowerCase().indexOf(keyword.toLowerCase()) <
                      `${b.name} ${b.lastName}`.toLowerCase().indexOf(keyword.toLowerCase())
                        ? -1
                        : 1
                    )
                    .map(candidate => candidate.id) ?? []
                : []
            }
            keyValueExtractor={candidateId => ({
              key: candidateId ?? 'unknown',
              value: (allCandidates
                .filter(candidate => candidate.id === candidateId)
                ?.map(candidate => `${candidate?.name} ${candidate?.lastName}`) ?? ['unknown'])[0],
            })}
            onChange={searchResult => setSelectedCandidate(searchResult ?? null)}
            minLengthToSearch={1}
          />
        </WithLightTitle>
        {isSuccess &&
          (selectedCandidateWorkStoppages.length > 0 ||
            selectedCandidateWithoutWorkStoppages.length > 0) && (
            <>
              <div className={Styles.WorkStoppagesCount}>
                {pluralFormat(
                  selectedCandidateWorkStoppages.length +
                    selectedCandidateWithoutWorkStoppages.length,
                  'absence identifiée'
                )}{' '}
                pour cet intérimaire
              </div>
              {selectedCandidateWorkStoppages.length > 0 &&
                selectedCandidateWorkStoppages.map(workStoppage => (
                  <React.Fragment key={workStoppage.id}>
                    <div className={Styles.Separator} />
                    <div className={Styles.WorkStoppageRadioButtonContainer}>
                      <RadioButtonGroup
                        name="workStoppages"
                        radioButtonsInfo={[
                          {
                            id: workStoppage.id ?? ' ',
                            value: workStoppage.id ?? ' ',
                            label: (
                              <div className={Styles.WorkStoppageTexts}>
                                <div className={Styles.WorkStoppageTitle}>
                                  {`${workStoppage.candidate?.name} ${workStoppage.candidate?.lastName}`}
                                </div>
                                <div className={Styles.WorkStoppageSubtitle}>
                                  {workStoppage.startDate
                                    ? moment(workStoppage.startDate).format('DD/MM/Y')
                                    : '--/--/--'}
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        onChange={() => setSelectedWorkStoppage(workStoppage.id)}
                        selected={selectedWorkStoppage}
                      />
                    </div>
                  </React.Fragment>
                ))}
              {selectedCandidateWithoutWorkStoppages.length > 0 &&
                selectedCandidateWithoutWorkStoppages.map(acc => (
                  <React.Fragment key={acc.accidentId}>
                    <div className={Styles.Separator} />
                    <div className={Styles.WorkStoppageRadioButtonContainer}>
                      <RadioButtonGroup
                        name="workStoppages"
                        radioButtonsInfo={[
                          {
                            id: acc.accidentId ?? ' ',
                            value: acc.accidentId ?? ' ',
                            label: (
                              <div className={Styles.WorkStoppageTexts}>
                                <div className={Styles.WorkStoppageTitle}>
                                  {`${acc.candidate?.firstName} ${acc.candidate?.name}`}
                                </div>
                                <div className={Styles.WorkStoppageSubtitle}>
                                  {`${
                                    acc.date
                                      ? moment(toDate(acc.date)).format('DD/MM/Y')
                                      : '--/--/--'
                                  }`}
                                </div>
                              </div>
                            ),
                          },
                        ]}
                        onChange={() => setSelectedAccident(acc.accidentId)}
                        selected={selectedAccident}
                      />
                    </div>
                  </React.Fragment>
                ))}
              <div className={Styles.Separator} />
            </>
          )}
      </>
    </ModalDeprecated>
  );
};

export default CreateWorkAccidentAnalysisModal;
