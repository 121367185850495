import {
  AutoSuggest,
  Badge,
  Button,
  PopupMenu,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  PinLocate,
  WhiteCircleCross,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { Department } from 'src/Services/API';
import styles from './DepartmentsPopupMenu.module.scss';
import { Props } from './DepartmentsPopupMenu.types';

const DepartmentsPopupMenu = ({
  allDepartments,
  departmentsFetchStatus,
  selectedDepartments,
  setSelectedDepartments,
}: Props) => {
  const [localDepartments, setLocalDepartments] = useState(selectedDepartments);
  const [searchResults, setSearchResults] = useState<Department[]>([]);
  const [open, setOpen] = useState(false);
  const [updateAnimation, setUpdateAnimation] = useState(true);
  const reset = () => {
    setLocalDepartments(selectedDepartments);
    setSearchResults([]);
  };

  useEffect(() => {
    setLocalDepartments(selectedDepartments);
  }, [selectedDepartments]);

  useEffect(() => {
    setUpdateAnimation(!updateAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDepartments]);

  return (
    <PopupMenu
      open={open}
      className={styles.popupMenu}
      width={400}
      position="bottom left"
      onClose={() => {
        setSelectedDepartments([...localDepartments]);
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      trigger={
        <Button.Secondary
          className={classnames(styles.filterButton, { [styles.openPopup]: open })}
          text="rayon"
          leftIcon={<PinLocate />}
          rightIcon={
            <Badge
              value={localDepartments.length.toString()}
              className={classnames(styles.dot, { [styles.openPopupDot]: open })}
            />
          }
        />
      }
    >
      <div className={styles.popupContainer}>
        <WithLightTitle title="sur le département">
          <AutoSuggest
            id="departmentSearch"
            className={styles.departmentSearch}
            value={localDepartments}
            searchResults={searchResults}
            fetchStatus={departmentsFetchStatus}
            keyValueExtractor={(departments: Department) => ({
              key: departments.id ?? '',
              value: departments.label ?? '',
            })}
            onChange={(departments: Department[]) => {
              setLocalDepartments(departments);
            }}
            onSearch={search =>
              setSearchResults(
                allDepartments.filter(
                  item =>
                    item.label?.toLowerCase().includes(search.toLowerCase()) &&
                    !localDepartments.includes(item)
                )
              )
            }
            disabled={localDepartments.length >= 10}
          />
          <div className={styles.badgeContainer}>
            {localDepartments.map(department => (
              <Badge
                key={department.label}
                className={styles.activeBadge}
                value={department.label?.toLowerCase() ?? ''}
                rightIcon={<WhiteCircleCross />}
                onRightIconClick={() =>
                  setLocalDepartments(localDepartments.filter(item => item.id !== department.id))
                }
              />
            ))}
          </div>
        </WithLightTitle>
        {localDepartments !== allDepartments && (
          <>
            <div className={styles.separator} />
            <p className={styles.lightTitle}>raccourcis</p>
            <PopupMenu.Item
              keepOpenOnClick
              icon={<PinLocate />}
              text="toute la france"
              className={styles.allButton}
              onClick={() => setLocalDepartments([])}
            />
          </>
        )}
        <div className={styles.buttons}>
          <Button.Secondary.XSmall
            text="annuler"
            onClick={() => {
              reset();
              setOpen(false);
            }}
          />
          <Button.Primary.XSmall
            text="valider"
            onClick={() => {
              setSelectedDepartments([...localDepartments]);
              setOpen(false);
            }}
          />
        </div>
      </div>
    </PopupMenu>
  );
};

export default DepartmentsPopupMenu;
