import { formatDepartments } from 'src/Utils/formatDepartments';
import styles from './TalentAcceptedMobility.module.scss';
import { Props } from './TalentAcceptedMobility.types';

const TalentAcceptedMobility = ({ acceptedMobility }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>mobilité acceptée</div>
      <div className={styles.acceptedMobility}>
        {acceptedMobility !== undefined && acceptedMobility.length > 0
          ? formatDepartments(acceptedMobility)
          : 'inconnue'}
      </div>
    </div>
  );
};

export default TalentAcceptedMobility;
