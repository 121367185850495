import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QualificationState } from './Types';

const initialState: QualificationState = {
  currentQualification: undefined,
};

export const { reducer: qualificationsReducer, actions: qualificationsActions } = createSlice({
  name: 'qualifications',
  initialState,
  reducers: {
    setCurrentQualification: (
      state,
      action: PayloadAction<QualificationState['currentQualification']>
    ) => {
      state.currentQualification = action.payload;
    },
  },
});
