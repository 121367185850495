import moment from 'moment';
import { TALENTS_STATUS } from './TemporaryWorkers.types';

export const getTalentStatus = (
  lastContractEndDate?: Date,
  availabilityDate?: Date
): TALENTS_STATUS => {
  const now = moment().hours(12).minutes(0).seconds(0).milliseconds(0);

  if (availabilityDate) {
    const availableDate = moment(availabilityDate).hours(12).minutes(0).seconds(0).milliseconds(0);
    const daysDiff = availableDate.diff(now, 'days');
    if (daysDiff > 0 && daysDiff <= 2) {
      return TALENTS_STATUS.SOON_AVAILABLE;
    }
  }

  if (lastContractEndDate) {
    const endDate = moment(lastContractEndDate).hours(12).minutes(0).seconds(0).milliseconds(0);
    if (endDate.isAfter(now)) {
      return TALENTS_STATUS.IN_MISSION;
    }
  }

  return TALENTS_STATUS.INTERMISSION;
};
