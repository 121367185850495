import { Badge } from '@randstad-lean-mobile-factory/react-components-core';
import classNames from 'classnames';
import { capitalizeNames } from 'src/Utils/capitalizeNames';
import { TALENTS_STATUS } from '../../../TemporaryWorkers.types';
import { getTalentStatus } from '../../../utils';
import styles from './TalentDetailsHeader.module.scss';
import { CandidateType, Props } from './TalentDetailsHeader.types';

export const TalentDetailsHeader = ({ talent }: Props) => {
  const talentStatus = getTalentStatus(
    talent?.lastContract?.endDate,
    talent?.availabilityForConsultantDate
  );

  return (
    <div className={styles.container}>
      <div className={styles.talentName}>
        {`${capitalizeNames(talent?.firstName ?? '')} ${capitalizeNames(talent?.name ?? '')}`}
      </div>
      <div className={styles.agencyAndBadge}>
        <div className={styles.unity}>
          {(talent?.mainAgencyCode ?? 'non précisé') +
            ' | ' +
            (talent?.candidateType === CandidateType.INTERIMAIRE ? 'INT' : 'non précisé') +
            ' | '}
        </div>
        <div className={styles.badge}>
          <Badge
            className={classNames({
              [styles.badgeInMission]: talentStatus === TALENTS_STATUS.IN_MISSION,
              [styles.badgeIntermission]: talentStatus === TALENTS_STATUS.INTERMISSION,
              [styles.badgeSoonAvailable]: talentStatus === TALENTS_STATUS.SOON_AVAILABLE,
            })}
            value={talentStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default TalentDetailsHeader;
