export enum CDII_STATUS {
  INTERMISSION = 'intermission',
  SOON_AVAILABLE = 'bientôt dispo',
  MISSION = 'en mission',
  AVAILABLE = 'sos envoyé',
  SUGGESTED = 'suggéré',
  PROCESSING = 'en traitement',
  ABSENT = 'absent',
}

export enum CDII_ACTIONS {
  EMPTY_STATUS = 'vider le statut',
  DENY = 'refuser',
}

export enum DASHBOARD_MENU {
  REPOSITIONING = 'repositioning',
  REPOSITIONED = 'repositioned',
  REGIONAL = 'regional',
}
