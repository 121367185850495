import { HorizontalTextIconCard } from '@randstad-lean-mobile-factory/react-components-core';
import { SkillMatchingMission } from 'src/Services/API';
import styles from './TabSkillMatching.module.scss';
import { useEffect, useMemo, useState } from 'react';

const TabSkillMatching = ({
  skillMatchingMission,
}: {
  skillMatchingMission?: SkillMatchingMission;
}) => {
  const [acquis, setAcquis] = useState<number>(0);
  const [displayAcquis, setDisplayAcquis] = useState(true);
  const skillsDetailsLength = skillMatchingMission?.skillsDetails
    ? skillMatchingMission?.skillsDetails.length
    : 100;
  useEffect(() => {
    if (skillMatchingMission && skillMatchingMission.skillsDetails) {
      let nb = 0;
      skillMatchingMission?.skillsDetails.map(skill => (skill.known === true ? nb++ : ''));
      setAcquis(nb);
    }
  }, [skillMatchingMission]);

  const displaySection = useMemo(() => {
    if (skillMatchingMission?.skillsDetails) {
      return (
        <div className={styles.displaySection}>
          {skillMatchingMission?.skillsDetails
            .filter(skill => skill.known === displayAcquis)
            .map(skill => (
              <div className={styles.displaySectionItem}>
                <div className={displayAcquis ? styles.greenCircle : styles.redCircle}></div>
                <div className={styles.displaySectionLabel}>{skill.skillLabel}</div>
              </div>
            ))}
        </div>
      );
    }
  }, [skillMatchingMission, displayAcquis]);
  return (
    <div className={styles.bottomContainer}>
      <HorizontalTextIconCard
        labelClassName={styles.matchingScoreLabel}
        containerClassName={styles.matchingScoreContainer}
        label={
          skillMatchingMission?.matchScore
            ? `${Math.round(
                parseFloat(skillMatchingMission.matchScore) * 100
              )}% de compatibilité avec les compétences attendues sur cette qualification`
            : 'pas de qualification'
        }
      />
      <div className={styles.progressBar}>
        <div className={styles.details}>
          <div className={styles.detailsLabel}>détails</div>
          <div className={styles.detailsLegende}>
            <div className={styles.detailsLegendeContainer}>
              <div className={styles.greenBox}></div>
              <div className={styles.textLegende}>acquis</div>
            </div>
            <div className={styles.detailsLegendeContainer}>
              <div className={styles.redBox}></div>
              <div className={styles.textLegende}>non acquis</div>
            </div>
          </div>
        </div>
        <div className={styles.progressBarContainer}>
          <div
            className={styles.progressBarKey}
            style={{
              width: `${Math.round((acquis / skillsDetailsLength) * 100)}%`,
            }}
            onClick={() => setDisplayAcquis(true)}
          >
            {acquis}
          </div>
          {acquis !== skillsDetailsLength && (
            <div
              className={styles.progressBarKey2}
              style={{
                width: `${Math.round(
                  ((skillsDetailsLength - acquis) / skillsDetailsLength) * 100
                )}%`,
              }}
              onClick={() => setDisplayAcquis(false)}
            >
              {skillsDetailsLength - acquis}
            </div>
          )}
        </div>
      </div>
      {displaySection}
    </div>
  );
};
export default TabSkillMatching;
