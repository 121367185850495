import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConsultantPerimeter } from 'src/Services/API';
import { getDefaultBrandAndAgency, isValidActivePerimeter } from 'src/Services/Perimeter';
import { PerimeterState } from './Types';

const initialState: PerimeterState = {
  companies: [],
  currentBrand: undefined,
  currentAgency: undefined,
};

export const { reducer: perimeterReducer, actions: perimeterActions } = createSlice({
  name: 'perimeter',
  initialState,
  reducers: {
    setCurrentBrand: (state, action: PayloadAction<PerimeterState['currentBrand']>) => {
      state.currentBrand = action.payload;
      state.currentAgency = action.payload?.agencies?.[0] ?? '';
    },
    setCurrentAgency: (state, action: PayloadAction<PerimeterState['currentAgency']>) => {
      state.currentAgency = action.payload;
    },
    setCompanies: (state, action: PayloadAction<ConsultantPerimeter>) => {
      state.companies = action.payload.companies ?? [];
      if (
        !isValidActivePerimeter({
          currentAgency: state.currentAgency,
          currentBrand: state.currentBrand,
          companies: state.companies,
        })
      ) {
        const { brand, agency } = getDefaultBrandAndAgency(action.payload);
        state.currentAgency = agency;
        state.currentBrand = brand;
      }
    },
    setUserInfo: (state, action: PayloadAction<ConsultantPerimeter>) => {
      state.regionId = action.payload.regionId;
      state.zoneId = action.payload.zoneId;
      state.branchId = action.payload.branchId;
    },
    resetCurrent: state => {
      state = initialState;
    },
  },
});
