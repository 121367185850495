import React from 'react';
import { startOfWeek, endOfWeek, addDays, getWeek, addWeeks } from 'date-fns';
import { Props } from './CalendarHeader.types';
import styles from './CalendarHeader.module.scss';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { LeftArrow, RightArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';

const CalendarHeader = ({ onWeekChange, currentMonth, currentWeek }: Props) => {
  const firstDay = startOfWeek(currentMonth, { weekStartsOn: 1 });
  const lastDay = endOfWeek(currentMonth, { weekStartsOn: 1 });
  return (
    <div className={classnames(styles.header, styles.row)}>
      <div className={styles.weekNavigationText}>
        <Button.Tertiary className={styles.leftArrowButton} onClick={() => onWeekChange('prev')}>
          <LeftArrow />
        </Button.Tertiary>
        {`semaine ${currentWeek} | ${firstDay.getDate()} - ${moment(lastDay)
          .locale('fr')
          .format('ll')
          .toLowerCase()}`}
      </div>

      <div className={styles.weekNavigationText} onClick={() => onWeekChange('next')}>
        {`semaine ${getWeek(addWeeks(currentMonth, 1))} | ${addDays(
          firstDay,
          7
        ).getDate()} - ${moment(addDays(lastDay, 7)).locale('fr').format('ll').toLowerCase()}`}
        <Button.Tertiary className={styles.rightArrowButton} onClick={() => onWeekChange('next')}>
          <RightArrow />
        </Button.Tertiary>
      </div>
    </div>
  );
};
export default CalendarHeader;
