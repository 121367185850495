export const getMissionStatus = (candidateOrClientAccept?: boolean): string => {
  switch (candidateOrClientAccept) {
    case undefined:
      return '--';
    case true:
      return 'accord';
    case false:
      return 'refus';
  }
};
