import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractsState } from './Types';
import { searchContracts, fetchContractDetail } from './Thunks';
import { FETCH_STATUS } from 'src/Redux/Types';
import { SEARCH_LIMIT } from '../Search/Types';

const initialState: ContractsState = {
  contracts: [],
  currentContract: null,
  contractsFetchStatus: FETCH_STATUS.FULFILLED,
  currentContractFetchStatus: FETCH_STATUS.FULFILLED,
  currentRequestId: undefined,
  canLoadMore: false,
};

export const { reducer: contractsReducer, actions: contractsActions } = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setCurrentContract: (state, action: PayloadAction<ContractsState['currentContract']>) => {
      state.currentContract = action.payload;
    },
    reset: state => {
      state.contracts = [];
      state.currentRequestId = undefined;
      state.currentContract = null;
    },
  },
  extraReducers: builder => {
    // Search contracts
    builder.addCase(searchContracts.pending, (state, action) => {
      state.contractsFetchStatus = FETCH_STATUS.PENDING;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(searchContracts.fulfilled, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentRequestId === action.meta.requestId) {
        state.contracts = state.contracts.concat(action.payload);
        state.canLoadMore = action.payload.length === SEARCH_LIMIT;
        state.contractsFetchStatus = FETCH_STATUS.FULFILLED;
      }
    });
    builder.addCase(searchContracts.rejected, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentRequestId === action.meta.requestId) {
        state.contractsFetchStatus = FETCH_STATUS.REJECTED;
      }
    });

    // Fetch contract detail
    builder.addCase(fetchContractDetail.pending, state => {
      state.currentContractFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(fetchContractDetail.fulfilled, (state, action) => {
      state.currentContract = action.payload;
      // We make sure the contacting status is up to date in the sidebar
      const contractPreview = state.contracts.find(
        contract => contract.contractId === action.payload.contractId
      );
      if (contractPreview) {
        contractPreview.clientContactingStatus = action.payload.clientContactingStatus;
        contractPreview.candidateContactingStatus = action.payload.candidateContactingStatus;
      }
      state.currentContractFetchStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(fetchContractDetail.rejected, state => {
      state.currentContractFetchStatus = FETCH_STATUS.REJECTED;
    });
  },
});
