/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

import { Expose, Transform, Type, plainToClass } from 'class-transformer';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class ConsultantsService {
  /**
   *
   */
  static consultantsControllerGetPerimeter(
    options: IRequestOptions = {}
  ): Promise<ConsultantPerimeter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/perimeter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(ConsultantPerimeter, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static consultantsControllerGetConsultantDetails(
    params: {
      /**  */
      consultantIds: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConsultantInfo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/consultants';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { consultantIds: params['consultantIds'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(ConsultantInfo, response)), reject);
    });
  }
}

export class ActionsService {
  /**
   *
   */
  static actionsControllerGetAction(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Action> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(Action, response)), reject);
    });
  }
  /**
   *
   */
  static actionsControllerUpdateAction(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/actions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WorkAccidentAnalysisService {
  /**
   *
   */
  static workAccidentAnalysisControllerGetCandidatesAccidentsUnderMinStoppageDuration(
    params: {
      /**  */
      agencies: any | null[];
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetCandidatesAccidentsUnderMinStoppageDuration> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/workAccidentsUnderMinStoppageDuration';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencies: params['agencies'], brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) =>
          resolve(plainToClass(GetCandidatesAccidentsUnderMinStoppageDuration, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentsByAgencies(
    params: {
      /** requestBody */
      body?: GetWorkAccidentsByAgenciesQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AgencyWorkAccidents[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/workAccidentsByAgencies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(AgencyWorkAccidents, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidents(
    params: {
      /**  */
      agencies: any | null[];
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GetWorkAccidents> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/workAccidents';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencies: params['agencies'], brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(GetWorkAccidents, response)), reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentsActionPlans(
    params: {
      /**  */
      agencies: any | null[];
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentActionPlan[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/actionPlans';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencies: params['agencies'], brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(WorkAccidentActionPlan, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentActionPlan(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentActionPlan> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/actionPlan/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(WorkAccidentActionPlan, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerCreateWorkAccident(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateWorkAccidentBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/create';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerCloseWorkAccidentActionPlan(
    params: {
      /**  */
      workAccidentId: string;
      /**  */
      actionId: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/actionPlan/{actionId}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        workAccidentId: params['workAccidentId'],
        actionId: params['actionId'],
        brandCode: params['brandCode'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerEditWorkAccidentActionPlan(
    params: {
      /** requestBody */
      body?: EditWorkAccidentAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/actionPlan/{actionId}';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentById(
    params: {
      /**  */
      ids: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentAnalysis[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ids: params['ids'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(WorkAccidentAnalysis, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerUpdateWorkAccidentById(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateWorkAccidentBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentAnalysis> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/update';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(WorkAccidentAnalysis, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerUpdateArchiveStatusWorkAccidentById(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateArchiveStatusWorkAccidentBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentAnalysis> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/archive';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(WorkAccidentAnalysis, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerUpdateWorkAccidentConclusionSignatory(
    params: {
      /**  */
      id: string;
      /**  */
      file: any;
      /**  */
      signatory: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/updateWorkAccidentConclusionSignatory';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      if (params['signatory']) {
        if (Object.prototype.toString.call(params['signatory']) === '[object Array]') {
          for (const item of params['signatory']) {
            data.append('signatory', item as any);
          }
        } else {
          data.append('signatory', params['signatory'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentFiles(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentFileData[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/files';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(WorkAccidentFileData, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerUploadWorkAccidentFile(
    params: {
      /**  */
      id: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkAccidentFileData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/files';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(WorkAccidentFileData, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkAccidentFileData(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/file/{id}/data';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerGetWorkingAnalysePdf(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: GetPdfQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/pdf';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerDeleteWorkAccidentFiles(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/file/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static workAccidentAnalysisControllerFinalizeWorkAccidentAnalysis(
    params: {
      /** requestBody */
      body?: FinalizeATAnalysis;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/work-accident-analysis/{id}/finalizeWorkAccidentAnalysis';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AgenciesService {
  /**
   *
   */
  static agenciesControllerGetAgencyCandidatesPlanning(
    params: {
      /**  */
      agencyId: string;
      /**  */
      offset?: number;
      /**  */
      limit?: number;
      /**  */
      brandCode?: string;
      /**  */
      startDate: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateWithPlanning[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/candidatesPlanning';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        offset: params['offset'],
        limit: params['limit'],
        brandCode: params['brandCode'],
        startDate: params['startDate'],
      };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CandidateWithPlanning, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static agenciesControllerGetAgencyConsultants(
    params: {
      /**  */
      agencyId: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Consultant[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/consultants';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencyId: params['agencyId'], brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Consultant, response)), reject);
    });
  }
  /**
   *
   */
  static agenciesControllerGetRelatedCgc(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CGCIdLabel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/{id}/associatedCGC';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CGCIdLabel, response)), reject);
    });
  }
  /**
   *
   */
  static agenciesControllerGetAgenciesDetails(
    params: {
      /**  */
      agenciesIds: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AgencyDetails[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/{id}/agenciesDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agenciesIds: params['agenciesIds'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(AgencyDetails, response)), reject);
    });
  }
  /**
   *
   */
  static agenciesControllerGetAgenciesDistances(
    params: {
      /** requestBody */
      body?: GetAgenciesDistancesParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AgencyDistance[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/agencies/agenciesDistances';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(AgencyDistance, response)), reject);
    });
  }
}

export class SkillsManagementCentersService {
  /**
   *
   */
  static skillsManagementCentersControllerGetIsCgc(
    params: {
      /**  */
      agencyId: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skills-management-centers/isCGC';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencyId: params['agencyId'], brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static skillsManagementCentersControllerGetAgenciesInfoFromCgc(
    params: {
      /**  */
      cgcName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AgenciesInfo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/skills-management-centers/agenciesInfoFromCGC';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { cgcName: params['cgcName'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(AgenciesInfo, response)), reject);
    });
  }
}

export class CandidatesService {
  /**
   *
   */
  static candidatesControllerGetCandidatesActivityPlanning(
    params: {
      /**  */
      brandCode: string;
      /**  */
      agencyId: string;
      /**  */
      startDate: string;
      /**  */
      loadActivityPlannings: boolean;
      /**  */
      candidateId?: string;
      /**  */
      offset?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateSearchActivityPlaning[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/getCandidatesActivityPlanning';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        agencyId: params['agencyId'],
        startDate: params['startDate'],
        loadActivityPlannings: params['loadActivityPlannings'],
        candidateId: params['candidateId'],
        offset: params['offset'],
      };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CandidateSearchActivityPlaning, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateActivityPlanning(
    params: {
      /**  */
      startDate: string;
      /**  */
      candidateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateSearchActivityPlaning> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/getCandidateActivityPlanning';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { startDate: params['startDate'], candidateId: params['candidateId'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(CandidateSearchActivityPlaning, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerFetchSuggestedCandidates(
    params: {
      /**  */
      agencyId: string;
      /**  */
      qualificationId?: string;
      /**  */
      zipCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SuggestedCandidate[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/fetchSuggestedCandidates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        qualificationId: params['qualificationId'],
        zipCode: params['zipCode'],
      };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(SuggestedCandidate, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidate(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OSMCandidate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(OSMCandidate, response)), reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetTemporaryWorkerDetail(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Talent> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/temporaryWorkerDetail';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(Talent, response)), reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateCurrentContract(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OSMCandidateCurrentContractWithEndorsements> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/currentContract';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) =>
          resolve(plainToClass(OSMCandidateCurrentContractWithEndorsements, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateLatestCdiContract(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateCDIContract> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/cdiContract';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(CandidateCDIContract, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateContract(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateContract[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/contracts';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CandidateContract, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerGetCvCompany(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/cvcompany';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateQualifications(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateQualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/qualifications';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CandidateQualification, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateHabilitations(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateHabilitation[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/habilitations';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CandidateHabilitation, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateMemos(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateMemo[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/memos';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CandidateMemo, response)), reject);
    });
  }
  /**
   *
   */
  static candidatesControllerCreateCandidateMemo(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateMemoParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/createMemo';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerDeleteCandidateMemo(
    params: {
      /**  */
      memoId: string;
      /**  */
      candidateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{candidateId}/memo/{memoId}';
      url = url.replace('{memoId}', params['memoId'] + '');
      url = url.replace('{candidateId}', params['candidateId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerEditCandidateMemo(
    params: {
      /** requestBody */
      body?: EditMemoParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{candidateId}/editMemo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateLastContractEndDate(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/lastContractEndDate';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateLastMedicalVisit(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/lastMedicalVisit';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCandidateSensibilisationTesting(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateTesting[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/sensibilisationTesting';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CandidateTesting, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerGetFirstContractDate(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CandidateFirstContractDate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/contractsInformation';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(CandidateFirstContractDate, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerSendToCgc(
    params: {
      /**  */
      id: string;
      /**  */
      cgcId: string;
      /**  */
      agencyId: string;
      /**  */
      candidateName: string;
      /**  */
      candidateFirstName: string;
      /**  */
      sosComment: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DBCDII> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/sendToCgc';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        cgcId: params['cgcId'],
        agencyId: params['agencyId'],
        candidateName: params['candidateName'],
        candidateFirstName: params['candidateFirstName'],
        sosComment: params['sosComment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DBCDII, response)), reject);
    });
  }
  /**
   *
   */
  static candidatesControllerSendSuggestionToAgencies(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: SuggestedMissionsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/sendSuggestionToAgencies';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerRefuseSuggestedCandidate(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: RefuseSuggestedCandidateParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/refuseSuggestedCandidate';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerGetCdiContractDetails(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OSMCdiContractDetails> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/cdiContractDetails';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(OSMCdiContractDetails, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerChangeManuallyRepoStatus(
    params: {
      /** requestBody */
      body?: ChangeRepoStatusParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/changeManuallyRepoStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerSuggestCandidateToAgencies(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: SuggestedAgenciesParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/{id}/suggestCandidateToAgencies';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static candidatesControllerFetchAgencyTemporaryWorkers(
    params: {
      /** requestBody */
      body?: FetchAgencyTemporaryWorkersParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedTemporaryWorkersWithoutDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/fetchAgencyTemporaryWorkers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(PaginatedTemporaryWorkersWithoutDetail, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static candidatesControllerFetchRegionalTemporaryWorkers(
    params: {
      /** requestBody */
      body?: FetchRegionalTemporaryWorkersParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaginatedTemporaryWorkersWithoutDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/candidates/fetchRegionalTemporaryWorkers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(PaginatedTemporaryWorkersWithoutDetail, response)),
        reject
      );
    });
  }
}

export class ContactsService {
  /**
   *
   */
  static contactsControllerGetContact(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RawContact> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contacts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(RawContact, response)), reject);
    });
  }
}

export class CompaniesService {
  /**
   *
   */
  static companiesControllerGetCompanies(
    params: {
      /** requestBody */
      body?: GetCompaniesBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanySearchResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CompanySearchResult, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static companiesControllerGetYouplanCompanies(
    params: {
      /** requestBody */
      body?: GetYouplanCompaniesBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanCompany[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/youplan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(YouplanCompany, response)), reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetServices(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyService[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/services';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CompanyService, response)), reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetContacts(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyContact[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/contacts';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CompanyContact, response)), reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetRequestReasons(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyRequestReason[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/requestreasons';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CompanyRequestReason, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyLastActions(
    params: {
      /**  */
      companyId: string;
      /** requestBody */
      body?: GetCompaniesActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyAction[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{companyId}/lastActions';
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CompanyAction, response)), reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyBySiret(
    params: {
      /**  */
      siret: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{siret}';
      url = url.replace('{siret}', params['siret'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companiesControllerAddNonExistentCompanyQualification(
    params: {
      /**  */
      companyId: string;
      /** requestBody */
      body?: PatchCompanyQualification;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyNewQualification> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{companyId}/addQualification';
      url = url.replace('{companyId}', params['companyId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(CompanyNewQualification, response)),
        reject
      );
    });
  }
}

export class YouplanMissionsService {
  /**
   *
   */
  static youplanMissionsControllerGetYouplanMissions(
    params: {
      /**  */
      agencyId: string;
      /**  */
      offset: number;
      /**  */
      limit: number;
      /**  */
      companyId: string;
      /**  */
      sortBy?: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMission[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        offset: params['offset'],
        limit: params['limit'],
        companyId: params['companyId'],
        sortBy: params['sortBy'],
        brandCode: params['brandCode'],
      };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(YouplanMission, response)), reject);
    });
  }
  /**
   *
   */
  static youplanMissionsControllerSetProcessingDateYouPlanMission(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMissionAction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/SetProcessingDate';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(YouplanMissionAction, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static youplanMissionsControllerGetYouplanMissionDetail(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MissionDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(MissionDetail, response)), reject);
    });
  }
  /**
   *
   */
  static youplanMissionsControllerCreateMissionFromYouplan(
    params: {
      /**  */
      brandCode: string;
      /** requestBody */
      body?: CreateMissionFromYouplanParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateMissionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/youplan';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(CreateMissionResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static youplanMissionsControllerMarkAsRead(
    params: {
      /** requestBody */
      body?: MarkMissionAsReadBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMissionsActionSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/markAsRead';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(YouplanMissionsActionSummaryResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static youplanMissionsControllerResolveRenewalConflict(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMissionActionSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/resolveRenewalConflict';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(YouplanMissionActionSummaryResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static youplanMissionsControllerUpdateRelatedMission(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMissionActionSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/updateRelatedMission';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(YouplanMissionActionSummaryResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static youplanMissionsControllerCancelRelatedMission(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<YouplanMissionActionSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/cancelRelatedMission';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(YouplanMissionActionSummaryResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static youplanMissionsControllerRefuseCandidateFromYouplanRelatedMission(
    params: {
      /**  */
      id: string;
      /**  */
      candidateId: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/youplan-missions/{id}/refuseCandidateFromRelatedMission';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);
      configs.params = { candidateId: params['candidateId'], brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExternalDemandsService {
  /**
   *
   */
  static externalDemandsControllerGetAgencyBrandCode(
    params: {
      /**  */
      agencyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/brandCode/{agencyId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerCreateAgencyJournal(
    params: {
      /**  */
      agencyId: string;
      /** requestBody */
      body?: CreateAgencyJournalParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/brandCode/{agencyId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerGetExternalDemand(
    params: {
      /** requestBody */
      body?: GetExternalCommandJournalParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExternalDemandDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/demands';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(ExternalDemandDto, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static externalDemandsControllerUpdateDemands(
    params: {
      /** requestBody */
      body?: UpdateDemandsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/updateDemands';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerGetAllMailParameters(
    params: {
      /**  */
      agencyId: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MailParameterDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/allMailParameters/{agencyId}';
      url = url.replace('{agencyId}', params['agencyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(MailParameterDto, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static externalDemandsControllerReplayCommandsError(
    params: {
      /** requestBody */
      body?: ReplayExternalDemandParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/replay';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalDemandsControllerUpdateMailParameter(
    params: {
      /** requestBody */
      body?: MailParametersToUpdateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/external-demands/mailParameter';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MissionsService {
  /**
   *
   */
  static missionsControllerGetMissions(
    params: {
      /** requestBody */
      body?: SearchMissionsBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MissionsWithCount> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(MissionsWithCount, response)), reject);
    });
  }
  /**
   *
   */
  static missionsControllerGetMissionsInfos(
    params: {
      /**  */
      missionIds: any | null[];
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MissionDetail[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/missionsDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { missionIds: params['missionIds'], brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(MissionDetail, response)), reject);
    });
  }
  /**
   *
   */
  static missionsControllerGetMission(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MissionDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(MissionDetail, response)), reject);
    });
  }
  /**
   *
   */
  static missionsControllerCreateMissionAction(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateActionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/{id}/action';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(CreateActionResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static missionsControllerCreateMissionWithoutSchedule(
    params: {
      /**  */
      brandCode: string;
      /** requestBody */
      body?: CreateCoreMission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateMissionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(CreateMissionResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static missionsControllerUpdateCandidateStatus(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateCandidateStatusBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/{id}/changeCandidateStatus';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static missionsControllerAddCandidateToMissions(
    params: {
      /** requestBody */
      body?: AttachCandidateToMissionsBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/missions/addCandidate';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PositionstudiesService {
  /**
   *
   */
  static positionStudiesControllerGetPositionStudiesCount(
    params: {
      /**  */
      since?: string;
      /**  */
      brandCode?: string;
      /**  */
      companyId: string;
      /**  */
      agencyId?: string;
      /**  */
      isVisible?: boolean;
      /**  */
      qualificationId: string;
      /**  */
      serviceId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        since: params['since'],
        brandCode: params['brandCode'],
        companyId: params['companyId'],
        agencyId: params['agencyId'],
        isVisible: params['isVisible'],
        qualificationId: params['qualificationId'],
        serviceId: params['serviceId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPositionStudies(
    params: {
      /**  */
      agencyId: string;
      /**  */
      since?: string;
      /**  */
      offset: number;
      /**  */
      limit: number;
      /**  */
      brandCode: string;
      /**  */
      qualificationId: string;
      /**  */
      companyId: string;
      /**  */
      isVisible?: boolean;
      /**  */
      serviceId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionStudy[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        since: params['since'],
        offset: params['offset'],
        limit: params['limit'],
        brandCode: params['brandCode'],
        qualificationId: params['qualificationId'],
        companyId: params['companyId'],
        isVisible: params['isVisible'],
        serviceId: params['serviceId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(PositionStudy, response)), reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPositionStudyById(
    params: {
      /**  */
      brandCode: string;
      /**  */
      positionStudyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionStudy> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/positionStudy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        positionStudyId: params['positionStudyId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PositionStudy, response)), reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPreventionSheetPdf(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: GetPreventionSheetPdfQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/pdf';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RepositoryService {
  /**
   *
   */
  static repositoryControllerGetQualifications(
    params: {
      /**  */
      keyword?: string;
      /**  */
      societyCode?: string;
      /**  */
      brand?: string;
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Qualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/qualifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        keyword: params['keyword'],
        societyCode: params['societyCode'],
        brand: params['brand'],
        brandCode: params['brandCode'],
      };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Qualification, response)), reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetDomains(
    params: {
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Domain[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/domains';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Domain, response)), reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetSalesphases(options: IRequestOptions = {}): Promise<Salesphase[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/salesphases';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Salesphase, response)), reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetDepartments(options: IRequestOptions = {}): Promise<Department[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/departments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Department, response)), reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetBusinessQualifications(
    params: {
      /** requestBody */
      body?: GetBusinessQualificationsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Qualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/jobQualifications';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Qualification, response)), reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetRisks(options: IRequestOptions = {}): Promise<Risk[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/risks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Risk, response)), reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetInjuryNatures(
    params: {
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InjuryNatures[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/injuryNatures';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(InjuryNatures, response)), reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetInjuryLocations(
    params: {
      /**  */
      brandCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InjuryLocations[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/injuryLocations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(InjuryLocations, response)), reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetInjuryLocationDetails(
    params: {
      /**  */
      brandCode?: string;
      /**  */
      injuryLocationId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InjuryLocationDetails[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/injuryLocationsDetails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        injuryLocationId: params['injuryLocationId'],
      };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(InjuryLocationDetails, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static repositoryControllerGetProtectionEquipments(
    options: IRequestOptions = {}
  ): Promise<ProtectionEquipments> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/protectionEquipments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(ProtectionEquipments, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static repositoryControllerGetCgcRepository(options: IRequestOptions = {}): Promise<CGC[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/cgc';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CGC, response)), reject);
    });
  }
  /**
   *
   */
  static repositoryControllerGetCities(
    params: {
      /**  */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<City[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repository/cities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(City, response)), reject);
    });
  }
}

export class ContractsService {
  /**
   *
   */
  static contractsControllerGetContracts(
    params: {
      /**  */
      agencyId: string;
      /**  */
      startDate: string;
      /**  */
      endDate?: string;
      /**  */
      offset: number;
      /**  */
      limit: number;
      /**  */
      brandCode: string;
      /**  */
      qualificationId?: string;
      /**  */
      companyId?: string;
      /**  */
      serviceId?: string;
      /**  */
      statusCode?: string;
      /**  */
      sortBy?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Contract[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contracts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        startDate: params['startDate'],
        endDate: params['endDate'],
        offset: params['offset'],
        limit: params['limit'],
        brandCode: params['brandCode'],
        qualificationId: params['qualificationId'],
        companyId: params['companyId'],
        serviceId: params['serviceId'],
        statusCode: params['statusCode'],
        sortBy: params['sortBy'],
      };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Contract, response)), reject);
    });
  }
  /**
   *
   */
  static contractsControllerGetContract(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contracts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ContractDetail, response)), reject);
    });
  }
  /**
   *
   */
  static contractsControllerCreateContractAction(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CreateActionBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CreateActionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contracts/{id}/action';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(CreateActionResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static contractsControllerRenewContract(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
      /** requestBody */
      body?: RenewContractBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/contracts/{id}/renew';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class QualificationsService {
  /**
   *
   */
  static qualificationsControllerGetPotentialQualificationsClients(
    params: {
      /** requestBody */
      body?: GetPotentialQualificationsClientsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PotentialQualificationClient[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications/potentialQualificationsClients';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(PotentialQualificationClient, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static qualificationsControllerGetMissionsBySkillMatching(
    params: {
      /** requestBody */
      body?: GetMissionsBySkillMatchingParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SkillMatchingMission[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications/missionsBySkillMatching';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(SkillMatchingMission, response)),
        reject
      );
    });
  }
}

export class VariablesimulatorService {
  /**
   *
   */
  static variableSimulatorControllerGetConsultantInformation(
    params: {
      /**  */
      siid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConsultantInformation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/siid/{siid}';
      url = url.replace('{siid}', params['siid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(ConsultantInformation, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static variableSimulatorControllerGetFunctionCategories(
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/functionCategories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerGetUploadedFilesStatus(
    options: IRequestOptions = {}
  ): Promise<UploadedFilesStatus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadedFilesStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(UploadedFilesStatus, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static variableSimulatorControllerSimulate(
    params: {
      /** requestBody */
      body?: PostVSSimulateBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/simulate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerUploadRhpiFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadFileResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadRhpiFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UploadFileResult, response)), reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerUploadUnitFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadFileResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadUnitFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UploadFileResult, response)), reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerUploadCoefficientFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadFileResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadCoefficientFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UploadFileResult, response)), reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerUploadTrancheFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadFileResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadTrancheFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UploadFileResult, response)), reject);
    });
  }
  /**
   *
   */
  static variableSimulatorControllerUploadRhpiFunctionFile(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadFileResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/variablesimulator/uploadRhpiFunctionFile';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UploadFileResult, response)), reject);
    });
  }
}

export class ExportService {
  /**
   *
   */
  static exportControllerGetExportMissionsData(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/export/export/missions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportControllerGetExportCandidatesData(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/export/export/candidates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportControllerInitializeDemandsExport(
    params: {
      /** requestBody */
      body?: ExportDemandsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/export/export/demand';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OSMConsultantModelDtoBrandDto {
  /**  */
  'code'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['code'] = data['code'];
    this['name'] = data['name'];
  }
}

export class OSMConsultantModelDtoSpecialityDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMConsultantModelDtoConsultantAgencyByCompany {
  /**  */
  'societyId'?: string;

  /**  */
  'brand'?: OSMConsultantModelDtoBrandDto;

  /**  */
  'brandCodeApiHeader'?: string;

  /**  */
  'agencies'?: string[];

  /**  */
  'specialities'?: OSMConsultantModelDtoSpecialityDto[];

  constructor(data: undefined | any = {}) {
    this['societyId'] = data['societyId'];
    this['brand'] = data['brand'];
    this['brandCodeApiHeader'] = data['brandCodeApiHeader'];
    this['agencies'] = data['agencies'];
    this['specialities'] = data['specialities'];
  }
}

export class ConsultantPerimeter {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'branchId'?: string;

  /**  */
  'regionId'?: string;

  /**  */
  'zoneId'?: string;

  /**  */
  'defaultAgencyId'?: string;

  /**  */
  'defaultCompanyId'?: string;

  /**  */
  'companies'?: OSMConsultantModelDtoConsultantAgencyByCompany[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['branchId'] = data['branchId'];
    this['regionId'] = data['regionId'];
    this['zoneId'] = data['zoneId'];
    this['defaultAgencyId'] = data['defaultAgencyId'];
    this['defaultCompanyId'] = data['defaultCompanyId'];
    this['companies'] = data['companies'];
  }
}

export class OSMConsultantModelDtoFullConsultantDto {
  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'mobilePhoneNumber'?: string;

  /**  */
  'phone'?: string;

  /**  */
  'email'?: string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['mobilePhoneNumber'] = data['mobilePhoneNumber'];
    this['phone'] = data['phone'];
    this['email'] = data['email'];
  }
}

export class ConsultantInfo {
  /**  */
  'consultant'?: OSMConsultantModelDtoFullConsultantDto;

  constructor(data: undefined | any = {}) {
    this['consultant'] = data['consultant'];
  }
}

export class OSMActionModelDtoCompanyDto {
  /**  */
  'companyId'?: string;

  /**  */
  'companyName'?: string;

  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'postalCode'?: string;

  /**  */
  'city'?: string;

  constructor(data: undefined | any = {}) {
    this['companyId'] = data['companyId'];
    this['companyName'] = data['companyName'];
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['postalCode'] = data['postalCode'];
    this['city'] = data['city'];
  }
}

export class OSMActionModelDtoContactDto {
  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'phone'?: string;

  /**  */
  'mobilePhoneNumber'?: string;

  /**  */
  'contactId'?: string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['phone'] = data['phone'];
    this['mobilePhoneNumber'] = data['mobilePhoneNumber'];
    this['contactId'] = data['contactId'];
  }
}

export class OSMActionModelDtoCandidateDto {
  /**  */
  'candidateId'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'phone1'?: string;

  /**  */
  'phone2'?: string;

  /**  */
  'email'?: string;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['phone1'] = data['phone1'];
    this['phone2'] = data['phone2'];
    this['email'] = data['email'];
  }
}

export class OSMActionModelDtoActorDto {
  /**  */
  'name'?: string;

  /**  */
  'id'?: string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['id'] = data['id'];
  }
}

export class OSMActionModelDtoAttachedDocumentDto {
  /**  */
  @Expose()
  @Type(() => Date)
  'attachementDate'?: Date;

  /**  */
  'attachedBy'?: string;

  /**  */
  'attachementDocumentId'?: string;

  /**  */
  'title'?: string;

  /**  */
  'fileName'?: string;

  /**  */
  'fileType'?: string;

  /**  */
  'binaryFile'?: string;

  constructor(data: undefined | any = {}) {
    this['attachementDate'] = data['attachementDate'];
    this['attachedBy'] = data['attachedBy'];
    this['attachementDocumentId'] = data['attachementDocumentId'];
    this['title'] = data['title'];
    this['fileName'] = data['fileName'];
    this['fileType'] = data['fileType'];
    this['binaryFile'] = data['binaryFile'];
  }
}

export class OSMRepositoriesModelTypeActionRepositoryDtoTypeActionDto {
  /**  */
  'label'?: string;

  /**  */
  'isActive'?: boolean;

  /**  */
  'id'?: string;

  constructor(data: undefined | any = {}) {
    this['label'] = data['label'];
    this['isActive'] = data['isActive'];
    this['id'] = data['id'];
  }
}

export class OSMRepositoriesModelActionStateRepositoryDtoActionStateDto {
  /**  */
  'label'?: string;

  /**  */
  'id'?: string;

  constructor(data: undefined | any = {}) {
    this['label'] = data['label'];
    this['id'] = data['id'];
  }
}

export class OSMRepositoriesModelActionTargetRepositoryDtoActionTargetDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'isActive'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['isActive'] = data['isActive'];
  }
}

export class OSMCommonModelDateDto {
  /**  */
  'year'?: number;

  /**  */
  'month'?: number;

  /**  */
  'day'?: number;

  constructor(data: undefined | any = {}) {
    this['year'] = data['year'];
    this['month'] = data['month'];
    this['day'] = data['day'];
  }
}

export class OSMRepositoriesModelFamilyGoalsRepositoryDtoFamilyGoalBaseDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMRepositoriesModelGoalRepositoryDtoGoalDto {
  /**  */
  'label'?: string;

  /**  */
  'id'?: string;

  constructor(data: undefined | any = {}) {
    this['label'] = data['label'];
    this['id'] = data['id'];
  }
}

export class OSMRepositoriesModelGoalRepositoryDtoFamilyByGoalDto {
  /**  */
  'familyGoals'?: OSMRepositoriesModelFamilyGoalsRepositoryDtoFamilyGoalBaseDto;

  /**  */
  'goals'?: OSMRepositoriesModelGoalRepositoryDtoGoalDto[];

  constructor(data: undefined | any = {}) {
    this['familyGoals'] = data['familyGoals'];
    this['goals'] = data['goals'];
  }
}

export class Action {
  /**  */
  'actionId'?: string;

  /**  */
  'company'?: OSMActionModelDtoCompanyDto;

  /**  */
  'contacts'?: OSMActionModelDtoContactDto[];

  /**  */
  'candidate'?: OSMActionModelDtoCandidateDto;

  /**  */
  'actors'?: OSMActionModelDtoActorDto[];

  /**  */
  'attachedDocuments'?: OSMActionModelDtoAttachedDocumentDto[];

  /**  */
  'type'?: OSMRepositoriesModelTypeActionRepositoryDtoTypeActionDto;

  /**  */
  'status'?: OSMRepositoriesModelActionStateRepositoryDtoActionStateDto;

  /**  */
  'target'?: OSMRepositoriesModelActionTargetRepositoryDtoActionTargetDto;

  /**  */
  'startDate'?: OSMCommonModelDateDto;

  /**  */
  'startTime'?: number;

  /**  */
  'endDate'?: OSMCommonModelDateDto;

  /**  */
  'endTime'?: number;

  /**  */
  'agencies'?: string[];

  /**  */
  'goalsByFamily'?: OSMRepositoriesModelGoalRepositoryDtoFamilyByGoalDto[];

  /**  */
  'report'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'creationDate'?: Date;

  /**  */
  'syncGoogleCal'?: boolean;

  /**  */
  'appointment'?: boolean;

  /**  */
  'seqAdress'?: string;

  /**  */
  'competitionIdentification'?: boolean;

  /**  */
  'title'?: string;

  /**  */
  'isEditable'?: boolean;

  constructor(data: undefined | any = {}) {
    this['actionId'] = data['actionId'];
    this['company'] = data['company'];
    this['contacts'] = data['contacts'];
    this['candidate'] = data['candidate'];
    this['actors'] = data['actors'];
    this['attachedDocuments'] = data['attachedDocuments'];
    this['type'] = data['type'];
    this['status'] = data['status'];
    this['target'] = data['target'];
    this['startDate'] = data['startDate'];
    this['startTime'] = data['startTime'];
    this['endDate'] = data['endDate'];
    this['endTime'] = data['endTime'];
    this['agencies'] = data['agencies'];
    this['goalsByFamily'] = data['goalsByFamily'];
    this['report'] = data['report'];
    this['creationDate'] = data['creationDate'];
    this['syncGoogleCal'] = data['syncGoogleCal'];
    this['appointment'] = data['appointment'];
    this['seqAdress'] = data['seqAdress'];
    this['competitionIdentification'] = data['competitionIdentification'];
    this['title'] = data['title'];
    this['isEditable'] = data['isEditable'];
  }
}

export class UpdateActionBody {
  /**  */
  'report': string;

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['report'] = data['report'];
    this['brandCode'] = data['brandCode'];
  }
}

export class OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'lastName'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['lastName'] = data['lastName'];
  }
}

export class CandidatesWorkAccidents {
  /**  */
  'id'?: string;

  /**  */
  'workStoppageTypeCode'?: string;

  /**  */
  'candidate'?: OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto;

  /**  */
  'workAccidentId'?: string;

  /**  */
  'stoppageDuration'?: number;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  'agencyId'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['workStoppageTypeCode'] = data['workStoppageTypeCode'];
    this['candidate'] = data['candidate'];
    this['workAccidentId'] = data['workAccidentId'];
    this['stoppageDuration'] = data['stoppageDuration'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['agencyId'] = data['agencyId'];
  }
}

export class GetCandidatesAccidentsUnderMinStoppageDuration {
  /**  */
  'workStoppages': CandidatesWorkAccidents[];

  constructor(data: undefined | any = {}) {
    this['workStoppages'] = data['workStoppages'];
  }
}

export class GetWorkAccidentsByAgenciesQueryParams {
  /**  */
  'dateFilterStrategy'?: DateFilterStrategy;

  /**  */
  'agencyIds': string[];

  /**  */
  'brandCode': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['dateFilterStrategy'] = data['dateFilterStrategy'];
    this['agencyIds'] = data['agencyIds'];
    this['brandCode'] = data['brandCode'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoCandidateDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoCompanyDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoAccidentPlaceDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoMaterialElementDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class AgencyWorkAccidents {
  /**  */
  'accidentId'?: string;

  /**  */
  'candidate'?: OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoCandidateDto;

  /**  */
  'company'?: OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoCompanyDto;

  /**  */
  'date'?: OSMCommonModelDateDto;

  /**  */
  'place'?: OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoAccidentPlaceDto;

  /**  */
  'materialElement'?: OSMWorkAccidentModelDtoWorkAccidentSearchMultiCriteriaDtoMaterialElementDto;

  constructor(data: undefined | any = {}) {
    this['accidentId'] = data['accidentId'];
    this['candidate'] = data['candidate'];
    this['company'] = data['company'];
    this['date'] = data['date'];
    this['place'] = data['place'];
    this['materialElement'] = data['materialElement'];
  }
}

export class GetWorkAccidents {
  /**  */
  'todo'?: CandidatesWorkAccidents[];

  /**  */
  'inProgress'?: CandidatesWorkAccidents[];

  /**  */
  'done'?: CandidatesWorkAccidents[];

  /**  */
  'archived'?: CandidatesWorkAccidents[];

  constructor(data: undefined | any = {}) {
    this['todo'] = data['todo'];
    this['inProgress'] = data['inProgress'];
    this['done'] = data['done'];
    this['archived'] = data['archived'];
  }
}

export class WhyAction {
  /**  */
  'delay': WhyActionDelayEnum;

  /**  */
  'actorType': WhyActionActorsEnum;

  /**  */
  'category': WhyActionCategoriesEnum;

  /**  */
  'name': WhyActionsEnum;

  /**  */
  'status': WhyActionsStatusEnum;

  /**  */
  'osmoseActionId'?: string;

  /**  */
  'description': string;

  /**  */
  'actor': string;

  /**  */
  'actorName': string;

  /**  */
  'consultantId'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'realisationDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['delay'] = data['delay'];
    this['actorType'] = data['actorType'];
    this['category'] = data['category'];
    this['name'] = data['name'];
    this['status'] = data['status'];
    this['osmoseActionId'] = data['osmoseActionId'];
    this['description'] = data['description'];
    this['actor'] = data['actor'];
    this['actorName'] = data['actorName'];
    this['consultantId'] = data['consultantId'];
    this['realisationDate'] = data['realisationDate'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentContractQualificationDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class WorkAccidentActionPlan {
  /**  */
  'candidate'?: OSMCandidateModelWorkStoppagesDtoWorkStoppagesCandidateDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  'agencyId'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'accidentDate'?: Date;

  /**  */
  'companyReferent'?: string;

  /**  */
  'accidentAddress'?: string;

  /**  */
  'whyActions'?: WhyAction[];

  /**  */
  'workAccidentId'?: string;

  /**  */
  'companyId'?: string;

  /**  */
  'contractQualification'?: OSMWorkAccidentModelDtoWorkAccidentContractQualificationDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'dateOfSignature'?: Date;

  constructor(data: undefined | any = {}) {
    this['candidate'] = data['candidate'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['agencyId'] = data['agencyId'];
    this['accidentDate'] = data['accidentDate'];
    this['companyReferent'] = data['companyReferent'];
    this['accidentAddress'] = data['accidentAddress'];
    this['whyActions'] = data['whyActions'];
    this['workAccidentId'] = data['workAccidentId'];
    this['companyId'] = data['companyId'];
    this['contractQualification'] = data['contractQualification'];
    this['dateOfSignature'] = data['dateOfSignature'];
  }
}

export class CreateWorkAccidentBody {
  /**  */
  'archivedReason'?: ArchiveATReason;

  /**  */
  'firstName': string;

  /**  */
  'lastName': string;

  /**  */
  'candidateId': string;

  /**  */
  'accidentId': string;

  /**  */
  'agencyId': string;

  /**  */
  'brandCode': string;

  /**  */
  'stoppageDuration': number;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate': Date;

  /**  */
  'archivedComment'?: string;

  constructor(data: undefined | any = {}) {
    this['archivedReason'] = data['archivedReason'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['candidateId'] = data['candidateId'];
    this['accidentId'] = data['accidentId'];
    this['agencyId'] = data['agencyId'];
    this['brandCode'] = data['brandCode'];
    this['stoppageDuration'] = data['stoppageDuration'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['archivedComment'] = data['archivedComment'];
  }
}

export class EditWorkAccidentAction {
  /**  */
  'workAccidentId': string;

  /**  */
  'osmoseActionId': string;

  /**  */
  'brandCode': string;

  /**  */
  'description'?: string;

  /**  */
  'actor'?: string;

  /**  */
  'actorName'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'realisationDate'?: Date;

  /**  */
  'companyId'?: string;

  constructor(data: undefined | any = {}) {
    this['workAccidentId'] = data['workAccidentId'];
    this['osmoseActionId'] = data['osmoseActionId'];
    this['brandCode'] = data['brandCode'];
    this['description'] = data['description'];
    this['actor'] = data['actor'];
    this['actorName'] = data['actorName'];
    this['realisationDate'] = data['realisationDate'];
    this['companyId'] = data['companyId'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentCandidateDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'phone1'?: string;

  /**  */
  'phone2'?: string;

  /**  */
  'socialSecurityId'?: string;

  /**  */
  'socialSecurityKey'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['phone1'] = data['phone1'];
    this['phone2'] = data['phone2'];
    this['socialSecurityId'] = data['socialSecurityId'];
    this['socialSecurityKey'] = data['socialSecurityKey'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentCompanyAddressDto {
  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'cityName'?: string;

  /**  */
  'zipCode'?: string;

  constructor(data: undefined | any = {}) {
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['cityName'] = data['cityName'];
    this['zipCode'] = data['zipCode'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentCompanyDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'siret'?: string;

  /**  */
  'acronym'?: string;

  /**  */
  'service'?: string;

  /**  */
  'country'?: string;

  /**  */
  'phone'?: string;

  /**  */
  'fax'?: string;

  /**  */
  'address'?: OSMWorkAccidentModelDtoWorkAccidentCompanyAddressDto;

  /**  */
  'contact'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['siret'] = data['siret'];
    this['acronym'] = data['acronym'];
    this['service'] = data['service'];
    this['country'] = data['country'];
    this['phone'] = data['phone'];
    this['fax'] = data['fax'];
    this['address'] = data['address'];
    this['contact'] = data['contact'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentDateAndLocationAccidentAddressDto {
  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'cityName'?: string;

  /**  */
  'zipCode'?: string;

  constructor(data: undefined | any = {}) {
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['cityName'] = data['cityName'];
    this['zipCode'] = data['zipCode'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentDateAndLocationWorkHoursDto {
  /**  */
  'startTime1'?: string;

  /**  */
  'endTime1'?: string;

  /**  */
  'startTime2'?: string;

  /**  */
  'endTime2'?: string;

  constructor(data: undefined | any = {}) {
    this['startTime1'] = data['startTime1'];
    this['endTime1'] = data['endTime1'];
    this['startTime2'] = data['startTime2'];
    this['endTime2'] = data['endTime2'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentDateAndLocationDto {
  /**  */
  'accidentDate'?: OSMCommonModelDateDto;

  /**  */
  'accidentTime'?: string;

  /**  */
  'accidentLocation'?: string;

  /**  */
  'accidentSpecificity'?: string;

  /**  */
  'accidentAddress'?: OSMWorkAccidentModelDtoWorkAccidentDateAndLocationAccidentAddressDto;

  /**  */
  'workHours'?: OSMWorkAccidentModelDtoWorkAccidentDateAndLocationWorkHoursDto;

  constructor(data: undefined | any = {}) {
    this['accidentDate'] = data['accidentDate'];
    this['accidentTime'] = data['accidentTime'];
    this['accidentLocation'] = data['accidentLocation'];
    this['accidentSpecificity'] = data['accidentSpecificity'];
    this['accidentAddress'] = data['accidentAddress'];
    this['workHours'] = data['workHours'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentCircumstancesAndConsequencesDto {
  /**  */
  'materialElements'?: string;

  /**  */
  'details'?: string;

  /**  */
  'victimActivity'?: string;

  /**  */
  'victimTransportedTo'?: string;

  /**  */
  'accidentNature'?: string;

  /**  */
  'injuryObject'?: string;

  /**  */
  'otherVictim'?: string;

  /**  */
  'consequences'?: string;

  constructor(data: undefined | any = {}) {
    this['materialElements'] = data['materialElements'];
    this['details'] = data['details'];
    this['victimActivity'] = data['victimActivity'];
    this['victimTransportedTo'] = data['victimTransportedTo'];
    this['accidentNature'] = data['accidentNature'];
    this['injuryObject'] = data['injuryObject'];
    this['otherVictim'] = data['otherVictim'];
    this['consequences'] = data['consequences'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentToAgencyDto {
  /**  */
  'flagKnownAccident'?: boolean;

  /**  */
  'agencyDate'?: OSMCommonModelDateDto;

  /**  */
  'agencyTime'?: string;

  /**  */
  'agencyOrigin'?: string;

  constructor(data: undefined | any = {}) {
    this['flagKnownAccident'] = data['flagKnownAccident'];
    this['agencyDate'] = data['agencyDate'];
    this['agencyTime'] = data['agencyTime'];
    this['agencyOrigin'] = data['agencyOrigin'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentCustomerSide {
  /**  */
  'flagSeenByCustomer'?: boolean;

  /**  */
  'customerDate'?: OSMCommonModelDateDto;

  /**  */
  'customerTime'?: string;

  /**  */
  'customerOrigin'?: string;

  constructor(data: undefined | any = {}) {
    this['flagSeenByCustomer'] = data['flagSeenByCustomer'];
    this['customerDate'] = data['customerDate'];
    this['customerTime'] = data['customerTime'];
    this['customerOrigin'] = data['customerOrigin'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentOtherPersonInformationsAddressDto {
  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'cityName'?: string;

  /**  */
  'zipCode'?: string;

  constructor(data: undefined | any = {}) {
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['cityName'] = data['cityName'];
    this['zipCode'] = data['zipCode'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentOtherPersonInformationsDto {
  /**  */
  'accidentCausedByAnotherPerson'?: boolean;

  /**  */
  'name'?: string;

  /**  */
  'insurance'?: string;

  /**  */
  'police'?: string;

  /**  */
  'address'?: OSMWorkAccidentModelDtoWorkAccidentOtherPersonInformationsAddressDto;

  constructor(data: undefined | any = {}) {
    this['accidentCausedByAnotherPerson'] = data['accidentCausedByAnotherPerson'];
    this['name'] = data['name'];
    this['insurance'] = data['insurance'];
    this['police'] = data['police'];
    this['address'] = data['address'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentPoliceReportAddressDto {
  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'cityName'?: string;

  /**  */
  'zipCode'?: string;

  constructor(data: undefined | any = {}) {
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['cityName'] = data['cityName'];
    this['zipCode'] = data['zipCode'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentPoliceReportDto {
  /**  */
  'policeStation'?: string;

  /**  */
  'minutes'?: string;

  /**  */
  'address'?: OSMWorkAccidentModelDtoWorkAccidentPoliceReportAddressDto;

  constructor(data: undefined | any = {}) {
    this['policeStation'] = data['policeStation'];
    this['minutes'] = data['minutes'];
    this['address'] = data['address'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentWitnessesTypeDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentWitnessesInformationsAddressDto {
  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'cityName'?: string;

  /**  */
  'zipCode'?: string;

  constructor(data: undefined | any = {}) {
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['cityName'] = data['cityName'];
    this['zipCode'] = data['zipCode'];
  }
}

export class OSMWorkAccidentModelDtoWorkAccidentWitnessesInformationsDto {
  /**  */
  'name'?: string;

  /**  */
  'type'?: OSMWorkAccidentModelDtoWorkAccidentWitnessesTypeDto;

  /**  */
  'address'?: OSMWorkAccidentModelDtoWorkAccidentWitnessesInformationsAddressDto;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['type'] = data['type'];
    this['address'] = data['address'];
  }
}

export class Referent {
  /**  */
  'contactId'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'name': string;

  /**  */
  'position': string;

  constructor(data: undefined | any = {}) {
    this['contactId'] = data['contactId'];
    this['firstName'] = data['firstName'];
    this['name'] = data['name'];
    this['position'] = data['position'];
  }
}

export class Witness {
  /**  */
  'contactId'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'name': string;

  /**  */
  'position': string;

  /**  */
  'societyId'?: string;

  /**  */
  'societyName': string;

  /**  */
  'phoneNumber'?: string;

  constructor(data: undefined | any = {}) {
    this['contactId'] = data['contactId'];
    this['firstName'] = data['firstName'];
    this['name'] = data['name'];
    this['position'] = data['position'];
    this['societyId'] = data['societyId'];
    this['societyName'] = data['societyName'];
    this['phoneNumber'] = data['phoneNumber'];
  }
}

export class Context {
  /**  */
  'laterality'?: Laterality;

  /**  */
  'firstAidPersonType'?: FirstAidPersonType;

  /**  */
  'accidentLocation'?: AccidentLocation;

  /**  */
  'causedByThirdParty'?: CausedByThirdParty;

  /**  */
  'seniorityAtClient'?: number;

  /**  */
  'seniorityAtRandstad'?: number;

  /**  */
  'registeredRisk'?: boolean;

  /**  */
  'existingRisk'?: boolean;

  /**  */
  'trainingEvaluation'?: boolean;

  /**  */
  'referents'?: Referent[];

  /**  */
  'hasWitnesses'?: boolean;

  /**  */
  'witnesses'?: Witness[];

  /**  */
  @Expose()
  @Type(() => Date)
  'welcomeDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'trainingDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'positionStudyDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'accidentDate'?: Date;

  /**  */
  'accidentAddress'?: string;

  /**  */
  'daysOff'?: number;

  /**  */
  'accidentTime'?: string;

  /**  */
  'workingDayStartTime'?: string;

  /**  */
  'workingDayEndTime'?: string;

  /**  */
  'otherFirstAidPerson'?: string;

  /**  */
  'personLastName'?: string;

  /**  */
  'personFirstName'?: string;

  /**  */
  'companyName'?: string;

  /**  */
  'victimPhoneNumber'?: string;

  constructor(data: undefined | any = {}) {
    this['laterality'] = data['laterality'];
    this['firstAidPersonType'] = data['firstAidPersonType'];
    this['accidentLocation'] = data['accidentLocation'];
    this['causedByThirdParty'] = data['causedByThirdParty'];
    this['seniorityAtClient'] = data['seniorityAtClient'];
    this['seniorityAtRandstad'] = data['seniorityAtRandstad'];
    this['registeredRisk'] = data['registeredRisk'];
    this['existingRisk'] = data['existingRisk'];
    this['trainingEvaluation'] = data['trainingEvaluation'];
    this['referents'] = data['referents'];
    this['hasWitnesses'] = data['hasWitnesses'];
    this['witnesses'] = data['witnesses'];
    this['welcomeDate'] = data['welcomeDate'];
    this['trainingDate'] = data['trainingDate'];
    this['positionStudyDate'] = data['positionStudyDate'];
    this['accidentDate'] = data['accidentDate'];
    this['accidentAddress'] = data['accidentAddress'];
    this['daysOff'] = data['daysOff'];
    this['accidentTime'] = data['accidentTime'];
    this['workingDayStartTime'] = data['workingDayStartTime'];
    this['workingDayEndTime'] = data['workingDayEndTime'];
    this['otherFirstAidPerson'] = data['otherFirstAidPerson'];
    this['personLastName'] = data['personLastName'];
    this['personFirstName'] = data['personFirstName'];
    this['companyName'] = data['companyName'];
    this['victimPhoneNumber'] = data['victimPhoneNumber'];
  }
}

export class MaseItem {
  /**  */
  'id': string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class Injury {
  /**  */
  'injuryLaterality'?: LateralityInjury;

  /**  */
  'injuryLocation'?: MaseItem;

  /**  */
  'injuryLocationDetails'?: MaseItem;

  /**  */
  'injuryNatures'?: MaseItem[];

  constructor(data: undefined | any = {}) {
    this['injuryLaterality'] = data['injuryLaterality'];
    this['injuryLocation'] = data['injuryLocation'];
    this['injuryLocationDetails'] = data['injuryLocationDetails'];
    this['injuryNatures'] = data['injuryNatures'];
  }
}

export class Epi {
  /**  */
  'whoGaveIt': WhoGaveEpi;

  /**  */
  'inContract': YesNoUnknown;

  /**  */
  'hasBeenWorn': boolean;

  /**  */
  'id': string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['whoGaveIt'] = data['whoGaveIt'];
    this['inContract'] = data['inContract'];
    this['hasBeenWorn'] = data['hasBeenWorn'];
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class Description {
  /**  */
  'hasEpis'?: YesNoUnknown;

  /**  */
  'hasEpcs'?: YesNoUnknown;

  /**  */
  'beforeAccident'?: string;

  /**  */
  'duringAccident'?: string;

  /**  */
  'afterAccident'?: string;

  /**  */
  'otherRisks'?: string;

  /**  */
  'risks'?: MaseItem[];

  /**  */
  'environmentRisks'?: MaseItem[];

  /**  */
  'injuries'?: Injury[];

  /**  */
  'epis'?: Epi[];

  /**  */
  'mainEpcs'?: MaseItem[];

  /**  */
  'otherEpcs'?: string;

  constructor(data: undefined | any = {}) {
    this['hasEpis'] = data['hasEpis'];
    this['hasEpcs'] = data['hasEpcs'];
    this['beforeAccident'] = data['beforeAccident'];
    this['duringAccident'] = data['duringAccident'];
    this['afterAccident'] = data['afterAccident'];
    this['otherRisks'] = data['otherRisks'];
    this['risks'] = data['risks'];
    this['environmentRisks'] = data['environmentRisks'];
    this['injuries'] = data['injuries'];
    this['epis'] = data['epis'];
    this['mainEpcs'] = data['mainEpcs'];
    this['otherEpcs'] = data['otherEpcs'];
  }
}

export class FiveWhyElement {
  /**  */
  'value'?: string;

  /**  */
  'rootCauseName'?: string;

  /**  */
  'isRootCause'?: boolean;

  /**  */
  'whyIndex'?: number;

  constructor(data: undefined | any = {}) {
    this['value'] = data['value'];
    this['rootCauseName'] = data['rootCauseName'];
    this['isRootCause'] = data['isRootCause'];
    this['whyIndex'] = data['whyIndex'];
  }
}

export class WhyAnalysis {
  /**  */
  'name'?: string;

  /**  */
  'description'?: string;

  /**  */
  'fiveWhys'?: FiveWhyElement[];

  /**  */
  'actions'?: WhyAction[];

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['fiveWhys'] = data['fiveWhys'];
    this['actions'] = data['actions'];
  }
}

export class SigningVictim {
  /**  */
  'victimSigningStatus'?: SigningStatusEnum;

  /**  */
  'isUnableToSign'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'moment'?: Date;

  /**  */
  'signed'?: boolean;

  constructor(data: undefined | any = {}) {
    this['victimSigningStatus'] = data['victimSigningStatus'];
    this['isUnableToSign'] = data['isUnableToSign'];
    this['moment'] = data['moment'];
    this['signed'] = data['signed'];
  }
}

export class SigningPerson {
  /**  */
  'personName'?: string;

  /**  */
  'id'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'moment'?: Date;

  /**  */
  'signed'?: boolean;

  constructor(data: undefined | any = {}) {
    this['personName'] = data['personName'];
    this['id'] = data['id'];
    this['moment'] = data['moment'];
    this['signed'] = data['signed'];
  }
}

export class ClientSigningPerson {
  /**  */
  'clientSigningStatus'?: SigningStatusEnum;

  /**  */
  'personName'?: string;

  /**  */
  'id'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'moment'?: Date;

  /**  */
  'signed'?: boolean;

  constructor(data: undefined | any = {}) {
    this['clientSigningStatus'] = data['clientSigningStatus'];
    this['personName'] = data['personName'];
    this['id'] = data['id'];
    this['moment'] = data['moment'];
    this['signed'] = data['signed'];
  }
}

export class SigningAdditionalPerson {
  /**  */
  'personName'?: string;

  /**  */
  'personRole'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'moment'?: Date;

  /**  */
  'signed'?: boolean;

  constructor(data: undefined | any = {}) {
    this['personName'] = data['personName'];
    this['personRole'] = data['personRole'];
    this['moment'] = data['moment'];
    this['signed'] = data['signed'];
  }
}

export class Conclusion {
  /**  */
  'comments'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'dateOfSignature'?: Date;

  /**  */
  'victimSigningPerson'?: SigningVictim;

  /**  */
  'randstadSigningPerson'?: SigningPerson;

  /**  */
  'clientSigningPerson'?: ClientSigningPerson;

  /**  */
  'additionalSigningPerson'?: SigningAdditionalPerson;

  constructor(data: undefined | any = {}) {
    this['comments'] = data['comments'];
    this['dateOfSignature'] = data['dateOfSignature'];
    this['victimSigningPerson'] = data['victimSigningPerson'];
    this['randstadSigningPerson'] = data['randstadSigningPerson'];
    this['clientSigningPerson'] = data['clientSigningPerson'];
    this['additionalSigningPerson'] = data['additionalSigningPerson'];
  }
}

export class Signature {
  /**  */
  'originalname': string;

  /**  */
  'size': number;

  /**  */
  'encoding': string;

  /**  */
  'mimetype': string;

  /**  */
  'buffer': object;

  constructor(data: undefined | any = {}) {
    this['originalname'] = data['originalname'];
    this['size'] = data['size'];
    this['encoding'] = data['encoding'];
    this['mimetype'] = data['mimetype'];
    this['buffer'] = data['buffer'];
  }
}

export class WorkAccidentAnalysis {
  /**  */
  'archivedReason'?: ArchiveATReason;

  /**  */
  'analyseId': string;

  /**  */
  'workAccidentId': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate': Date;

  /**  */
  'status': EnumWorkAccidentAnalysisStatus;

  /**  */
  'agencyId': string;

  /**  */
  'stoppageDuration': number;

  /**  */
  'brandCode': string;

  /**  */
  'candidate'?: OSMWorkAccidentModelDtoWorkAccidentCandidateDto;

  /**  */
  'company'?: OSMWorkAccidentModelDtoWorkAccidentCompanyDto;

  /**  */
  'accidentDateAndLocation'?: OSMWorkAccidentModelDtoWorkAccidentDateAndLocationDto;

  /**  */
  'accidentCircumstancesAndConsequences'?: OSMWorkAccidentModelDtoWorkAccidentCircumstancesAndConsequencesDto;

  /**  */
  'toAgency'?: OSMWorkAccidentModelDtoWorkAccidentToAgencyDto;

  /**  */
  'customerSide'?: OSMWorkAccidentModelDtoWorkAccidentCustomerSide;

  /**  */
  'contractId'?: string;

  /**  */
  'contractQualification'?: OSMWorkAccidentModelDtoWorkAccidentContractQualificationDto;

  /**  */
  'cdiContractId'?: string;

  /**  */
  'otherPersonInformations'?: OSMWorkAccidentModelDtoWorkAccidentOtherPersonInformationsDto;

  /**  */
  'policeReport'?: OSMWorkAccidentModelDtoWorkAccidentPoliceReportDto;

  /**  */
  'witnessesInformations'?: OSMWorkAccidentModelDtoWorkAccidentWitnessesInformationsDto;

  /**  */
  'context'?: Context;

  /**  */
  'description'?: Description;

  /**  */
  'whyAnalysis': WhyAnalysis;

  /**  */
  'conclusion'?: Conclusion;

  /**  */
  'randstadConsultantSignature'?: CombinedRandstadConsultantSignatureTypes;

  /**  */
  'clientSignature'?: CombinedClientSignatureTypes;

  /**  */
  'victimSignature'?: CombinedVictimSignatureTypes;

  /**  */
  'additionalSignature'?: CombinedAdditionalSignatureTypes;

  /**  */
  'archivedComment'?: string;

  constructor(data: undefined | any = {}) {
    this['archivedReason'] = data['archivedReason'];
    this['analyseId'] = data['analyseId'];
    this['workAccidentId'] = data['workAccidentId'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['status'] = data['status'];
    this['agencyId'] = data['agencyId'];
    this['stoppageDuration'] = data['stoppageDuration'];
    this['brandCode'] = data['brandCode'];
    this['candidate'] = data['candidate'];
    this['company'] = data['company'];
    this['accidentDateAndLocation'] = data['accidentDateAndLocation'];
    this['accidentCircumstancesAndConsequences'] = data['accidentCircumstancesAndConsequences'];
    this['toAgency'] = data['toAgency'];
    this['customerSide'] = data['customerSide'];
    this['contractId'] = data['contractId'];
    this['contractQualification'] = data['contractQualification'];
    this['cdiContractId'] = data['cdiContractId'];
    this['otherPersonInformations'] = data['otherPersonInformations'];
    this['policeReport'] = data['policeReport'];
    this['witnessesInformations'] = data['witnessesInformations'];
    this['context'] = data['context'];
    this['description'] = data['description'];
    this['whyAnalysis'] = data['whyAnalysis'];
    this['conclusion'] = data['conclusion'];
    this['randstadConsultantSignature'] = data['randstadConsultantSignature'];
    this['clientSignature'] = data['clientSignature'];
    this['victimSignature'] = data['victimSignature'];
    this['additionalSignature'] = data['additionalSignature'];
    this['archivedComment'] = data['archivedComment'];
  }
}

export class UpdateWorkAccidentBody {
  /**  */
  'status'?: AnalysisStatus;

  /**  */
  'context'?: Context;

  /**  */
  'description'?: Description;

  /**  */
  'whyAnalysis'?: WhyAnalysis;

  /**  */
  'conclusion'?: Conclusion;

  constructor(data: undefined | any = {}) {
    this['status'] = data['status'];
    this['context'] = data['context'];
    this['description'] = data['description'];
    this['whyAnalysis'] = data['whyAnalysis'];
    this['conclusion'] = data['conclusion'];
  }
}

export class UpdateArchiveStatusWorkAccidentBody {
  /**  */
  'archivedReason'?: ArchiveATReason;

  /**  */
  'archivedComment'?: string;

  constructor(data: undefined | any = {}) {
    this['archivedReason'] = data['archivedReason'];
    this['archivedComment'] = data['archivedComment'];
  }
}

export class Buffer {
  constructor(data: undefined | any = {}) {}
}

export class WorkAccidentFileData {
  /**  */
  'id': string;

  /**  */
  'name': string;

  /**  */
  'size': number;

  /**  */
  'path': string;

  /**  */
  'encoding': string;

  /**  */
  'mimetype': string;

  /**  */
  'data': Buffer;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['size'] = data['size'];
    this['path'] = data['path'];
    this['encoding'] = data['encoding'];
    this['mimetype'] = data['mimetype'];
    this['data'] = data['data'];
  }
}

export class AuthenticatedUser {
  /**  */
  'givenName'?: string;

  /**  */
  'familyName'?: string;

  /**  */
  'siid'?: string;

  /**  */
  'email'?: string;

  constructor(data: undefined | any = {}) {
    this['givenName'] = data['givenName'];
    this['familyName'] = data['familyName'];
    this['siid'] = data['siid'];
    this['email'] = data['email'];
  }
}

export class GetPdfQueryParams {
  /**  */
  'brandCode': string;

  /**  */
  'user': AuthenticatedUser;

  constructor(data: undefined | any = {}) {
    this['brandCode'] = data['brandCode'];
    this['user'] = data['user'];
  }
}

export class FinalizeATAnalysis {
  /**  */
  'analyseId': string;

  /**  */
  'user': AuthenticatedUser;

  /**  */
  'candidateName': string;

  /**  */
  'agencyId': string;

  /**  */
  'companyId': string;

  /**  */
  'createdAt': string;

  /**  */
  'randstadSignatory': string;

  /**  */
  'clientSignatory': string;

  /**  */
  'brandCode': EnumFinalizeATAnalysisBrandCode;

  constructor(data: undefined | any = {}) {
    this['analyseId'] = data['analyseId'];
    this['user'] = data['user'];
    this['candidateName'] = data['candidateName'];
    this['agencyId'] = data['agencyId'];
    this['companyId'] = data['companyId'];
    this['createdAt'] = data['createdAt'];
    this['randstadSignatory'] = data['randstadSignatory'];
    this['clientSignatory'] = data['clientSignatory'];
    this['brandCode'] = data['brandCode'];
  }
}

export class OSMCandidateModelActivityPlanningDtoActivityPlanningQualificationDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelActivityPlanningDtoCandidateCurrentCompanyFirstContractDto {
  /**  */
  'id'?: string;

  /**  */
  'companyId'?: string;

  /**  */
  'startDate'?: OSMCommonModelDateDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['companyId'] = data['companyId'];
    this['startDate'] = data['startDate'];
  }
}

export class DateWithStatus {
  /**  */
  @Expose()
  @Type(() => Date)
  'date'?: Date;

  /**  */
  'status'?: EnumDateWithStatusStatus;

  constructor(data: undefined | any = {}) {
    this['date'] = data['date'];
    this['status'] = data['status'];
  }
}

export class CandidateWithPlanning {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'cDIIReturn'?: string;

  /**  */
  'GMMR'?: number;

  /**  */
  'cPBalance'?: number;

  /**  */
  'cETBalance'?: number;

  /**  */
  'mainQualification'?: OSMCandidateModelActivityPlanningDtoActivityPlanningQualificationDto;

  /**  */
  'currentCompanyFirstContract'?: OSMCandidateModelActivityPlanningDtoCandidateCurrentCompanyFirstContractDto;

  /**  */
  'activityPlanning'?: DateWithStatus[];

  /**  */
  @Expose()
  @Type(() => Date)
  'lastContractEndDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['cDIIReturn'] = data['cDIIReturn'];
    this['GMMR'] = data['GMMR'];
    this['cPBalance'] = data['cPBalance'];
    this['cETBalance'] = data['cETBalance'];
    this['mainQualification'] = data['mainQualification'];
    this['currentCompanyFirstContract'] = data['currentCompanyFirstContract'];
    this['activityPlanning'] = data['activityPlanning'];
    this['lastContractEndDate'] = data['lastContractEndDate'];
  }
}

export class Consultant {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'email'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['email'] = data['email'];
  }
}

export class CGCIdLabel {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class AgencyDetails {
  /**  */
  'id': string;

  /**  */
  'idDecrypted': string;

  /**  */
  'email': string;

  /**  */
  'name': string;

  /**  */
  'city': string;

  /**  */
  'postalCode': string;

  /**  */
  'adress': string;

  /**  */
  'brandName': string;

  /**  */
  'serviceCode': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['idDecrypted'] = data['idDecrypted'];
    this['email'] = data['email'];
    this['name'] = data['name'];
    this['city'] = data['city'];
    this['postalCode'] = data['postalCode'];
    this['adress'] = data['adress'];
    this['brandName'] = data['brandName'];
    this['serviceCode'] = data['serviceCode'];
  }
}

export class GetAgencyDistanceParam {
  /**  */
  'agencyId': string;

  /**  */
  'zipCode': string;

  constructor(data: undefined | any = {}) {
    this['agencyId'] = data['agencyId'];
    this['zipCode'] = data['zipCode'];
  }
}

export class GetAgenciesDistancesParams {
  /**  */
  'zipCode': string;

  /**  */
  'agencies': GetAgencyDistanceParam[];

  constructor(data: undefined | any = {}) {
    this['zipCode'] = data['zipCode'];
    this['agencies'] = data['agencies'];
  }
}

export class AgencyDistance {
  /**  */
  'agencyId'?: string;

  /**  */
  'postalCode'?: string;

  /**  */
  'distance'?: number;

  constructor(data: undefined | any = {}) {
    this['agencyId'] = data['agencyId'];
    this['postalCode'] = data['postalCode'];
    this['distance'] = data['distance'];
  }
}

export class AgenciesInfo {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelActivityPlanningDtoActivityPlanningLastContractQualificationDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelActivityPlanningDtoLastContractAgencyDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelActivityPlanningDtoCandidateSearchActivityPlanningLastContractDto {
  /**  */
  'id'?: string;

  /**  */
  'qualification'?: OSMCandidateModelActivityPlanningDtoActivityPlanningLastContractQualificationDto;

  /**  */
  'agency'?: OSMCandidateModelActivityPlanningDtoLastContractAgencyDto;

  /**  */
  'endDate'?: OSMCommonModelDateDto;

  /**  */
  'realEndDate'?: OSMCommonModelDateDto;

  /**  */
  'processingEndDate'?: OSMCommonModelDateDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['qualification'] = data['qualification'];
    this['agency'] = data['agency'];
    this['endDate'] = data['endDate'];
    this['realEndDate'] = data['realEndDate'];
    this['processingEndDate'] = data['processingEndDate'];
  }
}

export class ActivityPlanningDay {
  /**  */
  @Expose()
  @Type(() => Date)
  'date'?: Date;

  /**  */
  'status'?: EnumActivityPlanningDayStatus;

  constructor(data: undefined | any = {}) {
    this['date'] = data['date'];
    this['status'] = data['status'];
  }
}

export class CandidateAddress {
  /**  */
  'candidatePostalCode'?: string;

  /**  */
  'candidateCityName'?: string;

  /**  */
  'distance'?: number;

  /**  */
  'address'?: string;

  constructor(data: undefined | any = {}) {
    this['candidatePostalCode'] = data['candidatePostalCode'];
    this['candidateCityName'] = data['candidateCityName'];
    this['distance'] = data['distance'];
    this['address'] = data['address'];
  }
}

export class OSMCandidateModelSkillsDtoCandidateQualificationsBusinessSkillsDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelSkillsDtoCandidateQualificationsSectorDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelSkillsDtoCandidateQualificationsSkillsDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'shortLabel'?: string;

  /**  */
  'business'?: OSMCandidateModelSkillsDtoCandidateQualificationsBusinessSkillsDto;

  /**  */
  'nbContracts'?: number;

  /**  */
  'nbCompany'?: number;

  /**  */
  'main'?: boolean;

  /**  */
  'sectors'?: OSMCandidateModelSkillsDtoCandidateQualificationsSectorDto[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['shortLabel'] = data['shortLabel'];
    this['business'] = data['business'];
    this['nbContracts'] = data['nbContracts'];
    this['nbCompany'] = data['nbCompany'];
    this['main'] = data['main'];
    this['sectors'] = data['sectors'];
  }
}

export class CandidateSearchActivityPlaning {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'cDIIReturn'?: string;

  /**  */
  'GMMR'?: number;

  /**  */
  'cPBalance'?: number;

  /**  */
  'cETBalance'?: number;

  /**  */
  'mainQualification'?: OSMCandidateModelActivityPlanningDtoActivityPlanningQualificationDto;

  /**  */
  'lastContract'?: OSMCandidateModelActivityPlanningDtoCandidateSearchActivityPlanningLastContractDto;

  /**  */
  'currentCompanyFirstContract'?: OSMCandidateModelActivityPlanningDtoCandidateCurrentCompanyFirstContractDto;

  /**  */
  'cdiiStatus'?: DBCDIIStatus;

  /**  */
  'activityPlanning'?: ActivityPlanningDay[];

  /**  */
  'candidateAddress'?: CandidateAddress;

  /**  */
  'attachedMissionIds'?: string[];

  /**  */
  'suggestedMissionIds'?: string[];

  /**  */
  'suggestedAgencyIds'?: string[];

  /**  */
  'sosAgencyId'?: string;

  /**  */
  'sosComment'?: string;

  /**  */
  'candidateQualifications'?: OSMCandidateModelSkillsDtoCandidateQualificationsSkillsDto[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['cDIIReturn'] = data['cDIIReturn'];
    this['GMMR'] = data['GMMR'];
    this['cPBalance'] = data['cPBalance'];
    this['cETBalance'] = data['cETBalance'];
    this['mainQualification'] = data['mainQualification'];
    this['lastContract'] = data['lastContract'];
    this['currentCompanyFirstContract'] = data['currentCompanyFirstContract'];
    this['cdiiStatus'] = data['cdiiStatus'];
    this['activityPlanning'] = data['activityPlanning'];
    this['candidateAddress'] = data['candidateAddress'];
    this['attachedMissionIds'] = data['attachedMissionIds'];
    this['suggestedMissionIds'] = data['suggestedMissionIds'];
    this['suggestedAgencyIds'] = data['suggestedAgencyIds'];
    this['sosAgencyId'] = data['sosAgencyId'];
    this['sosComment'] = data['sosComment'];
    this['candidateQualifications'] = data['candidateQualifications'];
  }
}

export class OSMCandidateModelCandidateDtoMainAgencyDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class SuggestedCandidate {
  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'cDIIReturn'?: string;

  /**  */
  'GMMR'?: number;

  /**  */
  'cPBalance'?: number;

  /**  */
  'cETBalance'?: number;

  /**  */
  'mainQualification'?: OSMCandidateModelActivityPlanningDtoActivityPlanningQualificationDto;

  /**  */
  'lastContract'?: OSMCandidateModelActivityPlanningDtoCandidateSearchActivityPlanningLastContractDto;

  /**  */
  'currentCompanyFirstContract'?: OSMCandidateModelActivityPlanningDtoCandidateCurrentCompanyFirstContractDto;

  /**  */
  'cdiiStatus'?: DBCDIIStatus;

  /**  */
  'id': string;

  /**  */
  'candidateAddress': CandidateAddress;

  /**  */
  'mainAgency'?: OSMCandidateModelCandidateDtoMainAgencyDto;

  /**  */
  'activityPlanning'?: ActivityPlanningDay[];

  /**  */
  'attachedMissionIds'?: string[];

  /**  */
  'suggestedMissionIds'?: string[];

  /**  */
  'suggestedAgencyIds'?: string[];

  /**  */
  'sosAgencyId'?: string;

  /**  */
  'sosComment'?: string;

  /**  */
  'candidateQualifications'?: OSMCandidateModelSkillsDtoCandidateQualificationsSkillsDto[];

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['cDIIReturn'] = data['cDIIReturn'];
    this['GMMR'] = data['GMMR'];
    this['cPBalance'] = data['cPBalance'];
    this['cETBalance'] = data['cETBalance'];
    this['mainQualification'] = data['mainQualification'];
    this['lastContract'] = data['lastContract'];
    this['currentCompanyFirstContract'] = data['currentCompanyFirstContract'];
    this['cdiiStatus'] = data['cdiiStatus'];
    this['id'] = data['id'];
    this['candidateAddress'] = data['candidateAddress'];
    this['mainAgency'] = data['mainAgency'];
    this['activityPlanning'] = data['activityPlanning'];
    this['attachedMissionIds'] = data['attachedMissionIds'];
    this['suggestedMissionIds'] = data['suggestedMissionIds'];
    this['suggestedAgencyIds'] = data['suggestedAgencyIds'];
    this['sosAgencyId'] = data['sosAgencyId'];
    this['sosComment'] = data['sosComment'];
    this['candidateQualifications'] = data['candidateQualifications'];
  }
}

export class OSMCandidateModelCandidateDtoAssesmentDto {
  /**  */
  'date'?: string;

  /**  */
  'author'?: string;

  /**  */
  'comment'?: string;

  constructor(data: undefined | any = {}) {
    this['date'] = data['date'];
    this['author'] = data['author'];
    this['comment'] = data['comment'];
  }
}

export class QualificationInCandidateDetails {
  /**  */
  'qualificationId'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['qualificationId'] = data['qualificationId'];
    this['label'] = data['label'];
  }
}

export class OSMCandidate {
  /**  */
  'gender': string;

  /**  */
  'name': string;

  /**  */
  'firstName': string;

  /**  */
  'phone1': string;

  /**  */
  'phone2': string;

  /**  */
  'email': string;

  /**  */
  'address': string;

  /**  */
  'address0'?: string;

  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'inseeCode': string;

  /**  */
  'zipCode': string;

  /**  */
  'city'?: string;

  /**  */
  'latitude'?: number;

  /**  */
  'longitude'?: number;

  /**  */
  'qualificationId': string;

  /**  */
  'contractAccepted': string[];

  /**  */
  'nbHoursWorked': number;

  /**  */
  'nbMissions': number;

  /**  */
  'nbCompanies': number;

  /**  */
  'skillUri': string;

  /**  */
  'levelOfStudy': string;

  /**  */
  'agenciesUri': string;

  /**  */
  'mainAgency'?: OSMCandidateModelCandidateDtoMainAgencyDto;

  /**  */
  'statusCode': string;

  /**  */
  'flagAcceptSMSMission': boolean;

  /**  */
  'flagAcceptNotifMission'?: boolean;

  /**  */
  'flagAcceptEmailMission'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'dateAcceptationCguAppli'?: Date;

  /**  */
  'candidateType'?: string;

  /**  */
  'assesment'?: OSMCandidateModelCandidateDtoAssesmentDto;

  /**  */
  'flagAcceptSMS'?: boolean;

  /**  */
  'facebookApplication'?: boolean;

  /**  */
  'smartphoneApplication'?: boolean;

  /**  */
  'conveyed'?: boolean;

  /**  */
  'lastMissionAgencyId'?: string;

  /**  */
  'countryId'?: string;

  /**  */
  'isBorderWorker'?: boolean;

  /**  */
  'domicilePaySendingFlag'?: boolean;

  /**  */
  'dependantsNumber'?: number;

  /**  */
  'birthName'?: string;

  /**  */
  'temporaryNumber'?: string;

  /**  */
  'disabilityWorkerConsentStatus'?: string;

  /**  */
  'mobility'?: string[];

  /**  */
  'availabilityForConsultantDate'?: OSMCommonModelDateDto;

  /**  */
  'newGraduate'?: boolean;

  /**  */
  'registrationDate'?: OSMCommonModelDateDto;

  /**  */
  'creator'?: string;

  /**  */
  'candidateId': string;

  /**  */
  'qualification': QualificationInCandidateDetails;

  constructor(data: undefined | any = {}) {
    this['gender'] = data['gender'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['phone1'] = data['phone1'];
    this['phone2'] = data['phone2'];
    this['email'] = data['email'];
    this['address'] = data['address'];
    this['address0'] = data['address0'];
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['inseeCode'] = data['inseeCode'];
    this['zipCode'] = data['zipCode'];
    this['city'] = data['city'];
    this['latitude'] = data['latitude'];
    this['longitude'] = data['longitude'];
    this['qualificationId'] = data['qualificationId'];
    this['contractAccepted'] = data['contractAccepted'];
    this['nbHoursWorked'] = data['nbHoursWorked'];
    this['nbMissions'] = data['nbMissions'];
    this['nbCompanies'] = data['nbCompanies'];
    this['skillUri'] = data['skillUri'];
    this['levelOfStudy'] = data['levelOfStudy'];
    this['agenciesUri'] = data['agenciesUri'];
    this['mainAgency'] = data['mainAgency'];
    this['statusCode'] = data['statusCode'];
    this['flagAcceptSMSMission'] = data['flagAcceptSMSMission'];
    this['flagAcceptNotifMission'] = data['flagAcceptNotifMission'];
    this['flagAcceptEmailMission'] = data['flagAcceptEmailMission'];
    this['dateAcceptationCguAppli'] = data['dateAcceptationCguAppli'];
    this['candidateType'] = data['candidateType'];
    this['assesment'] = data['assesment'];
    this['flagAcceptSMS'] = data['flagAcceptSMS'];
    this['facebookApplication'] = data['facebookApplication'];
    this['smartphoneApplication'] = data['smartphoneApplication'];
    this['conveyed'] = data['conveyed'];
    this['lastMissionAgencyId'] = data['lastMissionAgencyId'];
    this['countryId'] = data['countryId'];
    this['isBorderWorker'] = data['isBorderWorker'];
    this['domicilePaySendingFlag'] = data['domicilePaySendingFlag'];
    this['dependantsNumber'] = data['dependantsNumber'];
    this['birthName'] = data['birthName'];
    this['temporaryNumber'] = data['temporaryNumber'];
    this['disabilityWorkerConsentStatus'] = data['disabilityWorkerConsentStatus'];
    this['mobility'] = data['mobility'];
    this['availabilityForConsultantDate'] = data['availabilityForConsultantDate'];
    this['newGraduate'] = data['newGraduate'];
    this['registrationDate'] = data['registrationDate'];
    this['creator'] = data['creator'];
    this['candidateId'] = data['candidateId'];
    this['qualification'] = data['qualification'];
  }
}

export class OSMCandidateModelSearchMultiCriteriaDtoCandidateStatusDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelSearchMultiCriteriaDtoCandidateQualificationDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelSearchMultiCriteriaDtoCandidateTargetGroupDto {
  /**  */
  'candidateAgencyId'?: string;

  /**  */
  'targetGroup'?: string;

  /**  */
  'agencyId'?: string;

  constructor(data: undefined | any = {}) {
    this['candidateAgencyId'] = data['candidateAgencyId'];
    this['targetGroup'] = data['targetGroup'];
    this['agencyId'] = data['agencyId'];
  }
}

export class OSMCandidateModelSearchMultiCriteriaDtoCandidateWorkteamDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMContratModelDtoCompanyDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'siret'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['siret'] = data['siret'];
  }
}

export class OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMContratModelDtoModalityTypeDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMContratModelDtoEndorsementDetailsContractDto {
  /**  */
  'id'?: string;

  /**  */
  'startDate'?: OSMCommonModelDateDto;

  /**  */
  'endDate'?: OSMCommonModelDateDto;

  /**  */
  'clientValidationDate'?: OSMCommonModelDateDto;

  /**  */
  'status'?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  'number'?: string;

  /**  */
  'modalityType'?: OSMContratModelDtoModalityTypeDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['clientValidationDate'] = data['clientValidationDate'];
    this['status'] = data['status'];
    this['number'] = data['number'];
    this['modalityType'] = data['modalityType'];
  }
}

export class OSMContratModelDtoCandidateDto {
  /**  */
  'candidateId'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
  }
}

export class OSMContratModelDtoServiceDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class OSMContratModelDtoQualificationDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'shortLabel'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['shortLabel'] = data['shortLabel'];
  }
}

export class OSMContractModelDtoSuspensionDateDto {
  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
  }
}

export class OSMContratModelDtoMissionRequestReasonDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'requestReason'?: string;

  /**  */
  'requestReasonDetail'?: string;

  /**  */
  'civility'?: string;

  /**  */
  'firstname'?: string;

  /**  */
  'lastname'?: string;

  /**  */
  'qualificationLabel'?: string;

  /**  */
  'status'?: string;

  /**  */
  'receipt'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['requestReason'] = data['requestReason'];
    this['requestReasonDetail'] = data['requestReasonDetail'];
    this['civility'] = data['civility'];
    this['firstname'] = data['firstname'];
    this['lastname'] = data['lastname'];
    this['qualificationLabel'] = data['qualificationLabel'];
    this['status'] = data['status'];
    this['receipt'] = data['receipt'];
  }
}

export class TalentLastContractDetail {
  /**  */
  'contractId'?: string;

  /**  */
  'company'?: OSMContratModelDtoCompanyDto;

  /**  */
  'endorsements'?: OSMContratModelDtoEndorsementDetailsContractDto[];

  /**  */
  'contactId'?: string;

  /**  */
  'status'?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  'missionId'?: string;

  /**  */
  'candidate'?: OSMContratModelDtoCandidateDto;

  /**  */
  'service'?: OSMContratModelDtoServiceDto;

  /**  */
  'qualification'?: OSMContratModelDtoQualificationDto;

  /**  */
  'clientValidationDate'?: OSMCommonModelDateDto;

  /**  */
  'missionStartDate'?: OSMCommonModelDateDto;

  /**  */
  'missionActualEndDate'?: OSMCommonModelDateDto;

  /**  */
  'startDateFlexibility'?: OSMCommonModelDateDto;

  /**  */
  'endDateFlexibility'?: OSMCommonModelDateDto;

  /**  */
  'suspensions'?: OSMContractModelDtoSuspensionDateDto[];

  /**  */
  'isDerogatoryMissionLetter'?: boolean;

  /**  */
  'missionRequestReason'?: OSMContratModelDtoMissionRequestReasonDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['contractId'] = data['contractId'];
    this['company'] = data['company'];
    this['endorsements'] = data['endorsements'];
    this['contactId'] = data['contactId'];
    this['status'] = data['status'];
    this['missionId'] = data['missionId'];
    this['candidate'] = data['candidate'];
    this['service'] = data['service'];
    this['qualification'] = data['qualification'];
    this['clientValidationDate'] = data['clientValidationDate'];
    this['missionStartDate'] = data['missionStartDate'];
    this['missionActualEndDate'] = data['missionActualEndDate'];
    this['startDateFlexibility'] = data['startDateFlexibility'];
    this['endDateFlexibility'] = data['endDateFlexibility'];
    this['suspensions'] = data['suspensions'];
    this['isDerogatoryMissionLetter'] = data['isDerogatoryMissionLetter'];
    this['missionRequestReason'] = data['missionRequestReason'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
  }
}

export class CandidateQualification {
  /**  */
  'id'?: string;

  /**  */
  'jobCenterBusinessCode'?: string;

  /**  */
  'label'?: string;

  /**  */
  'professionAndSocioProfessionalCategoryId'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['jobCenterBusinessCode'] = data['jobCenterBusinessCode'];
    this['label'] = data['label'];
    this['professionAndSocioProfessionalCategoryId'] =
      data['professionAndSocioProfessionalCategoryId'];
  }
}

export class OSMCandidateModelResidencePermitDtoResidencePermitTypesDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelResidencePermitDtoClaimantAgencyDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelResidencePermitDtoPrefectureClaimDto {
  /**  */
  'claimDate'?: OSMCommonModelDateDto;

  /**  */
  'claimantAgency'?: OSMCandidateModelResidencePermitDtoClaimantAgencyDto;

  /**  */
  'sendingMode'?: string;

  constructor(data: undefined | any = {}) {
    this['claimDate'] = data['claimDate'];
    this['claimantAgency'] = data['claimantAgency'];
    this['sendingMode'] = data['sendingMode'];
  }
}

export class OSMCandidateModelResidencePermitDtoVerdictAgencyDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCandidateModelResidencePermitDtoPrefectureVerdictDto {
  /**  */
  'verdict'?: string;

  /**  */
  'verdictDate'?: OSMCommonModelDateDto;

  /**  */
  'verdictAgency'?: OSMCandidateModelResidencePermitDtoVerdictAgencyDto;

  /**  */
  'returnMode'?: string;

  constructor(data: undefined | any = {}) {
    this['verdict'] = data['verdict'];
    this['verdictDate'] = data['verdictDate'];
    this['verdictAgency'] = data['verdictAgency'];
    this['returnMode'] = data['returnMode'];
  }
}

export class OSMCandidateModelResidencePermitDtoResidencePermitDto {
  /**  */
  'residencePermitTypes'?: OSMCandidateModelResidencePermitDtoResidencePermitTypesDto;

  /**  */
  'number'?: string;

  /**  */
  'deliveredBy'?: string;

  /**  */
  'issueDate'?: OSMCommonModelDateDto;

  /**  */
  'expirationDate'?: OSMCommonModelDateDto;

  /**  */
  'prefectureClaim'?: OSMCandidateModelResidencePermitDtoPrefectureClaimDto;

  /**  */
  'prefectureVerdict'?: OSMCandidateModelResidencePermitDtoPrefectureVerdictDto;

  constructor(data: undefined | any = {}) {
    this['residencePermitTypes'] = data['residencePermitTypes'];
    this['number'] = data['number'];
    this['deliveredBy'] = data['deliveredBy'];
    this['issueDate'] = data['issueDate'];
    this['expirationDate'] = data['expirationDate'];
    this['prefectureClaim'] = data['prefectureClaim'];
    this['prefectureVerdict'] = data['prefectureVerdict'];
  }
}

export class OSMCandidateModelResidencePermitResidencePermitResponse {
  /**  */
  'residencePermit'?: OSMCandidateModelResidencePermitDtoResidencePermitDto;

  constructor(data: undefined | any = {}) {
    this['residencePermit'] = data['residencePermit'];
  }
}

export class CandidateHabilitation {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCommonModelLocationModelOutputLocationDto {
  /**  */
  'address'?: string;

  /**  */
  'cityName'?: string;

  /**  */
  'zipCode'?: string;

  /**  */
  'inseeCode'?: string;

  constructor(data: undefined | any = {}) {
    this['address'] = data['address'];
    this['cityName'] = data['cityName'];
    this['zipCode'] = data['zipCode'];
    this['inseeCode'] = data['inseeCode'];
  }
}

export class OSMCommonModelOnMissionCandidateBaseDto {
  /**  */
  'candidateId': string;

  /**  */
  'acceptCandidate'?: boolean;

  /**  */
  'acceptCustomer'?: boolean;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
    this['acceptCandidate'] = data['acceptCandidate'];
    this['acceptCustomer'] = data['acceptCustomer'];
  }
}

export class OSMCompanyModelMissionCompanyResponse {
  /**  */
  'companyId'?: string;

  /**  */
  'name'?: string;

  /**  */
  'location'?: OSMCommonModelLocationModelOutputLocationDto;

  constructor(data: undefined | any = {}) {
    this['companyId'] = data['companyId'];
    this['name'] = data['name'];
    this['location'] = data['location'];
  }
}

export class OSMCandidateModelCommonDtoQualificationDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'shortLabel'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['shortLabel'] = data['shortLabel'];
  }
}

export class OSMCandidateCandidateMission {
  /**  */
  'missionId'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'starDate'?: Date;

  /**  */
  'startTime'?: number;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  'salary'?: number;

  /**  */
  'missionDuration'?: number;

  /**  */
  'statutMissionId'?: string;

  /**  */
  'salesPhase'?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  'location'?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  'missionSchedule'?: string;

  /**  */
  'totalSalary'?: string;

  /**  */
  'candidate'?: OSMCommonModelOnMissionCandidateBaseDto;

  /**  */
  'totalCandidates'?: number;

  /**  */
  'companies'?: OSMCompanyModelMissionCompanyResponse;

  /**  */
  'origin'?: string;

  /**  */
  'qualification'?: OSMCandidateModelCommonDtoQualificationDto;

  /**  */
  'checkSum'?: string;

  constructor(data: undefined | any = {}) {
    this['missionId'] = data['missionId'];
    this['starDate'] = data['starDate'];
    this['startTime'] = data['startTime'];
    this['endDate'] = data['endDate'];
    this['salary'] = data['salary'];
    this['missionDuration'] = data['missionDuration'];
    this['statutMissionId'] = data['statutMissionId'];
    this['salesPhase'] = data['salesPhase'];
    this['location'] = data['location'];
    this['missionSchedule'] = data['missionSchedule'];
    this['totalSalary'] = data['totalSalary'];
    this['candidate'] = data['candidate'];
    this['totalCandidates'] = data['totalCandidates'];
    this['companies'] = data['companies'];
    this['origin'] = data['origin'];
    this['qualification'] = data['qualification'];
    this['checkSum'] = data['checkSum'];
  }
}

export class CandidateMemo {
  /**  */
  'id'?: string;

  /**  */
  'consultantId'?: string;

  /**  */
  'agencyId'?: string;

  /**  */
  'date'?: OSMCommonModelDateDto;

  /**  */
  'comment'?: string;

  /**  */
  'isBehaviorMemo'?: boolean;

  /**  */
  'consultant'?: ConsultantInfo;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['consultantId'] = data['consultantId'];
    this['agencyId'] = data['agencyId'];
    this['date'] = data['date'];
    this['comment'] = data['comment'];
    this['isBehaviorMemo'] = data['isBehaviorMemo'];
    this['consultant'] = data['consultant'];
  }
}

export class Talent {
  /**  */
  'candidateId'?: string;

  /**  */
  'gender'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'candidateStatus'?: OSMCandidateModelSearchMultiCriteriaDtoCandidateStatusDto;

  /**  */
  'qualification'?: OSMCandidateModelSearchMultiCriteriaDtoCandidateQualificationDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'firstContractStartDate'?: Date;

  /**  */
  'targetGroups'?: OSMCandidateModelSearchMultiCriteriaDtoCandidateTargetGroupDto[];

  /**  */
  'workteams'?: OSMCandidateModelSearchMultiCriteriaDtoCandidateWorkteamDto[];

  /**  */
  'type'?: string;

  /**  */
  'lastContract'?: TalentLastContractDetail;

  /**  */
  'candidateAddress'?: CandidateAddress;

  /**  */
  @Expose()
  @Type(() => Date)
  'availabilityForConsultantDate'?: Date;

  /**  */
  'qualifications'?: CandidateQualification[];

  /**  */
  'qualificationId'?: string;

  /**  */
  'residencePermit'?: OSMCandidateModelResidencePermitResidencePermitResponse;

  /**  */
  'nbCompanies'?: number;

  /**  */
  'nbHours'?: number;

  /**  */
  'nbMissions'?: number;

  /**  */
  'phoneNumber1'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'lastMedicalVisit'?: Date;

  /**  */
  'habilitations'?: CandidateHabilitation[];

  /**  */
  'acceptedMobility'?: string[];

  /**  */
  'candidateType'?: string;

  /**  */
  'mainAgencyCode'?: string;

  /**  */
  'missions'?: OSMCandidateCandidateMission[];

  /**  */
  'memos'?: CandidateMemo[];

  /**  */
  'talentId'?: string;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
    this['gender'] = data['gender'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['candidateStatus'] = data['candidateStatus'];
    this['qualification'] = data['qualification'];
    this['firstContractStartDate'] = data['firstContractStartDate'];
    this['targetGroups'] = data['targetGroups'];
    this['workteams'] = data['workteams'];
    this['type'] = data['type'];
    this['lastContract'] = data['lastContract'];
    this['candidateAddress'] = data['candidateAddress'];
    this['availabilityForConsultantDate'] = data['availabilityForConsultantDate'];
    this['qualifications'] = data['qualifications'];
    this['qualificationId'] = data['qualificationId'];
    this['residencePermit'] = data['residencePermit'];
    this['nbCompanies'] = data['nbCompanies'];
    this['nbHours'] = data['nbHours'];
    this['nbMissions'] = data['nbMissions'];
    this['phoneNumber1'] = data['phoneNumber1'];
    this['lastMedicalVisit'] = data['lastMedicalVisit'];
    this['habilitations'] = data['habilitations'];
    this['acceptedMobility'] = data['acceptedMobility'];
    this['candidateType'] = data['candidateType'];
    this['mainAgencyCode'] = data['mainAgencyCode'];
    this['missions'] = data['missions'];
    this['memos'] = data['memos'];
    this['talentId'] = data['talentId'];
  }
}

export class OSMContratModelDtoEndorsementModalityTypeDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMContratModelDtoEndorsementForCandidateDto {
  /**  */
  'id'?: string;

  /**  */
  'startDate'?: OSMCommonModelDateDto;

  /**  */
  'endDate'?: OSMCommonModelDateDto;

  /**  */
  'clientValidationDate'?: OSMCommonModelDateDto;

  /**  */
  'status'?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  'modalityType'?: OSMContratModelDtoEndorsementModalityTypeDto;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['clientValidationDate'] = data['clientValidationDate'];
    this['status'] = data['status'];
    this['modalityType'] = data['modalityType'];
  }
}

export class OSMCandidateCurrentContractWithEndorsements {
  /**  */
  'companyName'?: string;

  /**  */
  'missionId'?: string;

  /**  */
  'contractId'?: string;

  /**  */
  'endorsements'?: OSMContratModelDtoEndorsementForCandidateDto[];

  /**  */
  'statusCode'?: string;

  /**  */
  'statusLabel'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'missionStartDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'missionActualEndDate'?: Date;

  /**  */
  'isRenewable': boolean;

  constructor(data: undefined | any = {}) {
    this['companyName'] = data['companyName'];
    this['missionId'] = data['missionId'];
    this['contractId'] = data['contractId'];
    this['endorsements'] = data['endorsements'];
    this['statusCode'] = data['statusCode'];
    this['statusLabel'] = data['statusLabel'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['missionStartDate'] = data['missionStartDate'];
    this['missionActualEndDate'] = data['missionActualEndDate'];
    this['isRenewable'] = data['isRenewable'];
  }
}

export class OSMContratModelDtoContractTypeDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class CandidateCDIContract {
  /**  */
  'contractId'?: string;

  /**  */
  'endorsementId'?: string;

  /**  */
  'contractType'?: OSMContratModelDtoContractTypeDto;

  /**  */
  'statusCode'?: string;

  /**  */
  'statusLabel'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['contractId'] = data['contractId'];
    this['endorsementId'] = data['endorsementId'];
    this['contractType'] = data['contractType'];
    this['statusCode'] = data['statusCode'];
    this['statusLabel'] = data['statusLabel'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
  }
}

export class CandidateContract {
  /**  */
  'companyName'?: string;

  /**  */
  'missionId'?: string;

  /**  */
  'contractId'?: string;

  /**  */
  'endorsementId'?: string;

  /**  */
  'statusCode'?: string;

  /**  */
  'statusLabel'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'dateStartMission'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'dateFinReelMission'?: Date;

  constructor(data: undefined | any = {}) {
    this['companyName'] = data['companyName'];
    this['missionId'] = data['missionId'];
    this['contractId'] = data['contractId'];
    this['endorsementId'] = data['endorsementId'];
    this['statusCode'] = data['statusCode'];
    this['statusLabel'] = data['statusLabel'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['dateStartMission'] = data['dateStartMission'];
    this['dateFinReelMission'] = data['dateFinReelMission'];
  }
}

export class CreateMemoParams {
  /**  */
  'comment': string;

  /**  */
  'brandCode': string;

  /**  */
  'isBehaviorMemo': boolean;

  constructor(data: undefined | any = {}) {
    this['comment'] = data['comment'];
    this['brandCode'] = data['brandCode'];
    this['isBehaviorMemo'] = data['isBehaviorMemo'];
  }
}

export class EditMemoParams {
  /**  */
  'memoId': string;

  /**  */
  'candidateId': string;

  /**  */
  'brandCode': string;

  /**  */
  'comment': string;

  /**  */
  'isBehaviorMemo': boolean;

  constructor(data: undefined | any = {}) {
    this['memoId'] = data['memoId'];
    this['candidateId'] = data['candidateId'];
    this['brandCode'] = data['brandCode'];
    this['comment'] = data['comment'];
    this['isBehaviorMemo'] = data['isBehaviorMemo'];
  }
}

export class OSMCandidateModelETestingTestsDtoGoalDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class CandidateTesting {
  /**  */
  'testType'?: string;

  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'goal'?: OSMCandidateModelETestingTestsDtoGoalDto;

  /**  */
  'agencyId'?: string;

  /**  */
  'completionRate'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'completedOn'?: Date;

  constructor(data: undefined | any = {}) {
    this['testType'] = data['testType'];
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['goal'] = data['goal'];
    this['agencyId'] = data['agencyId'];
    this['completionRate'] = data['completionRate'];
    this['completedOn'] = data['completedOn'];
  }
}

export class CandidateFirstContractDate {
  /**  */
  @Expose()
  @Type(() => Date)
  'firstContractStartDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['firstContractStartDate'] = data['firstContractStartDate'];
  }
}

export class DBCDII {
  /**  */
  'candidateId': string;

  /**  */
  'status': EnumDBCDIIStatus;

  /**  */
  'attachedMissionIds'?: string[];

  /**  */
  'suggestedMissionIds'?: string[];

  /**  */
  'suggestedAgencyIds'?: string[];

  /**  */
  'sosComment': string;

  /**  */
  'sosAgencyId'?: string;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
    this['status'] = data['status'];
    this['attachedMissionIds'] = data['attachedMissionIds'];
    this['suggestedMissionIds'] = data['suggestedMissionIds'];
    this['suggestedAgencyIds'] = data['suggestedAgencyIds'];
    this['sosComment'] = data['sosComment'];
    this['sosAgencyId'] = data['sosAgencyId'];
  }
}

export class SendSuggestionToAgenciesParams {
  /**  */
  'agencyId': string;

  /**  */
  'missionNumber': string;

  /**  */
  'clientName': string;

  /**  */
  'qualificationName': string;

  /**  */
  'startDate': string;

  constructor(data: undefined | any = {}) {
    this['agencyId'] = data['agencyId'];
    this['missionNumber'] = data['missionNumber'];
    this['clientName'] = data['clientName'];
    this['qualificationName'] = data['qualificationName'];
    this['startDate'] = data['startDate'];
  }
}

export class SuggestedMissionsParams {
  /**  */
  'cgcId': string;

  /**  */
  'cc': string[];

  /**  */
  'suggestedMissions': SendSuggestionToAgenciesParams[];

  /**  */
  'comment': string;

  /**  */
  'candidateFullName': string;

  /**  */
  'candidateMainQualification': string;

  /**  */
  'lastContractEndDate': string;

  /**  */
  'lastMedicalVisit': string;

  constructor(data: undefined | any = {}) {
    this['cgcId'] = data['cgcId'];
    this['cc'] = data['cc'];
    this['suggestedMissions'] = data['suggestedMissions'];
    this['comment'] = data['comment'];
    this['candidateFullName'] = data['candidateFullName'];
    this['candidateMainQualification'] = data['candidateMainQualification'];
    this['lastContractEndDate'] = data['lastContractEndDate'];
    this['lastMedicalVisit'] = data['lastMedicalVisit'];
  }
}

export class RefuseSuggestedCandidateParams {
  /**  */
  'comment': string;

  /**  */
  'suggestedMissionsIds': string[];

  /**  */
  'cgcId': string;

  /**  */
  'agencyId': string;

  /**  */
  'companiesNames'?: string[];

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'gender'?: string;

  /**  */
  'reason'?: string;

  /**  */
  'refuseAll'?: boolean;

  constructor(data: undefined | any = {}) {
    this['comment'] = data['comment'];
    this['suggestedMissionsIds'] = data['suggestedMissionsIds'];
    this['cgcId'] = data['cgcId'];
    this['agencyId'] = data['agencyId'];
    this['companiesNames'] = data['companiesNames'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['gender'] = data['gender'];
    this['reason'] = data['reason'];
    this['refuseAll'] = data['refuseAll'];
  }
}

export class OSMCdiContractModelDtoCandidateDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
  }
}

export class OSMCdiContractModelDtoContractStatusDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCdiContractModelDtoTrialPeriodDateDto {
  /**  */
  'month'?: number;

  /**  */
  'day'?: number;

  constructor(data: undefined | any = {}) {
    this['month'] = data['month'];
    this['day'] = data['day'];
  }
}

export class OSMCdiContractModelDtoWorkingConditionDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCdiContractModelDtoContractMobilityDto {
  /**  */
  'geographicalZone'?: string;

  /**  */
  'distance'?: number;

  /**  */
  'timeTravel'?: number;

  constructor(data: undefined | any = {}) {
    this['geographicalZone'] = data['geographicalZone'];
    this['distance'] = data['distance'];
    this['timeTravel'] = data['timeTravel'];
  }
}

export class OSMCdiContractModelDtoContractSalaryDto {
  /**  */
  'monthlyDuration'?: string;

  /**  */
  'minimumMonthlySalary'?: number;

  constructor(data: undefined | any = {}) {
    this['monthlyDuration'] = data['monthlyDuration'];
    this['minimumMonthlySalary'] = data['minimumMonthlySalary'];
  }
}

export class OSMCdiContractModelDtoContractJobDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'main'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['main'] = data['main'];
  }
}

export class OSMCdiContractDetails {
  /**  */
  'candidate'?: OSMCdiContractModelDtoCandidateDto;

  /**  */
  'startDate'?: OSMCommonModelDateDto;

  /**  */
  'startingHour'?: number;

  /**  */
  'status'?: OSMCdiContractModelDtoContractStatusDto;

  /**  */
  'trialPeriod'?: OSMCdiContractModelDtoTrialPeriodDateDto;

  /**  */
  'workingConditions'?: OSMCdiContractModelDtoWorkingConditionDto[];

  /**  */
  'mobility'?: OSMCdiContractModelDtoContractMobilityDto;

  /**  */
  'salary'?: OSMCdiContractModelDtoContractSalaryDto;

  /**  */
  'jobList'?: OSMCdiContractModelDtoContractJobDto[];

  /**  */
  'agencyId'?: string;

  /**  */
  'lastEndorsementAgencyId'?: string;

  constructor(data: undefined | any = {}) {
    this['candidate'] = data['candidate'];
    this['startDate'] = data['startDate'];
    this['startingHour'] = data['startingHour'];
    this['status'] = data['status'];
    this['trialPeriod'] = data['trialPeriod'];
    this['workingConditions'] = data['workingConditions'];
    this['mobility'] = data['mobility'];
    this['salary'] = data['salary'];
    this['jobList'] = data['jobList'];
    this['agencyId'] = data['agencyId'];
    this['lastEndorsementAgencyId'] = data['lastEndorsementAgencyId'];
  }
}

export class ChangeRepoStatusParams {
  /**  */
  'comment': string;

  /**  */
  'suggestedMissionsIds': string[];

  /**  */
  'cgcId': string;

  /**  */
  'agencyId': string;

  /**  */
  'companiesNames'?: string[];

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'gender'?: string;

  /**  */
  'reason'?: string;

  /**  */
  'refuseAll'?: boolean;

  /**  */
  'oldStatus': string;

  /**  */
  'newStatus': string;

  /**  */
  'candidateId': string;

  /**  */
  'suggestedMissionsToAttach'?: string[];

  constructor(data: undefined | any = {}) {
    this['comment'] = data['comment'];
    this['suggestedMissionsIds'] = data['suggestedMissionsIds'];
    this['cgcId'] = data['cgcId'];
    this['agencyId'] = data['agencyId'];
    this['companiesNames'] = data['companiesNames'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['gender'] = data['gender'];
    this['reason'] = data['reason'];
    this['refuseAll'] = data['refuseAll'];
    this['oldStatus'] = data['oldStatus'];
    this['newStatus'] = data['newStatus'];
    this['candidateId'] = data['candidateId'];
    this['suggestedMissionsToAttach'] = data['suggestedMissionsToAttach'];
  }
}

export class SuggestedAgenciesParams {
  /**  */
  'suggestedAgencies': AgencyDetails[];

  /**  */
  'cgcId': string;

  /**  */
  'cc': string[];

  /**  */
  'candidateFullName': string;

  /**  */
  'candidateMainQualification': string;

  /**  */
  'comment'?: string;

  /**  */
  'lastContractEndDate'?: string;

  /**  */
  'lastMedicalVisit'?: string;

  constructor(data: undefined | any = {}) {
    this['suggestedAgencies'] = data['suggestedAgencies'];
    this['cgcId'] = data['cgcId'];
    this['cc'] = data['cc'];
    this['candidateFullName'] = data['candidateFullName'];
    this['candidateMainQualification'] = data['candidateMainQualification'];
    this['comment'] = data['comment'];
    this['lastContractEndDate'] = data['lastContractEndDate'];
    this['lastMedicalVisit'] = data['lastMedicalVisit'];
  }
}

export class FetchAgencyTemporaryWorkersParams {
  /**  */
  'sortingKey': TemporaryWorkersSortingKey;

  /**  */
  'sortingMode': TemporaryWorkersSortingMode;

  /**  */
  'page': number;

  /**  */
  'brandCode': string;

  /**  */
  'agency': string;

  constructor(data: undefined | any = {}) {
    this['sortingKey'] = data['sortingKey'];
    this['sortingMode'] = data['sortingMode'];
    this['page'] = data['page'];
    this['brandCode'] = data['brandCode'];
    this['agency'] = data['agency'];
  }
}

export class TemporaryWorkerWithoutDetail {
  /**  */
  'candidateId': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'availabilityForConsultantDate'?: Date;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'qualification'?: OSMCandidateModelSearchMultiCriteriaDtoCandidateQualificationDto;

  /**  */
  'agencyId'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'lastContractEndDate'?: Date;

  /**  */
  'city'?: string;

  /**  */
  'zipCode'?: string;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
    this['availabilityForConsultantDate'] = data['availabilityForConsultantDate'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['qualification'] = data['qualification'];
    this['agencyId'] = data['agencyId'];
    this['lastContractEndDate'] = data['lastContractEndDate'];
    this['city'] = data['city'];
    this['zipCode'] = data['zipCode'];
  }
}

export class PaginatedTemporaryWorkersWithoutDetail {
  /**  */
  'temporaryWorkers': TemporaryWorkerWithoutDetail[];

  /**  */
  'nextPage'?: number;

  constructor(data: undefined | any = {}) {
    this['temporaryWorkers'] = data['temporaryWorkers'];
    this['nextPage'] = data['nextPage'];
  }
}

export class FetchRegionalTemporaryWorkersParams {
  /**  */
  'sortingKey': TemporaryWorkersSortingKey;

  /**  */
  'sortingMode': TemporaryWorkersSortingMode;

  /**  */
  'page': number;

  /**  */
  'brandCode': string;

  /**  */
  'agency': string;

  /**  */
  'distanceInKm': number;

  constructor(data: undefined | any = {}) {
    this['sortingKey'] = data['sortingKey'];
    this['sortingMode'] = data['sortingMode'];
    this['page'] = data['page'];
    this['brandCode'] = data['brandCode'];
    this['agency'] = data['agency'];
    this['distanceInKm'] = data['distanceInKm'];
  }
}

export class OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto {
  /**  */
  'id'?: number;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class RawContact {
  /**  */
  'contactId'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'position'?: string;

  /**  */
  'statut'?: string;

  /**  */
  'mobilePhoneNumber'?: string;

  /**  */
  'phone'?: string;

  /**  */
  'email'?: string;

  /**  */
  'dontMailMe'?: boolean;

  /**  */
  'dontCallMe'?: boolean;

  /**  */
  'dontPostMe'?: boolean;

  /**  */
  'dontPlaceMe'?: boolean;

  /**  */
  'civility'?: OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto;

  constructor(data: undefined | any = {}) {
    this['contactId'] = data['contactId'];
    this['firstName'] = data['firstName'];
    this['name'] = data['name'];
    this['position'] = data['position'];
    this['statut'] = data['statut'];
    this['mobilePhoneNumber'] = data['mobilePhoneNumber'];
    this['phone'] = data['phone'];
    this['email'] = data['email'];
    this['dontMailMe'] = data['dontMailMe'];
    this['dontCallMe'] = data['dontCallMe'];
    this['dontPostMe'] = data['dontPostMe'];
    this['dontPlaceMe'] = data['dontPlaceMe'];
    this['civility'] = data['civility'];
  }
}

export class GetCompaniesBody {
  /**  */
  'keyword': string;

  /**  */
  'agencies': string[];

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['keyword'] = data['keyword'];
    this['agencies'] = data['agencies'];
    this['brandCode'] = data['brandCode'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyAddressDto {
  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'postalCode'?: string;

  /**  */
  'city'?: string;

  constructor(data: undefined | any = {}) {
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['postalCode'] = data['postalCode'];
    this['city'] = data['city'];
  }
}

export class OSMCompanyModelSearchDtoVisitDto {
  /**  */
  'startDate'?: OSMCommonModelDateDto;

  /**  */
  'endDate'?: OSMCommonModelDateDto;

  constructor(data: undefined | any = {}) {
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class CompanySearchResult {
  /**  */
  'societyStatus'?: string;

  /**  */
  'solvency'?: SolvencyEnum;

  /**  */
  'companyAddress'?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  'lastVisit'?: OSMCompanyModelSearchDtoVisitDto;

  /**  */
  'hasFrameworkAgreement'?: boolean;

  /**  */
  'delegationPotential'?: number;

  /**  */
  'externalCode'?: string;

  /**  */
  'collectiveAgreement'?: OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto;

  /**  */
  'timeTrackingTool'?: OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto;

  /**  */
  'companyId'?: string;

  /**  */
  'companyName'?: string;

  /**  */
  'siret'?: string;

  constructor(data: undefined | any = {}) {
    this['societyStatus'] = data['societyStatus'];
    this['solvency'] = data['solvency'];
    this['companyAddress'] = data['companyAddress'];
    this['lastVisit'] = data['lastVisit'];
    this['hasFrameworkAgreement'] = data['hasFrameworkAgreement'];
    this['delegationPotential'] = data['delegationPotential'];
    this['externalCode'] = data['externalCode'];
    this['collectiveAgreement'] = data['collectiveAgreement'];
    this['timeTrackingTool'] = data['timeTrackingTool'];
    this['companyId'] = data['companyId'];
    this['companyName'] = data['companyName'];
    this['siret'] = data['siret'];
  }
}

export class GetYouplanCompaniesBody {
  /**  */
  'agencies': string[];

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['agencies'] = data['agencies'];
    this['brandCode'] = data['brandCode'];
  }
}

export class YouplanCompany {
  /**  */
  'societyStatus'?: string;

  /**  */
  'solvency'?: SolvencyEnum;

  /**  */
  'companyAddress'?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  'lastVisit'?: OSMCompanyModelSearchDtoVisitDto;

  /**  */
  'hasFrameworkAgreement'?: boolean;

  /**  */
  'delegationPotential'?: number;

  /**  */
  'externalCode'?: string;

  /**  */
  'collectiveAgreement'?: OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto;

  /**  */
  'timeTrackingTool'?: OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto;

  /**  */
  'companyId'?: string;

  /**  */
  'companyName'?: string;

  /**  */
  'siret'?: string;

  /**  */
  'activeMissionsCount'?: number;

  constructor(data: undefined | any = {}) {
    this['societyStatus'] = data['societyStatus'];
    this['solvency'] = data['solvency'];
    this['companyAddress'] = data['companyAddress'];
    this['lastVisit'] = data['lastVisit'];
    this['hasFrameworkAgreement'] = data['hasFrameworkAgreement'];
    this['delegationPotential'] = data['delegationPotential'];
    this['externalCode'] = data['externalCode'];
    this['collectiveAgreement'] = data['collectiveAgreement'];
    this['timeTrackingTool'] = data['timeTrackingTool'];
    this['companyId'] = data['companyId'];
    this['companyName'] = data['companyName'];
    this['siret'] = data['siret'];
    this['activeMissionsCount'] = data['activeMissionsCount'];
  }
}

export class CompanyService {
  /**  */
  'active': boolean;

  /**  */
  'serviceId'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['active'] = data['active'];
    this['serviceId'] = data['serviceId'];
    this['name'] = data['name'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyContactServicesDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class CompanyContact {
  /**  */
  'contactId'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'position'?: string;

  /**  */
  'statut'?: string;

  /**  */
  'mobilePhoneNumber'?: string;

  /**  */
  'phone'?: string;

  /**  */
  'email'?: string;

  /**  */
  'isActiveOnCurrentCompany'?: boolean;

  /**  */
  'mainContact'?: boolean;

  /**  */
  'myContact'?: boolean;

  /**  */
  'dontMailMe'?: boolean;

  /**  */
  'dontCallMe'?: boolean;

  /**  */
  'dontPostMe'?: boolean;

  /**  */
  'dontPlaceMe'?: boolean;

  /**  */
  'civility'?: OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto;

  /**  */
  'services'?: OSMCompanyModelCompanyDtoCompanyContactServicesDto[];

  /**  */
  'portalAccess'?: boolean;

  constructor(data: undefined | any = {}) {
    this['contactId'] = data['contactId'];
    this['firstName'] = data['firstName'];
    this['name'] = data['name'];
    this['position'] = data['position'];
    this['statut'] = data['statut'];
    this['mobilePhoneNumber'] = data['mobilePhoneNumber'];
    this['phone'] = data['phone'];
    this['email'] = data['email'];
    this['isActiveOnCurrentCompany'] = data['isActiveOnCurrentCompany'];
    this['mainContact'] = data['mainContact'];
    this['myContact'] = data['myContact'];
    this['dontMailMe'] = data['dontMailMe'];
    this['dontCallMe'] = data['dontCallMe'];
    this['dontPostMe'] = data['dontPostMe'];
    this['dontPlaceMe'] = data['dontPlaceMe'];
    this['civility'] = data['civility'];
    this['services'] = data['services'];
    this['portalAccess'] = data['portalAccess'];
  }
}

export class CompanyRequestReason {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'shortLabel'?: string;

  /**  */
  'isFreeText'?: boolean;

  /**  */
  'isActive'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['shortLabel'] = data['shortLabel'];
    this['isFreeText'] = data['isFreeText'];
    this['isActive'] = data['isActive'];
  }
}

export class GetCompaniesActionBody {
  /**  */
  'brandCode': string;

  /**  */
  'agency': string;

  constructor(data: undefined | any = {}) {
    this['brandCode'] = data['brandCode'];
    this['agency'] = data['agency'];
  }
}

export class OSMCompanyModelActionDtoCandidateInfoDto {
  /**  */
  'candidateId'?: string;

  /**  */
  'name'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'phone1'?: string;

  /**  */
  'phone2'?: string;

  /**  */
  'email'?: string;

  constructor(data: undefined | any = {}) {
    this['candidateId'] = data['candidateId'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['phone1'] = data['phone1'];
    this['phone2'] = data['phone2'];
    this['email'] = data['email'];
  }
}

export class OSMCompanyModelActionDtoActionTargetInfoDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'isActive'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['isActive'] = data['isActive'];
  }
}

export class CompanyAction {
  /**  */
  'actionId'?: string;

  /**  */
  'contacts'?: OSMActionModelDtoContactDto[];

  /**  */
  'candidate'?: OSMCompanyModelActionDtoCandidateInfoDto;

  /**  */
  'actors'?: OSMActionModelDtoActorDto[];

  /**  */
  'attachedDocuments'?: OSMActionModelDtoAttachedDocumentDto[];

  /**  */
  'type'?: OSMRepositoriesModelTypeActionRepositoryDtoTypeActionDto;

  /**  */
  'status'?: OSMRepositoriesModelActionStateRepositoryDtoActionStateDto;

  /**  */
  'target'?: OSMCompanyModelActionDtoActionTargetInfoDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  'startTime'?: number;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  'endTime'?: number;

  /**  */
  'agencies'?: string[];

  /**  */
  'goalsByFamily'?: OSMRepositoriesModelGoalRepositoryDtoFamilyByGoalDto[];

  /**  */
  'report'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'creationDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'lastModificationDate'?: Date;

  /**  */
  'syncGoogleCal'?: boolean;

  /**  */
  'appointment'?: boolean;

  constructor(data: undefined | any = {}) {
    this['actionId'] = data['actionId'];
    this['contacts'] = data['contacts'];
    this['candidate'] = data['candidate'];
    this['actors'] = data['actors'];
    this['attachedDocuments'] = data['attachedDocuments'];
    this['type'] = data['type'];
    this['status'] = data['status'];
    this['target'] = data['target'];
    this['startDate'] = data['startDate'];
    this['startTime'] = data['startTime'];
    this['endDate'] = data['endDate'];
    this['endTime'] = data['endTime'];
    this['agencies'] = data['agencies'];
    this['goalsByFamily'] = data['goalsByFamily'];
    this['report'] = data['report'];
    this['creationDate'] = data['creationDate'];
    this['lastModificationDate'] = data['lastModificationDate'];
    this['syncGoogleCal'] = data['syncGoogleCal'];
    this['appointment'] = data['appointment'];
  }
}

export class PatchCompanyQualification {
  /**  */
  'potentialQualificationId': string;

  constructor(data: undefined | any = {}) {
    this['potentialQualificationId'] = data['potentialQualificationId'];
  }
}

export class CompanyNewQualification {
  /**  */
  'isNewQualif': boolean;

  /**  */
  'potentialQualificationId': string;

  constructor(data: undefined | any = {}) {
    this['isNewQualif'] = data['isNewQualif'];
    this['potentialQualificationId'] = data['potentialQualificationId'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoOutputCompanyDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'siret'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['siret'] = data['siret'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoServiceDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoQualificationDto {
  /**  */
  'id'?: string;

  /**  */
  'shortLabel'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['shortLabel'] = data['shortLabel'];
    this['label'] = data['label'];
  }
}

export class YouplanMissionActionSummary {
  /**  */
  'action': EnumYouplanMissionActionSummaryAction;

  /**  */
  'youplanMissionId': string;

  /**  */
  'entityId'?: string;

  /**  */
  'isInConflict'?: boolean;

  /**  */
  'lastConflictCause'?: EnumYouplanMissionActionSummaryLastConflictCause;

  constructor(data: undefined | any = {}) {
    this['action'] = data['action'];
    this['youplanMissionId'] = data['youplanMissionId'];
    this['entityId'] = data['entityId'];
    this['isInConflict'] = data['isInConflict'];
    this['lastConflictCause'] = data['lastConflictCause'];
  }
}

export class CoreMissionCandidate {
  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'candidateAdvancement'?: EnumCoreMissionCandidateCandidateAdvancement;

  /**  */
  'companyAdvancement'?: EnumCoreMissionCandidateCompanyAdvancement;

  /**  */
  'candidateId'?: string;

  constructor(data: undefined | any = {}) {
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['candidateAdvancement'] = data['candidateAdvancement'];
    this['companyAdvancement'] = data['companyAdvancement'];
    this['candidateId'] = data['candidateId'];
  }
}

export class YouplanMission {
  /**  */
  'missionId'?: string;

  /**  */
  'numberPositions'?: number;

  /**  */
  'company'?: OSMMissionsWithoutScheduleModelDtoOutputCompanyDto;

  /**  */
  'service'?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  'qualification'?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  'statutMissionId'?: string;

  /**  */
  'salesPhase'?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  'agencyId'?: string;

  /**  */
  'dPAEAgencyId'?: string;

  /**  */
  'workLocation'?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  'clientContactingStatus': ContactingStatus;

  /**  */
  'candidateContactingStatus': ContactingStatus;

  /**  */
  'youplanStatus'?: YouplanMissionActionSummary;

  /**  */
  'relatedMissionCandidates'?: string[];

  /**  */
  @Expose()
  @Type(() => Date)
  'processingDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'modificationDate'?: Date;

  /**  */
  'candidates'?: CoreMissionCandidate[];

  constructor(data: undefined | any = {}) {
    this['missionId'] = data['missionId'];
    this['numberPositions'] = data['numberPositions'];
    this['company'] = data['company'];
    this['service'] = data['service'];
    this['qualification'] = data['qualification'];
    this['statutMissionId'] = data['statutMissionId'];
    this['salesPhase'] = data['salesPhase'];
    this['agencyId'] = data['agencyId'];
    this['dPAEAgencyId'] = data['dPAEAgencyId'];
    this['workLocation'] = data['workLocation'];
    this['clientContactingStatus'] = data['clientContactingStatus'];
    this['candidateContactingStatus'] = data['candidateContactingStatus'];
    this['youplanStatus'] = data['youplanStatus'];
    this['relatedMissionCandidates'] = data['relatedMissionCandidates'];
    this['processingDate'] = data['processingDate'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['modificationDate'] = data['modificationDate'];
    this['candidates'] = data['candidates'];
  }
}

export class YouplanMissionAction {
  /**  */
  'youplanMissionId': string;

  /**  */
  'entityId'?: string;

  /**  */
  'brandCode': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate': Date;

  /**  */
  'action': EnumYouplanMissionActionAction;

  /**  */
  'isInConflict'?: boolean;

  /**  */
  'lastConflictCause'?: EnumYouplanMissionActionLastConflictCause;

  /**  */
  'companyId': string;

  /**  */
  'dpaeAgencyId': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'processingDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['youplanMissionId'] = data['youplanMissionId'];
    this['entityId'] = data['entityId'];
    this['brandCode'] = data['brandCode'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['action'] = data['action'];
    this['isInConflict'] = data['isInConflict'];
    this['lastConflictCause'] = data['lastConflictCause'];
    this['companyId'] = data['companyId'];
    this['dpaeAgencyId'] = data['dpaeAgencyId'];
    this['processingDate'] = data['processingDate'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDto {
  /**  */
  'siret'?: string;

  /**  */
  'companyId'?: string;

  /**  */
  'name'?: string;

  /**  */
  'location'?: OSMCommonModelLocationModelOutputLocationDto;

  constructor(data: undefined | any = {}) {
    this['siret'] = data['siret'];
    this['companyId'] = data['companyId'];
    this['name'] = data['name'];
    this['location'] = data['location'];
  }
}

export class OSMMissionModelDtoReplacedPersonDto {
  /**  */
  'requestReason'?: string;

  /**  */
  'requestReasonDetail'?: string;

  /**  */
  'civility'?: string;

  /**  */
  'firstname'?: string;

  /**  */
  'lastname'?: string;

  /**  */
  'qualificationLabel'?: string;

  /**  */
  'status'?: string;

  constructor(data: undefined | any = {}) {
    this['requestReason'] = data['requestReason'];
    this['requestReasonDetail'] = data['requestReasonDetail'];
    this['civility'] = data['civility'];
    this['firstname'] = data['firstname'];
    this['lastname'] = data['lastname'];
    this['qualificationLabel'] = data['qualificationLabel'];
    this['status'] = data['status'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoIncreaseDto {
  /**  */
  'flagNight'?: boolean;

  /**  */
  'nightSchedule'?: string;

  /**  */
  'nightIncrease'?: number;

  /**  */
  'nightCondition'?: string;

  /**  */
  'flagSunday'?: boolean;

  /**  */
  'sundaySchedule'?: string;

  /**  */
  'sundayIncrease'?: number;

  /**  */
  'sundayCondition'?: string;

  /**  */
  'flagHoliday'?: boolean;

  /**  */
  'holidayIncrease'?: number;

  /**  */
  'holidayCondition'?: string;

  constructor(data: undefined | any = {}) {
    this['flagNight'] = data['flagNight'];
    this['nightSchedule'] = data['nightSchedule'];
    this['nightIncrease'] = data['nightIncrease'];
    this['nightCondition'] = data['nightCondition'];
    this['flagSunday'] = data['flagSunday'];
    this['sundaySchedule'] = data['sundaySchedule'];
    this['sundayIncrease'] = data['sundayIncrease'];
    this['sundayCondition'] = data['sundayCondition'];
    this['flagHoliday'] = data['flagHoliday'];
    this['holidayIncrease'] = data['holidayIncrease'];
    this['holidayCondition'] = data['holidayCondition'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetWorkTeam {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto {
  /**  */
  'sequence'?: string;

  /**  */
  'label'?: string;

  /**  */
  'labelPriority'?: string;

  /**  */
  'typeCode'?: string;

  /**  */
  'id'?: string;

  /**  */
  'priorityId'?: number;

  constructor(data: undefined | any = {}) {
    this['sequence'] = data['sequence'];
    this['label'] = data['label'];
    this['labelPriority'] = data['labelPriority'];
    this['typeCode'] = data['typeCode'];
    this['id'] = data['id'];
    this['priorityId'] = data['priorityId'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetDrivingLicenseDto {
  /**  */
  'sequence'?: number;

  /**  */
  'label'?: string;

  /**  */
  'labelPriority'?: string;

  /**  */
  'id': string;

  /**  */
  'priorityId': number;

  constructor(data: undefined | any = {}) {
    this['sequence'] = data['sequence'];
    this['label'] = data['label'];
    this['labelPriority'] = data['labelPriority'];
    this['id'] = data['id'];
    this['priorityId'] = data['priorityId'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseDto {
  /**  */
  'id'?: string;

  /**  */
  'status'?: string;

  /**  */
  'natureForeseeableRiskToPostList'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  'followUpIndividualReinforcedList'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  'workRequiringTrainingList'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  'prohibitedWorksList'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  'drivingLicenseList'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetDrivingLicenseDto[];

  /**  */
  'welcomeById'?: string;

  /**  */
  'otherEquipment'?: string;

  /**  */
  'specialConditions'?: string;

  /**  */
  'instructionsToFollow'?: string;

  /**  */
  'painfulnessFlag'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'implementedHardnessDate'?: Date;

  /**  */
  'habilitationAuthorizationDetail'?: string;

  /**  */
  'collectiveProtectionEquipment'?: string;

  /**  */
  'practice'?: number;

  /**  */
  'asbestosFlag'?: boolean;

  /**  */
  'otherFlag'?: boolean;

  /**  */
  'slingFlag'?: boolean;

  /**  */
  'superToleranceNoiseFlag'?: boolean;

  /**  */
  'helmetFlag'?: boolean;

  /**  */
  'shoesFlag'?: boolean;

  /**  */
  'requestDerogationProhibitedWorkFlag'?: boolean;

  /**  */
  'nilStateFlag'?: boolean;

  /**  */
  'companyWelcomeSheetFlag'?: boolean;

  /**  */
  'formalizationHomeFlag'?: boolean;

  /**  */
  'enhancedSecurityTrainingFlag'?: boolean;

  /**  */
  'glovesFlag'?: boolean;

  /**  */
  'habilitationAuthorizationRequiredFlag'?: boolean;

  /**  */
  'disabledFacilitiesFlag'?: boolean;

  /**  */
  'disabledTypoFlag'?: boolean;

  /**  */
  'languageFlag'?: boolean;

  /**  */
  'listRiskPositionFlag'?: boolean;

  /**  */
  'glassesFlag'?: boolean;

  /**  */
  'drivingLicenseFlag'?: boolean;

  /**  */
  'riskPositionFlag'?: boolean;

  /**  */
  'predictableNatureRiskFlag'?: boolean;

  /**  */
  'workAtHeightFlag'?: boolean;

  /**  */
  'workOnScaffoldingFlag'?: boolean;

  /**  */
  'prohibitedWorksFlag'?: boolean;

  /**  */
  'medicalSupervisionFlag'?: boolean;

  /**  */
  'trainingProvidedBy'?: string;

  /**  */
  'disabledFacilitiesDetail'?: string;

  /**  */
  'disabledTypoDetail'?: string;

  /**  */
  'natureOtherForeseeableRisks'?: string;

  /**  */
  'securityBusinessTest'?: string;

  /**  */
  'tutorOrRefereeOfTheWorker'?: string;

  /**  */
  'typeOfVibration'?: string;

  /**  */
  'jobEnvironment'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['status'] = data['status'];
    this['natureForeseeableRiskToPostList'] = data['natureForeseeableRiskToPostList'];
    this['followUpIndividualReinforcedList'] = data['followUpIndividualReinforcedList'];
    this['workRequiringTrainingList'] = data['workRequiringTrainingList'];
    this['prohibitedWorksList'] = data['prohibitedWorksList'];
    this['drivingLicenseList'] = data['drivingLicenseList'];
    this['welcomeById'] = data['welcomeById'];
    this['otherEquipment'] = data['otherEquipment'];
    this['specialConditions'] = data['specialConditions'];
    this['instructionsToFollow'] = data['instructionsToFollow'];
    this['painfulnessFlag'] = data['painfulnessFlag'];
    this['implementedHardnessDate'] = data['implementedHardnessDate'];
    this['habilitationAuthorizationDetail'] = data['habilitationAuthorizationDetail'];
    this['collectiveProtectionEquipment'] = data['collectiveProtectionEquipment'];
    this['practice'] = data['practice'];
    this['asbestosFlag'] = data['asbestosFlag'];
    this['otherFlag'] = data['otherFlag'];
    this['slingFlag'] = data['slingFlag'];
    this['superToleranceNoiseFlag'] = data['superToleranceNoiseFlag'];
    this['helmetFlag'] = data['helmetFlag'];
    this['shoesFlag'] = data['shoesFlag'];
    this['requestDerogationProhibitedWorkFlag'] = data['requestDerogationProhibitedWorkFlag'];
    this['nilStateFlag'] = data['nilStateFlag'];
    this['companyWelcomeSheetFlag'] = data['companyWelcomeSheetFlag'];
    this['formalizationHomeFlag'] = data['formalizationHomeFlag'];
    this['enhancedSecurityTrainingFlag'] = data['enhancedSecurityTrainingFlag'];
    this['glovesFlag'] = data['glovesFlag'];
    this['habilitationAuthorizationRequiredFlag'] = data['habilitationAuthorizationRequiredFlag'];
    this['disabledFacilitiesFlag'] = data['disabledFacilitiesFlag'];
    this['disabledTypoFlag'] = data['disabledTypoFlag'];
    this['languageFlag'] = data['languageFlag'];
    this['listRiskPositionFlag'] = data['listRiskPositionFlag'];
    this['glassesFlag'] = data['glassesFlag'];
    this['drivingLicenseFlag'] = data['drivingLicenseFlag'];
    this['riskPositionFlag'] = data['riskPositionFlag'];
    this['predictableNatureRiskFlag'] = data['predictableNatureRiskFlag'];
    this['workAtHeightFlag'] = data['workAtHeightFlag'];
    this['workOnScaffoldingFlag'] = data['workOnScaffoldingFlag'];
    this['prohibitedWorksFlag'] = data['prohibitedWorksFlag'];
    this['medicalSupervisionFlag'] = data['medicalSupervisionFlag'];
    this['trainingProvidedBy'] = data['trainingProvidedBy'];
    this['disabledFacilitiesDetail'] = data['disabledFacilitiesDetail'];
    this['disabledTypoDetail'] = data['disabledTypoDetail'];
    this['natureOtherForeseeableRisks'] = data['natureOtherForeseeableRisks'];
    this['securityBusinessTest'] = data['securityBusinessTest'];
    this['tutorOrRefereeOfTheWorker'] = data['tutorOrRefereeOfTheWorker'];
    this['typeOfVibration'] = data['typeOfVibration'];
    this['jobEnvironment'] = data['jobEnvironment'];
  }
}

export class OSMRepositoriesModelBonusRatesDtoTypeDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMRepositoriesModelBonusRatesDtoCadencyDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetBonusRatesDto {
  /**  */
  'id'?: string;

  /**  */
  'type'?: OSMRepositoriesModelBonusRatesDtoTypeDto;

  /**  */
  'cadency'?: OSMRepositoriesModelBonusRatesDtoCadencyDto;

  /**  */
  'amount'?: number;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['type'] = data['type'];
    this['cadency'] = data['cadency'];
    this['amount'] = data['amount'];
    this['label'] = data['label'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoCustomerContactDto {
  /**  */
  'id'?: string;

  /**  */
  'civility'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'phone'?: string;

  /**  */
  'email'?: string;

  /**  */
  'fax'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['civility'] = data['civility'];
    this['firstName'] = data['firstName'];
    this['name'] = data['name'];
    this['phone'] = data['phone'];
    this['email'] = data['email'];
    this['fax'] = data['fax'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoMainSkillDto {
  /**  */
  'typeCode'?: string;

  /**  */
  'id': string;

  /**  */
  'priorityId'?: number;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['typeCode'] = data['typeCode'];
    this['id'] = data['id'];
    this['priorityId'] = data['priorityId'];
    this['label'] = data['label'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoSkillDto {
  /**  */
  'id': string;

  /**  */
  'priorityId'?: number;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['priorityId'] = data['priorityId'];
    this['label'] = data['label'];
  }
}

export class ContactingInfo {
  /**  */
  @Expose()
  @Type(() => Date)
  'at': Date;

  /**  */
  'by'?: OSMConsultantModelDtoFullConsultantDto;

  /**  */
  'actionId': string;

  constructor(data: undefined | any = {}) {
    this['at'] = data['at'];
    this['by'] = data['by'];
    this['actionId'] = data['actionId'];
  }
}

export class Candidate {
  /**  */
  'gender': string;

  /**  */
  'name': string;

  /**  */
  'firstName': string;

  /**  */
  'phone1': string;

  /**  */
  'phone2': string;

  /**  */
  'email': string;

  /**  */
  'address': string;

  /**  */
  'address0'?: string;

  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'inseeCode': string;

  /**  */
  'zipCode': string;

  /**  */
  'city'?: string;

  /**  */
  'latitude'?: number;

  /**  */
  'longitude'?: number;

  /**  */
  'qualificationId': string;

  /**  */
  'contractAccepted': string[];

  /**  */
  'nbHoursWorked': number;

  /**  */
  'nbMissions': number;

  /**  */
  'nbCompanies': number;

  /**  */
  'skillUri': string;

  /**  */
  'levelOfStudy': string;

  /**  */
  'agenciesUri': string;

  /**  */
  'mainAgency'?: OSMCandidateModelCandidateDtoMainAgencyDto;

  /**  */
  'statusCode': string;

  /**  */
  'flagAcceptSMSMission': boolean;

  /**  */
  'flagAcceptNotifMission'?: boolean;

  /**  */
  'flagAcceptEmailMission'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'dateAcceptationCguAppli'?: Date;

  /**  */
  'candidateType'?: string;

  /**  */
  'assesment'?: OSMCandidateModelCandidateDtoAssesmentDto;

  /**  */
  'flagAcceptSMS'?: boolean;

  /**  */
  'facebookApplication'?: boolean;

  /**  */
  'smartphoneApplication'?: boolean;

  /**  */
  'conveyed'?: boolean;

  /**  */
  'lastMissionAgencyId'?: string;

  /**  */
  'countryId'?: string;

  /**  */
  'isBorderWorker'?: boolean;

  /**  */
  'domicilePaySendingFlag'?: boolean;

  /**  */
  'dependantsNumber'?: number;

  /**  */
  'birthName'?: string;

  /**  */
  'temporaryNumber'?: string;

  /**  */
  'disabilityWorkerConsentStatus'?: string;

  /**  */
  'mobility'?: string[];

  /**  */
  'availabilityForConsultantDate'?: OSMCommonModelDateDto;

  /**  */
  'newGraduate'?: boolean;

  /**  */
  'registrationDate'?: OSMCommonModelDateDto;

  /**  */
  'creator'?: string;

  /**  */
  'candidateId': string;

  /**  */
  'qualification': QualificationInCandidateDetails;

  /**  */
  'lastContact'?: ContactingInfo;

  /**  */
  'candidateAdvancement'?: EnumCandidateCandidateAdvancement;

  /**  */
  'companyAdvancement'?: EnumCandidateCompanyAdvancement;

  constructor(data: undefined | any = {}) {
    this['gender'] = data['gender'];
    this['name'] = data['name'];
    this['firstName'] = data['firstName'];
    this['phone1'] = data['phone1'];
    this['phone2'] = data['phone2'];
    this['email'] = data['email'];
    this['address'] = data['address'];
    this['address0'] = data['address0'];
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['inseeCode'] = data['inseeCode'];
    this['zipCode'] = data['zipCode'];
    this['city'] = data['city'];
    this['latitude'] = data['latitude'];
    this['longitude'] = data['longitude'];
    this['qualificationId'] = data['qualificationId'];
    this['contractAccepted'] = data['contractAccepted'];
    this['nbHoursWorked'] = data['nbHoursWorked'];
    this['nbMissions'] = data['nbMissions'];
    this['nbCompanies'] = data['nbCompanies'];
    this['skillUri'] = data['skillUri'];
    this['levelOfStudy'] = data['levelOfStudy'];
    this['agenciesUri'] = data['agenciesUri'];
    this['mainAgency'] = data['mainAgency'];
    this['statusCode'] = data['statusCode'];
    this['flagAcceptSMSMission'] = data['flagAcceptSMSMission'];
    this['flagAcceptNotifMission'] = data['flagAcceptNotifMission'];
    this['flagAcceptEmailMission'] = data['flagAcceptEmailMission'];
    this['dateAcceptationCguAppli'] = data['dateAcceptationCguAppli'];
    this['candidateType'] = data['candidateType'];
    this['assesment'] = data['assesment'];
    this['flagAcceptSMS'] = data['flagAcceptSMS'];
    this['facebookApplication'] = data['facebookApplication'];
    this['smartphoneApplication'] = data['smartphoneApplication'];
    this['conveyed'] = data['conveyed'];
    this['lastMissionAgencyId'] = data['lastMissionAgencyId'];
    this['countryId'] = data['countryId'];
    this['isBorderWorker'] = data['isBorderWorker'];
    this['domicilePaySendingFlag'] = data['domicilePaySendingFlag'];
    this['dependantsNumber'] = data['dependantsNumber'];
    this['birthName'] = data['birthName'];
    this['temporaryNumber'] = data['temporaryNumber'];
    this['disabilityWorkerConsentStatus'] = data['disabilityWorkerConsentStatus'];
    this['mobility'] = data['mobility'];
    this['availabilityForConsultantDate'] = data['availabilityForConsultantDate'];
    this['newGraduate'] = data['newGraduate'];
    this['registrationDate'] = data['registrationDate'];
    this['creator'] = data['creator'];
    this['candidateId'] = data['candidateId'];
    this['qualification'] = data['qualification'];
    this['lastContact'] = data['lastContact'];
    this['candidateAdvancement'] = data['candidateAdvancement'];
    this['companyAdvancement'] = data['companyAdvancement'];
  }
}

export class Contact {
  /**  */
  'contactId'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'position'?: string;

  /**  */
  'statut'?: string;

  /**  */
  'mobilePhoneNumber'?: string;

  /**  */
  'phone'?: string;

  /**  */
  'email'?: string;

  /**  */
  'dontMailMe'?: boolean;

  /**  */
  'dontCallMe'?: boolean;

  /**  */
  'dontPostMe'?: boolean;

  /**  */
  'dontPlaceMe'?: boolean;

  /**  */
  'civility'?: OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto;

  /**  */
  'lastContact'?: ContactingInfo;

  constructor(data: undefined | any = {}) {
    this['contactId'] = data['contactId'];
    this['firstName'] = data['firstName'];
    this['name'] = data['name'];
    this['position'] = data['position'];
    this['statut'] = data['statut'];
    this['mobilePhoneNumber'] = data['mobilePhoneNumber'];
    this['phone'] = data['phone'];
    this['email'] = data['email'];
    this['dontMailMe'] = data['dontMailMe'];
    this['dontCallMe'] = data['dontCallMe'];
    this['dontPostMe'] = data['dontPostMe'];
    this['dontPlaceMe'] = data['dontPlaceMe'];
    this['civility'] = data['civility'];
    this['lastContact'] = data['lastContact'];
  }
}

export class StructuredMissionHours {
  /**  */
  'days': number[];

  /**  */
  'startTime': number;

  /**  */
  'endTime': number;

  constructor(data: undefined | any = {}) {
    this['days'] = data['days'];
    this['startTime'] = data['startTime'];
    this['endTime'] = data['endTime'];
  }
}

export class MissionDetail {
  /**  */
  'id'?: string;

  /**  */
  'startHour'?: number;

  /**  */
  'missionDuration'?: number;

  /**  */
  'numberPositions'?: number;

  /**  */
  'statusForRd'?: string;

  /**  */
  'salesPhase'?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  'company'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDto;

  /**  */
  'companyComment'?: string;

  /**  */
  'accordMissionUri'?: string;

  /**  */
  'agencyMissionUri'?: string;

  /**  */
  'reasonId'?: string;

  /**  */
  'reasonLabel'?: string;

  /**  */
  'replacedPerson'?: OSMMissionModelDtoReplacedPersonDto;

  /**  */
  'dPAEAgencyId'?: string;

  /**  */
  'increase'?: OSMMissionsWithoutScheduleModelDtoIncreaseDto;

  /**  */
  'workTeam'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetWorkTeam;

  /**  */
  'positionStudyId'?: string;

  /**  */
  'workLocation'?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'creationDate'?: Date;

  /**  */
  'origin'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'modificationDate'?: Date;

  /**  */
  'service'?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  'qualification'?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  'siretPosteId'?: number;

  /**  */
  'mase'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseDto;

  /**  */
  'isVisible'?: boolean;

  /**  */
  'bonusRates'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetBonusRatesDto[];

  /**  */
  'customerContact'?: OSMMissionsWithoutScheduleModelDtoCustomerContactDto;

  /**  */
  'agencyId'?: string;

  /**  */
  'receptionTime'?: number;

  /**  */
  'behavioralSkill'?: string;

  /**  */
  'technicalSkill'?: string;

  /**  */
  'otherSkill'?: string;

  /**  */
  'comment'?: string;

  /**  */
  'privateComment'?: string;

  /**  */
  'contactId'?: string;

  /**  */
  'getInTouchWith'?: string;

  /**  */
  'missionHours'?: string;

  /**  */
  'sirOriginCode'?: string;

  /**  */
  'salary'?: string;

  /**  */
  'salaryHourBase'?: number;

  /**  */
  'personalGear'?: string;

  /**  */
  'weekHours'?: number;

  /**  */
  'positionFeatures'?: string;

  /**  */
  'positionStatus'?: string;

  /**  */
  'resumeKeywords'?: string;

  /**  */
  'isDelegationMode'?: boolean;

  /**  */
  'mainSkill'?: OSMMissionsWithoutScheduleModelDtoMainSkillDto[];

  /**  */
  'languages'?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  'diplomas'?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  'habilitations'?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  'workingConditions'?: string[];

  /**  */
  'clientContactingStatus': ContactingStatus;

  /**  */
  'candidateContactingStatus': ContactingStatus;

  /**  */
  'rdiProgress'?: DTORdiProgress;

  /**  */
  'suggestedCandidates'?: OSMCandidate[];

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  'candidates'?: Candidate[];

  /**  */
  'lastClientContact'?: ContactingInfo;

  /**  */
  'contact'?: Contact;

  /**  */
  'structuredMissionHours': StructuredMissionHours[];

  /**  */
  'structuredIndividualEquipement'?: string[];

  /**  */
  'structuredSecurityBusinessTest'?: string[];

  /**  */
  'structuredCollectiveEquipment'?: string[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['startHour'] = data['startHour'];
    this['missionDuration'] = data['missionDuration'];
    this['numberPositions'] = data['numberPositions'];
    this['statusForRd'] = data['statusForRd'];
    this['salesPhase'] = data['salesPhase'];
    this['company'] = data['company'];
    this['companyComment'] = data['companyComment'];
    this['accordMissionUri'] = data['accordMissionUri'];
    this['agencyMissionUri'] = data['agencyMissionUri'];
    this['reasonId'] = data['reasonId'];
    this['reasonLabel'] = data['reasonLabel'];
    this['replacedPerson'] = data['replacedPerson'];
    this['dPAEAgencyId'] = data['dPAEAgencyId'];
    this['increase'] = data['increase'];
    this['workTeam'] = data['workTeam'];
    this['positionStudyId'] = data['positionStudyId'];
    this['workLocation'] = data['workLocation'];
    this['creationDate'] = data['creationDate'];
    this['origin'] = data['origin'];
    this['modificationDate'] = data['modificationDate'];
    this['service'] = data['service'];
    this['qualification'] = data['qualification'];
    this['siretPosteId'] = data['siretPosteId'];
    this['mase'] = data['mase'];
    this['isVisible'] = data['isVisible'];
    this['bonusRates'] = data['bonusRates'];
    this['customerContact'] = data['customerContact'];
    this['agencyId'] = data['agencyId'];
    this['receptionTime'] = data['receptionTime'];
    this['behavioralSkill'] = data['behavioralSkill'];
    this['technicalSkill'] = data['technicalSkill'];
    this['otherSkill'] = data['otherSkill'];
    this['comment'] = data['comment'];
    this['privateComment'] = data['privateComment'];
    this['contactId'] = data['contactId'];
    this['getInTouchWith'] = data['getInTouchWith'];
    this['missionHours'] = data['missionHours'];
    this['sirOriginCode'] = data['sirOriginCode'];
    this['salary'] = data['salary'];
    this['salaryHourBase'] = data['salaryHourBase'];
    this['personalGear'] = data['personalGear'];
    this['weekHours'] = data['weekHours'];
    this['positionFeatures'] = data['positionFeatures'];
    this['positionStatus'] = data['positionStatus'];
    this['resumeKeywords'] = data['resumeKeywords'];
    this['isDelegationMode'] = data['isDelegationMode'];
    this['mainSkill'] = data['mainSkill'];
    this['languages'] = data['languages'];
    this['diplomas'] = data['diplomas'];
    this['habilitations'] = data['habilitations'];
    this['workingConditions'] = data['workingConditions'];
    this['clientContactingStatus'] = data['clientContactingStatus'];
    this['candidateContactingStatus'] = data['candidateContactingStatus'];
    this['rdiProgress'] = data['rdiProgress'];
    this['suggestedCandidates'] = data['suggestedCandidates'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['candidates'] = data['candidates'];
    this['lastClientContact'] = data['lastClientContact'];
    this['contact'] = data['contact'];
    this['structuredMissionHours'] = data['structuredMissionHours'];
    this['structuredIndividualEquipement'] = data['structuredIndividualEquipement'];
    this['structuredSecurityBusinessTest'] = data['structuredSecurityBusinessTest'];
    this['structuredCollectiveEquipment'] = data['structuredCollectiveEquipment'];
  }
}

export class ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoCreateMissionConfigApiDto {
  constructor(data: undefined | any = {}) {}
}

export class ServiceApiVirtualAgencyModelsMissionModelDtoReplacedPersonDto {
  constructor(data: undefined | any = {}) {}
}

export class ServiceApiVirtualAgencyModelsCommonModelLocationModelInputLocationDto {
  constructor(data: undefined | any = {}) {}
}

export class ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoCreateBonusRatesDto {
  constructor(data: undefined | any = {}) {}
}

export class ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoUpdateMaseDto {
  constructor(data: undefined | any = {}) {}
}

export class ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoSkillDto {
  constructor(data: undefined | any = {}) {}
}

export class CreateCoreMission {
  /**  */
  'startHour'?: number;

  /**  */
  'missionDuration'?: number;

  /**  */
  'numberPositions'?: number;

  /**  */
  'companyComment'?: string;

  /**  */
  'startDate': string;

  /**  */
  'endDate': string;

  /**  */
  'qualificationId': string;

  /**  */
  'reasonId'?: number;

  /**  */
  'configAPI'?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoCreateMissionConfigApiDto;

  /**  */
  'dPAEAgencyId'?: string;

  /**  */
  'companyId'?: string;

  /**  */
  'serviceId'?: string;

  /**  */
  'agencyId'?: string;

  /**  */
  'receptionTime'?: number;

  /**  */
  'behavioralSkill'?: string;

  /**  */
  'technicalSkill'?: string;

  /**  */
  'otherSkill'?: string;

  /**  */
  'comment'?: string;

  /**  */
  'contactId'?: string;

  /**  */
  'getInTouchWith'?: string;

  /**  */
  'missionHours'?: string;

  /**  */
  'sirOriginCode'?: string;

  /**  */
  'salary'?: string;

  /**  */
  'salaryHourBase'?: number;

  /**  */
  'personalGear'?: string;

  /**  */
  'weekHours'?: number;

  /**  */
  'positionFeatures'?: string;

  /**  */
  'positionStatus'?: string;

  /**  */
  'resumeKeywords'?: string;

  /**  */
  'isDelegationMode'?: boolean;

  /**  */
  'workingConditions'?: string[];

  /**  */
  'candidates'?: object[];

  /**  */
  'replacedPerson'?: ServiceApiVirtualAgencyModelsMissionModelDtoReplacedPersonDto;

  /**  */
  'workLocation'?: ServiceApiVirtualAgencyModelsCommonModelLocationModelInputLocationDto;

  /**  */
  'bonusRates'?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoCreateBonusRatesDto[];

  /**  */
  'mase'?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoUpdateMaseDto;

  /**  */
  'mainSkill'?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  'languages'?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  'diplomas'?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  'habilitations'?: ServiceApiVirtualAgencyModelsMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  'structuredMissionHours': StructuredMissionHours[];

  constructor(data: undefined | any = {}) {
    this['startHour'] = data['startHour'];
    this['missionDuration'] = data['missionDuration'];
    this['numberPositions'] = data['numberPositions'];
    this['companyComment'] = data['companyComment'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['qualificationId'] = data['qualificationId'];
    this['reasonId'] = data['reasonId'];
    this['configAPI'] = data['configAPI'];
    this['dPAEAgencyId'] = data['dPAEAgencyId'];
    this['companyId'] = data['companyId'];
    this['serviceId'] = data['serviceId'];
    this['agencyId'] = data['agencyId'];
    this['receptionTime'] = data['receptionTime'];
    this['behavioralSkill'] = data['behavioralSkill'];
    this['technicalSkill'] = data['technicalSkill'];
    this['otherSkill'] = data['otherSkill'];
    this['comment'] = data['comment'];
    this['contactId'] = data['contactId'];
    this['getInTouchWith'] = data['getInTouchWith'];
    this['missionHours'] = data['missionHours'];
    this['sirOriginCode'] = data['sirOriginCode'];
    this['salary'] = data['salary'];
    this['salaryHourBase'] = data['salaryHourBase'];
    this['personalGear'] = data['personalGear'];
    this['weekHours'] = data['weekHours'];
    this['positionFeatures'] = data['positionFeatures'];
    this['positionStatus'] = data['positionStatus'];
    this['resumeKeywords'] = data['resumeKeywords'];
    this['isDelegationMode'] = data['isDelegationMode'];
    this['workingConditions'] = data['workingConditions'];
    this['candidates'] = data['candidates'];
    this['replacedPerson'] = data['replacedPerson'];
    this['workLocation'] = data['workLocation'];
    this['bonusRates'] = data['bonusRates'];
    this['mase'] = data['mase'];
    this['mainSkill'] = data['mainSkill'];
    this['languages'] = data['languages'];
    this['diplomas'] = data['diplomas'];
    this['habilitations'] = data['habilitations'];
    this['structuredMissionHours'] = data['structuredMissionHours'];
  }
}

export class CreateMissionFromYouplanParams {
  /**  */
  'mission': CreateCoreMission;

  /**  */
  'youplanMissionIds': string[];

  constructor(data: undefined | any = {}) {
    this['mission'] = data['mission'];
    this['youplanMissionIds'] = data['youplanMissionIds'];
  }
}

export class CreateMissionResponse {
  /**  */
  'missionId'?: string;

  constructor(data: undefined | any = {}) {
    this['missionId'] = data['missionId'];
  }
}

export class MarkMissionAsReadBody {
  /**  */
  'youplanMissionIds': string[];

  /**  */
  'brandCode': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate': Date;

  /**  */
  'dpaeAgencyId': string;

  /**  */
  'companyId': string;

  constructor(data: undefined | any = {}) {
    this['youplanMissionIds'] = data['youplanMissionIds'];
    this['brandCode'] = data['brandCode'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['dpaeAgencyId'] = data['dpaeAgencyId'];
    this['companyId'] = data['companyId'];
  }
}

export class YouplanMissionsActionSummaryResponse {
  /**  */
  'youplanActionSummary'?: YouplanMissionActionSummary[];

  constructor(data: undefined | any = {}) {
    this['youplanActionSummary'] = data['youplanActionSummary'];
  }
}

export class YouplanMissionActionSummaryResponse {
  /**  */
  'youplanActionSummary'?: YouplanMissionActionSummary;

  constructor(data: undefined | any = {}) {
    this['youplanActionSummary'] = data['youplanActionSummary'];
  }
}

export class CreateAgencyJournalParams {
  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['brandCode'] = data['brandCode'];
  }
}

export class GetExternalCommandJournalParams {
  constructor(data: undefined | any = {}) {}
}

export class ExternalDemandItem {
  /**  */
  'status': AppelMedicalEventStatus;

  /**  */
  'id': string;

  /**  */
  'osmoseId'?: string;

  /**  */
  'schedule'?: string;

  /**  */
  'service'?: string;

  /**  */
  'cause'?: string;

  /**  */
  'qualification'?: string;

  /**  */
  'motive'?: string;

  /**  */
  'rattachementLink'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'timestamp'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'dateMission'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'limitDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['status'] = data['status'];
    this['id'] = data['id'];
    this['osmoseId'] = data['osmoseId'];
    this['schedule'] = data['schedule'];
    this['service'] = data['service'];
    this['cause'] = data['cause'];
    this['qualification'] = data['qualification'];
    this['motive'] = data['motive'];
    this['rattachementLink'] = data['rattachementLink'];
    this['timestamp'] = data['timestamp'];
    this['dateMission'] = data['dateMission'];
    this['limitDate'] = data['limitDate'];
  }
}

export class ExternalDemandDto {
  /**  */
  'status': ExternalDemandStatus;

  /**  */
  'source': AppelMedicalEventSource;

  /**  */
  'agencyId': string;

  /**  */
  'commandId': string;

  /**  */
  'osmoseId': string;

  /**  */
  'clientName'?: string;

  /**  */
  'siret'?: string;

  /**  */
  'service'?: string;

  /**  */
  'domain'?: string;

  /**  */
  'qualification'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'dateMission'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'date': Date;

  /**  */
  'items': ExternalDemandItem[];

  constructor(data: undefined | any = {}) {
    this['status'] = data['status'];
    this['source'] = data['source'];
    this['agencyId'] = data['agencyId'];
    this['commandId'] = data['commandId'];
    this['osmoseId'] = data['osmoseId'];
    this['clientName'] = data['clientName'];
    this['siret'] = data['siret'];
    this['service'] = data['service'];
    this['domain'] = data['domain'];
    this['qualification'] = data['qualification'];
    this['dateMission'] = data['dateMission'];
    this['date'] = data['date'];
    this['items'] = data['items'];
  }
}

export class CommandStatusToUpdateDto {
  /**  */
  'displayStatus': DisplayStatus;

  /**  */
  'commandId': string;

  /**  */
  'sourceIds'?: string[];

  constructor(data: undefined | any = {}) {
    this['displayStatus'] = data['displayStatus'];
    this['commandId'] = data['commandId'];
    this['sourceIds'] = data['sourceIds'];
  }
}

export class UpdateDemandsParams {
  /**  */
  'agencyId': string;

  /**  */
  'commandStatusToUpdate': CommandStatusToUpdateDto[];

  constructor(data: undefined | any = {}) {
    this['agencyId'] = data['agencyId'];
    this['commandStatusToUpdate'] = data['commandStatusToUpdate'];
  }
}

export class MailParameterDto {
  /**  */
  'typeField': TypeField;

  /**  */
  'source': AppelMedicalEventSource;

  /**  */
  'id': string;

  /**  */
  'sourceLabel'?: string;

  /**  */
  'osmoseLabel'?: string;

  /**  */
  'siret'?: string;

  /**  */
  'clientName'?: string;

  /**  */
  'companyId'?: string;

  /**  */
  'companyName'?: string;

  constructor(data: undefined | any = {}) {
    this['typeField'] = data['typeField'];
    this['source'] = data['source'];
    this['id'] = data['id'];
    this['sourceLabel'] = data['sourceLabel'];
    this['osmoseLabel'] = data['osmoseLabel'];
    this['siret'] = data['siret'];
    this['clientName'] = data['clientName'];
    this['companyId'] = data['companyId'];
    this['companyName'] = data['companyName'];
  }
}

export class ReplayExternalDemandParams {
  /**  */
  'source': AppelMedicalEventSource;

  /**  */
  'agencyId': string;

  /**  */
  'sourceIds': string[];

  constructor(data: undefined | any = {}) {
    this['source'] = data['source'];
    this['agencyId'] = data['agencyId'];
    this['sourceIds'] = data['sourceIds'];
  }
}

export class MailParameterToUpdateDto {
  /**  */
  'typeField': TypeField;

  /**  */
  'source': AppelMedicalEventSource;

  /**  */
  'agencyId': string;

  /**  */
  'siret': string;

  /**  */
  'sourceLabel': string;

  /**  */
  'osmoseLabel'?: string;

  /**  */
  'updatedOsmoseLabel'?: string;

  /**  */
  'id': string;

  constructor(data: undefined | any = {}) {
    this['typeField'] = data['typeField'];
    this['source'] = data['source'];
    this['agencyId'] = data['agencyId'];
    this['siret'] = data['siret'];
    this['sourceLabel'] = data['sourceLabel'];
    this['osmoseLabel'] = data['osmoseLabel'];
    this['updatedOsmoseLabel'] = data['updatedOsmoseLabel'];
    this['id'] = data['id'];
  }
}

export class MailParametersToUpdateDto {
  /**  */
  'source': AppelMedicalEventSource;

  /**  */
  'agencyId': string;

  /**  */
  'mailParametersToUpdate': MailParameterToUpdateDto[];

  constructor(data: undefined | any = {}) {
    this['source'] = data['source'];
    this['agencyId'] = data['agencyId'];
    this['mailParametersToUpdate'] = data['mailParametersToUpdate'];
  }
}

export class SearchMissionsBody {
  /**  */
  'agencyIds'?: string[];

  /**  */
  'startDate': string;

  /**  */
  'endDate'?: string;

  /**  */
  'offset': number;

  /**  */
  'limit'?: number;

  /**  */
  'brandCode': string;

  /**  */
  'qualificationIds'?: string[];

  /**  */
  'companyId'?: string;

  /**  */
  'serviceId'?: string;

  /**  */
  'salesphases'?: string[];

  /**  */
  'sortBy'?: EnumSearchMissionsBodySortBy;

  /**  */
  'departmentIds'?: string[];

  /**  */
  'withCount'?: boolean;

  /**  */
  'candidateIdAlreadyOnMissions'?: string;

  /**  */
  'search'?: boolean;

  constructor(data: undefined | any = {}) {
    this['agencyIds'] = data['agencyIds'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['offset'] = data['offset'];
    this['limit'] = data['limit'];
    this['brandCode'] = data['brandCode'];
    this['qualificationIds'] = data['qualificationIds'];
    this['companyId'] = data['companyId'];
    this['serviceId'] = data['serviceId'];
    this['salesphases'] = data['salesphases'];
    this['sortBy'] = data['sortBy'];
    this['departmentIds'] = data['departmentIds'];
    this['withCount'] = data['withCount'];
    this['candidateIdAlreadyOnMissions'] = data['candidateIdAlreadyOnMissions'];
    this['search'] = data['search'];
  }
}

export class Mission {
  /**  */
  'missionId'?: string;

  /**  */
  'numberPositions'?: number;

  /**  */
  'company'?: OSMMissionsWithoutScheduleModelDtoOutputCompanyDto;

  /**  */
  'service'?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  'qualification'?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  'statutMissionId'?: string;

  /**  */
  'salesPhase'?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  'agencyId'?: string;

  /**  */
  'dPAEAgencyId'?: string;

  /**  */
  'workLocation'?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  'clientContactingStatus': ContactingStatus;

  /**  */
  'candidateContactingStatus': ContactingStatus;

  /**  */
  'suggestedCandidateCount'?: number;

  /**  */
  'distanceToTT'?: number;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'modificationDate'?: Date;

  /**  */
  'candidates'?: CoreMissionCandidate[];

  constructor(data: undefined | any = {}) {
    this['missionId'] = data['missionId'];
    this['numberPositions'] = data['numberPositions'];
    this['company'] = data['company'];
    this['service'] = data['service'];
    this['qualification'] = data['qualification'];
    this['statutMissionId'] = data['statutMissionId'];
    this['salesPhase'] = data['salesPhase'];
    this['agencyId'] = data['agencyId'];
    this['dPAEAgencyId'] = data['dPAEAgencyId'];
    this['workLocation'] = data['workLocation'];
    this['clientContactingStatus'] = data['clientContactingStatus'];
    this['candidateContactingStatus'] = data['candidateContactingStatus'];
    this['suggestedCandidateCount'] = data['suggestedCandidateCount'];
    this['distanceToTT'] = data['distanceToTT'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['modificationDate'] = data['modificationDate'];
    this['candidates'] = data['candidates'];
  }
}

export class MissionsWithCount {
  /**  */
  'count'?: number;

  /**  */
  'missions'?: Mission[];

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['missions'] = data['missions'];
  }
}

export class CreateActionBody {
  /**  */
  'actionType': EnumCreateActionBodyActionType;

  /**  */
  'actionTarget': EnumCreateActionBodyActionTarget;

  /**  */
  'report': string;

  /**  */
  'targetId': string;

  /**  */
  'agencyId': string;

  /**  */
  'companyId': string;

  /**  */
  'createdAt': string;

  /**  */
  'brandCode': EnumCreateActionBodyBrandCode;

  constructor(data: undefined | any = {}) {
    this['actionType'] = data['actionType'];
    this['actionTarget'] = data['actionTarget'];
    this['report'] = data['report'];
    this['targetId'] = data['targetId'];
    this['agencyId'] = data['agencyId'];
    this['companyId'] = data['companyId'];
    this['createdAt'] = data['createdAt'];
    this['brandCode'] = data['brandCode'];
  }
}

export class CreateActionResponse {
  /**  */
  'actionId': string;

  constructor(data: undefined | any = {}) {
    this['actionId'] = data['actionId'];
  }
}

export class CandidateAdvancementInfo {
  /**  */
  'candidateAdvancement'?: EnumCandidateAdvancementInfoCandidateAdvancement;

  /**  */
  'companyAdvancement'?: EnumCandidateAdvancementInfoCompanyAdvancement;

  /**  */
  'candidateId': string;

  constructor(data: undefined | any = {}) {
    this['candidateAdvancement'] = data['candidateAdvancement'];
    this['companyAdvancement'] = data['companyAdvancement'];
    this['candidateId'] = data['candidateId'];
  }
}

export class UpdateCandidateStatusBody {
  /**  */
  'rdiProgress'?: EnumUpdateCandidateStatusBodyRdiProgress;

  /**  */
  'candidates': CandidateAdvancementInfo[];

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['rdiProgress'] = data['rdiProgress'];
    this['candidates'] = data['candidates'];
    this['brandCode'] = data['brandCode'];
  }
}

export class AttachCandidateToMissionsBody {
  /**  */
  'missionIds': string[];

  /**  */
  'candidateId': string;

  /**  */
  'brandCode': string;

  /**  */
  'isRepositioningTalent'?: boolean;

  constructor(data: undefined | any = {}) {
    this['missionIds'] = data['missionIds'];
    this['candidateId'] = data['candidateId'];
    this['brandCode'] = data['brandCode'];
    this['isRepositioningTalent'] = data['isRepositioningTalent'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDtoBase {
  /**  */
  'companyId'?: string;

  /**  */
  'name'?: string;

  /**  */
  'location'?: OSMCommonModelLocationModelOutputLocationDto;

  constructor(data: undefined | any = {}) {
    this['companyId'] = data['companyId'];
    this['name'] = data['name'];
    this['location'] = data['location'];
  }
}

export class PositionStudy {
  /**  */
  'positionStudyId'?: string;

  /**  */
  'positionStudyName'?: string;

  /**  */
  'company'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDtoBase;

  /**  */
  'workLocation'?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'creationDate'?: Date;

  /**  */
  'origin'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'modificationDate'?: Date;

  /**  */
  'service'?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  'qualification'?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  'siretPosteId'?: number;

  /**  */
  'mase'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseDto;

  /**  */
  'isVisible'?: boolean;

  /**  */
  'bonusRates'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetBonusRatesDto[];

  /**  */
  'customerContact'?: OSMMissionsWithoutScheduleModelDtoCustomerContactDto;

  /**  */
  'agencyId'?: string;

  /**  */
  'receptionTime'?: number;

  /**  */
  'behavioralSkill'?: string;

  /**  */
  'technicalSkill'?: string;

  /**  */
  'otherSkill'?: string;

  /**  */
  'comment'?: string;

  /**  */
  'privateComment'?: string;

  /**  */
  'contactId'?: string;

  /**  */
  'getInTouchWith'?: string;

  /**  */
  'missionHours'?: string;

  /**  */
  'sirOriginCode'?: string;

  /**  */
  'salary'?: string;

  /**  */
  'salaryHourBase'?: number;

  /**  */
  'personalGear'?: string;

  /**  */
  'weekHours'?: number;

  /**  */
  'positionFeatures'?: string;

  /**  */
  'positionStatus'?: string;

  /**  */
  'resumeKeywords'?: string;

  /**  */
  'isDelegationMode'?: boolean;

  /**  */
  'mainSkill'?: OSMMissionsWithoutScheduleModelDtoMainSkillDto[];

  /**  */
  'languages'?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  'diplomas'?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  'habilitations'?: OSMMissionsWithoutScheduleModelDtoSkillDto[];

  /**  */
  'workingConditions'?: string[];

  /**  */
  'structuredMissionHours': StructuredMissionHours[];

  /**  */
  'structuredIndividualEquipement'?: string[];

  /**  */
  'structuredSecurityBusinessTest'?: string[];

  /**  */
  'structuredCollectiveEquipment'?: string[];

  constructor(data: undefined | any = {}) {
    this['positionStudyId'] = data['positionStudyId'];
    this['positionStudyName'] = data['positionStudyName'];
    this['company'] = data['company'];
    this['workLocation'] = data['workLocation'];
    this['creationDate'] = data['creationDate'];
    this['origin'] = data['origin'];
    this['modificationDate'] = data['modificationDate'];
    this['service'] = data['service'];
    this['qualification'] = data['qualification'];
    this['siretPosteId'] = data['siretPosteId'];
    this['mase'] = data['mase'];
    this['isVisible'] = data['isVisible'];
    this['bonusRates'] = data['bonusRates'];
    this['customerContact'] = data['customerContact'];
    this['agencyId'] = data['agencyId'];
    this['receptionTime'] = data['receptionTime'];
    this['behavioralSkill'] = data['behavioralSkill'];
    this['technicalSkill'] = data['technicalSkill'];
    this['otherSkill'] = data['otherSkill'];
    this['comment'] = data['comment'];
    this['privateComment'] = data['privateComment'];
    this['contactId'] = data['contactId'];
    this['getInTouchWith'] = data['getInTouchWith'];
    this['missionHours'] = data['missionHours'];
    this['sirOriginCode'] = data['sirOriginCode'];
    this['salary'] = data['salary'];
    this['salaryHourBase'] = data['salaryHourBase'];
    this['personalGear'] = data['personalGear'];
    this['weekHours'] = data['weekHours'];
    this['positionFeatures'] = data['positionFeatures'];
    this['positionStatus'] = data['positionStatus'];
    this['resumeKeywords'] = data['resumeKeywords'];
    this['isDelegationMode'] = data['isDelegationMode'];
    this['mainSkill'] = data['mainSkill'];
    this['languages'] = data['languages'];
    this['diplomas'] = data['diplomas'];
    this['habilitations'] = data['habilitations'];
    this['workingConditions'] = data['workingConditions'];
    this['structuredMissionHours'] = data['structuredMissionHours'];
    this['structuredIndividualEquipement'] = data['structuredIndividualEquipement'];
    this['structuredSecurityBusinessTest'] = data['structuredSecurityBusinessTest'];
    this['structuredCollectiveEquipment'] = data['structuredCollectiveEquipment'];
  }
}

export class GetPreventionSheetPdfQueryParams {
  /**  */
  'user': AuthenticatedUser;

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['user'] = data['user'];
    this['brandCode'] = data['brandCode'];
  }
}

export class Qualification {
  /**  */
  'businessId'?: string;

  /**  */
  'id'?: string;

  /**  */
  'jobCenterBusinessCode'?: string;

  /**  */
  'label'?: string;

  /**  */
  'shortLabel'?: string;

  /**  */
  'professionAndSocioProfessionalCategoryId'?: string;

  constructor(data: undefined | any = {}) {
    this['businessId'] = data['businessId'];
    this['id'] = data['id'];
    this['jobCenterBusinessCode'] = data['jobCenterBusinessCode'];
    this['label'] = data['label'];
    this['shortLabel'] = data['shortLabel'];
    this['professionAndSocioProfessionalCategoryId'] =
      data['professionAndSocioProfessionalCategoryId'];
  }
}

export class Domain {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'shortLabel'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['shortLabel'] = data['shortLabel'];
  }
}

export class Salesphase {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class Department {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class GetBusinessQualificationsParams {
  /**  */
  'societyCode'?: string;

  /**  */
  'brand'?: string;

  /**  */
  'businessIds': string[];

  constructor(data: undefined | any = {}) {
    this['societyCode'] = data['societyCode'];
    this['brand'] = data['brand'];
    this['businessIds'] = data['businessIds'];
  }
}

export class Risk {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class InjuryNatures {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class InjuryLocations {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class InjuryLocationDetails {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class ProtectionEquipments {
  /**  */
  'epis': MaseItem[];

  /**  */
  'epcs': MaseItem[];

  constructor(data: undefined | any = {}) {
    this['epis'] = data['epis'];
    this['epcs'] = data['epcs'];
  }
}

export class CGC {
  /**  */
  'id'?: string;

  /**  */
  'societyId'?: string;

  /**  */
  'brandCode'?: string;

  /**  */
  'label'?: string;

  /**  */
  'isOpen'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['societyId'] = data['societyId'];
    this['brandCode'] = data['brandCode'];
    this['label'] = data['label'];
    this['isOpen'] = data['isOpen'];
  }
}

export class City {
  /**  */
  'uId'?: string;

  /**  */
  'insee'?: string;

  /**  */
  'zipCode'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['uId'] = data['uId'];
    this['insee'] = data['insee'];
    this['zipCode'] = data['zipCode'];
    this['name'] = data['name'];
  }
}

export class OSMContratModelDtoContractsMultiCriteriaEndOfMissionReasonDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class Endorsement {
  /**  */
  'id'?: string;

  /**  */
  'status'?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  'number'?: string;

  /**  */
  'modalityType'?: OSMContratModelDtoModalityTypeDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'clientValidationDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['status'] = data['status'];
    this['number'] = data['number'];
    this['modalityType'] = data['modalityType'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['clientValidationDate'] = data['clientValidationDate'];
  }
}

export class Contract {
  /**  */
  'contractId'?: string;

  /**  */
  'company'?: OSMContratModelDtoCompanyDto;

  /**  */
  'contactId'?: string;

  /**  */
  'status'?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  'missionId'?: string;

  /**  */
  'candidate'?: OSMContratModelDtoCandidateDto;

  /**  */
  'service'?: OSMContratModelDtoServiceDto;

  /**  */
  'qualification'?: OSMContratModelDtoQualificationDto;

  /**  */
  'isDerogatoryMissionLetter'?: boolean;

  /**  */
  'endOfMissionReason'?: OSMContratModelDtoContractsMultiCriteriaEndOfMissionReasonDto;

  /**  */
  'clientContactingStatus': ContactingStatus;

  /**  */
  'candidateContactingStatus': ContactingStatus;

  /**  */
  'endorsements'?: Endorsement[];

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'clientValidationDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'missionStartDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'missionActualEndDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDateFlexibility'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDateFlexibility'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'effectiveStartDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'effectiveEndDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['contractId'] = data['contractId'];
    this['company'] = data['company'];
    this['contactId'] = data['contactId'];
    this['status'] = data['status'];
    this['missionId'] = data['missionId'];
    this['candidate'] = data['candidate'];
    this['service'] = data['service'];
    this['qualification'] = data['qualification'];
    this['isDerogatoryMissionLetter'] = data['isDerogatoryMissionLetter'];
    this['endOfMissionReason'] = data['endOfMissionReason'];
    this['clientContactingStatus'] = data['clientContactingStatus'];
    this['candidateContactingStatus'] = data['candidateContactingStatus'];
    this['endorsements'] = data['endorsements'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['clientValidationDate'] = data['clientValidationDate'];
    this['missionStartDate'] = data['missionStartDate'];
    this['missionActualEndDate'] = data['missionActualEndDate'];
    this['startDateFlexibility'] = data['startDateFlexibility'];
    this['endDateFlexibility'] = data['endDateFlexibility'];
    this['effectiveStartDate'] = data['effectiveStartDate'];
    this['effectiveEndDate'] = data['effectiveEndDate'];
  }
}

export class ContractDetail {
  /**  */
  'contractId'?: string;

  /**  */
  'company'?: OSMContratModelDtoCompanyDto;

  /**  */
  'contactId'?: string;

  /**  */
  'status'?: OSMRepositoriesModelCandidateStatusRepositoryDtoCandidateStatusDto;

  /**  */
  'missionId'?: string;

  /**  */
  'service'?: OSMContratModelDtoServiceDto;

  /**  */
  'qualification'?: OSMContratModelDtoQualificationDto;

  /**  */
  'isDerogatoryMissionLetter'?: boolean;

  /**  */
  'missionRequestReason'?: OSMContratModelDtoMissionRequestReasonDto;

  /**  */
  'clientContactingStatus': ContactingStatus;

  /**  */
  'candidateContactingStatus': ContactingStatus;

  /**  */
  'endorsements'?: Endorsement[];

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'clientValidationDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'missionStartDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'missionActualEndDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDateFlexibility'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDateFlexibility'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'effectiveStartDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'effectiveEndDate'?: Date;

  /**  */
  'candidate'?: Candidate;

  /**  */
  'contact': Contact;

  /**  */
  'lastClientContact'?: ContactingInfo;

  /**  */
  'isRenewable': boolean;

  constructor(data: undefined | any = {}) {
    this['contractId'] = data['contractId'];
    this['company'] = data['company'];
    this['contactId'] = data['contactId'];
    this['status'] = data['status'];
    this['missionId'] = data['missionId'];
    this['service'] = data['service'];
    this['qualification'] = data['qualification'];
    this['isDerogatoryMissionLetter'] = data['isDerogatoryMissionLetter'];
    this['missionRequestReason'] = data['missionRequestReason'];
    this['clientContactingStatus'] = data['clientContactingStatus'];
    this['candidateContactingStatus'] = data['candidateContactingStatus'];
    this['endorsements'] = data['endorsements'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['clientValidationDate'] = data['clientValidationDate'];
    this['missionStartDate'] = data['missionStartDate'];
    this['missionActualEndDate'] = data['missionActualEndDate'];
    this['startDateFlexibility'] = data['startDateFlexibility'];
    this['endDateFlexibility'] = data['endDateFlexibility'];
    this['effectiveStartDate'] = data['effectiveStartDate'];
    this['effectiveEndDate'] = data['effectiveEndDate'];
    this['candidate'] = data['candidate'];
    this['contact'] = data['contact'];
    this['lastClientContact'] = data['lastClientContact'];
    this['isRenewable'] = data['isRenewable'];
  }
}

export class RenewContractBody {
  /**  */
  'renewalDate': string;

  /**  */
  'contactId': string;

  /**  */
  'youplanMissionId'?: string;

  constructor(data: undefined | any = {}) {
    this['renewalDate'] = data['renewalDate'];
    this['contactId'] = data['contactId'];
    this['youplanMissionId'] = data['youplanMissionId'];
  }
}

export class GetPotentialQualificationsClientsParams {
  /**  */
  'agencyId': string;

  /**  */
  'offset': number;

  /**  */
  'limit': number;

  /**  */
  'brandCode': string;

  /**  */
  'mainQualifications': string[];

  /**  */
  'departmentIds'?: string[];

  constructor(data: undefined | any = {}) {
    this['agencyId'] = data['agencyId'];
    this['offset'] = data['offset'];
    this['limit'] = data['limit'];
    this['brandCode'] = data['brandCode'];
    this['mainQualifications'] = data['mainQualifications'];
    this['departmentIds'] = data['departmentIds'];
  }
}

export class CompanyWithContact {
  /**  */
  'societyStatus'?: string;

  /**  */
  'solvency'?: SolvencyEnum;

  /**  */
  'companyAddress'?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  'lastVisit'?: OSMCompanyModelSearchDtoVisitDto;

  /**  */
  'hasFrameworkAgreement'?: boolean;

  /**  */
  'delegationPotential'?: number;

  /**  */
  'externalCode'?: string;

  /**  */
  'collectiveAgreement'?: OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto;

  /**  */
  'timeTrackingTool'?: OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto;

  /**  */
  'companyId'?: string;

  /**  */
  'companyName'?: string;

  /**  */
  'siret'?: string;

  /**  */
  'contact'?: Contact;

  constructor(data: undefined | any = {}) {
    this['societyStatus'] = data['societyStatus'];
    this['solvency'] = data['solvency'];
    this['companyAddress'] = data['companyAddress'];
    this['lastVisit'] = data['lastVisit'];
    this['hasFrameworkAgreement'] = data['hasFrameworkAgreement'];
    this['delegationPotential'] = data['delegationPotential'];
    this['externalCode'] = data['externalCode'];
    this['collectiveAgreement'] = data['collectiveAgreement'];
    this['timeTrackingTool'] = data['timeTrackingTool'];
    this['companyId'] = data['companyId'];
    this['companyName'] = data['companyName'];
    this['siret'] = data['siret'];
    this['contact'] = data['contact'];
  }
}

export class PotentialQualificationClient {
  /**  */
  'client': CompanyWithContact;

  /**  */
  'potentialQualification': Qualification;

  constructor(data: undefined | any = {}) {
    this['client'] = data['client'];
    this['potentialQualification'] = data['potentialQualification'];
  }
}

export class GetMissionsBySkillMatchingParams {
  /**  */
  'agencyId': string;

  /**  */
  'startDate': string;

  /**  */
  'endDate': string;

  /**  */
  'offset': number;

  /**  */
  'limit': number;

  /**  */
  'brandCode': string;

  /**  */
  'mainQualifications': string[];

  /**  */
  'departmentIds'?: string[];

  /**  */
  'salesphases'?: string[];

  constructor(data: undefined | any = {}) {
    this['agencyId'] = data['agencyId'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['offset'] = data['offset'];
    this['limit'] = data['limit'];
    this['brandCode'] = data['brandCode'];
    this['mainQualifications'] = data['mainQualifications'];
    this['departmentIds'] = data['departmentIds'];
    this['salesphases'] = data['salesphases'];
  }
}

export class SkillDetail {
  /**  */
  'skillLabel': string;

  /**  */
  'known': boolean;

  constructor(data: undefined | any = {}) {
    this['skillLabel'] = data['skillLabel'];
    this['known'] = data['known'];
  }
}

export class SkillMatchingMission {
  /**  */
  'missionId'?: string;

  /**  */
  'numberPositions'?: number;

  /**  */
  'company'?: OSMMissionsWithoutScheduleModelDtoOutputCompanyDto;

  /**  */
  'service'?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  /**  */
  'qualification'?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  'statutMissionId'?: string;

  /**  */
  'salesPhase'?: OSMRepositoriesModelSalesPhaseRepositoryDtoSalesPhaseDto;

  /**  */
  'agencyId'?: string;

  /**  */
  'dPAEAgencyId'?: string;

  /**  */
  'workLocation'?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  'clientContactingStatus': ContactingStatus;

  /**  */
  'candidateContactingStatus': ContactingStatus;

  /**  */
  'suggestedCandidateCount'?: number;

  /**  */
  'distanceToTT'?: number;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'modificationDate'?: Date;

  /**  */
  'candidates'?: CoreMissionCandidate[];

  /**  */
  'matchScore'?: string;

  /**  */
  'skillsDetails'?: SkillDetail[];

  /**  */
  'contact'?: Contact;

  constructor(data: undefined | any = {}) {
    this['missionId'] = data['missionId'];
    this['numberPositions'] = data['numberPositions'];
    this['company'] = data['company'];
    this['service'] = data['service'];
    this['qualification'] = data['qualification'];
    this['statutMissionId'] = data['statutMissionId'];
    this['salesPhase'] = data['salesPhase'];
    this['agencyId'] = data['agencyId'];
    this['dPAEAgencyId'] = data['dPAEAgencyId'];
    this['workLocation'] = data['workLocation'];
    this['clientContactingStatus'] = data['clientContactingStatus'];
    this['candidateContactingStatus'] = data['candidateContactingStatus'];
    this['suggestedCandidateCount'] = data['suggestedCandidateCount'];
    this['distanceToTT'] = data['distanceToTT'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['modificationDate'] = data['modificationDate'];
    this['candidates'] = data['candidates'];
    this['matchScore'] = data['matchScore'];
    this['skillsDetails'] = data['skillsDetails'];
    this['contact'] = data['contact'];
  }
}

export class UnitData {
  /**  */
  'code': string;

  /**  */
  'typology': string;

  /**  */
  'fte': number;

  /**  */
  'analyticalCoef': number;

  constructor(data: undefined | any = {}) {
    this['code'] = data['code'];
    this['typology'] = data['typology'];
    this['fte'] = data['fte'];
    this['analyticalCoef'] = data['analyticalCoef'];
  }
}

export class ConsultantInformation {
  /**  */
  'rhpiVersion': string;

  /**  */
  'unitVersion': string;

  /**  */
  'dataStatus': EnumConsultantInformationDataStatus;

  /**  */
  'siid': string;

  /**  */
  'numBadge': string;

  /**  */
  'name': string;

  /**  */
  'isAdmin': boolean;

  /**  */
  'consultantFunction': string;

  /**  */
  'categoryFunction': string;

  /**  */
  'coefOfPresence': number;

  /**  */
  'unitDatas': UnitData[];

  constructor(data: undefined | any = {}) {
    this['rhpiVersion'] = data['rhpiVersion'];
    this['unitVersion'] = data['unitVersion'];
    this['dataStatus'] = data['dataStatus'];
    this['siid'] = data['siid'];
    this['numBadge'] = data['numBadge'];
    this['name'] = data['name'];
    this['isAdmin'] = data['isAdmin'];
    this['consultantFunction'] = data['consultantFunction'];
    this['categoryFunction'] = data['categoryFunction'];
    this['coefOfPresence'] = data['coefOfPresence'];
    this['unitDatas'] = data['unitDatas'];
  }
}

export class UploadFileResult {
  /**  */
  'fileType': EnumUploadFileResultFileType;

  /**  */
  'fileName': string;

  /**  */
  'siid': string;

  /**  */
  @Expose()
  @Type(() => Date)
  'uploadedAt': Date;

  constructor(data: undefined | any = {}) {
    this['fileType'] = data['fileType'];
    this['fileName'] = data['fileName'];
    this['siid'] = data['siid'];
    this['uploadedAt'] = data['uploadedAt'];
  }
}

export class UploadedFilesStatus {
  /**  */
  'rhpiFile'?: UploadFileResult;

  /**  */
  'unitFile'?: UploadFileResult;

  /**  */
  'coefficientFile'?: UploadFileResult;

  /**  */
  'trancheFile'?: UploadFileResult;

  /**  */
  'rhpiFunctionFile'?: UploadFileResult;

  constructor(data: undefined | any = {}) {
    this['rhpiFile'] = data['rhpiFile'];
    this['unitFile'] = data['unitFile'];
    this['coefficientFile'] = data['coefficientFile'];
    this['trancheFile'] = data['trancheFile'];
    this['rhpiFunctionFile'] = data['rhpiFunctionFile'];
  }
}

export class PostVSSimulateBody {
  /**  */
  'unitType': string;

  /**  */
  'categoryFunction': string;

  /**  */
  'fteTranche': number;

  /**  */
  'coefficientOfPresence': number;

  /**  */
  'analyticalCoef': number;

  /**  */
  'margeBruteBaseVariable': number;

  /**  */
  'margeBrutePlacement': number;

  constructor(data: undefined | any = {}) {
    this['unitType'] = data['unitType'];
    this['categoryFunction'] = data['categoryFunction'];
    this['fteTranche'] = data['fteTranche'];
    this['coefficientOfPresence'] = data['coefficientOfPresence'];
    this['analyticalCoef'] = data['analyticalCoef'];
    this['margeBruteBaseVariable'] = data['margeBruteBaseVariable'];
    this['margeBrutePlacement'] = data['margeBrutePlacement'];
  }
}

export class ExportDemandsParams {
  /**  */
  'displayStatus'?: DisplayStatus;

  /**  */
  'agencyId': string;

  /**  */
  'commandIds'?: string[];

  /**  */
  'user': AuthenticatedUser;

  constructor(data: undefined | any = {}) {
    this['displayStatus'] = data['displayStatus'];
    this['agencyId'] = data['agencyId'];
    this['commandIds'] = data['commandIds'];
    this['user'] = data['user'];
  }
}

export enum DateFilterStrategy {
  'NONE' = 'NONE',
  'STARTAFTERSTARTDATE' = 'STARTAFTERSTARTDATE',
  'ISBETWEEN' = 'ISBETWEEN',
}

export enum WhyActionDelayEnum {
  'DONE' = 'DONE',
  '1DAY' = '1DAY',
  '3DAYS' = '3DAYS',
  '1WEEK' = '1WEEK',
  '2WEEKS' = '2WEEKS',
  '1MONTH' = '1MONTH',
  '3MONTHS' = '3MONTHS',
}

export enum WhyActionActorsEnum {
  'CLIENT' = 'CLIENT',
  'AGENCY' = 'AGENCY',
}

export enum WhyActionCategoriesEnum {
  'ORGANIZATION' = 'ORGANIZATION',
  'TECHNICAL' = 'TECHNICAL',
  'MANAGEMENT' = 'MANAGEMENT',
}

export enum WhyActionsEnum {
  'LIST_RISKY_POSITIONS' = 'LIST_RISKY_POSITIONS',
  'SET_POSITION_BRIEF' = 'SET_POSITION_BRIEF',
  'REVIEW_OPERATING_MODE' = 'REVIEW_OPERATING_MODE',
  'ADD_COLLECTIVE_EPI' = 'ADD_COLLECTIVE_EPI',
  'ADD_PERSONAL_EPI' = 'ADD_PERSONAL_EPI',
  'FORMALIZE_ON_BOARDING' = 'FORMALIZE_ON_BOARDING',
  'SET_TUTOR' = 'SET_TUTOR',
  'REVIEW_HABILITATIONS' = 'REVIEW_HABILITATIONS',
  'TRAIN_TO_SECURITY' = 'TRAIN_TO_SECURITY',
  'REMIND_SECURITY_INSTRUCTIONS' = 'REMIND_SECURITY_INSTRUCTIONS',
  'GET_PREVENTION_PROCESS' = 'GET_PREVENTION_PROCESS',
  'REVIEW_POSITION_BRIEF' = 'REVIEW_POSITION_BRIEF',
  'GET_RISKY_POSITIONS_LIST' = 'GET_RISKY_POSITIONS_LIST',
  'FOLLOW_MEDICAL_MONITORING' = 'FOLLOW_MEDICAL_MONITORING',
  'CHECK_OTHERS_SENSITIZATION' = 'CHECK_OTHERS_SENSITIZATION',
  'SET_QUITUS_EPI' = 'SET_QUITUS_EPI',
  'DIPLOMAS_AND_HABILITATIONS' = 'DIPLOMAS_AND_HABILITATIONS',
  'UPDATE_CONTRACTS_DATA' = 'UPDATE_CONTRACTS_DATA',
  'REMIND_OTHERS_ALERT_RIGHT' = 'REMIND_OTHERS_ALERT_RIGHT',
  'RAISE_CANDIDATE_AWARENESS' = 'RAISE_CANDIDATE_AWARENESS',
  'SEND_SENSITIZATION_MESSAGES_TO_OTHERS' = 'SEND_SENSITIZATION_MESSAGES_TO_OTHERS',
  'SUBSTITUTE_CANDIDATE' = 'SUBSTITUTE_CANDIDATE',
}

export enum WhyActionsStatusEnum {
  'IN_PROGRESS' = 'IN_PROGRESS',
  'DONE' = 'DONE',
}

export enum ArchiveATReason {
  'DUPLICATE' = 'DUPLICATE',
  'AT_ALREADY_SENT' = 'AT_ALREADY_SENT',
  'CLIENT_SIGN_REFUSAL' = 'CLIENT_SIGN_REFUSAL',
  'CLIENT_REFUSAL' = 'CLIENT_REFUSAL',
}

export enum Laterality {
  'LEFT_HANDED' = 'LEFT_HANDED',
  'RIGHT_HANDED' = 'RIGHT_HANDED',
  'UNKNOWN' = 'UNKNOWN',
}

export enum FirstAidPersonType {
  'FIRST_AID_RESCUE_WORKER' = 'FIRST_AID_RESCUE_WORKER',
  'NURSE' = 'NURSE',
  'EMERGENCY_SERVICES' = 'EMERGENCY_SERVICES',
  'FIREFIGHTER' = 'FIREFIGHTER',
  'OTHER' = 'OTHER',
}

export enum AccidentLocation {
  'WORKSHOP' = 'WORKSHOP',
  'SERVICE' = 'SERVICE',
  'CONSTRUCTION_SITE' = 'CONSTRUCTION_SITE',
  'MISSION' = 'MISSION',
  'JOURNEY' = 'JOURNEY',
}

export enum CausedByThirdParty {
  'YES' = 'YES',
  'NO' = 'NO',
  'UNKNOWN' = 'UNKNOWN',
}

export enum YesNoUnknown {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}

export enum LateralityInjury {
  'Gauche' = 'Gauche',
  'Droit' = 'Droit',
  'Gauche et droit' = 'Gauche et droit',
}

export enum WhoGaveEpi {
  'EU' = 'EU',
  'RI' = 'RI',
  'UNKNOWN' = 'UNKNOWN',
}

export enum SigningStatusEnum {
  'DOES_NOT_WANT_TO_SIGN' = 'DOES_NOT_WANT_TO_SIGN',
  'CANNOT_SIGN' = 'CANNOT_SIGN',
  'SIGNS' = 'SIGNS',
}
export enum EnumWorkAccidentAnalysisStatus {
  'IN_PROGRESS' = 'IN_PROGRESS',
  'DONE' = 'DONE',
  'ARCHIVED' = 'ARCHIVED',
}
export type CombinedRandstadConsultantSignatureTypes = Signature;
export type CombinedClientSignatureTypes = Signature;
export type CombinedVictimSignatureTypes = Signature;
export type CombinedAdditionalSignatureTypes = Signature;
export enum AnalysisStatus {
  'IN_PROGRESS' = 'IN_PROGRESS',
  'DONE' = 'DONE',
  'ARCHIVED' = 'ARCHIVED',
}
export enum EnumFinalizeATAnalysisBrandCode {
  '001VB' = '001VB',
  '307EE' = '307EE',
  '001RIS' = '001RIS',
}
export enum EnumDateWithStatusStatus {
  'mission' = 'mission',
  'weekEnd' = 'weekEnd',
  'noContract' = 'noContract',
  'absent' = 'absent',
}
export enum DBCDIIStatus {
  'available' = 'available',
  'suggested' = 'suggested',
  'processing' = 'processing',
}
export enum EnumActivityPlanningDayStatus {
  'Intermission' = 'Intermission',
  'ValidatedAbsence' = 'ValidatedAbsence',
  'UnvalidatedAbsence' = 'UnvalidatedAbsence',
  'CompletedTimeTrackings' = 'CompletedTimeTrackings',
  'UncompletedTimeTrackings' = 'UncompletedTimeTrackings',
  'ValidatedAbsenceCompletedTimeTrackings' = 'ValidatedAbsenceCompletedTimeTrackings',
  'UnvalidatedAbsenceCompletedTimeTrackings' = 'UnvalidatedAbsenceCompletedTimeTrackings',
  'ValidatedAbsenceUncompletedTimeTrackings' = 'ValidatedAbsenceUncompletedTimeTrackings',
  'UnvalidatedAbsenceUncompletedTimeTrackings' = 'UnvalidatedAbsenceUncompletedTimeTrackings',
  'OutOfPermanentContract' = 'OutOfPermanentContract',
  'Weekend' = 'Weekend',
  'BankHoliday' = 'BankHoliday',
  'Day' = 'Day',
  'Suspension' = 'Suspension',
  'Capped' = 'Capped',
  'HrAbsence' = 'HrAbsence',
}
export enum EnumDBCDIIStatus {
  'available' = 'available',
  'suggested' = 'suggested',
  'processing' = 'processing',
}
export enum TemporaryWorkersSortingKey {
  'agency' = 'agency',
  'availability' = 'availability',
}

export enum TemporaryWorkersSortingMode {
  'asc' = 'asc',
  'desc' = 'desc',
}

export enum SolvencyEnum {
  'Green' = 'Green',
  'Orange' = 'Orange',
  'Red' = 'Red',
  'Black' = 'Black',
  'None' = 'None',
}

export enum ContactingStatus {
  'NONE' = 'NONE',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'COMPLETED' = 'COMPLETED',
}
export enum EnumYouplanMissionActionSummaryAction {
  'MISSION_CREATION' = 'MISSION_CREATION',
  'CONTRACT_RENEWAL' = 'CONTRACT_RENEWAL',
  'MARKED_AS_READ' = 'MARKED_AS_READ',
}
export enum EnumYouplanMissionActionSummaryLastConflictCause {
  'CANCELLED' = 'CANCELLED',
  'MODIFIED' = 'MODIFIED',
  'REJECTED' = 'REJECTED',
}
export enum EnumCoreMissionCandidateCandidateAdvancement {
  'accepté' = 'accepté',
  'accepté par Pixid' = 'accepté par Pixid',
  'refusé' = 'refusé',
  'refusé par Pixid' = 'refusé par Pixid',
  'message' = 'message',
}
export enum EnumCoreMissionCandidateCompanyAdvancement {
  'accepté' = 'accepté',
  'refus' = 'refus',
  'rendez-vous' = 'rendez-vous',
  'CV envoyé' = 'CV envoyé',
  'proposition' = 'proposition',
}
export enum EnumYouplanMissionActionAction {
  'MISSION_CREATION' = 'MISSION_CREATION',
  'CONTRACT_RENEWAL' = 'CONTRACT_RENEWAL',
  'MARKED_AS_READ' = 'MARKED_AS_READ',
}
export enum EnumYouplanMissionActionLastConflictCause {
  'CANCELLED' = 'CANCELLED',
  'MODIFIED' = 'MODIFIED',
  'REJECTED' = 'REJECTED',
}
export enum DTORdiProgress {
  'CandidateSearch' = 'CandidateSearch',
  'MissionDetail' = 'MissionDetail',
  'MissionPrice' = 'MissionPrice',
  'RefineCandidateSearch' = 'RefineCandidateSearch',
  'ToSubmit' = 'ToSubmit',
}
export enum EnumCandidateCandidateAdvancement {
  'accepté' = 'accepté',
  'accepté par Pixid' = 'accepté par Pixid',
  'refusé' = 'refusé',
  'refusé par Pixid' = 'refusé par Pixid',
  'message' = 'message',
}
export enum EnumCandidateCompanyAdvancement {
  'accepté' = 'accepté',
  'refus' = 'refus',
  'rendez-vous' = 'rendez-vous',
  'CV envoyé' = 'CV envoyé',
  'proposition' = 'proposition',
}
export enum ExternalDemandStatus {
  'COMPLETE' = 'COMPLETE',
  'MIXED' = 'MIXED',
  'ERROR' = 'ERROR',
}

export enum AppelMedicalEventSource {
  'HUBLO' = 'HUBLO',
  'ARTEMIHS' = 'ARTEMIHS',
}

export enum AppelMedicalEventStatus {
  'Commande mise à jour' = 'Commande mise à jour',
  'Commande créée' = 'Commande créée',
  'Commande Annulée' = 'Commande Annulée',
  'Erreur annulation' = 'Erreur annulation',
  'Erreur' = 'Erreur',
  'Ignorée' = 'Ignorée',
}

export enum DisplayStatus {
  'displayable' = 'displayable',
  'archived' = 'archived',
  'not displayable' = 'not displayable',
}

export enum TypeField {
  'QUALIFICATION' = 'QUALIFICATION',
  'SERVICE' = 'SERVICE',
  'DOMAINE' = 'DOMAINE',
}
export enum EnumSearchMissionsBodySortBy {
  'date de début' = 'date de début',
  'date de fin' = 'date de fin',
  'nombre de postes' = 'nombre de postes',
}
export enum EnumCreateActionBodyActionType {
  'SMS' = 'SMS',
  'CALL' = 'CALL',
  'EMAIL' = 'EMAIL',
}
export enum EnumCreateActionBodyActionTarget {
  'CONTACT' = 'CONTACT',
  'CANDIDATE' = 'CANDIDATE',
}
export enum EnumCreateActionBodyBrandCode {
  '001VB' = '001VB',
  '307EE' = '307EE',
  '001RIS' = '001RIS',
}
export enum EnumCandidateAdvancementInfoCandidateAdvancement {
  'accepté' = 'accepté',
  'accepté par Pixid' = 'accepté par Pixid',
  'refusé' = 'refusé',
  'refusé par Pixid' = 'refusé par Pixid',
  'message' = 'message',
}
export enum EnumCandidateAdvancementInfoCompanyAdvancement {
  'accepté' = 'accepté',
  'refus' = 'refus',
  'rendez-vous' = 'rendez-vous',
  'CV envoyé' = 'CV envoyé',
  'proposition' = 'proposition',
}
export enum EnumUpdateCandidateStatusBodyRdiProgress {
  'CandidateSearch' = 'CandidateSearch',
  'MissionDetail' = 'MissionDetail',
  'MissionPrice' = 'MissionPrice',
  'RefineCandidateSearch' = 'RefineCandidateSearch',
  'ToSubmit' = 'ToSubmit',
}
export enum EnumConsultantInformationDataStatus {
  'CONSULTANT_FOUND' = 'CONSULTANT_FOUND',
  'CONSULTANT_NOT_FOUND' = 'CONSULTANT_NOT_FOUND',
  'CONSULTANT_FOR_TEST' = 'CONSULTANT_FOR_TEST',
}
export enum EnumUploadFileResultFileType {
  'RHPI_FILE' = 'RHPI_FILE',
  'UNIT_FILE' = 'UNIT_FILE',
  'COEFFICIENT_FILE' = 'COEFFICIENT_FILE',
  'TRANCHE_FILE' = 'TRANCHE_FILE',
  'RHPI_FUNCTION_FILE' = 'RHPI_FUNCTION_FILE',
}
