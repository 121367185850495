import { useState } from 'react';
import { addWeeks, getWeek, subWeeks } from 'date-fns';
import styles from './CandidateCalendar.module.scss';

import CalendarHeader from './CalendarHeader';
import CalendarWeeks from './CalendarWeeks';
import { Props } from './CandidateCalendar.types';
import moment from 'moment';
import CalendarDayCube from './CalendarDayCube';
import { EnumActivityPlanningDayStatus } from 'src/Services/API';

const CandidateCalendar = ({ candidate, startDate, setStartDate }: Props) => {
  const [currentWeek, setCurrentWeek] = useState(getWeek(startDate));
  const changeWeekHandle = (btnType: string) => {
    if (btnType === 'prev') {
      setStartDate(subWeeks(startDate, 1));
      setCurrentWeek(getWeek(subWeeks(startDate, 1)));
    }
    if (btnType === 'next') {
      setStartDate(addWeeks(startDate, 1));
      setCurrentWeek(getWeek(addWeeks(startDate, 1)));
    }
  };
  const scheduleStartIndex = candidate?.activityPlanning?.findIndex(
    day => moment(day.date).diff(moment(startDate), 'days') === 0
  );
  const schedule = candidate?.activityPlanning?.slice(
    scheduleStartIndex ?? 0,
    (scheduleStartIndex ?? 0) + 14
  );
  const firstWeek = schedule?.slice(0, 7);
  const secondWeek = schedule?.slice(7, 14);
  return (
    <div className={styles.container}>
      <div className={styles.calendar}>
        <CalendarHeader
          currentMonth={startDate}
          currentWeek={currentWeek}
          onWeekChange={changeWeekHandle}
        />
        <CalendarWeeks currentMonth={startDate} />
      </div>
      {candidate === undefined && (
        <p className={styles.errorMessage}>
          Le planning du TT n'a pas pu être récupéré sur cette période, vérifiez qu'il est bien en
          contrat CDI sur cette période
        </p>
      )}
      {candidate !== undefined && (
        <div className={styles.candidateLine}>
          <div className={styles.firstWeek}>
            {firstWeek?.map(day => {
              return (
                <CalendarDayCube
                  status={day.status as EnumActivityPlanningDayStatus}
                  key={`${day.date}`}
                />
              );
            })}
          </div>
          <div className={styles.verticalSeparator} />
          <div className={styles.firstWeek}>
            {secondWeek?.map(day => {
              return (
                <CalendarDayCube
                  status={day.status as EnumActivityPlanningDayStatus}
                  key={`${day.date}`}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CandidateCalendar;
