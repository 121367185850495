import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { SearchState, SEARCH_LIMIT } from './Types';
import { EnumSearchMissionsBodySortBy, Salesphase } from 'src/Services/API';

const initialState: SearchState = {
  startDate: moment().startOf('week').toISOString(),
  endDate: moment().endOf('week').toISOString(),
  singleDate: moment().startOf('week').toISOString(),
  // WARNING: if the salesphases ids change in the future, this default values will do nothing
  salesphases: [{ id: 'AS', label: 'à servir' }],
  sortBy: EnumSearchMissionsBodySortBy['date de début'],
  offset: 0,
};

export const { reducer: searchReducer, actions: searchActions } = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSingleDate: (state, action: PayloadAction<string>) => {
      state.singleDate = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setSortMode: (state, action: PayloadAction<SearchState['sortBy']>) => {
      state.sortBy = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
    setSalesphases: (state, action: PayloadAction<Salesphase[]>) => {
      state.salesphases = action.payload;
    },
    incrementOffset: state => {
      state.offset = state.offset + SEARCH_LIMIT;
    },
    resetOffset: state => {
      state.offset = 0;
    },
  },
});
