import React from 'react';
import moment from 'moment';
import fr from 'date-fns/locale/fr';

import { Props } from './SingleDatePicker.types';
import { DatePickerDeprecated as LibDatePicker } from '@randstad-lean-mobile-factory/react-components-core';

const SingleDatePicker = (props: Props) => {
  moment.locale(fr.code?.toString());
  return (
    <LibDatePicker
      startDate={props.startDate?.toDate()}
      onChange={(date: Date | null) => {
        props.onDateChange({
          startDate: date ? moment(date) : null,
        });
      }}
      monthsShown={props.monthsShown ?? 1}
      showYearDropdown={true}
      selected={props.startDate?.toDate()}
    />
  );
};

export default SingleDatePicker;
