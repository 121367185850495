import { Popup } from '@randstad-lean-mobile-factory/react-components-core';
import { useLocation, useNavigate } from 'react-router-dom';
import { eye } from 'src/Assets_DEPRECATED';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { Colors } from 'src/Utils/constants';
import styles from './LastCompanyAction.module.scss';
import { Props } from './LastCompanyAction.types';

const LastCompanyAction = ({ contactingInfo }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {contactingInfo && (
          <Popup
            key="lastCompanyAction"
            nested
            contentStyle={{
              textAlign: 'center',
              backgroundColor: Colors.darkBlue,
              borderWidth: 0,
              borderRadius: 2,
            }}
            arrowStyle={{ backgroundColor: Colors.darkBlue }}
            keepTooltipInside
            trigger={
              <span>
                <span> (par </span>
                <span className={styles.actorName}>
                  {contactingInfo.by?.firstName} {contactingInfo.by?.name}
                </span>
                )
              </span>
            }
            on="hover"
          >
            <a className={styles.actorEmail} href={`mailto:${contactingInfo.by?.email}`}>
              {contactingInfo.by?.email}
            </a>
          </Popup>
        )}
      </p>
      <div className={styles.value}>
        {contactingInfo && (
          <button
            className={styles.lastCRButton}
            onClick={() => {
              navigate(MODAL_ROUTES.ACTION_PREVIEW_MODAL.route.buildPath({}), {
                state: {
                  background: location,
                  modalParameters: { actionId: contactingInfo.actionId ?? '' },
                },
              });
            }}
          >
            <img src={eye} alt="eye" />
            <span className={styles.lastCRButtonText}>dernier CR</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default LastCompanyAction;
