import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TopBar } from 'src/Components/TopBar';
import { useFetchRepositioningMissions } from 'src/Hooks/Missions/useFetchRepositioningMissions';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { capitalizeNames } from 'src/Utils/capitalizeNames';
import RepositioningMissionsTalent from './RepositioningMissionsTalent/RepositioningMissionsTalent.component';
import styles from './RepositioningTalent.module.scss';
import { Props } from './RepositioningTalent.types';

const RepositioningTalent = ({ talentId, talentData, statut }: Props) => {
  const qualifications = talentData.qualifications;
  const [selectedQualifications, setSelectedQualifications] = useState<string[]>(
    qualifications?.map(qualification => qualification.id ?? '') ?? []
  );

  const currentAgency = useSelector(getCurrentAgency);

  const [filteredDistance, setFilteredDistance] = useState<number>(110);
  const { data: missionsWithCount } = useFetchRepositioningMissions({
    qualificationIds:
      selectedQualifications.length > 0
        ? selectedQualifications?.map(qualification => qualification ?? '')
        : undefined,
    agencies: [currentAgency ?? ''],
    candidateId: talentId,
  });

  const distanceMissions: number[] = (missionsWithCount?.pages.map(page => page.missions!) ?? [])
    .flat(1)
    .filter(mission => moment(mission.endDate).isAfter(moment()) || mission.endDate === undefined)
    .map(mission => mission.distanceToTT ?? 0);

  useEffect(() => {
    if (qualifications !== undefined && selectedQualifications.length === 0) {
      setSelectedQualifications(qualifications?.map(qualification => qualification.id ?? ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualifications]);

  return (
    <div className={styles.container}>
      <TopBar
        title={`repositionner ${capitalizeNames(talentData.firstName ?? '')} ${capitalizeNames(
          talentData.name ?? ''
        )}`}
        goBackTo={
          talentId &&
          ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.buildPath({
            talentId,
          })
        }
        disablePerimeterSelection
      />

      <div className={styles.missionContainer}>
        <RepositioningMissionsTalent
          talentId={talentId}
          selectedQualifications={selectedQualifications}
          selectedAgencies={[currentAgency ?? '']}
          filteredDistance={filteredDistance}
          setFilteredDistance={setFilteredDistance}
          distanceMissions={distanceMissions}
          qualifications={qualifications}
          setSelectedQualifications={setSelectedQualifications}
        />
      </div>
    </div>
  );
};

export default RepositioningTalent;
