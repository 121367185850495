import { useLocation, useNavigate } from 'react-router-dom';
import { DemandDetailsState } from 'src/Services/Routing';

import { Button, ModalDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { CircleCheck, Warning } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import { useCallback } from 'react';
import { EyeIllustration } from 'src/Assets_DEPRECATED';
import { useUpdateExternalDemands } from 'src/Hooks/ExternalDemands/useUpdateExternalDemands';
import { useFetchMissionDetails } from 'src/Hooks/Missions/useFetchMissionDetails';
import { completeEnums } from 'src/Routes/ExternalDemands/DemandsList/ExternalDemandsListMenu/ExternalDemandsListMenu.types';
import { ROUTES } from 'src/Routes/Routes.types';
import { DisplayStatus, ExternalDemandStatus } from 'src/Services/API';
import styles from './ExternalDemandsDetailsModal.module.scss';
import { formatError } from '../../Routes/ExternalDemands/DemandsList/transformers';

const ExternalDemandsDetailsModal = () => {
  const modalParameters = (useLocation().state as DemandDetailsState | undefined)?.modalParameters;
  moment.locale('fr');
  const externalDemand = modalParameters?.externalDemand;
  const navigate = useNavigate();
  const onClose: () => void = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const updateExternalDemandsMutation = useUpdateExternalDemands();

  const { data } = useFetchMissionDetails({ missionId: externalDemand?.commandId });

  const isComplete =
    externalDemand?.items.length === 1
      ? completeEnums.includes(externalDemand?.items[0].status)
      : externalDemand
      ? completeEnums.includes(externalDemand?.status)
      : false;

  const getDateMission = () => {
    const dates: Date[] =
      externalDemand?.items
        ?.filter(item => item.dateMission !== undefined)
        .map(item => moment(item.dateMission).toDate()) ?? [];
    dates.sort((a, b) => a.getTime() - b.getTime());

    const firstDate = dates[0];
    const lastDate = dates[dates.length - 1];
    const days =
      firstDate.getDate() === lastDate.getDate()
        ? `le ${moment(firstDate).format('DD/MM')}`
        : `${moment(firstDate).format('DD/MM')} au ${moment(lastDate).format('DD/MM')}`;

    return externalDemand?.items.length === 1 ? (
      <>
        <div>{moment(externalDemand?.items[0].dateMission).format('DD MMMM YYYY')}</div>
        <div>{externalDemand?.items[0].schedule}</div>
      </>
    ) : (
      `${days} - ${externalDemand?.items.length} roulements`
    );
  };

  const getLimitDate = () => {
    const dates: Date[] =
      externalDemand?.items
        ?.filter(item => item.limitDate !== undefined)
        .map(item => moment(item.limitDate).toDate()) ?? [];
    dates.sort((a, b) => a.getTime() - b.getTime());
    const lastDate = dates[dates.length - 1];

    return (
      <div>
        <p key={`limit-date`}>
          {`${moment(lastDate).format('DD MMMM YYYY')} à ${moment(lastDate).utc().format('HH:mm')}`}
        </p>
      </div>
    );
  };

  const row = ({
    header1,
    header2,
    text1,
    text2,
  }: {
    header1?: string;
    text1?: React.ReactNode;
    header2?: string;
    text2?: React.ReactNode;
  }) => (
    <div className={styles.row}>
      <div className={styles.cell}>
        <div className={styles.header}>{header1}</div>
        <div className={styles.text}>{text1 ?? 'information inconnue'}</div>
      </div>
      <div className={styles.cell}>
        <div className={styles.header}>{header2}</div>
        <div className={styles.text}>
          {(header2 !== undefined && text2) ?? 'information inconnue'}
        </div>
      </div>
    </div>
  );

  return (
    <ModalDeprecated
      nested
      key={`demand-details-ModalDeprecated-${externalDemand?.commandId}`}
      open
      onClose={onClose}
      icon={<EyeIllustration />}
      iconClassName={styles.icon}
      title={`détails de la demande ${externalDemand?.osmoseId}`}
      footerActionsRight={[
        <Button.Secondary.Small
          text={'voir les correspondances'}
          onClick={() =>
            navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildPath({}), {
              state: ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildState({
                siret: externalDemand?.siret,
              }),
            })
          }
        />,
        <Button.Primary.Small
          text="archiver la demande"
          onClick={() => {
            updateExternalDemandsMutation.mutate({
              commandStatusToUpdate: [
                {
                  commandId: externalDemand?.commandId ?? '',
                  displayStatus: DisplayStatus.archived,
                },
              ],
            });
          }}
        />,
      ]}
      footerActionsLeft={[<Button.Tertiary.Small text="fermer" onClick={onClose} />]}
    >
      <div className={styles.main}>
        {row({
          header1: 'statut',
          text1: (
            <div className={styles.badge}>
              <div
                className={classnames(
                  isComplete
                    ? styles.badgeComplete
                    : externalDemand?.status === ExternalDemandStatus.MIXED
                    ? styles.badgeMixed
                    : styles.badgeError
                )}
              >
                {isComplete ? <CircleCheck /> : <Warning />}
              </div>
              <div>{externalDemand ? formatError(externalDemand) : ''}</div>
            </div>
          ),
          header2: '',
          text2: '',
        })}
        {row({
          header1: 'établissement',
          text1: externalDemand?.clientName,
          header2: 'SIRET',
          text2: externalDemand?.siret,
        })}
        {row({
          header1: 'domaine',
          text1: externalDemand?.domain,
          header2: 'qualifications',
          text2: externalDemand?.qualification,
        })}
        {row({
          header1: 'service',
          text1: externalDemand?.service,
          header2: externalDemand?.items.length === 1 ? 'date et heure' : 'dates et roulements',
          text2: getDateMission(),
        })}
        {row({
          header1: 'admin',
          text1: data?.contact
            ? `${data?.contact?.name} ${data?.contact?.firstName}`
            : 'information inconnue',
          header2: 'numéro commande',
          text2: externalDemand?.commandId,
        })}
        {row({
          header1: 'email',
          text1: data?.contact?.email ?? 'information inconnue',
          header2: 'date limite de traitement',
          text2: getLimitDate(),
        })}
      </div>
    </ModalDeprecated>
  );
};

export default ExternalDemandsDetailsModal;
