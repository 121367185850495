import { Button, ModalDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { BinocularFullCut } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCompanies } from 'src/Redux/Perimeter/Selectors';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { ROUTES } from 'src/Routes/Routes.types';
import { RepositioningValidationState } from 'src/Services/Routing';
import styles from './RepositioningValidationModal.module.scss';

const RepositioningValidationModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const consultantCompanies = useSelector(getCompanies);
  const modalParameters = (useLocation().state as RepositioningValidationState).modalParameters;
  const onClose = () => navigate(-1);
  const consultantAgencies = consultantCompanies?.flatMap(company => company.agencies);
  return (
    <ModalDeprecated
      open
      onClose={onClose}
      icon={<BinocularFullCut />}
      footerActionsRight={[
        <>
          {modalParameters?.selectedMissionNumber === 1 &&
          consultantAgencies?.includes(modalParameters.missionAgencyId) ? (
            <Button.Secondary
              onClick={() => {
                const missionBrandCode = consultantCompanies?.find(company =>
                  company.agencies?.includes(modalParameters.missionAgencyId ?? '')
                );
                modalParameters?.missionId &&
                  dispatch(perimeterActions.setCurrentBrand(missionBrandCode)) &&
                  dispatch(perimeterActions.setCurrentAgency(modalParameters?.missionAgencyId)) &&
                  navigate(
                    ROUTES.ACTIVITY.MISSIONS.MISSION_DETAIL.buildPath({
                      missionId: modalParameters.missionId,
                    })
                  );
              }}
            >
              voir la commande
            </Button.Secondary>
          ) : undefined}
        </>,

        <Button.Primary onClick={onClose}>retour à la liste</Button.Primary>,
      ]}
      title="validation du rattachement"
    >
      <p className={styles.text}>
        Le candidat a bien été rattaché
        {modalParameters?.selectedMissionNumber !== undefined &&
        modalParameters.selectedMissionNumber > 1
          ? ' aux missions sélectionnées'
          : ' à la mission sélectionnée'}
        .
      </p>
    </ModalDeprecated>
  );
};
export default RepositioningValidationModal;
