import {
  Button,
  DropDown,
  FetchButton,
  ModalDeprecated,
  PopupActions,
  TextArea,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BDPDeleteWarning } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useRef, useState } from 'react';
import styles from './ArchiveATModal.module.scss';

import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useCreateWorkAccidentAnalysis';
import { useUpdateArchiveStatusWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useUpdateArchiveStatusWorkAccidentAnalysis';
import { ArchiveATReason } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { ArchiveATState } from 'src/Services/Routing';
import { AnalysisStatus } from 'src/Routes/Accidentology/WorkAccidentAnalysis/KanbanWorkAccidentAnalysis/utils';
import { getArchiveReasonTranslation } from './utils';

const ArchiveATModal = () => {
  const ref = useRef<PopupActions>(null);
  const navigate = useNavigate();
  const modalParameters = (useLocation().state as ArchiveATState).modalParameters;
  const [archiveReason, setArchiveReason] = useState<ArchiveATReason | undefined>();
  const [comment, setComment] = useState('');
  const createWorkAccidentAnalysis = useCreateWorkAccidentAnalysis();
  const createWorkAccidentAnalysisFetchStatus = toFetchStatus(createWorkAccidentAnalysis);
  const archiveWorkAccidentAnalysis = useUpdateArchiveStatusWorkAccidentAnalysis();
  const archiveWorkAccidentAnalysisFetchStatus = toFetchStatus(archiveWorkAccidentAnalysis);

  return (
    <ModalDeprecated
      ref={ref}
      open
      title="justification"
      icon={<BDPDeleteWarning />}
      onClose={() => {
        setComment('');
        setArchiveReason(undefined);
        navigate(-1);
      }}
      footerActionsRight={[
        <Button.Secondary onClick={() => ref.current?.close()}>annuler</Button.Secondary>,
        <FetchButton
          disabled={!(comment !== '' && archiveReason !== undefined)}
          fetchStatus={
            modalParameters?.status === AnalysisStatus.TODO
              ? createWorkAccidentAnalysisFetchStatus
              : archiveWorkAccidentAnalysisFetchStatus
          }
          onClick={() => {
            modalParameters?.status === AnalysisStatus.TODO
              ? createWorkAccidentAnalysis.mutate({
                  accidentId: modalParameters.analysis?.workAccidentId ?? '',
                  candidateId: modalParameters.analysis?.candidate?.id ?? '',
                  firstName: modalParameters.analysis?.candidate?.name ?? '',
                  lastName: modalParameters.analysis?.candidate?.lastName ?? '',
                  analyseId: modalParameters.analysis?.id ?? '',
                  startDate: modalParameters.analysis?.startDate,
                  endDate: modalParameters.analysis?.endDate,
                  stoppageDuration: modalParameters.analysis?.stoppageDuration ?? 0,
                  archiveComment: comment,
                  archivedReason: archiveReason ?? ArchiveATReason.AT_ALREADY_SENT,
                })
              : archiveWorkAccidentAnalysis.mutate({
                  analysisId: modalParameters?.analysis?.id ?? '',
                  archiveComment: comment,
                  archiveReason: archiveReason ?? ArchiveATReason.AT_ALREADY_SENT,
                });
          }}
          title="valider"
          onSuccess={() => ref.current?.close()}
        />,
      ]}
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setComment('');
            setArchiveReason(undefined);
          }}
        >
          réinitialiser
        </Button.Tertiary>,
      ]}
    >
      <WithLightTitle title="motif">
        <DropDown
          items={Object.values(ArchiveATReason)}
          keyValueExtractor={(item: ArchiveATReason) => {
            return { key: item, value: getArchiveReasonTranslation(item) };
          }}
          onSelectItem={(item?: ArchiveATReason) => {
            setArchiveReason(item);
          }}
          selectedItem={archiveReason}
          placeholder="choisir un motif"
        />
      </WithLightTitle>
      <WithLightTitle title="message" className={styles.commentContainer}>
        <TextArea
          className={styles.comment}
          value={comment}
          onChange={e => setComment(e.currentTarget.value)}
        />
      </WithLightTitle>
    </ModalDeprecated>
  );
};

export default ArchiveATModal;
