import { ROUTES } from 'src/Routes/Routes.types';

export const firstSectionTitle = {
  [ROUTES.ACTIVITY.path]: "suivi d'activité",
  [ROUTES.TALENTS.path]: 'planning de mission',
  [ROUTES.ACCIDENTOLOGY.path]: 'accidentologie',
  [ROUTES.EXTERNAL_DEMANDS.path]: 'demandes externes',
};

export const secondSectionTitle = {
  [ROUTES.ACTIVITY.MISSIONS.path]: 'commandes',
  [ROUTES.ACTIVITY.CONTRACTS.path]: 'contrats',
  [ROUTES.ACTIVITY.YOUPLAN.path]: 'youplan',
  [ROUTES.TALENTS.CANDIDATES.path]: 'candidats',
  [ROUTES.TALENTS.CDII.path]: 'cdii',
  [ROUTES.TALENTS.TEMPORARY_WORKERS.path]: 'intérimaires',
  [ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.path]: 'analyse AT',
  [ROUTES.ACCIDENTOLOGY.ACTION_PLAN.path]: "plan d'actions",
  [ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.path]: 'liste demandes',
  [ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.path]: 'corresp.',
};
