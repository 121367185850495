import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { QueryKeys } from '../types';
import { ExternalDemandsService } from 'src/Services/API';

export const useFetchMailParameters = () => {
  const agencyId = useSelector(getCurrentAgency) ?? '';
  const brandCode = useSelector(getCurrentBrandCode);
  return useQuery(
    [QueryKeys.fetchMailParameters, agencyId],
    async () => {
      const mailParameters = await ExternalDemandsService.externalDemandsControllerGetAllMailParameters(
        {
          agencyId,
          brandCode,
        }
      );
      return mailParameters;
    },
    { refetchOnWindowFocus: false }
  );
};
