import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';
import { Props } from './SelectRequestReason.types';

const SelectRequestReason = ({
  requestReasons,
  selectedRequestReason,
  requestReasonsFetchStatus,
  canBeReset,
  getCompanyRequestReasons,
  onRequestReasonSelect,
}: Props) => (
  <ComboBox
    id="SelectRequestReason"
    disableClearable={!canBeReset}
    value={selectedRequestReason}
    onSearch={getCompanyRequestReasons}
    searchResults={requestReasons}
    keyValueExtractor={({ id, label }) => ({
      key: id ?? 'unknown',
      value: label ?? '',
    })}
    onChange={searchResult => onRequestReasonSelect(searchResult ?? undefined)}
    fetchStatus={requestReasonsFetchStatus}
    minLengthToSearch={0}
  />
);

export default SelectRequestReason;
