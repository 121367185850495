import { useMatch } from 'react-router-dom';
import CreateWorkAccidentAnalysisModal from 'src/Components/TopBar/WorkAccidentAnalysisTopBarComponent/CreateWorkAccidentAnalysisModal';
import { ROUTES } from 'src/Routes/Routes.types';
import ArchivedAccidentAnalysisButton from './ArchivedAccidentAnalysisButton';
import Styles from './WorkAccidentAnalysisTopBarComponent.module.scss';

const WorkAccidentAnalysisTopBarComponent = () => {
  if (!useMatch(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.path)) return null;

  return (
    <div className={Styles.ButtonContainer}>
      <CreateWorkAccidentAnalysisModal />
      <ArchivedAccidentAnalysisButton />
    </div>
  );
};

export default WorkAccidentAnalysisTopBarComponent;
