import { useQuery } from '@tanstack/react-query';
import { WorkAccidentAnalysisService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchWorkAccidentByIds = ({
  analyseIds,
}: {
  analyseIds: (string | undefined)[] | undefined;
}) => {
  return useQuery(
    [QueryKeys.fetchWorkAccidentById, analyseIds],
    async () => {
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkAccidentById({
        ids: analyseIds,
      });
    },
    {
      enabled: analyseIds !== undefined,
    }
  );
};
