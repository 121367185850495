import React from 'react';

import { Props } from './CalendarDay.types';
import styles from './CalendarDay.module.scss';
import { format, addDays, isSameDay } from 'date-fns';
import fr from 'date-fns/locale/fr';
import classnames from 'classnames';

const CalendarDay = ({ day, iteration, startDate }: Props) => {
  const dateFormat = 'dd';
  const dateLabelFormat = 'EEE';
  const formattedDate = format(day, dateFormat);
  const isToday = isSameDay(day, new Date());
  return (
    <div
      className={classnames(styles.dateContainer, { [styles.today]: isToday })}
      key={addDays(day, 7).getUTCDate().toString()}
    >
      <div
        className={classnames(styles.daysLabel, { [styles.todayLabel]: isToday })}
        key={iteration}
      >
        {format(addDays(startDate, iteration), dateLabelFormat, { locale: fr })}
      </div>
      <span className={classnames(styles.daysNumber, { [styles.todayNumber]: isToday })}>
        {formattedDate}
      </span>
    </div>
  );
};

export default CalendarDay;
