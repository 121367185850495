import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  Action,
  ActionsService,
  OSMConsultantModelDtoConsultantAgencyByCompany,
} from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchActionDetail = (
  id?: string,
  extraOptions?: UseQueryOptions<
    Action,
    unknown,
    Action,
    (string | undefined | OSMConsultantModelDtoConsultantAgencyByCompany)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchActionDetail, id, currentBrand],
    async () => {
      if (!currentBrand) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return await ActionsService.actionsControllerGetAction({
        id: id ?? '',
        brandCode: currentBrand.brandCodeApiHeader ?? '',
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: id !== undefined,
      staleTime: 3600000,
      ...extraOptions,
    }
  );
};
