import { Props } from './NewMissionConfirmation.types';

const NewMissionConfirmation = ({ positionStudiesCount }: Props) => (
  <p>{`Vous confirmez partir sur de nouvelles données et non sur l'${
    positionStudiesCount! > 1 ? `une des ${positionStudiesCount} ` : ''
  }  EDP déjà existante${
    positionStudiesCount! > 1 ? 's' : ''
  } sur ce compte et sur cette qualification`}</p>
);

export default NewMissionConfirmation;
