import { CompanySearchResult, Mission } from 'src/Services/API';

export interface Props {
  onClose: ({ success }: { success?: boolean }) => void;
  open?: boolean;
  isValidCompanyQualificationCouple: boolean;
  isValidCreationWay: boolean;
  isValidPreviousMissionSelected: boolean;
  isValidPreviousPositionStudySelected: boolean;
  isValidProcessedMission: boolean;
  selectedCreationWay?: MISSION_CREATION_WAY;
  selectedPreviousMission?: Mission;
  selectedCompany?: CompanySearchResult;
  brandCode?: string;
  positionStudiesCount: number;
  isFromYouplan: boolean;
  searchPreviousMissions: () => void;
  resetSelectedPreviousMission: () => void;
  resetSelectedCreationWay: () => void;
  resetProcessedMission: () => void;
  setSelectedPreviousMissionDetails: () => void;
  searchCompanyInfo: () => void;
  createMissionWithoutSchedule: () => void;
  createMIssionWithoutScheduleFromYouplan: () => void;
  searchPreviousPositionStudies: () => void;
  resetSelectedPreviousPositionStudy: () => void;
  setSelectedPreviousPositionStudyForCreation: () => void;
  setIsFromYouplan: () => void;
  setIsFromMyRegion: () => void;
  setIsTalent: () => void;
  redirectOnMission: () => void;
}

export enum MISSION_CREATION_STEP {
  COMPANY_QUALIFICATION_SELECTION,
  CREATION_WAY_SELECTION,
  NEW_DATA_SELECTED_VALIDATION,
  NEW_DATA_SELECTION,
  EDP,
  OLD_DATA,
  VALIDATION,
  END,
  FROM_YOUPLAN_ENTRY,
}

export enum MISSION_CREATION_WAY {
  FROM_NEW_DATA = 'FROM_NEW_DATA',
  FROM_EDP = 'FROM_EDP',
  FROM_OLD_MISSION = 'FROM_OLD_MISSION',
}
