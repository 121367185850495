import {
  CardCell,
  CardRow,
  StyledTable,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import styles from './TalentMissions.module.scss';
import { Props } from './TalentMissions.types';
import { getMissionStatus } from './utils';

const TalentMissions = ({ missions }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>commandes pour ce talent</div>
      {missions !== undefined && missions?.length > 0 ? (
        <StyledTable size="medium">
          <TableHeader>
            <TableRow>
              <TableHead>infos commande</TableHead>
              <TableHead>statut commande</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {missions.map(mission => {
              const missionId = mission.missionId;
              const companyName = mission.companies?.name;
              const qualification = mission.qualification?.shortLabel;
              const startDate = mission.starDate;
              const endDate = mission.endDate;

              const formattedStartDate = startDate
                ? `débute le ${moment(startDate).format('DD/MM/YYYY')}`
                : 'date de début inconnue';
              const formattedEndDate = endDate
                ? `fin le ${moment(endDate).format('DD/MM/YYYY')}`
                : 'date de fin inconnue';

              return (
                <CardRow key={mission.missionId} color="greenish">
                  <CardCell>
                    <div>
                      <div className={styles.missionCodeAndCompanyName}>
                        {missionId} - {companyName}
                      </div>
                      <div className={styles.qualificationAndDates}>
                        {qualification}, {formattedStartDate}, {formattedEndDate}
                      </div>
                    </div>
                  </CardCell>
                  <CardCell>
                    <div className={styles.statuses}>
                      <div>talent : {getMissionStatus(mission.candidate?.acceptCandidate)}</div>
                      <div>compte : {getMissionStatus(mission.candidate?.acceptCustomer)}</div>
                    </div>
                  </CardCell>
                </CardRow>
              );
            })}
          </TableBody>
        </StyledTable>
      ) : (
        <div className={styles.noMissions}>Pas de commandes en cours pour ce talent.</div>
      )}
    </div>
  );
};

export default TalentMissions;
