import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  AnalysisStatus,
  Conclusion,
  Context,
  Description,
  EnumWorkAccidentAnalysisStatus,
  WhyAnalysis,
  WorkAccidentAnalysis,
  WorkAccidentAnalysisService,
} from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

interface WorkAccidentUpdateParams {
  analyseId: string;
  status?: AnalysisStatus;
  context?: Context;
  description?: Description;
  whyAnalysis?: WhyAnalysis;
  conclusion?: Conclusion;
}

export const useUpdateWorkAccidentAnalysis = (
  extraOptions?: UseMutationOptions<
    WorkAccidentAnalysis,
    unknown,
    WorkAccidentUpdateParams,
    unknown
  >
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgencyId = useSelector(getCurrentAgency);

  return useMutation(
    [MutationKeys.updateWorkAccidentAnalysis, currentBrand, currentAgencyId],
    async (params: WorkAccidentUpdateParams) => {
      if (!currentBrand?.brandCodeApiHeader || !currentAgencyId) {
        throw new Error('No current brand or has an invalid current brand');
      }
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerUpdateWorkAccidentById(
        {
          id: params.analyseId,
          body: {
            ...params,
          },
        }
      );
    },
    {
      ...extraOptions,
      onSuccess: data => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkAccidentById] });
        if (data.status === EnumWorkAccidentAnalysisStatus.DONE) {
          navigate(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.buildPath({}));
        }
      },
    }
  );
};
