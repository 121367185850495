import { useEffect, useState } from 'react';
import useAuth from '../Authentication/useAuth';
import { useFetchMissionDetailsFromEDP } from '../MissionCreation/useFetchMissionDetailsFromEDP';

export const useWatchForEdpURLParam = () => {
  const [currentEdpId, setCurrentEdpId] = useState<string>();
  const auth = useAuth();
  const { mutate } = useFetchMissionDetailsFromEDP();
  useEffect(() => {
    if (auth.isAuthenticated) {
      const params = new URLSearchParams(window.location.search);
      const edpId = params.get('edpId');
      const brandCode = params.get('brandCode');
      if (edpId && brandCode && edpId !== currentEdpId) {
        setCurrentEdpId(edpId);
        mutate({ edpId, edpBrandCode: brandCode });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, auth.isAuthenticated]);
};
