import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getAnalyticsUserInfo, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { MarkMissionAsReadBody, YouplanMissionsService } from 'src/Services/API/index';
import { QueryKeys } from '../types';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';

export const useMarkYouplanMissionsAsRead = (
  opts?: UseMutationOptions<unknown, unknown, Omit<MarkMissionAsReadBody, 'brandCode'>>
) => {
  const queryClient = useQueryClient();
  const brandCode = useSelector(getCurrentBrandCode) ?? '';
  const userInfo = useSelector(getAnalyticsUserInfo);

  return useMutation(
    (params: Omit<MarkMissionAsReadBody, 'brandCode'>) => {
      return YouplanMissionsService.youplanMissionsControllerMarkAsRead({
        body: { ...params, brandCode },
      });
    },
    {
      onSuccess: () => {
        toast.success('le besoin a été correctement marqué comme traité');
        ReactGA.event(ANALYTICS_EVENT.YOUPLAN_MARK_AS_DONE, {
          ...userInfo,
        });
      },
      onError: () => {
        toast.error('impossible de marquer le besoin comme traité');
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchYouplanMissions] });
      },
      ...opts,
    }
  );
};
