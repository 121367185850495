import { Props } from './MemoCard.types';
import styles from './MemoCard.module.scss';
import {
  Badge,
  Button,
  ModalDeprecated,
  PopupActions,
  PopupMenu,
  ThreeDotsButton,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useRef, useState } from 'react';
import classnames from 'classnames';

interface MemoCardProps extends Props {
  onDelete: (memoId: string) => void;
  onEdit: () => void;
}

const MemoCard = ({
  isLastMemo,
  id,
  candidateId,
  title,
  subtitle,
  description,
  isBehaviorMemo,
  onDelete,
  onEdit,
}: MemoCardProps) => {
  const ref = useRef<PopupActions>(null);
  const [open, setOpen] = useState(false);
  const [openMemoModal, setOpenMemoModal] = useState<boolean>(false);
  const [openDeletionModal, setOpenDeletionModal] = useState<boolean>(false);

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div className={styles.title}>{title}</div>
        <p className={styles.subtitle}>{subtitle}</p>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.badgeContainer}>
        {isBehaviorMemo && <Badge value="disciplinaire" className={styles.badgeBehavior} />}
      </div>
      <div className={styles.iconContainer}>
        <PopupMenu
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          width={208}
          ref={ref}
          position={'left top'}
          trigger={
            <ThreeDotsButton className={classnames(styles.button, { [styles.openButton]: open })} />
          }
        >
          <PopupMenu.Item text="voir le mémo" onClick={() => setOpenMemoModal(true)} />
          {isLastMemo && (
            <PopupMenu.Item text="supprimer le mémo" onClick={() => setOpenDeletionModal(true)} />
          )}
          <PopupMenu.Item text="modifier le mémo" onClick={onEdit} />
        </PopupMenu>

        {openMemoModal && (
          <ModalDeprecated
            onClose={() => setOpenMemoModal(false)}
            ref={ref}
            title={`détail du mémo du ${title}`}
            open={openMemoModal}
            footerActionsRight={[
              <Button.Primary
                key="memoModalCancelButton"
                className={styles.closeButton}
                onClick={() => ref.current?.close()}
              >
                fermer
              </Button.Primary>,
            ]}
          >
            <div className={styles.subtitleDetail}>
              {isBehaviorMemo && (
                <>
                  <Badge value="disciplinaire" className={styles.badgeBehavior} />
                  {' | '}
                </>
              )}
              {`de ${subtitle}`}
            </div>
            <div className={styles.comment}>{description}</div>
          </ModalDeprecated>
        )}
        {openDeletionModal && (
          <ModalDeprecated
            onClose={() => setOpenDeletionModal(false)}
            ref={ref}
            title={`Voulez-vous vraiment supprimer ce mémo du ${title} ?`}
            open={openDeletionModal}
            footerActionsLeft={[
              <Button.Primary
                key="memoModalDeletionButton"
                className={styles.deletionButton}
                onClick={() => {
                  onDelete(id);
                  setOpenDeletionModal(false);
                }}
              >
                supprimer
              </Button.Primary>,
            ]}
            footerActionsRight={[
              <Button.Primary
                key="deletionModalCancelButton"
                className={styles.closeButton}
                onClick={() => ref.current?.close()}
              >
                annuler
              </Button.Primary>,
            ]}
          >
            <div className={styles.subtitleDetail}>
              {isBehaviorMemo && (
                <>
                  <Badge value="disciplinaire" className={styles.badgeBehavior} />
                  {' | '}
                </>
              )}
              {`de ${subtitle}`}
            </div>
            <div className={styles.comment}>{description}</div>
          </ModalDeprecated>
        )}
      </div>
    </div>
  );
};

export default MemoCard;
