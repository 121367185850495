import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import {
  getPositionStudiesCountSelector,
  getSelectedCompany,
  getSelectedCreationWay,
  getSelectedQualification,
} from 'src/Redux/MissionCreation/Selectors';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { getPositionStudiesCount } from 'src/Redux/MissionCreation/Thunks';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { RootState } from 'src/Redux/RootReducer';
import { CompanySearchResult, Qualification } from 'src/Services/API';
import { MISSION_CREATION_WAY } from '../MissionCreationModal.types';
import FirstContent from './FirstContent.component';
import { OwnProps } from './FirstContent.types';

const MemoFirstContent = React.memo(FirstContent);

const EnhancedFirstContent = ({ shouldChooseCreationWay }: OwnProps) => {
  const user = useAuthenticatedUser();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const selectedAgency = useSelector(getCurrentAgency);
  const selectedBrand = useSelector(getCurrentBrandCode);
  const selectedCompany = useSelector(getSelectedCompany);
  const selectedQualification = useSelector(getSelectedQualification);
  const selectedCreationWay = useSelector(getSelectedCreationWay);
  const positionStudyCount = useSelector(getPositionStudiesCountSelector);
  const setSelectedCompany = useCallback(
    (company?: CompanySearchResult) => {
      dispatch(missionCreationActions.setSelectedCompany(company));
      selectedQualification && dispatch(getPositionStudiesCount());
    },
    [dispatch, selectedQualification]
  );
  const setSelectedQualification = useCallback(
    (qualification?: Qualification) => {
      dispatch(missionCreationActions.setSelectedQualification(qualification));
      selectedCompany && dispatch(getPositionStudiesCount());
    },
    [dispatch, selectedCompany]
  );
  const setSelectedCreationWay = useCallback(
    (creationWay?: MISSION_CREATION_WAY) =>
      dispatch(missionCreationActions.setSelectedCreationWay(creationWay)),
    [dispatch]
  );
  return (
    <MemoFirstContent
      currentAgency={selectedAgency}
      currentBrandcode={selectedBrand}
      selectedCompany={selectedCompany}
      selectedQualification={selectedQualification}
      selectedCreationWay={selectedCreationWay}
      userFirstName={user.givenName}
      userLastName={user.familyName}
      setSelectedQualification={setSelectedQualification}
      setSelectedCompany={setSelectedCompany}
      setSelectedCreationWay={setSelectedCreationWay}
      shouldChooseCreationWay={shouldChooseCreationWay}
      positionStudyCount={positionStudyCount}
    />
  );
};

export default EnhancedFirstContent;
