import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

import { CandidateHabilitation, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateHabilitations = (
  id?: string,
  extraOptions?: UseQueryOptions<
    CandidateHabilitation[],
    unknown,
    CandidateHabilitation[],
    (string | undefined)[]
  >
) => {
  const currentBrandCode = useSelector(getCurrentBrandCode);
  return useQuery(
    [QueryKeys.fetchCandidateHabilitations, id],
    async () => {
      if (!currentBrandCode || !id) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return await CandidatesService.candidatesControllerGetCandidateHabilitations({
        id: id,
      });
    },
    { staleTime: Infinity, ...extraOptions }
  );
};
