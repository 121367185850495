import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { getCurrentQualification } from 'src/Redux/Qualifications/Selectors';
import { ThunkAPI } from 'src/Redux/RootReducer';
import { getEndDate, getSearchOffset, getSortMode, getStartDate } from 'src/Redux/Search/Selectors';
import { SEARCH_LIMIT } from 'src/Redux/Search/Types';
import { getCurrentService } from 'src/Redux/Services/Selectors';
import { MissionsService } from 'src/Services/API';
import { getSelectedSalesphases } from '../Search/Selectors';
import { MissionsState } from './Types';

export const searchMissions = createAsyncThunk<MissionsState['missions'], undefined, ThunkAPI>(
  'missions/search',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const currentQualification = getCurrentQualification(state);
    const currentCompany = getCurrentCompany(state);
    const currentService = getCurrentService(state);
    const salesphases = getSelectedSalesphases(state).map(salesphase => salesphase.id ?? '');
    const brandCode = getCurrentBrandCode(state) || '';
    const agencyId = getCurrentAgency(state) || '';
    const startDate = getStartDate(state).toISOString();
    const endDate = getEndDate(state).toISOString();
    const sortBy = getSortMode(state);
    const searchOffset = getSearchOffset(state);
    const missionsWithCount = await MissionsService.missionsControllerGetMissions({
      body: {
        startDate,
        endDate,
        brandCode,
        agencyIds: [agencyId],
        qualificationIds: currentQualification?.id ? [currentQualification?.id] : undefined,
        companyId: currentCompany?.companyId,
        serviceId: currentService?.serviceId,
        salesphases,
        offset: searchOffset,
        limit: SEARCH_LIMIT,
        sortBy,
        search: true,
      },
    });

    return missionsWithCount.missions ?? [];
  }
);
