import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';
import { ServiceState } from './Types';

const getServiceState = (state: RootState) => state.services;

export const getServices = createSelector(
  [getServiceState],
  (state: ServiceState) => state.services
);

export const getCurrentService = createSelector(
  [getServiceState],
  (state: ServiceState) => state.currentService
);

export const getFetchStatus = createSelector(
  [getServiceState],
  (state: ServiceState) => state.fetchStatus
);
