import {
  Button,
  DropDown,
  FetchButton,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { InterversionHeader } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetchMissionDetails } from 'src/Hooks/Missions/useFetchMissionDetails';
import { useUpdateCandidateStatus } from 'src/Hooks/Missions/useUpdateCandidateStatus';
import { getCurrentMissionId } from 'src/Redux/Missions/Selectors';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  EnumCandidateAdvancementInfoCandidateAdvancement,
  EnumCandidateAdvancementInfoCompanyAdvancement,
  EnumCandidateCandidateAdvancement,
  EnumCandidateCompanyAdvancement,
  EnumUpdateCandidateStatusBodyRdiProgress,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Utils/fetch-status';
import styles from './StatusModalContent.module.scss';
import { Props } from './StatusModalContent.types';

const StatusModalContent = ({ onClose, candidateId, candidateStatus, companyStatus }: Props) => {
  const selectedMissionId = useSelector(getCurrentMissionId);
  const { data: currentMission } = useFetchMissionDetails({ missionId: selectedMissionId ?? '' });
  const currentBrand = useSelector(getCurrentBrand);
  const updateCandidateStatus = useUpdateCandidateStatus();

  const [selectedCurrentCandidateStatus, setSelectedCurrentCandidateStatus] = useState<
    EnumCandidateCandidateAdvancement
  >(candidateStatus);
  const [selectedCurrentCompanyStatus, setSelectedCurrentCompanyStatus] = useState<
    EnumCandidateCompanyAdvancement
  >(companyStatus);

  return (
    <ModalDeprecated
      icon={<InterversionHeader />}
      title="changement de statut"
      key="statusmodification"
      nested
      open
      onClose={onClose}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          errorTitle="réessayer"
          fetchStatus={toFetchStatus(updateCandidateStatus)}
          onClick={() => {
            updateCandidateStatus.mutate({
              id: currentMission?.id ?? '',
              body: {
                candidates: [
                  {
                    candidateId,
                    candidateAdvancement: selectedCurrentCandidateStatus
                      ? EnumCandidateAdvancementInfoCandidateAdvancement[
                          selectedCurrentCandidateStatus
                        ]
                      : undefined,
                    companyAdvancement: selectedCurrentCompanyStatus
                      ? EnumCandidateAdvancementInfoCompanyAdvancement[selectedCurrentCompanyStatus]
                      : undefined,
                  },
                ],
                rdiProgress: EnumUpdateCandidateStatusBodyRdiProgress.MissionDetail,
                brandCode: currentBrand?.brandCodeApiHeader ?? '',
              },
            });
          }}
          onSuccess={onClose}
        />,
      ]}
    >
      <div className={styles.flexRow}>
        <WithLightTitle title="statut intérimaire" className={styles.filter}>
          <DropDown
            className={styles.flexRowChildren}
            placeholder={'choisir un statut intérimaire'}
            items={Object.values(EnumCandidateCandidateAdvancement).filter(
              val => !val.includes('Pixid')
            )}
            keyValueExtractor={(searchResult: string) => ({
              key: searchResult,
              value: searchResult,
            })}
            selectedItem={selectedCurrentCandidateStatus}
            onSelectItem={(element?: EnumCandidateCandidateAdvancement) =>
              setSelectedCurrentCandidateStatus(element ?? candidateStatus)
            }
          />
        </WithLightTitle>
        <WithLightTitle title="statut compte" className={styles.filter}>
          <DropDown
            className={styles.flexRowChildren}
            placeholder={'choisir un statut compte'}
            items={Object.values(EnumCandidateCompanyAdvancement)}
            keyValueExtractor={(searchResult: string) => ({
              key: searchResult,
              value: searchResult,
            })}
            selectedItem={selectedCurrentCompanyStatus}
            onSelectItem={(element?: EnumCandidateCompanyAdvancement) =>
              setSelectedCurrentCompanyStatus(element ?? companyStatus)
            }
          />
        </WithLightTitle>
      </div>
    </ModalDeprecated>
  );
};

export default StatusModalContent;
