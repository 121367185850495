import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { Calendar } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import DateRangePicker from 'src/Components/DateRangePicker';
import styles from './DatesPopupMenu.module.scss';
import { Props } from './DatesPopupMenu.types';

const DatesPopupMenu = ({
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [newStartDate, setNewStartDate] = useState<Moment | null>(selectedStartDate);
  const [newEndDate, setNewEndDate] = useState<Moment | null>(selectedEndDate);
  const onDatesChange = (args: { startDate: Moment | null; endDate: Moment | null }) => {
    setNewStartDate(args.startDate);
    setNewEndDate(args.endDate);
  };
  useEffect(() => {
    setNewStartDate(selectedStartDate);
  }, [selectedStartDate]);
  useEffect(() => {
    setNewEndDate(selectedEndDate);
  }, [selectedEndDate]);
  return (
    <PopupMenu
      open={open}
      width={280}
      position="bottom left"
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      trigger={
        <Button.Secondary
          className={classnames(styles.filterButton, { [styles.openPopup]: open })}
          text="date"
          leftIcon={<Calendar />}
        />
      }
    >
      <div className={styles.popupContainer}>
        <DateRangePicker
          startDate={newStartDate}
          endDate={newEndDate}
          onDatesChange={onDatesChange}
          monthsShown={1}
        />
        <div className={styles.buttons}>
          <Button.Secondary.XSmall
            text="annuler"
            onClick={() => {
              setOpen(false);
            }}
          />
          <Button.Primary.XSmall
            text="valider"
            disabled={!newStartDate || !newEndDate}
            onClick={() => {
              setSelectedStartDate(newStartDate);
              setSelectedEndDate(newEndDate);
              setOpen(false);
            }}
          />
        </div>
      </div>
    </PopupMenu>
  );
};

export default DatesPopupMenu;
