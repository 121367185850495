import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CandidateFirstContractDate, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchLastYearCandidateContracts = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateFirstContractDate, string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateFirstContract, id],
    async () => {
      const candidate = await CandidatesService.candidatesControllerGetFirstContractDate({
        id: id,
      });
      return candidate;
    },
    { staleTime: Infinity, enabled: id !== '', ...extraOptions }
  );
};
