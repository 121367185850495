import { Animation } from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useState } from 'react';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';
import styles from './SidebarWorkAccidentAnalysis.module.scss';
import { Props } from './SidebarWorkAccidentAnalysis.types';

export const SidebarAT = (props: Props) => {
  const { analysisId } = useTypedParams(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={styles.container}>
      <Animation.Unroll visible={isVisible}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>concernant l'accident de travail du TT :</p>
          <p className={styles.name}>{props.candidateFullName.toLowerCase() ?? ''}</p>
          <p className={styles.qualification}>{props.qualification.toLowerCase()}</p>
        </div>
      </Animation.Unroll>
      <div className={styles.linkContainer}>
        <NavLink
          to={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({
            analysisId,
          })}
          className={({ isActive }) => classnames(styles.link, { [styles.active]: isActive })}
        >
          <div className={styles.navNumber}>1.</div> contexte
        </NavLink>
        <NavLink
          to={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.DESCRIPTION.buildPath({
            analysisId,
          })}
          className={({ isActive }) => classnames(styles.link, { [styles.active]: isActive })}
        >
          <div className={styles.navNumber}>2.</div> description
        </NavLink>
        <NavLink
          to={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.WHY_ANALYSIS.buildPath({
            analysisId,
          })}
          className={({ isActive }) => classnames(styles.link, { [styles.active]: isActive })}
        >
          <div className={styles.navNumber}>3.</div> analyse des 5 pourquoi
        </NavLink>
        <NavLink
          to={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.ACTION.buildPath({
            analysisId,
          })}
          className={({ isActive }) => classnames(styles.link, { [styles.active]: isActive })}
        >
          <div className={styles.navNumber}>4.</div> action client et agence
        </NavLink>
        <NavLink
          to={ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONCLUSION.buildPath({
            analysisId,
          })}
          className={({ isActive }) => classnames(styles.link, { [styles.active]: isActive })}
        >
          <div className={styles.navNumber}>5.</div> conclusion
        </NavLink>
      </div>
    </div>
  );
};
