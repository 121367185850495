import React, { useState } from 'react';
import { Props } from './DistanceFilter.types';
import styles from './DistanceFilter.module.scss';
import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import classNames from 'classnames';
import DistanceFilterContent from './DistanceFIlterContent/DistanceFIlterContent.component';
import { PinOutlineWhite } from '@randstad-lean-mobile-factory/react-components-ui-shared';

export const DistanceFilter = ({ filteredDistance, setFilteredDistance, data }: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <PopupMenu
      position="bottom right"
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      contentStyle={{ width: '32.75rem' }}
      trigger={
        <div>
          <Button.Secondary
            className={classNames(styles.filterButton, { [styles.openButton]: isOpened })}
            text={`rayon - ${filteredDistance === 110 ? '100+ ' : filteredDistance}km`}
            leftIcon={<PinOutlineWhite />}
          ></Button.Secondary>
        </div>
      }
    >
      <DistanceFilterContent
        filteredDistance={filteredDistance}
        setFilteredDistance={setFilteredDistance}
        data={data}
      ></DistanceFilterContent>
    </PopupMenu>
  );
};
