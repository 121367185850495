import { useQuery } from '@tanstack/react-query';
import { AgenciesService, GetAgenciesDistancesParams } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchAgenciesDistances = (params: GetAgenciesDistancesParams) => {
  return useQuery(
    [QueryKeys.fetchAgenciesDistances],
    async () => {
      const agenciesDistances = await AgenciesService.agenciesControllerGetAgenciesDistances({
        body: params,
      });

      return agenciesDistances;
    },
    { enabled: params.agencies.length > 0 }
  );
};
