import { AnyAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ContractsState } from './Types';
import { ThunkAPI } from 'src/Redux/RootReducer';
import { getCurrentQualification } from 'src/Redux/Qualifications/Selectors';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { getCurrentBrandCode, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { getStartDate, getEndDate, getSortMode, getSearchOffset } from 'src/Redux/Search/Selectors';
import { getRawCurrentContract } from 'src/Redux/Contracts/Selectors';
import { SEARCH_LIMIT } from 'src/Redux/Search/Types';
import { ContractsService, ContractDetail, Contract } from 'src/Services/API';
import { getCurrentService } from 'src/Redux/Services/Selectors';

export const searchContracts = createAsyncThunk<ContractsState['contracts'], undefined, ThunkAPI>(
  'contracts/search',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const currentQualification = getCurrentQualification(state);
    const currentCompany = getCurrentCompany(state);
    const currentService = getCurrentService(state);
    const brandCode = getCurrentBrandCode(state) || '';
    const agencyId = getCurrentAgency(state) || '';
    const startDate = getStartDate(state).toISOString();
    const endDate = getEndDate(state).toISOString();
    const sortBy = getSortMode(state);
    const searchOffset = getSearchOffset(state);
    const contracts = await ContractsService.contractsControllerGetContracts({
      startDate,
      endDate,
      brandCode,
      agencyId,
      qualificationId: currentQualification?.id,
      companyId: currentCompany?.companyId,
      serviceId: currentService?.serviceId,
      offset: searchOffset,
      limit: SEARCH_LIMIT,
      sortBy,
    });
    if (searchOffset === 0 && contracts.length > 0) {
      thunkAPI.dispatch((fetchContractDetail(contracts[0]) as unknown) as AnyAction);
    }
    return contracts;
  }
);

export const fetchContractDetail = createAsyncThunk<ContractDetail, Contract, ThunkAPI>(
  'contracts/fetchById',
  async (contract, thunkAPI) => {
    const state = thunkAPI.getState();
    const brandCode = getCurrentBrandCode(state) || '';
    return ContractsService.contractsControllerGetContract({
      id: contract.contractId ?? '',
      brandCode,
    });
  }
);

export const reloadCurrentContract = createAsyncThunk<void, void, ThunkAPI>(
  'contracts/reloadCurrent',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const contract = getRawCurrentContract(state);
    if (contract !== null) {
      thunkAPI.dispatch(
        (fetchContractDetail({
          ...contract,
          contactId: contract.contact.contactId,
        }) as unknown) as AnyAction
      );
    }
  }
);
