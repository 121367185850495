import moment from 'moment';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getPlanningStartDate } from 'src/Redux/CDICandidates/Selectors';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { AgenciesService } from 'src/Services/API';
import { QueryKeys } from '../types';

const getCandidatesPlanningSearchLimit = 10;

export const useFetchCandidatesPlanning = () => {
  const agencyId = useSelector(getCurrentAgency) ?? '';
  const brandCode = useSelector(getCurrentBrandCode);
  const startDate = useSelector(getPlanningStartDate);
  const monthNumberFromToday = Math.floor(
    moment().subtract(1, 'week').startOf('week').diff(moment(startDate), 'weeks') / 3
  );
  return useInfiniteQuery(
    [QueryKeys.fetchCandidatesPlanning, agencyId, brandCode, monthNumberFromToday],
    async ({ pageParam: offset = 0 }) => {
      const candidates = await AgenciesService.agenciesControllerGetAgencyCandidatesPlanning({
        agencyId,
        offset,
        limit: getCandidatesPlanningSearchLimit,
        brandCode,
        startDate: moment(startDate).toISOString(),
      });

      return candidates;
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === getCandidatesPlanningSearchLimit
          ? (pages.length - 1) * getCandidatesPlanningSearchLimit + lastPage.length
          : undefined,
    }
  );
};
