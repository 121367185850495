import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { MissionsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchMissionsDetails = ({ missionIds }: { missionIds?: string[] }) => {
  const brandCode = useSelector(getCurrentBrandCode)!;

  return useQuery(
    [QueryKeys.fetchMissionsDetails, missionIds],

    () => {
      return MissionsService.missionsControllerGetMissionsInfos({
        missionIds: missionIds,
        brandCode,
      });
    },
    { enabled: missionIds !== undefined, refetchOnWindowFocus: false }
  );
};
