import { useMutation } from '@tanstack/react-query';
import { ContractsService } from 'src/Services/API';
import { useSelector } from 'react-redux';
import { getAnalyticsUserInfo, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { useSetProcessingDateYouPlanMission } from '../Youplan/useSetProcessingDateYouPlanMission';

export const useRenewContract = () => {
  const brandCode = useSelector(getCurrentBrandCode) ?? '';
  const userInfo = useSelector(getAnalyticsUserInfo);
  const { mutate: setProcessingDateYouPlanMission } = useSetProcessingDateYouPlanMission({});
  return useMutation(
    async ({
      contractId,
      contactId,
      renewalDate,
      youplanMissionId,
    }: {
      contractId: string;
      contactId: string;
      renewalDate: Date;
      youplanMissionId?: string;
    }) => {
      const res = await ContractsService.contractsControllerRenewContract({
        id: contractId,
        brandCode,
        body: { contactId, renewalDate: renewalDate.toISOString(), youplanMissionId },
      });
      if (youplanMissionId) {
        setProcessingDateYouPlanMission({ id: youplanMissionId });
      }

      ReactGA.event(
        youplanMissionId
          ? ANALYTICS_EVENT.YOUPLAN_CONTRACT_EXTENSION
          : ANALYTICS_EVENT.CONTRACT_EXTENSION,
        {
          ...userInfo,
        }
      );
      return res;
    }
  );
};
