import { Props } from './RepositioningPotentialOffers.types';
import { useFetchPotentialQualificationsClientsForRepositioning } from 'src/Hooks/Qualifications/useFetchPotentialQualificationsClientsForRepositioning';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './RepositioningPotentialOffers.module.scss';
import { LoadingCard } from 'src/Components/Card';
import RepositioningCardWithoutCheckBox from '../RepositioningCardWithoutCheckBox';

const RepositioningPotentialOffers = ({
  isCGC,
  brandCode,
  selectedQualifications,
  selectedDepartments,
}: Props) => {
  const { inView, ref } = useInView();

  const {
    data: potentialQualificationsClientsPages,
    fetchNextPage: fetchNextPageQualif,
    isFetchingNextPage: isFetchingNextPageQualif,
    isLoading: isLoadingQualif,
  } = useFetchPotentialQualificationsClientsForRepositioning({
    mainQualifications: selectedQualifications,
    departmentIds: selectedDepartments?.map(dep => dep.id ?? ''),
    brandCode,
    isCGC: isCGC ?? false,
  });

  const potentialQualificationsClients = (potentialQualificationsClientsPages?.pages ?? []).flat(1);

  useEffect(() => {
    if (inView && !isFetchingNextPageQualif) {
      fetchNextPageQualif();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return isLoadingQualif ? (
    <div className={styles.loadingContainer}>
      {Array.from({ length: 10 }).map((_, idx) => (
        <div className={styles.loadingCard} key={`${idx} repositioningCard`}>
          <LoadingCard />
        </div>
      ))}
    </div>
  ) : potentialQualificationsClients.length ? (
    <div className={styles.container}>
      {potentialQualificationsClients.map(potentialQualif => (
        <div className={styles.card}>
          <RepositioningCardWithoutCheckBox
            key={potentialQualif.client?.companyId}
            companyName={potentialQualif.client.companyName ?? ''}
            qualificationName={potentialQualif?.potentialQualification?.label ?? ''}
            city={potentialQualif.client?.companyAddress?.city ?? ''}
            postCode={potentialQualif.client?.companyAddress?.postalCode ?? ''}
            contact={potentialQualif.client?.contact}
          />
        </div>
      ))}
      <div ref={ref} />
    </div>
  ) : (
    <div className={styles.noMission}>
      Il n'y a aucun poste potentiel correspondant aux critères
    </div>
  );
};

export default RepositioningPotentialOffers;
