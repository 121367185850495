import { useLocation, useNavigate } from 'react-router-dom';
import { EventDetailsState } from 'src/Services/Routing';

import { Button, ModalDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { CircleCheck, Warning } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import { useCallback } from 'react';
import { EyeIllustration } from 'src/Assets_DEPRECATED';
import { completeEnums } from 'src/Routes/ExternalDemands/DemandsList/ExternalDemandsListMenu/ExternalDemandsListMenu.types';
import { useFetchMissionDetails } from 'src/Hooks/Missions/useFetchMissionDetails';
import { ROUTES } from 'src/Routes/Routes.types';
import styles from './ExternalDemandsEventDetailsModal.module.scss';
import { formatError } from '../../Routes/ExternalDemands/DemandsList/transformers';
import { ExternalDemandChild } from '../../Routes/ExternalDemands/DemandsList/ExternalDemandsList/ExternalDemandsList.types';

const ExternalDemandsEventDetailsModal = () => {
  const modalParameters = (useLocation().state as EventDetailsState | undefined)?.modalParameters;
  const externalDemand = modalParameters?.externalDemand;
  const eventId =
    modalParameters?.eventId === externalDemand?.commandId
      ? externalDemand?.items[0].id
      : modalParameters?.eventId;
  const event = externalDemand?.items.find(item => item.id === eventId);
  const navigate = useNavigate();
  const onClose: () => void = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const { data } = useFetchMissionDetails({ missionId: externalDemand?.commandId });

  const isComplete = event ? completeEnums.includes(event.status) : false;

  const row = ({
    header1,
    header2,
    text1,
    text2,
  }: {
    header1?: string;
    text1?: React.ReactNode;
    header2?: string;
    text2?: React.ReactNode;
  }) => (
    <div className={styles.row}>
      <div className={styles.cell}>
        <div className={styles.header}>{header1}</div>
        <div className={styles.text}>{text1 ?? 'information inconnue'}</div>
      </div>
      <div className={styles.cell}>
        <div className={styles.header}>{header2}</div>
        <div className={styles.text}>
          {(header2 !== undefined && text2) ?? 'information inconnue'}
        </div>
      </div>
    </div>
  );

  return (
    <ModalDeprecated
      nested
      key="crModal"
      open
      onClose={onClose}
      icon={<EyeIllustration />}
      iconClassName={styles.icon}
      footerActionsLeft={[<Button.Tertiary.Small text="fermer" onClick={onClose} />]}
      title={`détails de la demande ${externalDemand?.osmoseId}`}
      footerActionsRight={[
        <Button.Secondary.Small
          text={'voir les correspondances'}
          onClick={() =>
            navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildPath({}), {
              state: ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildState({
                siret: externalDemand?.siret,
              }),
            })
          }
        />,

        <Button.Primary.Small text="fermer" onClick={onClose} />,
      ]}
    >
      <div className={styles.main}>
        {row({
          header1: 'statut',
          text1: (
            <div className={styles.badge}>
              <div className={classnames(isComplete ? styles.badgeComplete : styles.badgeError)}>
                {isComplete ? <CircleCheck /> : <Warning />}
              </div>
              <div>{event ? formatError(event as ExternalDemandChild) : ''}</div>
            </div>
          ),
          header2: '',
          text2: '',
        })}
        {row({
          header1: 'établissement',
          text1: externalDemand?.clientName,
          header2: 'SIRET',
          text2: externalDemand?.siret,
        })}
        {row({
          header1: 'domaine',
          text1: externalDemand?.domain,
          header2: 'qualifications',
          text2: externalDemand?.qualification,
        })}
        {row({
          header1: 'service',
          text1: externalDemand?.service,
          header2: 'date et heure',
          text2: (
            <div>
              <div>{moment(event?.dateMission).format('DD MMMM YYYY')}</div>
              <div>{event?.schedule}</div>
            </div>
          ),
        })}
        {row({
          header1: `id ${externalDemand?.source.toLowerCase()}`,
          text1: event?.id,
          header2: 'numéro commande',
          text2: externalDemand?.commandId,
        })}
        {row({
          header1: 'admin',
          text1: data?.contact
            ? `${data?.contact?.name} ${data?.contact?.firstName}`
            : 'information inconnue',
          header2: 'motif',
          text2: event?.motive,
        })}
        {row({
          header1: 'email',
          text1: data?.contact?.email ?? 'information inconnue',
          header2: 'date limite de traitement',
          text2: event?.limitDate
            ? `${moment(event?.limitDate).format('DD MMMM YYYY')} à ${moment(event?.limitDate)
                .utc()
                .format('HH:mm')}`
            : 'information inconnue',
        })}
      </div>
    </ModalDeprecated>
  );
};

export default ExternalDemandsEventDetailsModal;
