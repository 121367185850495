import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { WorkAccidentActionPlan, WorkAccidentAnalysisService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchWorkAccidentActionPlans = (
  extraOptions?: UseQueryOptions<
    WorkAccidentActionPlan[],
    unknown,
    WorkAccidentActionPlan[],
    (string | undefined)[]
  >
) => {
  const brandCode = useSelector(getCurrentBrandCode);
  const agency = useSelector(getCurrentAgency);

  return useQuery(
    [QueryKeys.fetchWorkAccidentActionPlans, agency, brandCode],
    async () => {
      return WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkAccidentsActionPlans({
        agencies: [agency],
        brandCode: brandCode ?? '',
      });
    },
    { ...extraOptions }
  );
};
