import React from 'react';

import styles from './SearchButton.module.scss';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Search } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { Props } from './SearchButton.types';

const SearchButton = ({ searchOpen, setSearchOpen }: Props) => {
  return (
    <Button.Tertiary
      className={styles.button}
      onClick={() => {
        setSearchOpen(!searchOpen);
      }}
    >
      <Search />
    </Button.Tertiary>
  );
};

export default SearchButton;
