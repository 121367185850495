import { PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { useLocation, useNavigate } from 'react-router-dom';

import { extend } from 'src/Assets_DEPRECATED';

import { ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import styles from './PopOverActions.module.scss';
import { Props } from './PopOverActions.types';

const PopOverActions = ({ isRenewable, contractId }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  return isRenewable ? (
    <PopupMenu
      trigger={
        <button className={styles.triggerContainer}>
          <ThreeDots className={styles.threeDots} />
        </button>
      }
      position="left top"
    >
      <PopupMenu.Item
        onClick={() => {
          isRenewable &&
            navigate(MODAL_ROUTES.CONTRACT_RENEWAL.route.buildPath({}), {
              state: {
                background: location,
                contractId,
              },
            });
        }}
        icon={<img className={styles.icon} src={extend} alt="extend contract" />}
        text="prolonger le contrat"
      />
    </PopupMenu>
  ) : null;
};

export default PopOverActions;
