import { useState } from 'react';
import ContentLoader from 'react-content-loader';

import { LetterBadge } from '@randstad-lean-mobile-factory/react-components-core';
import { LightArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classNames from 'classnames';
import { animated, useSpring } from 'react-spring';
import Dot from 'src/Components/Dot';
import PopOverContact from 'src/Components/PopOverContact';
import { EnumCreateActionBodyActionTarget } from 'src/Services/API';
import styles from './MissionBySkillMatchingCard.module.scss';
import { Props } from './MissionBySkillMatchingCard.types';

export const LoadingCard = () => (
  <ContentLoader uniqueKey="loadingCard" height="117px" width="100%">
    {[
      { width: '80%', height: '1.5rem', y: '0' },
      { width: '60%', height: '0.65rem', y: '1.8rem' },
      { width: '60%', height: '0.65rem', y: '2.7rem' },
      { width: '70%', height: '0.75rem', y: '4rem' },
      { width: '90%', height: '0.85rem', y: '5rem' },
      { width: '60%', height: '0.75rem', y: '6rem' },
    ].map((props, idx) => (
      <rect key={idx} x="0" rx="4" ry="4" {...props} />
    ))}
  </ContentLoader>
);

const MissionBySkillMatchingCard = ({ mission }: Props) => {
  const [open, setOpen] = useState(false);
  const spin = useSpring({
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  });
  const totalSkills = {
    known: mission.skillsDetails?.filter(({ known }) => known).length,
    unknown: mission.skillsDetails?.filter(({ known }) => !known).length,
  };
  return (
    <div className={styles.container}>
      <div className={styles.firstPart}>
        <div className={styles.firstPartContent}>
          <LetterBadge
            text={mission.matchScore ? `${Math.round(parseFloat(mission.matchScore) * 100)}%` : ''}
            className={styles.letterBadge}
          />
          <div className={styles.text}>
            <div className={styles.title}>{mission.company?.name ?? ''}</div>
            <p className={styles.subtitle}>{mission.service?.name ?? 'service non renseigné'}</p>
            <p className={styles.subtitle}>{mission.qualification?.label}</p>
            {mission.contact && (
              <PopOverContact
                target={EnumCreateActionBodyActionTarget.CONTACT}
                contact={{ ...mission.contact, lastContact: undefined }}
                triggerButton={<button className={styles.contact}>contacter ce client</button>}
                position="right bottom"
                enableEditMissionStatus={false}
              />
            )}
          </div>
        </div>
        <button className={styles.arrowButton} onClick={() => setOpen(!open)}>
          <animated.div className={styles.arrow} style={spin}>
            <LightArrow />
          </animated.div>
        </button>
      </div>
      {open && (
        <div>
          <div className={styles.separator} />
          <div className={styles.detailsTop}>
            <div className={styles.detailsTitle}>détails</div>
            <div className={styles.detailsLegend}>
              <div className={classNames(styles.colorLegend, styles.green)} />
              acquis
              <div className={classNames(styles.colorLegend, styles.red)} />
              non acquis
            </div>
          </div>
          <div className={styles.detailsBar}>
            <div
              className={classNames(styles.green, styles.detailsBarText)}
              style={{
                borderRadius: '0.25rem 0 0 0.25rem',
                height: '100%',
                width: mission.matchScore
                  ? `${Math.round(parseFloat(mission.matchScore) * 100)}%`
                  : '',
              }}
            >
              {totalSkills.known}
            </div>
            <div
              className={styles.detailsBarText}
              style={{
                borderRadius: '0 0.25rem 0.25rem 0',
                height: '100%',
                width: mission.matchScore
                  ? `${100 - Math.round(parseFloat(mission.matchScore) * 100)}%`
                  : '',
              }}
            >
              {totalSkills.unknown}
            </div>
          </div>
          <div className={styles.skillsDetails}>
            {mission.skillsDetails?.map(skill => (
              <div className={styles.skill}>
                <Dot className={skill.known ? styles.green : styles.red} />
                <div className={styles.skillLabel}>{skill.skillLabel}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MissionBySkillMatchingCard;
