import {
  Button,
  FetchButton,
  ModalDeprecated,
  TextArea,
} from '@randstad-lean-mobile-factory/react-components-core';
import QRCode from 'qrcode.react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchMissionDetails } from 'src/Hooks/Missions/useFetchMissionDetails';
import { getCurrentMissionId } from 'src/Redux/Missions/Selectors';
import { getContactDeepLink, getImage, getLabel } from 'src/Services/Actions';
import { ContactLocationState } from 'src/Services/Routing';
import { Colors, REPORT_MAX_LENGTH } from 'src/Utils/constants';
import styles from './ContactModal.module.scss';

import { Props } from './ContactModal.types';

const ContactModal = (props: Props) => {
  const [report, setReport] = useState('');
  const navigate = useNavigate();
  const modalParameters = (useLocation().state as ContactLocationState | undefined)
    ?.modalParameters;
  const Icon = modalParameters && getImage(modalParameters.type);
  const selectedMissionId = useSelector(getCurrentMissionId);
  const { data: currentMission } = useFetchMissionDetails({ missionId: selectedMissionId ?? '' });

  return (
    <ModalDeprecated
      onClose={() => navigate(-1)}
      key={'key'}
      nested
      open
      icon={Icon ? <Icon /> : undefined}
      title={
        <p>{`contact avec ${modalParameters?.contactName} par ${
          modalParameters && getLabel(modalParameters.type)
        }`}</p>
      }
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setReport('');
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            navigate(-1);
          }}
        >
          annuler
        </Button.Secondary>,
        <FetchButton
          onClick={() => {
            modalParameters &&
              props.newMissionAction({
                missionId: selectedMissionId,
                params: {
                  actionTarget: modalParameters.target,
                  actionType: modalParameters.type,
                  targetId: modalParameters.contactId,
                  report,
                  companyId: currentMission?.company?.companyId ?? '',
                },
              });
          }}
          onSuccess={() => {
            props.onActionCreationSuccess();
          }}
          title={'valider'}
          fetchStatus={props.fetchStatus}
        />,
      ]}
    >
      <div className={styles.content}>
        {modalParameters?.contactInfo.map((elem, idx) => (
          <div style={{ display: 'flex' }}>
            {idx > 0 && <div style={{ width: '2rem' }} />}
            <div className={styles.infoContainer}>
              <div className={styles.qrcodeContainer}>
                <QRCode
                  value={getContactDeepLink(modalParameters.type, elem.info)}
                  fgColor={Colors.white}
                  bgColor={Colors.darkBlue}
                />
              </div>
              <p className={styles.infoType}>{elem.infoType}</p>
              <a href={getContactDeepLink(modalParameters.type, elem.info)} className={styles.info}>
                {elem.info}
              </a>
            </div>
          </div>
        ))}
      </div>
      <div style={{ height: '2rem' }} />
      <div className={styles.reportHeader}>
        <span className={styles.reportHeaderTitle}>compte rendu de votre échange</span>
        <span className={styles.reportCharCount}>
          {report.length}/{REPORT_MAX_LENGTH} caractères
        </span>
      </div>
      <TextArea
        className={styles.reportTextArea}
        value={report}
        onChange={event => setReport((event.target as HTMLTextAreaElement).value)}
        maxLength={REPORT_MAX_LENGTH}
      />
    </ModalDeprecated>
  );
};

export default ContactModal;
