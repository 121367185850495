import { useQuery } from '@tanstack/react-query';
import { SkillsManagementCentersService } from 'src/Services/API';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { QueryKeys } from '../types';

export const useIsCGC = () => {
  const brandCode = useSelector(getCurrentBrand)?.brandCodeApiHeader ?? '';
  const agencyId = useSelector(getCurrentAgency) ?? '';

  return useQuery(
    [QueryKeys.isCGC],
    async () => {
      const isCGC = await SkillsManagementCentersService.skillsManagementCentersControllerGetIsCgc({
        agencyId,
        brandCode,
      });

      return isCGC;
    },
    { cacheTime: 1 }
  );
};
