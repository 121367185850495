import React from 'react';
import { useSelector } from 'react-redux';
import { getPositionStudiesCountSelector } from 'src/Redux/MissionCreation/Selectors';
import NewMissionConfirmation from './NewMissionConfirmation.component';

const MemoNewMissionConfirmation = React.memo(NewMissionConfirmation);

const EnhancedNewMissionConfirmation = () => {
  const positionStudiesCount = useSelector(getPositionStudiesCountSelector);

  return <MemoNewMissionConfirmation positionStudiesCount={positionStudiesCount} />;
};

export default EnhancedNewMissionConfirmation;
