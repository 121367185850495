import { FETCH_STATUS } from 'src/Redux/Types';
import { Mission } from 'src/Services/API';

export interface MissionsState {
  missions: Mission[];
  currentMissionId: string;
  missionsFetchStatus: FETCH_STATUS;
  currentMissionFetchStatus: FETCH_STATUS;
  currentRequestId?: string;
  canLoadMore: boolean;
  sortBy: MissionSortBy;
  sortOrder: SortOrder;
}

export enum MissionSortBy {
  DISTANCE,
  NB_POSITIONS,
  START_DATE,
  STATUT,
}

export enum SortOrder {
  ASC,
  DESC,
}
