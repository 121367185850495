import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { PositionstudiesService } from 'src/Services/API';

import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import { MutationKeys } from '../types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export const useGeneratePreventionSheet = (
  extraOptions?: UseMutationOptions<Buffer, unknown, unknown>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const user = useAuthenticatedUser();
  return useMutation(
    [MutationKeys.generatePreventionSheet],
    async (edpId: string) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return await PositionstudiesService.positionStudiesControllerGetPreventionSheetPdf(
        { id: edpId, body: { brandCode: currentBrand?.brandCodeApiHeader, user } },
        { responseType: 'arraybuffer' }
      );
    },
    {
      ...extraOptions,
    }
  );
};
