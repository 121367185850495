import { BaseModalProps } from '@randstad-lean-mobile-factory/react-components-core/dist/ModalsDeprecated/BaseModalDeprecated/BaseModal.types';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { ThunkAPI } from 'src/Redux/RootReducer';
import { FETCH_STATUS } from 'src/Redux/Types';
import { ConsultantInformation } from 'src/Services/API';

export enum ENUM_FTE_TRANCHE {
  TRANCHE_0 = 0,
  TRANCHE_1 = 1,
  TRANCHE_2 = 2,
  TRANCHE_3 = 3,
  TRANCHE_4 = 4,
}

export const fteTrancheForScale3LargeAndOnsite: string[] = [
  '0 - unité inférieure à 2 FTE',
  '1 - unité entre  2 et 4 FTE',
  '2 - unité de 4 FTE et plus',
];

export const fteTrancheForScale4LargeAndOnsite: string[] = [
  '0 - unité inférieure à 2 FTE',
  '1 - unité entre  2 et 3 FTE',
  '2 - unité entre  3 et 4 FTE',
  '3 - unité de 4 FTE et plus',
];

export const fteTrancheForMarketAndPool: string[] = [
  '0 - unité inférieure à 1,5 FTE',
  '1 - unité entre 1,5 et 2,5 FTE',
  '2 - unité entre 2,5 et 3 FTE',
  '3 - unité entre 3 et 4 FTE',
  '4 - unité de 4 FTE et plus',
];

export interface Props extends Omit<BaseModalProps, 'children'> {
  ssid?: string;
  lastSyncDate?: Moment;
  consultantInformation?: ConsultantInformation;
  fetchStatus?: FETCH_STATUS;
  fetchVariableSimulator: (
    params: string
  ) => AsyncThunkAction<{ consultantInformation: ConsultantInformation }, string, ThunkAPI>;
}
