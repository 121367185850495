import { SelectionIconButtonsForm } from 'src/Components/SelectionIconButtonsForm';

import { Props } from './EpcsSelection.types';
import { MaseItem } from 'src/Services/API';
import ContentLoader from 'react-content-loader';

import styles from './EpcsSelection.module.scss';
import { iconMapping } from '../ProtectionEquipments.types';
import { DefaultIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';

const EpcsSelection = ({
  allEpcs,
  selectedEpcs,
  onEpcClick,
  ...selectionIconButtonsFormProps
}: Props) => {
  return (
    <SelectionIconButtonsForm
      {...selectionIconButtonsFormProps}
      loadingItems={6}
      error="une erreur est survenue lors de la récupération du référentiel des équipements de protection"
      IconButton={{
        Loading: () => (
          <ContentLoader width="100%" uniqueKey="criteriaButton" className={styles.iconLoading}>
            <circle cx="50%" cy="30" r="30" />
            <rect y="80" rx="4" ry="4" width="100%" height="15" />
          </ContentLoader>
        ),
      }}
      selectIconButtonClassName={styles.iconButton}
      data={
        allEpcs?.map((epc: MaseItem) => ({
          key: epc.id,
          label: epc.label ?? epc.id,
          icon: iconMapping[epc.id] ?? <DefaultIcon />,
          selected: selectedEpcs.find((selected: MaseItem) => selected.id === epc.id) !== undefined,
          onClick: () => {
            onEpcClick(epc);
          },
        })) ?? []
      }
    />
  );
};

export default EpcsSelection;
