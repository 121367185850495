import { AppelMedicalEventSource } from 'src/Services/API';
import { GroupedMailParameters } from '../MailParametersList.types';

export interface MailParameterTableProps {
  group: GroupedMailParameters;
  source: AppelMedicalEventSource;
  qualifications: string[];
  domains: string[];
  isOpen: boolean;
  defaultOpenState: boolean;
  onToggle: () => void;
  searchInput: string;
}

export const OSMOSE_IGNORE_VALUE = '## ignore ##';

export const OSMOSE_IGNORE_LABEL = 'à ignorer';
