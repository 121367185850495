import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { MutationKeys, QueryKeys } from '../types';
import { CommandStatusToUpdateDto, ExternalDemandsService } from 'src/Services/API';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';

interface ExternalDemandsUpdateParams {
  commandStatusToUpdate: CommandStatusToUpdateDto[];
}

export const useUpdateExternalDemands = (
  extraOptions?: UseMutationOptions<void, unknown, ExternalDemandsUpdateParams, unknown>
) => {
  const queryClient = useQueryClient();
  const currentAgencyId = useSelector(getCurrentAgency);

  return useMutation(
    [MutationKeys.updateExternalDemands],
    async (params: ExternalDemandsUpdateParams) => {
      await ExternalDemandsService.externalDemandsControllerUpdateDemands({
        body: {
          agencyId: currentAgencyId ?? '',
          commandStatusToUpdate: params.commandStatusToUpdate,
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchExternalDemands],
        });
        toast.success('les demandes exterrnes ont été mise à jour');
      },
      onError: (...args) => {
        toast.error(
          "certaines demandes n'ont pas pu être mises à jour : " +
            args[1].commandStatusToUpdate.map(command => command).join(', ')
        );
      },
    }
  );
};
