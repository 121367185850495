import moment from 'moment';
import { CandidatesWorkAccidents } from 'src/Services/API';

export enum SortAccidentsBy {
  Name,
  StartDate,
}

export enum AnalysisStatus {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export function sortAccidents({
  accidents,
  sortBy,
  sortOrder,
}: {
  accidents: CandidatesWorkAccidents[];
  sortBy: SortAccidentsBy;
  sortOrder: 'asc' | 'desc';
}): CandidatesWorkAccidents[] {
  return accidents.sort((a, b) => {
    if (sortBy === SortAccidentsBy.Name && a.candidate?.lastName && b.candidate?.lastName) {
      return sortOrder === 'asc'
        ? a.candidate?.lastName > b.candidate?.lastName
          ? 1
          : -1
        : a.candidate?.lastName < b.candidate?.lastName
        ? 1
        : -1;
    }
    if (sortBy === SortAccidentsBy.StartDate && a.startDate && b.startDate) {
      return sortOrder === 'asc'
        ? moment(a.startDate).isAfter(moment(b.startDate))
          ? 1
          : -1
        : moment(a.startDate).isBefore(moment(b.startDate))
        ? 1
        : -1;
    } else {
      return 1;
    }
  });
}
