import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ExternalDemandListState } from './Types';

const initialState: ExternalDemandListState = {
  searchInput: '',
  filteredColumns: [],
};

export const {
  reducer: ExternalDemandListReducer,
  actions: ExternalDemandListActions,
} = createSlice({
  name: 'ExternalDemandList',
  initialState,
  reducers: {
    setSearchInput: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
    setFilteredColumns: (state, action: PayloadAction<string[]>) => {
      state.filteredColumns = action.payload;
    },
  },
});
