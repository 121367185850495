import { DocumentPdf, Eye } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, Menu, MenuItem } from '@randstad-lean-mobile-factory/react-components-core';
import { ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateArchiveStatusWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useUpdateArchiveStatusWorkAccidentAnalysis';
import { ROUTES } from 'src/Routes/Routes.types';
import styles from './CardArchivedAT.module.scss';
import { Props } from './CardATArchived.types';

const CardArchivedAT = ({ AT, idx, archivedAnalysis }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const unarchiveWorkAccidentAnalysis = useUpdateArchiveStatusWorkAccidentAnalysis();

  return (
    <div className={classnames(styles.container, { [styles.containerSelected]: open })}>
      <div className={styles.textContainer}>
        <div className={styles.title}>
          {AT.candidate?.name} {AT.candidate?.lastName}
        </div>
        <p className={styles.subtitle}>{AT.startDate ? moment(AT.startDate).format('L') : ''}</p>
        <p className={styles.description}>
          {archivedAnalysis?.[idx].company?.name} |{' '}
          {archivedAnalysis?.[idx].contractQualification?.label}
        </p>
      </div>
      <div className={styles.iconContainer}>
        <Menu
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          trigger={
            <Button.Secondary
              className={classnames(styles.button, {
                [styles.buttonSelected]: open,
              })}
            >
              <ThreeDots className={styles.menuIcon} />
            </Button.Secondary>
          }
        >
          <MenuItem
            onClick={() =>
              AT.id &&
              navigate(
                ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({
                  analysisId: AT.id,
                })
              )
            }
          >
            <Eye />
            consulter l'analyse AT
          </MenuItem>
          <MenuItem
            onClick={() =>
              unarchiveWorkAccidentAnalysis.mutate({
                analysisId: AT.id ?? '',
              })
            }
          >
            <DocumentPdf />
            déclasser l'analyse AT
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default CardArchivedAT;
