import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Qualification, RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchQualifications = ({
  keyword,
  societyCode,
  brand,
  extraOptions,
  brandCode,
}: {
  keyword?: string;
  societyCode?: string;
  brand?: string;
  brandCode?: string;
  extraOptions?: UseQueryOptions<Qualification[], unknown, Qualification[], (string | undefined)[]>;
}) => {
  return useQuery(
    [QueryKeys.fetchQualifications, keyword, societyCode, brand],
    async () => {
      const qualifications = await RepositoryService.repositoryControllerGetQualifications({
        keyword,
        societyCode,
        brand,
        brandCode,
      });
      return qualifications.sort((a, b) =>
        a.shortLabel === b.shortLabel ? 0 : (a.shortLabel ?? '') < (b.shortLabel ?? '') ? -1 : 1
      );
    },
    { enabled: keyword !== '', ...extraOptions }
  );
};
