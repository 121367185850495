import { DropDown, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import styles from './FTEInput.module.scss';
import { Props } from './FTEInput.types';
import {
  fteTrancheForMarketAndPool,
  fteTrancheForScale3LargeAndOnsite,
  fteTrancheForScale4LargeAndOnsite,
} from '../VariableSimulator.types';

const FTEInput = (props: Props) => {
  const getItems = () => {
    if (
      ['LARGE_VOLUME_UNIT', 'ONSITE_UNIT', 'ON_SITE', 'CLIENT_UNIT'].includes(props.unitType ?? '')
    ) {
      if (props.fteScale === 3) return fteTrancheForScale3LargeAndOnsite;
      if (props.fteScale === 4) return fteTrancheForScale4LargeAndOnsite;
    }
    return fteTrancheForMarketAndPool ?? [];
  };

  const getSelectedItem = () => {
    if (props.fteTranche !== undefined) {
      if (
        ['LARGE_VOLUME_UNIT', 'ONSITE_UNIT', 'ON_SITE', 'CLIENT_UNIT'].includes(
          props.unitType ?? ''
        )
      ) {
        if (props.fteScale === 3) return fteTrancheForScale3LargeAndOnsite[props.fteTranche];
        if (props.fteScale === 4) return fteTrancheForScale4LargeAndOnsite[props.fteTranche];
      }
      return fteTrancheForMarketAndPool[props.fteTranche];
    }
    return 'choisir la Tranche FTE';
  };

  return (
    <WithLightTitle className={styles.section} title={`Tranche FTE (scale:${props.fteScale})`}>
      {(props.fteScale === 3 || props.fteScale === 4) && (
        <DropDown
          key={`Tranche-${props.fteScale}`}
          className={props.disabled ? styles.disabledDropdown : styles.inputFieldDebug}
          items={getItems()}
          placeholder="choisir la Tranche FTE"
          canBeReset={true}
          keyValueExtractor={(item: string) => ({ key: item, value: item })}
          selectedItem={getSelectedItem()}
          onSelectItem={(item?: string) => props.onSelect(item ? parseInt(item.charAt(0)) : 0)}
          disabled={props.disabled}
        />
      )}
    </WithLightTitle>
  );
};

export default FTEInput;
