import { DateFilterStrategy, WorkAccidentAnalysisService } from 'src/Services/API';
import { QueryKeys } from '../types';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

export const useFetchWorkAccidentsByAgencies = ({
  startDate,
  endDate,
  dateFilterStrategy,
}: {
  startDate: Date;
  endDate?: Date;
  dateFilterStrategy?: DateFilterStrategy;
}) => {
  const brandCode = useSelector(getCurrentBrandCode) ?? '';
  const agency = useSelector(getCurrentAgency) ?? '';
  return useQuery(
    [QueryKeys.fetchWorkAccidentsByAgencies, brandCode, agency],
    async () => {
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkAccidentsByAgencies(
        {
          body: {
            agencyIds: [agency],
            brandCode: brandCode,
            startDate: startDate,
            endDate: endDate,
            dateFilterStrategy: dateFilterStrategy,
          },
        }
      );
    },
    {
      enabled: agency !== undefined,
    }
  );
};
