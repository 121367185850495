import { Flash } from '@randstad-lean-mobile-factory/react-assets/dist/logos';
import { Button, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import useAuth from 'src/Hooks/Authentication/useAuth';
import Styles from './Login.module.scss';

export const Login = () => {
  const { isLoading, loginWithRedirect } = useAuth();

  return (
    <div className={Styles.Container}>
      <div className={Styles.Content}>
        <Flash className={Styles.Logo} size="large" />
        <p className={Styles.Title}>
          Bienvenue
          <br />
          sur l'application flash
        </p>
        <p className={Styles.Description}>
          votre service de relance
          <br />
          clients et intérimaires
        </p>
        {isLoading ? (
          <Loader heightInRem={3.5} darkMode />
        ) : (
          <Button.Primary
            onClick={() => loginWithRedirect({ appState: { returnTo: window.location.href } })}
          >
            se connecter
          </Button.Primary>
        )}
      </div>
    </div>
  );
};
