import { createSelector } from '@reduxjs/toolkit';
import { ExternalDemandListState } from './Types';
import { RootState } from '../RootReducer';

const getExternalDemandListState = (state: RootState) => state.ExternalDemandList;

export const getSearchInput = createSelector(
  [getExternalDemandListState],
  (state: ExternalDemandListState) => state.searchInput
);

export const getFilteredColumns = createSelector(
  [getExternalDemandListState],
  (state: ExternalDemandListState) => state.filteredColumns
);
