export const REPORT_MAX_LENGTH = 1000;

export const Colors = {
  white: '#ffffff',
  darkBlue: '#0f1941',
};

export const INTERN_EMAILS = ['randstad.fr', 'expectra.fr', 'appel-medical.com'];

export const DEFAULT_SOURCE_VALUE = '## defaut ##';

export const AGENCY_PARAM = 'Paramètres agence';
