import { ArchiveATReason } from 'src/Services/API';

export function getArchiveReasonTranslation(enumKey: ArchiveATReason): string {
  switch (enumKey) {
    case ArchiveATReason.AT_ALREADY_SENT:
      return 'analyse déjà envoyée';
    case ArchiveATReason.CLIENT_SIGN_REFUSAL:
      return 'refuse signature client';
    case ArchiveATReason.CLIENT_REFUSAL:
      return 'refus réalisation client';
    case ArchiveATReason.DUPLICATE:
      return 'doublon';
  }
}
