import { createMigrate } from 'redux-persist';
import { RootState } from './RootReducer';

type PersistedRootStateV2 = RootState;

// The state.perimeter.perimeter field was renamed to state.perimeter.companies from V1 to V2
type PersistedRootStateV1 = Omit<PersistedRootStateV2, 'perimeter'> & {
  perimeter: Omit<PersistedRootStateV2['perimeter'], 'companies'> & {
    perimeter: PersistedRootStateV2['perimeter']['companies'];
  };
};

const persistMigrations = {
  '2': (state: PersistedRootStateV1): PersistedRootStateV2 => {
    const { perimeter: companies, ...rest } = state.perimeter;
    return {
      ...state,
      perimeter: {
        ...rest,
        companies,
      },
    };
  },
};

// the current createMigrate typings do not allow different incoming and outgoing state types
export const persistMigrate = createMigrate(persistMigrations as any);

export const persistVersion = 2;
