import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createMissionWithoutSchedule,
  createMissionWithoutScheduleFromYouplan,
  fetchPreviousMissionDetail,
  fetchYouplanMissionForNewMission,
  getPositionStudiesCount,
  searchPreviousMissions,
  searchPreviousPositionStudies,
} from './Thunks';
import { FETCH_STATUS } from '../Types';
import {
  CompanyContact,
  CompanyRequestReason,
  CompanyService,
  ContactingStatus,
  MissionDetail,
  OSMCommonModelLocationModelOutputLocationDto,
  PositionStudy,
} from 'src/Services/API';

import MissionCreationState from './Types';
import { DayTimes } from 'src/Components/DayTimePicker/DayTimePicker.types';
import { timeInputToOSM } from 'src/Utils/datetime';

export const initialState: MissionCreationState = {
  selectedCompany: undefined,
  selectedCreationWay: undefined,
  selectedQualification: undefined,
  selectedPreviousMission: undefined,
  previousMissions: [],
  previousPositionStudies: [],
  positionStudiesFetchStatus: FETCH_STATUS.FULFILLED,
  selectedPreviousPositionStudy: undefined,
  missionsFetchStatus: FETCH_STATUS.FULFILLED,
  missionDataFetchStatus: FETCH_STATUS.FULFILLED,
  newMissionId: '',
  missionCreationFetchStatus: FETCH_STATUS.FULFILLED,
  currentRequestId: undefined,
  processedMission: {
    agencyId: '',
    numberPositions: 1,
    startHour: 12,
    clientContactingStatus: ContactingStatus.NONE,
    candidateContactingStatus: ContactingStatus.NONE,
    structuredMissionHours: [{ days: [], startTime: 0, endTime: 0 }],
  },
  positionStudiesCount: 0,
  positionStudiesCountFetchStatus: FETCH_STATUS.FULFILLED,
  isFromYouplanMission: false,
  isFromMyRegion: false,
  isTalent: false,
  youplanMissionIds: [],
  youplanMissionCandidates: [],
  numberPositions: 1,
  candidateId: undefined,
};

const cleanExistingMissionForCreation = (mission: MissionDetail): MissionDetail => {
  const transformedMission = {
    ...mission,
    contactId: undefined,
    contact: undefined,
    workLocation: undefined,
    numberPositions: undefined,
    id: undefined,
    missionDuration: undefined,
    statusForRd: undefined,
    salesPhase: undefined,
    creationDate: undefined,
    origin: undefined,
    modificationDate: undefined,
    receptionTime: undefined,
    getInTouchWith: undefined,
    structuredMissionHours:
      mission.structuredMissionHours?.length !== 0
        ? mission.structuredMissionHours
        : initialState.processedMission.structuredMissionHours,
  };
  return transformedMission;
};

const cleanExistingPositionStudyForCreation = (positionStudy: PositionStudy): MissionDetail => {
  const transformedMission = {
    ...positionStudy,
    numberPositions: 1,
    workLocation: undefined,
    clientContactingStatus: ContactingStatus.NONE,
    candidateContactingStatus: ContactingStatus.NONE,
    agencyId: positionStudy?.agencyId ?? '',
    structuredMissionHours:
      positionStudy?.structuredMissionHours.length !== 0
        ? positionStudy?.structuredMissionHours
        : initialState.processedMission.structuredMissionHours,
    contact: positionStudy.customerContact?.id
      ? {
          ...positionStudy?.customerContact,
          civility: { label: positionStudy?.customerContact?.civility },
        }
      : undefined,
  };
  return transformedMission;
};

const { reducer, actions } = createSlice({
  name: 'missionCreation',
  initialState,
  reducers: {
    reset: () => initialState,

    updateSelectedWorkingTime: (
      state,
      action: PayloadAction<{ workingTime: DayTimes; index: number }>
    ) => {
      state.processedMission.structuredMissionHours![
        action.payload.index
      ].startTime = timeInputToOSM(action.payload.workingTime.startTime);
      state.processedMission.structuredMissionHours![action.payload.index].endTime = timeInputToOSM(
        action.payload.workingTime.endTime
      );
      state.processedMission.structuredMissionHours![action.payload.index].days =
        action.payload.workingTime.selectedDays;
    },
    addWorkingTime: state => {
      state.processedMission.structuredMissionHours?.push({ days: [], startTime: 0, endTime: 0 });
    },
    eraseWorkingTime: (state, action: PayloadAction<{ index: number }>) => {
      state.processedMission.structuredMissionHours?.splice(action.payload.index, 1);
    },
    setSelectedCompany: (state, action: PayloadAction<MissionCreationState['selectedCompany']>) => {
      state.selectedCompany = action.payload;
    },
    setSelectedQualification: (
      state,
      action: PayloadAction<MissionCreationState['selectedQualification']>
    ) => {
      state.selectedQualification = action.payload;
    },
    setSelectedService: (state, action: PayloadAction<{ service: CompanyService }>) => {
      state.processedMission.service = action.payload.service
        ? {
            id: action.payload.service.serviceId,
            name: action.payload.service.name,
          }
        : undefined;
    },
    setSelectedContact: (state, action: PayloadAction<{ contact: CompanyContact }>) => {
      state.processedMission.contact = action.payload.contact;
      state.processedMission.contactId = action.payload.contact.contactId;
    },
    setSelectedWorkLocation: (
      state,
      action: PayloadAction<{
        workLocation: OSMCommonModelLocationModelOutputLocationDto | undefined;
      }>
    ) => {
      state.processedMission.workLocation = action.payload.workLocation;
    },
    setSelectedRequestReason: (
      state,
      action: PayloadAction<{ requestReason?: CompanyRequestReason }>
    ) => {
      state.processedMission.reasonId = action.payload.requestReason?.id;
      state.processedMission.reasonLabel = action.payload.requestReason?.label;
    },
    setSelectedCreationWay: (
      state,
      action: PayloadAction<MissionCreationState['selectedCreationWay']>
    ) => {
      state.selectedCreationWay = action.payload;
    },
    setSelectedPreviousMission: (
      state,
      action: PayloadAction<MissionCreationState['selectedPreviousMission']>
    ) => {
      state.selectedPreviousMission = action.payload;
    },
    resetSelectedPreviousMission: state => {
      state.selectedPreviousMission = undefined;
    },

    setSelectedPreviousPositionStudy: (
      state,
      action: PayloadAction<MissionCreationState['selectedPreviousPositionStudy']>
    ) => {
      state.selectedPreviousPositionStudy = action.payload;
    },
    resetSelectedPreviousPositionStudy: state => {
      state.selectedPreviousPositionStudy = undefined;
    },

    setSelectedPreviousPositionStudyForNewMission: state => {
      state.processedMission = cleanExistingPositionStudyForCreation(
        state.selectedPreviousPositionStudy!
      );
    },

    resetSelectedCreationWay: state => {
      state.selectedCreationWay = undefined;
    },
    setProcessedMission: (
      state,
      action: PayloadAction<MissionCreationState['processedMission']>
    ) => {
      state.processedMission = action.payload;
    },
    resetProcessedMission: state => {
      state.processedMission = initialState.processedMission;
      state.numberPositions = initialState.numberPositions;
      state.missionDataFetchStatus = FETCH_STATUS.FULFILLED;
    },
    setIsFromYouplan: state => {
      state.isFromYouplanMission = true;
    },
    resetIsFromYouplan: state => {
      state.isFromYouplanMission = false;
    },
    setYouplanMissionIds: (
      state,
      action: PayloadAction<MissionCreationState['youplanMissionIds']>
    ) => {
      state.youplanMissionIds = action.payload;
    },
    setYouPlanMissionCandidates: (
      state,
      action: PayloadAction<MissionCreationState['youplanMissionCandidates']>
    ) => {
      state.youplanMissionCandidates = action.payload;
    },
    setNumberPositions: (state, action: PayloadAction<MissionCreationState['numberPositions']>) => {
      state.numberPositions = action.payload;
    },
    setCandidateId: (state, action: PayloadAction<MissionCreationState['candidateId']>) => {
      state.candidateId = action.payload;
    },
    setIsFromMyRegion: state => {
      state.isFromMyRegion = true;
    },
    resetIsFromMyRegion: state => {
      state.isFromMyRegion = false;
    },
    setIsTalent: state => {
      state.isTalent = true;
    },
    resetIsTalent: state => {
      state.isTalent = false;
    },
  },
  extraReducers: builder => {
    // Search missions
    builder.addCase(searchPreviousMissions.pending, (state, action) => {
      state.missionsFetchStatus = FETCH_STATUS.PENDING;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(searchPreviousMissions.fulfilled, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentRequestId === action.meta.requestId) {
        state.previousMissions = action.payload;
        state.missionsFetchStatus = FETCH_STATUS.FULFILLED;
      }
    });
    builder.addCase(searchPreviousMissions.rejected, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentRequestId === action.meta.requestId) {
        state.missionsFetchStatus = FETCH_STATUS.REJECTED;
      }
    });
    // Fetch mission detail
    builder.addCase(fetchPreviousMissionDetail.pending, state => {
      state.missionDataFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(fetchPreviousMissionDetail.fulfilled, (state, action) => {
      state.processedMission = {
        ...cleanExistingMissionForCreation(action.payload),
        candidates: undefined,
        startHour: undefined,
        startDate: undefined,
        endDate: undefined,
      };
      state.missionDataFetchStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(fetchPreviousMissionDetail.rejected, state => {
      state.missionDataFetchStatus = FETCH_STATUS.REJECTED;
    });

    // Create new mission
    builder.addCase(createMissionWithoutSchedule.pending, state => {
      state.missionCreationFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(createMissionWithoutSchedule.fulfilled, (state, action) => {
      state.newMissionId = action.payload;
      state.missionCreationFetchStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(createMissionWithoutSchedule.rejected, state => {
      state.missionCreationFetchStatus = FETCH_STATUS.REJECTED;
    });

    // Create new mission from youplan
    builder.addCase(createMissionWithoutScheduleFromYouplan.pending, state => {
      state.missionCreationFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(createMissionWithoutScheduleFromYouplan.fulfilled, (state, action) => {
      state.newMissionId = action.payload;
      state.missionCreationFetchStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(createMissionWithoutScheduleFromYouplan.rejected, state => {
      state.missionCreationFetchStatus = FETCH_STATUS.REJECTED;
    });

    // Fetch youplan mission detail
    builder.addCase(fetchYouplanMissionForNewMission.pending, state => {
      state.missionDataFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(fetchYouplanMissionForNewMission.fulfilled, (state, action) => {
      state.processedMission = cleanExistingMissionForCreation(action.payload);
      state.selectedCompany = {
        ...action.payload.company,
        companyName: action.payload.company?.name,
      };
      state.selectedQualification = action.payload.qualification;
      // we need to manually specify the selected company based on the Youplan mission infos
      state.selectedCompany = {
        companyId: action.payload.company?.companyId,
        companyName: action.payload.company?.name,
        siret: action.payload.company?.siret,
        companyAddress: {
          address1: action.payload.company?.location?.address,
          city: action.payload.company?.location?.cityName,
          postalCode: action.payload.company?.location?.zipCode,
        },
      };
      state.missionDataFetchStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(fetchYouplanMissionForNewMission.rejected, state => {
      state.missionDataFetchStatus = FETCH_STATUS.REJECTED;
    });

    // Fetch positionStudies
    builder.addCase(searchPreviousPositionStudies.pending, (state, action) => {
      state.positionStudiesFetchStatus = FETCH_STATUS.PENDING;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(searchPreviousPositionStudies.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.previousPositionStudies = action.payload;
        state.positionStudiesFetchStatus = FETCH_STATUS.FULFILLED;
      }
    });
    builder.addCase(searchPreviousPositionStudies.rejected, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.positionStudiesFetchStatus = FETCH_STATUS.REJECTED;
      }
    });

    // Get count of Position Studies
    builder.addCase(getPositionStudiesCount.pending, state => {
      state.positionStudiesCountFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getPositionStudiesCount.fulfilled, (state, action) => {
      state.positionStudiesCount = action.payload;
      state.positionStudiesCountFetchStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(getPositionStudiesCount.rejected, state => {
      state.positionStudiesCountFetchStatus = FETCH_STATUS.REJECTED;
    });
  },
});

export const missionCreationReducer = reducer;
export const missionCreationActions = actions;
