import { useEffect, useState } from 'react';

import {
  AddButton,
  FileDropzone,
  HorizontalCardWithTitle,
  PopupMenu,
  TextArea,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import styles from './Description.module.scss';

import {
  Pencil,
  PlusCircle,
  ThreeDots,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import ContentLoader from 'react-content-loader';
import { useController, UseFormReturn } from 'react-hook-form';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { hurtPerson } from 'src/Assets_DEPRECATED';
import EnvironmentRisksSelection from 'src/Components/EnvironmentRisksSelection/EnvironmentRisksSelection.component';
import RiskSelection from 'src/Components/RiskSelection';
import { useDeleteWorkAccidentFile } from 'src/Hooks/WorkAccidentAnalysis/useDeleteWorkAccidentFile';
import { useFetchWorkAccidentByIds } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentByIds';
import { useFetchWorkAccidentFiles } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentFiles';
import { useFetchWorkAccidentFileData } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentFilesData';
import { useUpdateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentAnalysis';
import { useUploadWorkAccidentFile } from 'src/Hooks/WorkAccidentAnalysis/useUploadWorkAccidentFiles';
import { FETCH_STATUS } from 'src/Redux/Types';
import { ROUTES } from 'src/Routes/Routes.types';
import { EnumWorkAccidentAnalysisStatus, YesNoUnknown } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { bytesToSize } from 'src/Utils/bytesToSize';
import { onFileOpen } from 'src/Utils/onFileOpen';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { descriptionSchema, descriptionSchemaType } from './Description.schema';
import { DropzoneItem, UploadStatus } from './Description.types';
import LesionDescriptionModal from './LesionsDescription';
import ProtectionEquipments from './ProtectionEquipment/ProtectionEquipments.component';

export const DescriptionRoute = () => {
  const { analysisId } = useTypedParams(
    ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.DESCRIPTION
  );
  const currentAnalysis = useFetchWorkAccidentByIds({ analyseIds: [analysisId] });
  const isEditable =
    currentAnalysis.data?.[0].status === EnumWorkAccidentAnalysisStatus.IN_PROGRESS;
  const currentAnalysisFetchStatus = toFetchStatus(currentAnalysis);
  const { data, isLoading, isError, isSuccess } = useFetchWorkAccidentFiles(analysisId);
  const fetchWorkAccidentFileData = useFetchWorkAccidentFileData();
  const updateWorkAnalysisMutation = useUpdateWorkAccidentAnalysis();
  const uploadMutation = useUploadWorkAccidentFile();
  const deleteWorkAnalysisFile = useDeleteWorkAccidentFile();

  const [openEditModal, setOpenEditModal] = useState<number | null>(null);

  const [files, setFiles] = useState(
    data
      ? data.map(
          file =>
            ({
              id: file.id,
              subtitle: bytesToSize(file.size),
              file: {
                name: file.name,
                size: file.size,
                type: file.mimetype,
              } as File,
              status: UploadStatus.None,
            } as DropzoneItem)
        )
      : []
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: descriptionSchema,
    defaultValues: {
      beforeAccident: undefined,
      duringAccident: undefined,
      afterAccident: undefined,
      injuries: undefined,
      risks: [],
      environmentRisks: [],
      otherRisks: undefined,
      hasEpis: YesNoUnknown.Unknown,
      epis: [],
      hasEpcs: YesNoUnknown.Unknown,
      mainEpcs: [],
      otherEpcs: undefined,
    },
  }) as UseFormReturn<descriptionSchemaType>;

  const beforeAccident = useController({ control, name: 'beforeAccident' });
  const duringAccident = useController({ control, name: 'duringAccident' });
  const afterAccident = useController({ control, name: 'afterAccident' });
  const risks = useController({ control, name: 'risks' });
  const otherRisks = useController({ control, name: 'otherRisks' });

  const dropzoneData = {
    maxFiles: 20,
    maxSize: 5000000,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/*': ['.jpg', '.png', '.gif'],
    },
    advice: 'ajouter tous document utiles',
  };

  const injuries = useController({ control, name: 'injuries' });

  const onFileDropAction = (items: DropzoneItem[]) => {
    const promises = items
      .filter(item => item.status === UploadStatus.InProgress)
      .map(item => {
        return uploadMutation.mutateAsync({
          id: analysisId,
          file: item.file,
        });
      });
    Promise.all(promises).then(dbFiles => {
      setFiles(
        items.map(item => ({
          ...item,
          id: item.id ?? dbFiles.find(dbFile => dbFile.name === item.file.name)?.id,
          status: item.status === UploadStatus.InProgress ? UploadStatus.Done : item.status,
        }))
      );
    });
  };
  const onFileDropped = (items: DropzoneItem[]) => {
    setFiles(items);
    if (items.length > 0) {
      onFileDropAction(items);
    } else {
      setFiles([]);
    }
  };

  const onFileDeleted = async (item: DropzoneItem) => {
    const result = await deleteWorkAnalysisFile.mutateAsync({
      id: item.id ?? '',
    });
    if (result) setFiles(files.filter(deletedItem => item.file.name !== deletedItem.file.name));
  };

  useEffect(
    () => {
      if (
        currentAnalysisFetchStatus === FETCH_STATUS.FULFILLED &&
        currentAnalysis.data?.[0].description !== undefined
      ) {
        setValue('beforeAccident', currentAnalysis.data?.[0].description?.beforeAccident);
        setValue('duringAccident', currentAnalysis.data?.[0].description?.duringAccident);
        setValue('afterAccident', currentAnalysis.data?.[0].description?.afterAccident);
        setValue(
          'risks',
          currentAnalysis.data?.[0].description?.risks?.map(risk => {
            return { id: risk.id, label: risk.label ?? '' };
          }) ?? []
        );
        setValue(
          'environmentRisks',
          currentAnalysis.data?.[0].description?.environmentRisks?.map(risk => {
            return { id: risk.id, label: risk.label ?? '' };
          }) ?? []
        );
        setValue('otherRisks', currentAnalysis.data[0].description?.otherRisks);
        setValue('injuries', currentAnalysis.data[0].description?.injuries ?? []);
        setValue('hasEpis', currentAnalysis.data[0].description?.hasEpis ?? YesNoUnknown.Unknown);
        setValue('epis', currentAnalysis.data[0].description?.epis ?? []);
        setValue('hasEpcs', currentAnalysis.data[0].description?.hasEpcs ?? YesNoUnknown.Unknown);
        setValue('mainEpcs', currentAnalysis.data[0].description?.mainEpcs ?? []);
        setValue('otherEpcs', currentAnalysis.data[0].description?.otherEpcs);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAnalysisFetchStatus]
  );

  useEffect(
    () => {
      if (data && isSuccess) {
        setFiles(
          data.map(
            file =>
              ({
                id: file.id,
                subtitle: bytesToSize(file.size),
                file: {
                  name: file.name,
                  size: file.size,
                  type: file.mimetype,
                } as File,
                status: UploadStatus.None,
              } as DropzoneItem)
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const descriptionHandleSubmit = () =>
    handleSubmit((values: descriptionSchemaType) => {
      isEditable &&
        updateWorkAnalysisMutation.mutate({
          analyseId: analysisId,
          description: {
            afterAccident: values.afterAccident,
            beforeAccident: values.beforeAccident,
            duringAccident: values.duringAccident,
            risks: values.risks,
            environmentRisks: values.environmentRisks,
            otherRisks: values.otherRisks,
            injuries: values.injuries,
            hasEpis:
              values.hasEpis === YesNoUnknown.Yes && values.epis.length === 0
                ? YesNoUnknown.Unknown
                : values.hasEpis,
            epis: values.hasEpis === YesNoUnknown.Yes ? values.epis : [],
            hasEpcs:
              values.hasEpcs === YesNoUnknown.Yes &&
              values.mainEpcs.length === 0 &&
              !values.otherEpcs
                ? YesNoUnknown.Unknown
                : values.hasEpcs,
            mainEpcs: values.hasEpcs === YesNoUnknown.Yes ? values.mainEpcs : [],
            otherEpcs: values.hasEpcs === YesNoUnknown.Yes ? values.otherEpcs : undefined,
          },
        });
    });

  useEffect(
    () => {
      if (currentAnalysisFetchStatus === FETCH_STATUS.FULFILLED && isDirty) {
        descriptionHandleSubmit()();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [risks.field.value, injuries.field.value]
  );

  return (
    <div className={styles.page}>
      <div className={styles.titleContainer}>2.</div>
      <div className={styles.content}>
        <div className={styles.title}>description</div>
        <div className={styles.subTitle}>faits sur l'accident</div>
        <WithLightTitle
          title="décrivez ce que faisait le salarié avant l’accident"
          className={styles.lightTitle}
          rightTitleComponent={
            isEditable ? (
              <div className={styles.rightTitle}>
                {beforeAccident.field.value?.length ?? 0}/{REPORT_MAX_LENGTH} caractères
              </div>
            ) : (
              <></>
            )
          }
          children={
            isEditable ? (
              <TextArea
                value={beforeAccident.field.value}
                className={styles.textArea}
                placeholder={'en quelques mots…'}
                maxLength={REPORT_MAX_LENGTH}
                onChange={(event: any) =>
                  beforeAccident.field.onChange((event.target as HTMLTextAreaElement).value)
                }
                onBlurCapture={descriptionHandleSubmit()}
              />
            ) : (
              <div>{beforeAccident.field.value ?? 'non renseigné'}</div>
            )
          }
        />
        <WithLightTitle
          title="décrivez ce qui s’est passé au moment de l’accident"
          className={styles.lightTitle}
          rightTitleComponent={
            isEditable ? (
              <div className={styles.rightTitle}>
                {duringAccident.field.value?.length ?? 0}/{REPORT_MAX_LENGTH} caractères
              </div>
            ) : (
              <></>
            )
          }
          children={
            isEditable ? (
              <TextArea
                value={duringAccident.field.value}
                className={styles.textArea}
                placeholder={'en quelques mots…'}
                maxLength={REPORT_MAX_LENGTH}
                onChange={(event: any) =>
                  duringAccident.field.onChange((event.target as HTMLTextAreaElement).value)
                }
                onBlurCapture={descriptionHandleSubmit()}
              />
            ) : (
              <div>{duringAccident.field.value ?? 'non renseigné'}</div>
            )
          }
        />
        <WithLightTitle
          title="décrivez ce qu’a fait la victime immédiatement après l’accident"
          className={styles.lightTitle}
          rightTitleComponent={
            isEditable ? (
              <div className={styles.rightTitle}>
                {afterAccident.field.value?.length ?? 0}/{REPORT_MAX_LENGTH} caractères
              </div>
            ) : (
              <></>
            )
          }
          children={
            isEditable ? (
              <TextArea
                value={afterAccident.field.value}
                className={styles.textArea}
                placeholder={'en quelques mots…'}
                maxLength={REPORT_MAX_LENGTH}
                onChange={(event: any) =>
                  afterAccident.field.onChange((event.target as HTMLTextAreaElement).value)
                }
                onBlurCapture={descriptionHandleSubmit()}
              />
            ) : (
              <div>{afterAccident.field.value ?? 'non renseigné'}</div>
            )
          }
        />
        <WithLightTitle
          title="ajouter une photo, un croquis"
          className={styles.lightTitle}
          rightTitleComponent={
            isEditable ? (
              <div className={styles.rightTitle}>{pluralFormat(files.length, 'fichier')}</div>
            ) : (
              <></>
            )
          }
          children={
            isEditable ? (
              <>
                {isLoading && (
                  <div>
                    <ContentLoader height="4.5rem" width="100%" uniqueKey="documents">
                      <rect x="2%" y="10" rx="4" ry="4" width="50%" height="10" />
                      <rect x="2%" y="30" rx="4" ry="4" width="80%" height="10" />
                      <rect x="2%" y="50" rx="4" ry="4" width="80%" height="10" />
                    </ContentLoader>
                  </div>
                )}
                {isError && <div>erreur lors du chargement...</div>}
                {isSuccess && (
                  <FileDropzone
                    {...dropzoneData}
                    items={files}
                    onUpload={onFileDropped}
                    onDelete={item => {
                      if (item.id) onFileDeleted(item);
                    }}
                    onClick={item => {
                      if (item.id) {
                        fetchWorkAccidentFileData.mutate(item.id, {
                          onSuccess: buffer => onFileOpen(buffer, item.file.type),
                        });
                      } else {
                        window.open(URL.createObjectURL(item.file));
                      }
                    }}
                  />
                )}
              </>
            ) : (
              <div>
                {files.map(file => (
                  <div>{`${file.file.name} (${Math.round((file.file.size ?? 0) / 10e5)}Mo)`}</div>
                ))}
                {files.length === 0 && <div>aucun document</div>}
              </div>
            )
          }
        />

        <div className={styles.divider} />

        <div className={styles.subTitle}>descriptions des lésions</div>

        {injuries?.field?.value?.length > 0 && (
          <WithLightTitle title="liste des lésions décrites" className={styles.lightTitle}>
            {injuries?.field.value.map((injury, index) => (
              <>
                <HorizontalCardWithTitle
                  key={index}
                  className={classnames(styles.horizontalCard, {
                    [styles.visualisationHorizontalCard]: !isEditable,
                  })}
                  title={`${injury.injuryLocation?.label}, ${injury.injuryLocationDetails?.label}, ${injury.injuryLaterality}`}
                  subtitles={[injury.injuryNatures?.map(nature => nature.label).join(' | ') ?? '']}
                  rightActions={
                    isEditable ? (
                      <PopupMenu
                        trigger={
                          <button className={styles.triggerContainer}>
                            <ThreeDots />
                          </button>
                        }
                        position="left top"
                      >
                        <PopupMenu.Item
                          icon={<Pencil />}
                          text="modifier la lésion"
                          onClick={() => setOpenEditModal(index)}
                        />

                        <PopupMenu.Item
                          key={'delete'}
                          onClick={() => {
                            const newValues = [...injuries.field.value];
                            newValues.splice(index, 1);
                            injuries.field.onChange(newValues);
                          }}
                          icon={<Trashcan />}
                          text="supprimer"
                        />
                      </PopupMenu>
                    ) : (
                      <></>
                    )
                  }
                />
                {isEditable && (
                  <LesionDescriptionModal
                    key={index}
                    injury={injury}
                    onValidate={updatedInjuryValues => {
                      const newValues = [...injuries.field.value];
                      newValues[index] = updatedInjuryValues;
                      injuries.field.onChange(newValues);
                      setOpenEditModal(null);
                    }}
                    open={openEditModal === index}
                  />
                )}
              </>
            ))}
          </WithLightTitle>
        )}

        {!injuries?.field?.value?.length && (
          <div className={styles.lesionCard}>
            <img src={hurtPerson} alt="no-result" className={styles.lesionIcon} />
            <div className={styles.lesionCardText}>
              <div className={styles.lesionCardTitle}>aucune description de lésions</div>
              {isEditable && (
                <LesionDescriptionModal
                  trigger={<div className={styles.lesionCardLink}>ajouter un type de lésion</div>}
                  onValidate={updatesInjuryValues => {
                    injuries.field.onChange([...(injuries.field.value ?? []), updatesInjuryValues]);
                  }}
                />
              )}
            </div>
          </div>
        )}

        {isEditable && (
          <LesionDescriptionModal
            trigger={<AddButton text={`ajouter un type de lésion`} icon={<PlusCircle />} />}
            onValidate={updatesInjuryValues => {
              injuries.field.onChange([...(injuries.field.value ?? []), updatesInjuryValues]);
            }}
          />
        )}

        <div className={styles.divider} />

        <div className={styles.subTitle}>risques</div>

        <RiskSelection
          isEditable={isEditable}
          title="sélectionner parmi ces risques potentiels du poste"
          control={control}
          submit={descriptionHandleSubmit()}
        />
        <EnvironmentRisksSelection
          isEditable={isEditable}
          title="sélectionner parmi ces risques liés à l’environnement de travail"
          control={control}
          submit={descriptionHandleSubmit()}
        />
        <WithLightTitle
          title="ou précisez d'autres risques"
          className={styles.lightTitle}
          rightTitleComponent={
            isEditable ? (
              <div className={styles.rightTitle}>
                {otherRisks.field.value?.length ?? 0}/{REPORT_MAX_LENGTH} caractères
              </div>
            ) : (
              <></>
            )
          }
          children={
            isEditable ? (
              <TextArea
                value={otherRisks.field.value}
                className={styles.textArea}
                placeholder={'en quelques mots…'}
                maxLength={REPORT_MAX_LENGTH}
                onChange={(event: any) =>
                  otherRisks.field.onChange((event.target as HTMLTextAreaElement).value)
                }
                onBlurCapture={descriptionHandleSubmit()}
              />
            ) : (
              <div>{otherRisks.field.value ?? 'non renseigné'}</div>
            )
          }
        />

        <ProtectionEquipments
          isEditable={isEditable}
          control={control}
          watch={watch}
          submit={descriptionHandleSubmit()}
          isDirty={isDirty}
        />
      </div>
    </div>
  );
};
