import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { CandidatesService, RefuseSuggestedCandidateParams } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

export const useRefuseSuggestedCCdii = (
  extraOptions?: UseMutationOptions<void, unknown, RefuseSuggestedCandidateParams, MutationKeys[]>
) => {
  const queryClient = useQueryClient();
  const userInfo = useSelector(getAnalyticsUserInfo);

  return useMutation(
    [MutationKeys.refuseSuggestedCCdii],
    async (params: RefuseSuggestedCandidateParams & { candidateId: string }) => {
      return await CandidatesService.candidatesControllerRefuseSuggestedCandidate({
        id: params.candidateId,
        body: params,
      });
    },
    {
      ...extraOptions,
      onSuccess: (...args) => {
        ReactGA.event(ANALYTICS_EVENT.CLICK_ORDER_SUGGEST_REFUSAL, {
          ...userInfo,
          reason: args[1].reason,
        });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchSuggestedCandidates] });
        queryClient.resetQueries({ queryKey: [QueryKeys.fetchCandidatesActivityPlanning] });
        queryClient.resetQueries({ queryKey: [QueryKeys.fetchCandidateActivityPlanning] });
      },
    }
  );
};
