import {
  Button,
  Checkbox,
  SegmentedMenu,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TopBar } from 'src/Components/TopBar';
import { useFetchAgenciesDetails } from 'src/Hooks/Agencies/useFetchAgenciesDetails';
import useFetchCGC from 'src/Hooks/Agencies/useFetchCGC';
import { useFetchCandidateQualifications } from 'src/Hooks/Candidate/useFetchCandidateQualifications';
import useFetchDepartments from 'src/Hooks/Departments/useFetchDepartments';
import { useFetchRepositioningMissions } from 'src/Hooks/Missions/useFetchRepositioningMissions';
import { useFetchAgenciesInfoFromCGC } from 'src/Hooks/SkillsManagementCenters/useFetchAgenciesInfoFromCGC';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import DepartmentsPopupMenu from 'src/Routes/Talents/Candidates/RepositioningModal/DepartmentsPopupMenu';
import QualificationsPopupMenu from 'src/Routes/Talents/Candidates/RepositioningModal/QualificationsPopupMenu';
import { AgencyDetails, Department } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { mergeFetchStatus } from 'src/Utils/fetch-status';
import FiltersMenu from '../FiltersMenu';
import CGCAgencyPropositionModal from './CGCAgencyPropositionModal/CGCAgencyPropositionModal.component';
import { DistanceFilter } from 'src/Components/DistanceFilter';
import styles from './Repositioning.module.scss';
import { Props, REPOSITIONING_CDII_TAB } from './Repositioning.types';
import RepositioningInternPotentialOffers from './RepositioningInternPotentialOffers/RepositioningInternPotentialOffers.component';
import RepositioningMissions from './RepositioningMissions';
import RepositioningPotentialOffers from './RepositioningPotentialOffers/RepositioningPotentialOffers.component';
import RepositioningSkillMatching from './RepositioningSkillMatching';

const Repositioning = ({ candidateData, statut, dashboardSection }: Props) => {
  const departmentsQuery = useFetchDepartments();
  const departmentsFetchStatus = toFetchStatus(departmentsQuery);
  const qualificationQuery = useFetchCandidateQualifications(candidateData.id);
  const [selectedQualifications, setSelectedQualifications] = useState<string[]>(
    qualificationQuery.data?.map(qualification => qualification.id ?? '') ?? []
  );

  const cgcQuery = useFetchCGC();
  const { data: isCGC } = useIsCGC();

  const [selectedTab, setSelectedTab] = useState(REPOSITIONING_CDII_TAB.COMMANDES);
  const [selectedDepartments, setSelectedDepartments] = useState<Department[] | null>(null);
  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  const [selectedAgencies, setSelectedAgencies] = useState([currentAgency ?? '']);
  const [selectedAgenciesIntern, setSelectedAgenciesIntern] = useState<AgencyDetails[]>([]);
  const cgcName = useSelector(getCurrentAgency) ?? '';
  const agencies = useFetchAgenciesInfoFromCGC(cgcName);
  const agenciesIds = agencies.data?.map(agency => {
    return agency.id;
  });
  const { data: agenciesDetails, isSuccess, isLoading, isError } = useFetchAgenciesDetails(
    agenciesIds ?? []
  );
  const fetchStatus = mergeFetchStatus(toFetchStatus({ isError, isLoading, isSuccess }));

  const [filteredDistance, setFilteredDistance] = useState<number>(110);
  const { data: missionsWithCount } = useFetchRepositioningMissions({
    qualificationIds:
      selectedQualifications.length > 0
        ? selectedQualifications?.map(qualification => qualification ?? '')
        : undefined,
    agencies: selectedAgencies,
    candidateId: candidateData.id ?? '',
  });
  const distanceMissions: number[] = (missionsWithCount?.pages.map(page => page.missions!) ?? [])
    .flat(1)
    .filter(mission => moment(mission.endDate).isAfter(moment()) || mission.endDate === undefined)
    .map(mission => mission.distanceToTT ?? 0);
  useEffect(() => {
    setSelectedAgencies([currentAgency ?? '']);
  }, [currentAgency]);

  useEffect(
    () =>
      setSelectedDepartments(
        departmentsQuery.data?.filter(
          dep => dep.id === candidateData.candidateAddress?.candidatePostalCode?.slice(0, 2)
        ) ?? null
      ),
    [candidateData.candidateAddress?.candidatePostalCode, departmentsQuery.data]
  );

  useEffect(() => {
    if (
      qualificationQuery.isSuccess &&
      qualificationQuery.data !== undefined &&
      selectedQualifications.length === 0
    ) {
      setSelectedQualifications(
        qualificationQuery.data?.map(qualification => qualification.id ?? '')
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualificationQuery.data, qualificationQuery.isSuccess]);

  const displayedSection = useMemo((): React.ReactNode => {
    switch (selectedTab) {
      case REPOSITIONING_CDII_TAB.COMMANDES:
        return (
          <RepositioningMissions
            isCGC={isCGC ?? false}
            statut={statut}
            selectedQualifications={selectedQualifications}
            candidateData={candidateData}
            selectedAgencies={selectedAgencies}
            filteredDistance={filteredDistance}
            dashboardSection={dashboardSection}
          />
        );

      case REPOSITIONING_CDII_TAB.QUALIFICATION_RANDSTAD:
        return (
          <RepositioningPotentialOffers
            brandCode="001VB"
            isCGC={isCGC ?? false}
            candidateId={candidateData.id ?? ''}
            selectedQualifications={selectedQualifications}
            selectedDepartments={selectedDepartments}
          />
        );

      case REPOSITIONING_CDII_TAB.QUALIFICATION_INTERNE:
        return (
          <RepositioningInternPotentialOffers
            candidateData={candidateData}
            selectedAgencies={selectedAgenciesIntern}
            setSelectedAgencies={setSelectedAgenciesIntern}
            agenciesDetails={agenciesDetails}
            fetchStatus={fetchStatus}
          />
        );
      case REPOSITIONING_CDII_TAB.SKILL_MATCHING:
        return (
          <RepositioningSkillMatching
            candidateId={candidateData.id ?? ''}
            candidateData={candidateData}
            statut={statut}
            selectedDepartments={selectedDepartments ?? []}
            selectedQualifications={selectedQualifications}
          />
        );
    }
  }, [
    selectedTab,
    isCGC,
    statut,
    selectedQualifications,
    candidateData,
    selectedAgencies,
    filteredDistance,
    dashboardSection,
    selectedDepartments,
    selectedAgenciesIntern,
    agenciesDetails,
    fetchStatus,
  ]);

  return (
    <div className={styles.container}>
      <TopBar
        title={`repositionnement de ${candidateData.firstName} ${candidateData.name} (${
          candidateData.lastContract?.agency?.id ?? 'non précisé'
        }) - ${candidateData.candidateAddress?.candidateCityName} (${
          candidateData.candidateAddress?.candidatePostalCode
        })`}
        goBackTo={
          candidateData.id &&
          ROUTES.TALENTS.CDII.buildPath({}, {}, [dashboardSection, candidateData.id].join('/'))
        }
        disablePerimeterSelection
      />

      {isCGC && (
        <SegmentedMenu
          selected={selectedTab}
          controls={Object.values(REPOSITIONING_CDII_TAB)}
          onSelectionChange={setSelectedTab}
          keyValueExtractor={tab => ({ key: tab, value: tab })}
          controlItemClassName={styles.controlsLabel}
          bottomBarClassName={styles.bottomMenuBar}
          className={styles.menuContainer}
        />
      )}

      {cgcQuery.isSuccess && (
        <div className={styles.missionContainer}>
          <div className={styles.missionsHeader}>
            {selectedTab === REPOSITIONING_CDII_TAB.QUALIFICATION_INTERNE && (
              <div className={styles.header}>
                <div className={styles.mainCheckbox}>
                  <Checkbox
                    checked={selectedAgenciesIntern.length !== 0}
                    halfChecked={selectedAgenciesIntern.length > 0}
                    onClick={() => {
                      selectedAgenciesIntern.length
                        ? setSelectedAgenciesIntern([])
                        : setSelectedAgenciesIntern(agenciesDetails ?? []);
                    }}
                  />
                </div>
                <CGCAgencyPropositionModal
                  candidateData={candidateData}
                  selectedAgencies={selectedAgenciesIntern}
                  buttonOn={true}
                />
              </div>
            )}
            <div className={styles.filterButton}>
              {selectedTab !== REPOSITIONING_CDII_TAB.COMMANDES &&
                selectedTab !== REPOSITIONING_CDII_TAB.SKILL_MATCHING && (
                  <DepartmentsPopupMenu
                    allDepartments={departmentsQuery.data ?? []}
                    departmentsFetchStatus={departmentsFetchStatus}
                    selectedDepartments={selectedDepartments ?? []}
                    setSelectedDepartments={setSelectedDepartments}
                  />
                )}
              {!isCGC &&
                currentBrand?.agencies !== undefined &&
                currentBrand.agencies.length <= 10 && (
                  <Button
                    className={styles.perimeterButton}
                    onClick={() =>
                      selectedAgencies.length > 1
                        ? setSelectedAgencies([currentAgency ?? ''])
                        : setSelectedAgencies(currentBrand.agencies ?? [])
                    }
                  >
                    {selectedAgencies.length > 1
                      ? ` revenir à ${currentAgency}`
                      : 'élargir à tout mon périmètre'}
                  </Button>
                )}
              {selectedTab !== REPOSITIONING_CDII_TAB.SKILL_MATCHING && (
                <DistanceFilter
                  filteredDistance={filteredDistance}
                  setFilteredDistance={setFilteredDistance}
                  data={distanceMissions}
                />
              )}
              {selectedTab !== REPOSITIONING_CDII_TAB.QUALIFICATION_INTERNE &&
                selectedTab !== REPOSITIONING_CDII_TAB.SKILL_MATCHING && (
                  <QualificationsPopupMenu
                    allQualifications={qualificationQuery.data ?? []}
                    setSelectedQualifications={setSelectedQualifications}
                    selectedQualifications={selectedQualifications}
                  />
                )}
              <FiltersMenu isRepositioning={true} />
            </div>
          </div>
          <div
            className={
              selectedTab === REPOSITIONING_CDII_TAB.COMMANDES ||
              selectedTab === REPOSITIONING_CDII_TAB.SKILL_MATCHING
                ? styles.listContainer
                : styles.gridContainer
            }
          >
            {displayedSection}
          </div>
        </div>
      )}
    </div>
  );
};

export default Repositioning;
