import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/Hooks/types';
import { YouplanMissionsService } from 'src/Services/API';

export const useFetchYouplanMissionDetail = ({ missionId }: { missionId?: string }) => {
  return useQuery(
    [QueryKeys.fetchYouplanMissionDetail, missionId],
    () => {
      return YouplanMissionsService.youplanMissionsControllerGetYouplanMissionDetail({
        id: missionId!,
      });
    },
    { enabled: missionId !== undefined }
  );
};
