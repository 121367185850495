import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCandidateActivityPlanning = ({
  candidateId,
  startDate,
}: {
  candidateId: string;
  startDate: Date;
}) => {
  const agencyId = useSelector(getCurrentAgency) ?? '';
  const brandCode = useSelector(getCurrentBrandCode) ?? '';
  return useQuery(
    [QueryKeys.fetchCandidateActivityPlanning, agencyId, brandCode, candidateId, startDate],
    async () => {
      const candidate = await CandidatesService.candidatesControllerGetCandidateActivityPlanning({
        startDate: moment(startDate).toISOString(),
        candidateId,
      });
      return candidate;
    },
    { refetchOnWindowFocus: false, enabled: candidateId !== undefined }
  );
};
