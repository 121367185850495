import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { AttachCandidateToMissionsBody, MissionsService } from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';

export const useAddCandidateToMissions = (
  extraOptions?: UseMutationOptions<
    void,
    unknown,
    { body: Omit<AttachCandidateToMissionsBody, 'consultantId' | 'consultantMail' | 'brandCode'> }
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const queryClient = useQueryClient();

  return useMutation(
    [MutationKeys.updateCandidateStatus],
    async ({
      body,
    }: {
      body: Omit<AttachCandidateToMissionsBody, 'consultantId' | 'consultantMail' | 'brandCode'>;
    }) => {
      if (!currentBrand?.brandCodeApiHeader) {
        throw new Error('No current brand or has an invalid current brand');
      }

      return MissionsService.missionsControllerAddCandidateToMissions({
        body: {
          candidateId: body.candidateId,
          missionIds: body.missionIds,
          brandCode: currentBrand.brandCodeApiHeader,
          isRepositioningTalent: body.isRepositioningTalent,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchRepositioningMissions] });
      },
      ...extraOptions,
    }
  );
};
