import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { QualificationsService } from 'src/Services/API';

import { QueryKeys } from '../types';

const GET_POTENTIAL_QUALIFICATIONS_CLIENTS_LIMIT = 20;

export const useFetchPotentialQualificationsClientsForRepositioning = ({
  mainQualifications,
  departmentIds,
  brandCode,
  isCGC,
}: {
  mainQualifications: string[];
  departmentIds: string[] | undefined;
  brandCode: string;
  isCGC: boolean;
}) => {
  const currentAgency = useSelector(getCurrentAgency);

  return useInfiniteQuery(
    [
      QueryKeys.fetchPotentialQualificationsClientsForRepositioning,
      currentAgency,
      brandCode,
      mainQualifications,
      departmentIds,
    ],

    ({ pageParam: offset = 0 }) => {
      if (!brandCode || !currentAgency) {
        return Promise.reject('No current brand or has an invalid current brand');
      } /// Company search multicriteria de scope
      return QualificationsService.qualificationsControllerGetPotentialQualificationsClients({
        body: {
          agencyId: currentAgency,
          brandCode,
          mainQualifications,
          departmentIds,
          offset,
          limit: GET_POTENTIAL_QUALIFICATIONS_CLIENTS_LIMIT,
        },
      });
    },
    {
      enabled: isCGC && departmentIds !== undefined,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === GET_POTENTIAL_QUALIFICATIONS_CLIENTS_LIMIT
          ? (pages.length - 1) * GET_POTENTIAL_QUALIFICATIONS_CLIENTS_LIMIT + lastPage.length
          : undefined,
    }
  );
};
