import { useQuery } from '@tanstack/react-query';
import { ExternalDemandsService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchAgencyBrandCode = (agencyId: string) => {
  return useQuery([QueryKeys.fetchAgencyBrandCode, agencyId], async () => {
    return ExternalDemandsService.externalDemandsControllerGetAgencyBrandCode({
      agencyId: agencyId ?? '',
    });
  });
};
