import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCompanyRequestReasons,
  getCompanyRequestReasonsFetchStatus,
} from 'src/Redux/Companies/Selectors';
import SelectRequestReason from './SelectRequestReason.component';
import { OwnProps } from './SelectRequestReason.types';

const MemoSelectRequestReason = React.memo(SelectRequestReason);

const EnhancedSelectRequestReason = (ownProps: OwnProps) => {
  const companyRequestReasons = useSelector(getCompanyRequestReasons);
  const requestReasonsFetchStatus = useSelector(getCompanyRequestReasonsFetchStatus);
  const [requestReasons, setRequestReasons] = useState(companyRequestReasons);
  useEffect(() => setRequestReasons(companyRequestReasons), [companyRequestReasons]);
  const _getCompanyRequestReasons = useCallback(
    (searchValue: string) => {
      setRequestReasons(
        companyRequestReasons.filter(requestReason =>
          requestReason.label?.toUpperCase().includes(searchValue.toUpperCase())
        )
      );
    },
    [companyRequestReasons]
  );
  return (
    <MemoSelectRequestReason
      requestReasons={requestReasons}
      requestReasonsFetchStatus={requestReasonsFetchStatus}
      getCompanyRequestReasons={_getCompanyRequestReasons}
      {...ownProps}
    />
  );
};

export default EnhancedSelectRequestReason;
