import {
  IllusFlashTelephone,
  IllusFlashSMS,
  IllusFlashEmail,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { EnumCreateActionBodyActionTarget, EnumCreateActionBodyActionType } from 'src/Services/API';

export const getImage = (type: EnumCreateActionBodyActionType) => {
  switch (type) {
    case EnumCreateActionBodyActionType.CALL:
      return IllusFlashTelephone;
    case EnumCreateActionBodyActionType.EMAIL:
      return IllusFlashEmail;
    case EnumCreateActionBodyActionType.SMS:
      return IllusFlashSMS;
  }
};

export const getLabel = (type: EnumCreateActionBodyActionType) => {
  switch (type) {
    case EnumCreateActionBodyActionType.CALL:
      return 'téléphone';
    case EnumCreateActionBodyActionType.EMAIL:
      return 'email';
    case EnumCreateActionBodyActionType.SMS:
      return 'sms';
  }
};

export const getContactDeepLink = (type: EnumCreateActionBodyActionType, value: string) => {
  switch (type) {
    case EnumCreateActionBodyActionType.CALL:
      return `tel:${value}`;
    case EnumCreateActionBodyActionType.EMAIL:
      return `mailto:${value}`;
    case EnumCreateActionBodyActionType.SMS:
      return `sms:${value}`;
  }
};

export const getActionTargetLabel = (type: EnumCreateActionBodyActionTarget) => {
  switch (type) {
    case EnumCreateActionBodyActionTarget.CANDIDATE:
      return 'intérimaire';
    case EnumCreateActionBodyActionTarget.CONTACT:
      return 'interlocuteur client';
  }
};
