import { AppState, Auth0Provider as RawAuth0Provider } from '@auth0/auth0-react';
import React, { useCallback } from 'react';

export const Auth0Provider = ({ children }: { children: React.ReactNode }) => {
  const onRedirectCallback = useCallback((appState?: AppState) => {
    if (appState?.returnTo) window.location.replace(appState.returnTo);
  }, []);

  return (
    <RawAuth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? '',
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI ?? '',
      }}
    >
      {children}
    </RawAuth0Provider>
  );
};
