import moment from 'moment';
import { useCallback, useMemo, useRef } from 'react';

import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { useFetchCandidatesActivityPlanning } from 'src/Hooks/Candidate/useFetchCandidatesActivityPlanning';
import { useFetchSuggestedCandidates } from 'src/Hooks/Candidate/useFetchSuggestedCandidates';
import RepositioningScreen from 'src/Routes/Talents/Cdii/Repositioning';
import { getEndMission } from 'src/Routes/Talents/Cdii/utils';
import { ROUTES } from 'src/Routes/Routes.types';
import { EnumActivityPlanningDayStatus } from 'src/Services/API';
import styles from './Repositioning.module.scss';

export enum DASHBOARD_MENU {
  REPOSITIONING = 'repositioning',
  REPOSITIONED = 'repositioned',
  REGIONAL = 'regional',
}

export enum CGC_DASHBOARD_MENU {
  REPOSITIONING = 'repositioning',
  REPOSITIONED = 'repositioned',
}

/**
 * TODO: La façon dont le repositionnement c'est actuellement fait c'est n'importe quoi, on charge la liste de tous les CDI de ma région pour trouver celui qu'on veut.
 * Il faudrait aller chercher les infos nécessaires juste à partir du candidateId dans le backend à la place
 */
export const RepositioningRoute = () => {
  const { candidateId } = useTypedParams(ROUTES.TALENTS.CDII.CDII_DETAIL.REPOSITIONING);
  const {
    data: cdii,
    isSuccess: cdiiSuccess,
    isLoading: cdiiLoading,
  } = useFetchCandidatesActivityPlanning();
  const {
    data: regionCdii,
    isLoading: regionCdiiLoading,
    isSuccess: regionCdiiSuccess,
  } = useFetchSuggestedCandidates({});
  const containerRef = useRef<HTMLDivElement>(null);

  const { candidateData, dashboardSection } = useMemo(() => {
    const candidate = cdii?.find(cdii => cdii.id === candidateId);
    if (candidate)
      return {
        candidateData: candidate,
        dashboardSection: candidate.cdiiStatus
          ? DASHBOARD_MENU.REPOSITIONED
          : DASHBOARD_MENU.REPOSITIONING,
      };
    let regionCandidate = regionCdii?.find(cdii => cdii.id === candidateId);
    return { candidateData: regionCandidate, dashboardSection: DASHBOARD_MENU.REGIONAL };
  }, [cdii, candidateId, regionCdii]);

  const getMissionStatus = useCallback(
    (
      missionEnd: { year?: number; month?: number; day?: number } | undefined,
      statut?: EnumActivityPlanningDayStatus
    ) => getEndMission(missionEnd, statut),
    []
  );

  const isLoading = regionCdiiLoading || cdiiLoading;
  const isSuccess = regionCdiiSuccess && cdiiSuccess;

  return (
    <div className={styles.containerWithModal} ref={containerRef}>
      {isLoading ? (
        <div className={styles.loadingScreen}>
          <Loader heightInRem={4} className={styles.loader} />
          <p>chargement des CDII-TT, cela peut prendre du temps</p>
        </div>
      ) : isSuccess && candidateData ? (
        <div className={styles.repositioningCDII}>
          <RepositioningScreen
            candidateData={candidateData}
            statut={getMissionStatus(
              candidateData.lastContract?.endDate,
              candidateData.activityPlanning?.find(
                day => moment(day.date)?.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
              )?.status
            )}
            dashboardSection={dashboardSection}
          />
        </div>
      ) : (
        <>Une erreur s'est produite</> // TODO plus beau message d'erreur
      )}
    </div>
  );
};
