import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

import { Candidate, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateDetails = (
  id?: string,
  extraOptions?: UseQueryOptions<Candidate, unknown, Candidate, (string | undefined)[]>
) => {
  const currentBrandCode = useSelector(getCurrentBrandCode);
  return useQuery(
    [QueryKeys.fetchCandidateDetails, id],
    async () => {
      if (!currentBrandCode || !id) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const candidate = await CandidatesService.candidatesControllerGetCandidate({
        id: id,
        brandCode: currentBrandCode,
      });
      return candidate;
    },
    { staleTime: Infinity, enabled: id !== undefined, ...extraOptions }
  );
};
