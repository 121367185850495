import { createAsyncThunk } from '@reduxjs/toolkit';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { getAnalyticsUserInfo } from '../Perimeter/Selectors';
import { ThunkAPI } from 'src/Redux/RootReducer';
import ReactGA from 'react-ga4';

export const emitLoginEvent = createAsyncThunk<void, void, ThunkAPI>(
  'analytics/login',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    ReactGA.event(ANALYTICS_EVENT.LOGIN, getAnalyticsUserInfo(state));
  }
);
