import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  CandidateCDIContract,
  CandidatesService,
  OSMConsultantModelDtoConsultantAgencyByCompany,
} from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCandidateCDIContract = (
  candidateId?: string,
  extraOptions?: UseQueryOptions<
    CandidateCDIContract,
    unknown,
    CandidateCDIContract,
    (string | undefined | OSMConsultantModelDtoConsultantAgencyByCompany)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  return useQuery(
    [QueryKeys.fetchCandidateCDIContracts, candidateId, currentBrand, currentAgency],
    async () => {
      if (!currentBrand || !currentAgency) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return await CandidatesService.candidatesControllerGetCandidateCurrentContract({
        id: candidateId ?? '',
        brandCode: currentBrand.brand?.code ?? '',
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: candidateId !== undefined,
      staleTime: 3600000,
      ...extraOptions,
    }
  );
};
