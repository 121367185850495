import { Flash } from '@randstad-lean-mobile-factory/react-assets/dist/logos';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../Routes.types';
import Styles from './NotFound.module.scss';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className={Styles.Container}>
      <div className={Styles.Content}>
        <Flash className={Styles.Logo} size="large" />
        <p className={Styles.Title}>
          Bienvenue
          <br />
          sur l'application flash
        </p>
        <p className={Styles.Description}>
          La page recherchée
          <br />
          est introuvable
        </p>
        <Button.Primary onClick={() => navigate(ROUTES.ROOT.buildPath({}))}>
          retour à l'app
        </Button.Primary>
      </div>
    </div>
  );
};
