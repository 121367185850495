import {
  Button,
  Popup,
  PopupMenu,
  toast,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  CheckLine,
  Continue,
  DangerLine,
  EyeLine,
  Filters,
  ThreeDots,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Pen } from 'src/Assets_DEPRECATED';
import { useReplayExternalDemands } from 'src/Hooks/ExternalDemands/useReplayExternalDemands';
import { useUpdateExternalDemands } from 'src/Hooks/ExternalDemands/useUpdateExternalDemands';
import { MODAL_ROUTES, useBackgroundLocation } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { AppelMedicalEventStatus, DisplayStatus, ExternalDemandStatus } from 'src/Services/API';
import {
  filterUniqueIds,
  isDemandDto,
  isDemandItem,
} from '../ExternalDemandsList/ExternalDemandsList.types';
import styles from './ExternalDemandsListMenu.module.scss';
import { Props } from './ExternalDemandsListMenu.types';
import { formatError } from '../transformers';

const errorEnums = [
  AppelMedicalEventStatus['Erreur'],
  AppelMedicalEventStatus['Erreur annulation'],
  ExternalDemandStatus.ERROR,
];
const completeEnums = [
  AppelMedicalEventStatus['Commande créée'],
  AppelMedicalEventStatus['Commande mise à jour'],
  AppelMedicalEventStatus['Commande Annulée'],
  ExternalDemandStatus.COMPLETE,
];

const ExternalDemandsListMenu = ({ element, showArchivedDemands }: Props) => {
  const location = useBackgroundLocation();
  const agencyId = useSelector(getCurrentAgency) ?? '';
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const updateExternalDemandsMutation = useUpdateExternalDemands();
  const { mutate: replayDemands } = useReplayExternalDemands();

  const isError = errorEnums.includes(element.status);

  const isComplete = completeEnums.includes(element.status);
  const isCancelError =
    isDemandItem(element) &&
    element.cause === 'demande annulée par le client mais des intérimaires y sont rattachés';

  return (
    <div className={styles.container}>
      <Popup
        className={styles.popup}
        trigger={
          <div
            className={classnames(styles.badge, {
              [styles.item]: isDemandItem(element),
              [styles.badgeComplete]: isComplete && !isCancelError,
              [styles.badgeIgnored]: element.status === AppelMedicalEventStatus['Ignorée'],
              [styles.badgeMixed]: element.status === ExternalDemandStatus.MIXED || isCancelError,
              [styles.badgeError]: isError,
            })}
          >
            {isComplete || element.status === AppelMedicalEventStatus['Ignorée'] ? (
              <CheckLine />
            ) : (
              <DangerLine />
            )}
          </div>
        }
        on="hover"
      >
        <div className={styles.tooltip}>{formatError(element)}</div>
      </Popup>
      <Button.Tertiary
        onClick={event => {
          event.stopPropagation();
          if (isDemandDto(element)) {
            navigate(MODAL_ROUTES.EXTERNAL_DEMANDS_DETAILS.route.buildPath({}), {
              state: {
                background: location,
                modalParameters: {
                  externalDemand: element,
                },
              },
            });
          } else {
            navigate(MODAL_ROUTES.EXTERNAL_DEMANDS_EVENT_DETAILS.route.buildPath({}), {
              state: {
                background: location,
                modalParameters: {
                  externalDemand: element.parent,
                  eventId: element.id,
                },
              },
            });
          }
        }}
        className={styles.button}
      >
        <EyeLine width="2rem" height="2rem" />
      </Button.Tertiary>
      {!showArchivedDemands && (
        <div>
          <PopupMenu
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => {
              setOpen(false);
            }}
            trigger={
              <Button.Tertiary className={styles.button}>
                <ThreeDots className={styles.threeDotsIcon} />
              </Button.Tertiary>
            }
          >
            <PopupMenu.Item
              icon={<Filters />}
              text="correspondances"
              onClick={() =>
                navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildPath({}), {
                  state: ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildState({
                    siret: isDemandDto(element) ? element.siret : element.parent.siret,
                  }),
                })
              }
            />
            <PopupMenu.Item
              icon={<Continue />}
              text="archiver"
              onClick={() => {
                updateExternalDemandsMutation.mutate({
                  commandStatusToUpdate: [
                    {
                      commandId: isDemandDto(element)
                        ? element.commandId
                        : element.parent.commandId,
                      displayStatus: DisplayStatus.archived,
                      sourceIds: isDemandDto(element) ? undefined : [element.id],
                    },
                  ],
                });
              }}
            />
            {[
              AppelMedicalEventStatus.Erreur,
              AppelMedicalEventStatus['Erreur annulation'],
              ExternalDemandStatus.ERROR,
            ].includes(element.status) && (
              <PopupMenu.Item
                icon={<Pen />}
                text="horaire roulement actualisé "
                onClick={() => {
                  if (isDemandItem(element)) {
                    if (
                      element.parent.service &&
                      element.parent.qualification &&
                      element.parent.domain
                    ) {
                      return replayDemands({
                        agencyId,
                        source: element.parent.source,
                        sourceIds: [element.id],
                      });
                    } else {
                      toast.warning(
                        'veuillez vérifier les correpondances avant de relancer cette demande'
                      );
                      return;
                    }
                  }
                  if (isDemandDto(element)) {
                    if (!(element.service && element.qualification && element.domain)) {
                      toast.warning(
                        'veuillez vérifier les correpondances avant de relancer cette demande'
                      );
                    }
                    if (element.items.length > 1) {
                      return replayDemands({
                        agencyId,
                        source: element.source,
                        sourceIds: filterUniqueIds(element.items).map(item => item.id),
                      });
                    }
                    return replayDemands({
                      agencyId,
                      source: element.source,
                      sourceIds: [element.items[0].id],
                    });
                  }
                }}
              />
            )}
          </PopupMenu>
        </div>
      )}
    </div>
  );
};
export default ExternalDemandsListMenu;
