import { ConsultantPerimeter } from '../API';
import { PerimeterState } from 'src/Redux/Perimeter/Types';

export function isValidActivePerimeter({
  currentBrand,
  currentAgency,
  companies,
}: {
  currentBrand?: PerimeterState['currentBrand'];
  currentAgency?: string;
  companies: ConsultantPerimeter['companies'];
}): boolean {
  return (
    companies?.some(
      company =>
        company?.brand?.name === currentBrand?.brand?.name &&
        company.agencies?.some(agency => agency === currentAgency)
    ) ?? false
  );
}

export const getDefaultBrandAndAgency = (perimeter: ConsultantPerimeter) => {
  const defaultBrandFromFE = perimeter.companies?.find(company =>
    company.agencies?.includes(perimeter.defaultAgencyId ?? '')
  );
  const defaultBrand =
    defaultBrandFromFE ??
    perimeter.companies?.find(company => company.brand?.code === 'VB') ??
    perimeter.companies?.[0];
  const agency = defaultBrandFromFE ? perimeter.defaultAgencyId : defaultBrand?.agencies?.[0];
  return {
    brand: defaultBrand,
    agency,
  };
};
