import { LateralityInjury, WhoGaveEpi, YesNoUnknown } from 'src/Services/API';
import * as z from 'zod';

export const descriptionSchema = z.object({
  beforeAccident: z.string().optional(),
  duringAccident: z.string().optional(),
  afterAccident: z.string().optional(),
  risks: z.array(z.object({ id: z.string(), label: z.string() })),
  otherRisks: z.string().optional(),
  environmentRisks: z.array(z.object({ id: z.string(), label: z.string() })),
  hasEpis: z.nativeEnum(YesNoUnknown),
  epis: z.array(
    z.object({
      id: z.string(),
      label: z.string().optional(),
      hasBeenWorn: z.boolean(),
      whoGaveIt: z.nativeEnum(WhoGaveEpi),
      inContract: z.nativeEnum(YesNoUnknown),
    })
  ),
  injuries: z.array(
    z.object({
      injuryLaterality: z.nativeEnum(LateralityInjury).optional(),
      injuryLocation: z.object({ id: z.string(), label: z.string().optional() }).optional(),
      injuryLocationDetails: z.object({ id: z.string(), label: z.string().optional() }).optional(),
      injuryNatures: z
        .array(
          z.object({
            id: z.string(),
            label: z.string().optional(),
          })
        )
        .optional(),
    })
  ),

  hasEpcs: z.nativeEnum(YesNoUnknown),
  mainEpcs: z.array(z.object({ id: z.string(), label: z.string().optional() })),
  otherEpcs: z.string().optional(),
});

export type descriptionSchemaType = z.infer<typeof descriptionSchema>;
