import moment from 'moment';
import { useInfiniteQuery } from '@tanstack/react-query';
import { MissionsService } from 'src/Services/API';

import { QueryKeys } from '../types';

const GET_MISSIONS_SEARCH_LIMIT = 20;

export const useFetchRepositioningMissions = ({
  qualificationIds,
  agencies,
  candidateId,
}: {
  qualificationIds?: string[];
  agencies: string[];
  candidateId: string;
}) => {
  return useInfiniteQuery(
    [QueryKeys.fetchRepositioningMissions, qualificationIds, agencies, candidateId],
    async ({ pageParam: offset = 0 }) => {
      return MissionsService.missionsControllerGetMissions({
        body: {
          offset,
          limit: GET_MISSIONS_SEARCH_LIMIT,
          startDate: moment(new Date()).subtract(7, 'days').toISOString(),
          endDate: moment(new Date()).add(3, 'years').toISOString(),
          salesphases: ['AS', 'SP'],
          brandCode: '001VB',
          qualificationIds,
          agencyIds: agencies,
          candidateIdAlreadyOnMissions: candidateId,
        },
      });
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.missions?.length === GET_MISSIONS_SEARCH_LIMIT
          ? (pages.length - 1) * GET_MISSIONS_SEARCH_LIMIT + lastPage.missions.length
          : undefined,
      enabled: agencies.length <= 10,
    }
  );
};
