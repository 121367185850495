import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from 'src/Redux/RootReducer';
import { SearchState } from './Types';

const getSearchState = (state: RootState) => state.search;

export const getSortMode = createSelector([getSearchState], (state: SearchState) => state.sortBy);

export const getStartDate = createSelector([getSearchState], (state: SearchState) =>
  moment(state.startDate)
);

export const getSingleDate = createSelector([getSearchState], (state: SearchState) =>
  moment(state.singleDate)
);

export const getEndDate = createSelector([getSearchState], (state: SearchState) =>
  moment(state.endDate)
);

export const getSelectedSalesphases = createSelector(
  [getSearchState],
  (state: SearchState) => state.salesphases
);

export const getSearchOffset = createSelector(
  [getSearchState],
  (state: SearchState) => state.offset
);
