import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => {
  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          staleTime: Infinity,
          // Any cache older than 24 hours will be dismissed
          // See https://@tanstack/react-query.tanstack.com/plugins/persist-localstorage#usage to change this behavior
          cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
      },
    })
  );
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
