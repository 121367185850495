import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { redCloud, watermelon } from 'src/Assets_DEPRECATED';
import Card, { LoadingCard } from 'src/Components/Card';
import { FETCH_STATUS } from 'src/Redux/Types';
import styles from './CardList.module.scss';

import { Props } from './CardList.types';

const CardList = ({ fetchStatus, canLoadMore, cards, selectedId, onLoadMore }: Props) => {
  if (fetchStatus === FETCH_STATUS.REJECTED) {
    return (
      <div className={styles.error}>
        <img src={redCloud} alt="error-img" />
        <p>Une erreur est survenue</p>
      </div>
    );
  }
  if (fetchStatus === FETCH_STATUS.FULFILLED && cards.length === 0) {
    return (
      <div className={styles.noResult}>
        <img src={watermelon} className={styles.noResultImage} alt="no result" />
        <p>{`pas de résultat correspondant à votre recherche`}</p>
      </div>
    );
  }

  return (
    <ul className={styles.container}>
      {cards.map(({ onClick, id, ...cardProps }) => (
        <li key={id}>
          <button
            disabled={selectedId === id}
            onClick={() => {
              onClick();
            }}
          >
            <Card disabled={selectedId !== id} {...cardProps} />
          </button>
        </li>
      ))}
      {fetchStatus === FETCH_STATUS.PENDING && (
        <>
          {Array.from({ length: 10 }).map((_, idx) => (
            <li key={`LoadingCard${idx}`}>
              <LoadingCard />
            </li>
          ))}
        </>
      )}
      {canLoadMore && (
        <Button.Tertiary className={styles.loadMore} onClick={onLoadMore}>
          charger plus
        </Button.Tertiary>
      )}
    </ul>
  );
};

export default CardList;
