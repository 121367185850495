import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import invariant from 'invariant';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

import { CandidatesService, Talent } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchTemporaryWorkerDetail = (
  candidateId?: string,
  extraOptions?: UseQueryOptions<Talent, unknown, Talent, (string | undefined)[]>
) => {
  const currentBrandCode = useSelector(getCurrentBrandCode);
  return useQuery(
    [QueryKeys.fetchCandidateDetails, candidateId],
    async () => {
      invariant(
        currentBrandCode && candidateId,
        'currentBrandCode and candidateId should be defined'
      );
      const candidate = await CandidatesService.candidatesControllerGetTemporaryWorkerDetail({
        id: candidateId,
        brandCode: currentBrandCode,
      });
      return candidate;
    },
    {
      enabled: !!currentBrandCode && !!candidateId,
      ...extraOptions,
    }
  );
};
