import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../types';
import { WorkAccidentAnalysisService } from 'src/Services/API';

export const useFetchWorkAccidentActionPlanById = ({
  workAccidentId,
}: {
  workAccidentId: string;
}) => {
  return useQuery([QueryKeys.fetchWorkAccidentActionPlanById, workAccidentId], async () => {
    return await WorkAccidentAnalysisService.workAccidentAnalysisControllerGetWorkAccidentActionPlan(
      {
        id: workAccidentId,
      }
    );
  });
};
