import styles from './../../Context.module.scss';
import { useEffect } from 'react';
import {
  HorizontalCardWithTitle,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { Props } from './SelectCompanyContacts.types';
import { useController } from 'react-hook-form';
import { Referent, Witness } from 'src/Services/API';
import WorkAccidentAnalysisContactModal from './WorkAccidentAnalysisContactModal/WorkAccidentAnalysisContactModal.component';

const SelectCompanyContacts = ({
  isEditable,
  control,
  name,
  show,
  title,
  contactLabel,
  companyId,
  companyName,
  handleSubmit,
}: Props) => {
  const controller = useController({ control, name });
  const value = controller.field.value as (Referent | Witness)[];

  useEffect(() => {
    window.addEventListener('beforeunload', handleSubmit);
    return () => {
      window.removeEventListener('beforeunload', handleSubmit);
    };
  }, [handleSubmit]);

  useEffect(() => {
    if (controller.formState.isDirty) handleSubmit()();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {show && (
        <WithLightTitle title={title} className={styles.lightTitleWithFullWidth}>
          <>
            {value?.map(contact => (
              <HorizontalCardWithTitle
                key={contact.contactId ?? contact.name}
                title={`${contact.name.toUpperCase()} ${contact.firstName?.toUpperCase() ?? ''}`}
                subtitles={[
                  'societyName' in contact
                    ? `${contact.position.toUpperCase()} | ${contact.societyName.toUpperCase()} | ${
                        contact.phoneNumber && contact.phoneNumber.length > 0
                          ? contact.phoneNumber
                          : 'téléphone non renseigné'
                      }`
                    : contact.position.toUpperCase(),
                ]}
                rightActions={
                  isEditable && (
                    <Trashcan
                      onClick={() =>
                        controller.field.onChange(
                          value?.filter(
                            contactInList =>
                              contactInList.contactId !== contact.contactId ||
                              contactInList.firstName !== contact.firstName ||
                              contactInList.name !== contact.name ||
                              contactInList.position !== contact.position ||
                              ('societyName' in contactInList &&
                                'societyName' in contact &&
                                (contactInList.societyId !== contact.societyId ||
                                  contactInList.societyName !== contact.societyName ||
                                  contactInList.phoneNumber !== contact.phoneNumber))
                          )
                        )
                      }
                    />
                  )
                }
              />
            ))}
            {!isEditable && (!value || value.length === 0) && <div>aucun renseigné</div>}
            {isEditable && (
              <WorkAccidentAnalysisContactModal
                contactLabel={contactLabel}
                selectedContacts={value ?? []}
                companyId={companyId}
                companyName={companyName}
                onValidate={(addedContact: Referent | Witness) =>
                  controller.field.onChange([...(value ?? []), addedContact])
                }
              />
            )}
          </>
        </WithLightTitle>
      )}
    </>
  );
};

export default SelectCompanyContacts;
