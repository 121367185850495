import * as moment from 'moment';
import { OSMCommonModelDateDto } from 'src/Services/API';

const toHHMMTimeFormat = (seconds: number, separator: string): string => {
  const duration = moment.duration(seconds, 'seconds');
  return `${Math.floor(duration.asHours())
    .toString()
    .padStart(2, '0')}${separator}${duration.minutes().toString().padStart(2, '0')}`;
};

export const OSMToTimeInput = (time: number): string => {
  return toHHMMTimeFormat(time, ':');
};

export const OSMToTimeDisplay = (time: number): string => {
  return toHHMMTimeFormat(time, 'h');
};

export const timeInputToOSM = (time: string): number => {
  return moment.duration(time).asSeconds();
};

export function toDate(date: OSMCommonModelDateDto): Date {
  return new Date(
    date.year || new Date().getUTCFullYear(),
    (date.month || new Date().getUTCMonth() + 1) - 1,
    date.day
  );
}
