import {
  Candidate,
  Contact,
  ContactingInfo,
  ContactingStatus,
  CreateActionBody,
} from 'src/Services/API';

export type WithMoment<T extends {}, K extends keyof T> = Omit<T, K> &
  { [key in K]: undefined extends T[K] ? moment.Moment | undefined : moment.Moment };

export enum FETCH_STATUS {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export type ContactingInfoWithMoment = WithMoment<ContactingInfo, 'at'>;

export interface ContactingStatusSummary {
  clientContactingStatus: ContactingStatus;
  candidateContactingStatus: ContactingStatus;
}

export interface Consultant {
  name: string;
  firstName: string;
  mobilePhoneNumber: string;
  phone: string;
  email: string;
}

export interface CandidateWithMoment extends Omit<Candidate, 'lastContact'> {
  lastContact?: ContactingInfoWithMoment;
}

export interface ContactWithMoment extends Omit<Contact, 'lastContact'> {
  lastContact?: ContactingInfoWithMoment;
}

export interface ActionCreationParams
  extends Omit<CreateActionBody, 'agencyId' | 'brandCode' | 'createdAt'> {}
