import { LogoutOptions } from '@auth0/auth0-react';

import { AxiosInterceptors } from './types';

let getAccessTokenSilently: (() => Promise<string>) | null = null;
let getIdTokenClaims: (() => Promise<any>) | null = null;
let logout: ((options?: LogoutOptions | undefined) => void) | null = null;

export const security = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: () => Promise<string>) => (getAccessTokenSilently = func),
  getIdTokenClaims: () => getIdTokenClaims,
  setIdTokenClaims: (func: () => Promise<any>) => (getIdTokenClaims = func),
  logout: () => logout,
  setLogout: (func: (options?: LogoutOptions | undefined) => void) => (logout = func),
};

const axiosInterceptors: AxiosInterceptors = {
  requestInterceptor: async config => {
    const getIdTokenClaims = security.getIdTokenClaims();
    const getAccessTokenSilently = security.getAccessTokenSilently();
    while (!getIdTokenClaims || !(await getIdTokenClaims()) || !getAccessTokenSilently) {
      await new Promise(res => setTimeout(res));
    }
    const idToken = await getIdTokenClaims();
    const token = await getAccessTokenSilently();
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        Identification: `Bearer ${idToken.__raw}`,
      },
    };
  },
  errorInterceptor: async error => {
    const { response, config } = error;
    if (config && response?.status === 401) {
      security.logout()?.();
    }

    return Promise.reject(error);
  },
};

export default axiosInterceptors;
