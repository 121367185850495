import React, { useState } from 'react';

import styles from './ExternalDemandsInit.module.scss';
import { TopBar } from 'src/Components/TopBar';
import { Button, SegmentedControl } from '@randstad-lean-mobile-factory/react-components-core';
import { AppelMedicalEventSource } from 'src/Services/API';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedSource } from 'src/Redux/ExternalDemandPreferences/Selectors';
import { ExternalDemandPreferencesActions } from 'src/Redux/ExternalDemandPreferences/Slice';
import { PointingFinger } from 'src/Assets_DEPRECATED';
import { useCreateExternalDemandsJournal } from 'src/Hooks/ExternalDemands/useCreateExternalDemandsJournal';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/Hooks/types';
import { Props } from './ExternalDemandsInit.types';

const ExternalDemandsInit = ({ agencyId }: Props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const updateCreateExternalDemandsJournalMutation = useCreateExternalDemandsJournal();
  const initialSource = useSelector(getSelectedSource);
  const [selectedSource, setSelectedSource] = useState<AppelMedicalEventSource>(initialSource);

  const text = `Je souhaite connecter mon unité ${agencyId} à Hublo`;
  return (
    <>
      <div className={styles.container}>
        <TopBar title="demandes externes" />
        <div className={styles.segmentedControlContainer}>
          <SegmentedControl
            className={styles.segmentedControl}
            controls={Object.values(AppelMedicalEventSource)}
            selected={selectedSource}
            getValue={(dashboardMenuItem: AppelMedicalEventSource) => {
              return <div className={styles.segmentContent}>{dashboardMenuItem.toLowerCase()}</div>;
            }}
            onSelectionChange={dashboardMenuItem => {
              setSelectedSource(dashboardMenuItem);
              dispatch(ExternalDemandPreferencesActions.setSelectedSource(dashboardMenuItem));
            }}
          />
        </div>
        <div className={styles.contentContainer}>
          <PointingFinger />
          <div className={styles.content}>
            <div className={styles.text}>{text}</div>
            <div className={styles.buttons}>
              <Button.Primary
                disabled={updateCreateExternalDemandsJournalMutation.isLoading}
                onClick={() => {
                  updateCreateExternalDemandsJournalMutation.mutate(agencyId, {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: [QueryKeys.fetchAgencyBrandCode],
                      });
                    },
                  });
                }}
              >
                c'est parti !
              </Button.Primary>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalDemandsInit;
