import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { getCompanies } from 'src/Redux/Perimeter/Selectors';

import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { searchMissions } from 'src/Redux/Missions/Thunks';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { PerimeterState } from 'src/Redux/Perimeter/Types';
import { RootState } from 'src/Redux/RootReducer';
import { PositionstudiesService } from 'src/Services/API';
import { positionStudyToMissionCompany } from 'src/Utils/positionStudyToMission';
import { MutationKeys, QueryKeys } from '../types';

export const useFetchMissionDetailsFromEDP = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const navigate = useNavigate();
  const companies = useSelector(getCompanies);
  const perimeterBrandCodes = companies?.map(company => company.brandCodeApiHeader);
  const location = useLocation();
  const queryClient = useQueryClient();
  const setSelectedAgency = useCallback(
    (selectedAgency?: string) => {
      dispatch(perimeterActions.setCurrentAgency(selectedAgency));
      queryClient.invalidateQueries({ queryKey: [QueryKeys.isCGC] });
    },
    [queryClient, dispatch]
  );
  const setSelectedBrand = useCallback(
    (selectedBrand?: PerimeterState['currentBrand']) =>
      dispatch(perimeterActions.setCurrentBrand(selectedBrand)),
    [dispatch]
  );
  return useMutation(
    [MutationKeys.fetchMissionDetailsFromEDP],
    async (params: { edpId: string; edpBrandCode: string }) => {
      const positionStudy = await PositionstudiesService.positionStudiesControllerGetPositionStudyById(
        { positionStudyId: params.edpId, brandCode: params.edpBrandCode ?? '' }
      );
      const isValidAgency =
        positionStudy.agencyId &&
        perimeterBrandCodes?.includes(params.edpBrandCode) &&
        companies
          ?.find(brand => brand.brandCodeApiHeader === params.edpBrandCode)
          ?.agencies?.includes(positionStudy.agencyId);
      if (isValidAgency) {
        setSelectedBrand(
          companies?.find(brand => brand.brandCodeApiHeader === params.edpBrandCode)
        );
        setSelectedAgency(positionStudy.agencyId);
        dispatch(missionCreationActions.setSelectedPreviousPositionStudy(positionStudy));
        dispatch(
          missionCreationActions.setSelectedCompany(
            positionStudyToMissionCompany(positionStudy.company)
          )
        );
        dispatch(missionCreationActions.setSelectedQualification(positionStudy.qualification));
        dispatch(searchMissions());
        navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
          state: {
            background: location,
          },
        });
      } else {
        navigate(MODAL_ROUTES.WRONG_AGENCY_FROM_EDP.route.buildPath({}), {
          state: {
            background: location,
          },
        });
      }
    }
  );
};
