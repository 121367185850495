import styles from './MissionsStatus.module.scss';

export const getStatusLabel = (status: string | undefined) => {
  switch (status) {
    case 'S':
      return 'servie';
    case 'SP':
      return 'servie partiellement';
    case 'NS':
      return 'non servie';
    case 'AS':
      return 'à servir';
    case 'AN':
      return 'annulée';
    case 'F':
      return 'fermée';
    default:
      return 'N/A';
  }
};

export const getStatusStyle = (status: string | undefined) => {
  switch (status) {
    case 'S':
      return styles.served;
    case 'SP':
      return styles.partiallyServed;
    case 'NS':
      return styles.notServed;
    case 'AS':
      return styles.toBeServed;
    case 'AN':
      return styles.canceled;
    case 'F':
      return styles.canceled;
  }
};
