import { useEffect, useMemo, useRef } from 'react';

import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import { useWatchForEdpURLParam } from 'src/Hooks/Missions/useWatchForEdpURLParam';
import { useFetchPerimeter } from 'src/Hooks/Perimeter';
import { useBackgroundLocation } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { emitLoginEvent } from 'src/Redux/Analytics/Thunks';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { RootState } from 'src/Redux/RootReducer';
import { ROUTES } from 'src/Routes/Routes.types';
import SideBar from 'src/Components/SideBar';
import styles from './AppRoot.module.scss';

const appelMedicalBrandCodes = ['307AM', '307AMS', '307JBM'];

export const AppRoot = () => {
  const navigate = useNavigate();

  const perimeterQuery = useFetchPerimeter();
  const location = useBackgroundLocation();

  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const currentBrandCode = useSelector(getCurrentBrandCode);

  const isCurrentBrandAppelMedical = useMemo(
    () => appelMedicalBrandCodes.includes(currentBrandCode || ''),
    [currentBrandCode]
  );

  const prevIsCurrentBrandAppelMedical = useRef(isCurrentBrandAppelMedical);

  useEffect(() => {
    if (perimeterQuery.isSuccess) {
      dispatch(emitLoginEvent());
    }
  }, [dispatch, perimeterQuery]);
  useWatchForEdpURLParam();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const edpId = params.get('edpId');
    if (
      (location.pathname === ROUTES.ROOT.path ||
        prevIsCurrentBrandAppelMedical.current !== isCurrentBrandAppelMedical) &&
      edpId === null
    ) {
      prevIsCurrentBrandAppelMedical.current = isCurrentBrandAppelMedical;
      if (isCurrentBrandAppelMedical) {
        navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.buildPath({}));
      } else {
        navigate(ROUTES.ACTIVITY.MISSIONS.buildPath({}));
      }
    }
  }, [location.pathname, navigate, isCurrentBrandAppelMedical]);

  return (
    <div className={styles.container}>
      <SideBar isCurrentBrandAppelMedical={isCurrentBrandAppelMedical} />
      <div className={styles.contentContainer}>{perimeterQuery.isSuccess && <Outlet />}</div>
    </div>
  );
};
