import {
  RadioButtonGroup,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import SelectCompany from 'src/Components/Selects/SelectCompany';
import SelectQualification from 'src/Components/Selects/SelectQualification';
import { MISSION_CREATION_WAY } from '../MissionCreationModal.types';
import styles from './FirstContent.module.scss';
import { Props } from './FirstContent.types';

const RadioButtons = [
  {
    id: MISSION_CREATION_WAY.FROM_NEW_DATA,
    value: MISSION_CREATION_WAY.FROM_NEW_DATA,
    label: 'de nouvelles données',
  },
  {
    id: MISSION_CREATION_WAY.FROM_EDP,
    value: MISSION_CREATION_WAY.FROM_EDP,
    label: "d'une EDP liée au compte et à la qualification",
  },
  {
    id: MISSION_CREATION_WAY.FROM_OLD_MISSION,
    value: MISSION_CREATION_WAY.FROM_OLD_MISSION,
    label: "d'une ancienne commande",
  },
];

const FirstContent = (props: Props) => {
  return (
    <div className={styles.container}>
      <WithLightTitle title="compte" className={styles.filter}>
        <SelectCompany
          selectedCompany={props.selectedCompany}
          setSelectedCompany={selectedCompany => {
            props.setSelectedCompany(selectedCompany);
          }}
          agency={props.currentAgency ?? ''}
          brandCode={props.currentBrandcode ?? ''}
          canBeReset={false}
          defaultValue="chercher un compte"
        />
      </WithLightTitle>

      {props.shouldChooseCreationWay && (
        <WithLightTitle title="suivi par" className={styles.followBy}>
          <div
            className={styles.followByLabel}
          >{`${props.userFirstName} ${props.userLastName}`}</div>
        </WithLightTitle>
      )}

      <WithLightTitle title="qualification" className={styles.filter}>
        <SelectQualification
          selectedQualification={props.selectedQualification}
          setSelectedQualification={selectedQualification => {
            props.setSelectedQualification(selectedQualification);
          }}
          // We specify no society / brand to have the same behavior as OSM
          canBeReset={false}
          defaultValue="définir une qualification"
        />
      </WithLightTitle>

      {props.shouldChooseCreationWay && (
        <div>
          <RadioButtonGroup
            title="Compléter cette commande à partir :"
            name="wayToDo"
            radioButtonsInfo={
              props.positionStudyCount
                ? RadioButtons
                : RadioButtons.filter(button => button.id !== MISSION_CREATION_WAY.FROM_EDP)
            }
            onChange={(_, id) =>
              props.setSelectedCreationWay(
                RadioButtons.find(button => button.id === id)?.value ??
                  MISSION_CREATION_WAY.FROM_NEW_DATA
              )
            }
            selected={props.selectedCreationWay}
          />
        </div>
      )}
    </div>
  );
};

export default FirstContent;
