import { useEffect, useState } from 'react';

import {
  Button,
  DatePickerInput,
  FetchButton,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { renewalEndorsementReducer } from 'src/Utils/contracts';
import styles from './ContractRenewalModalContent.module.scss';
import { Props } from './ContractRenewalModalContent.types';

const ContractRenewalModalContent = ({
  contract,
  onClose,
  onRenewal,
  onRenewalSuccess,
  renewalFetchStatus,
  suggestedEndDate,
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(suggestedEndDate);

  useEffect(() => {
    if (
      suggestedEndDate !== undefined &&
      contract?.effectiveEndDate !== undefined &&
      moment(suggestedEndDate).isAfter(moment(contract.effectiveEndDate).clone().add(1, 'day'))
    ) {
      setSelectedDate(suggestedEndDate);
    }
  }, [suggestedEndDate, contract?.effectiveEndDate]);

  return (
    <ModalDeprecated
      title="prolongation de contrat"
      key="contractRenewalModal"
      nested
      open
      onClose={onClose}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          fetchStatus={renewalFetchStatus}
          onClick={() => {
            if (selectedDate) {
              onRenewal(selectedDate);
            }
          }}
          onSuccess={() => {
            onClose();
            onRenewalSuccess();
          }}
          disabled={selectedDate === undefined}
        />,
      ]}
    >
      <div className={styles.datesSection}>
        <WithLightTitle title="début mission">
          <p className={styles.value}>
            {moment(contract?.effectiveStartDate).format('DD/MM/YYYY')}
          </p>
        </WithLightTitle>
        <div style={{ width: '6rem' }} />
        <WithLightTitle title="fin mission">
          <p className={styles.value}>{moment(contract?.effectiveEndDate).format('DD/MM/YYYY')}</p>
        </WithLightTitle>
      </div>
      <WithLightTitle title="prolongation jusqu'au" className={styles.datePicker}>
        <DatePickerInput
          onSelectDate={date => {
            setSelectedDate(date);
          }}
          date={selectedDate}
          position="bottom left"
          minDate={moment(contract?.effectiveEndDate).clone().add(1, 'day').toDate()}
        />
      </WithLightTitle>
      <WithLightTitle title="nombre de renouvellement">
        <p className={styles.value}>
          {contract?.endorsements?.reduce(renewalEndorsementReducer, []).length}
        </p>
      </WithLightTitle>
    </ModalDeprecated>
  );
};

export default ContractRenewalModalContent;
