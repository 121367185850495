import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { Domain, RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchDomains = ({
  brandCode,
  extraOptions,
}: {
  brandCode?: string;
  extraOptions?: UseQueryOptions<Domain[], unknown, Domain[], (string | undefined)[]>;
}) => {
  return useQuery(
    [QueryKeys.fetchDomains, brandCode],
    async () => {
      const domains = await RepositoryService.repositoryControllerGetDomains({
        brandCode,
      });
      return domains.sort((a, b) =>
        a.shortLabel === b.shortLabel ? 0 : (a.shortLabel ?? '') < (b.shortLabel ?? '') ? -1 : 1
      );
    },
    { ...extraOptions }
  );
};
