import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SelectService from './SelectService.component';

import { getFetchStatus, getServices } from 'src/Redux/Services/Selectors';
import { OwnProps } from './SelectService.types';

const MemoSelectService = React.memo(SelectService);

const EnhancedSelectService = (ownProps: OwnProps) => {
  const companyServices = useSelector(getServices);
  const fetchStatus = useSelector(getFetchStatus);

  // We store the fetched services in an inner state to do a local search on it
  const [services, setServices] = useState(companyServices);
  useEffect(() => setServices(companyServices), [companyServices]);

  const _searchServices = useCallback(
    (searchValue: string) => {
      setServices(
        companyServices.filter(service =>
          service.name?.toUpperCase().includes(searchValue.toUpperCase())
        )
      );
    },
    [companyServices]
  );

  return (
    <MemoSelectService
      services={services}
      fetchStatus={fetchStatus}
      searchServices={_searchServices}
      {...ownProps}
    />
  );
};

export default EnhancedSelectService;
