import { useQuery } from '@tanstack/react-query';
import { SkillsManagementCentersService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchAgenciesInfoFromCGC = (cgcName: string) => {
  return useQuery(
    [QueryKeys.fetchAgenciesInfoFromCGC],
    async () => {
      const agencies = await SkillsManagementCentersService.skillsManagementCentersControllerGetAgenciesInfoFromCgc(
        {
          cgcName: cgcName,
        }
      );

      return agencies;
    },
    { cacheTime: 1 }
  );
};
