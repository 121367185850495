import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrandCode,
} from 'src/Redux/Perimeter/Selectors';
import { MutationKeys } from '../types';
import { DisplayStatus, ExportService } from 'src/Services/API';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useAuthenticatedUser } from '../Authentication/useAuthenticatedUser';
import ReactGA from 'react-ga4';
import { getFilteredColumns } from 'src/Redux/ExternalDemandList/Selectors';
export const useExportExternalDemands = (
  extraOptions?: UseMutationOptions<void, unknown, DisplayStatus, unknown>
) => {
  const agencyId = useSelector(getCurrentAgency) ?? '';
  const brandCode = useSelector(getCurrentBrandCode);
  const { zoneId, regionId } = useSelector(getAnalyticsUserInfo);
  const user = useAuthenticatedUser();
  const filteredColumns = useSelector(getFilteredColumns);
  return useMutation(
    [MutationKeys.exportExternalDemands],
    async displayStatus => {
      const result = await ExportService.exportControllerInitializeDemandsExport({
        body: {
          agencyId: agencyId,
          user: user,
          displayStatus,
          commandIds: filteredColumns.length !== 0 ? filteredColumns : undefined,
        },
      });

      ReactGA.event('export-external-demands', { brandCode, agencyId, zoneId, regionId });
      return result;
    },
    {
      ...extraOptions,
      onSuccess: () => {
        toast.success(
          "votre demande a bien été enregistrée, vous recevrez votre export par mail d'ici quelques minutes",
          { autoClose: 5000 }
        );
      },
    }
  );
};
