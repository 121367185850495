import React from 'react';

import { Props } from './TimePicker.types';
import styles from './TimePicker.module.scss';
import classnames from 'classnames';

const TimePicker = (props: Props) => (
  <input
    className={classnames(styles.timePicker, {
      [styles.leftTimePicker]: props.left,
      [styles.rightTimePicker]: props.right,
      [styles.middleTimePicker]: props.middle,
    })}
    type={'time'}
    value={props.value}
    onChange={props.onChange}
  />
);

export default TimePicker;
