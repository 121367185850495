import { SigningStatusEnum } from 'src/Services/API';

export interface Props {}

export enum Signatory {
  CONSULTANT = 'CONSULTANT',
  CLIENT = 'CLIENT',
  VICTIM = 'VICTIM',
  ADDITIONAL = 'ADDITIONAL',
}

export const ClientSigningStatusRadioButtons = [
  {
    id: SigningStatusEnum.DOES_NOT_WANT_TO_SIGN,
    value: SigningStatusEnum.DOES_NOT_WANT_TO_SIGN,
    label: 'le client ne veut pas signer',
  },
  {
    id: SigningStatusEnum.CANNOT_SIGN,
    value: SigningStatusEnum.CANNOT_SIGN,
    label: 'le client ne peut pas signer',
  },
  {
    id: SigningStatusEnum.SIGNS,
    value: SigningStatusEnum.SIGNS,
    label: 'le client signe',
  },
];

export const VictimSigningStatusRadioButtons = [
  {
    id: SigningStatusEnum.DOES_NOT_WANT_TO_SIGN,
    value: SigningStatusEnum.DOES_NOT_WANT_TO_SIGN,
    label: "l'intérimaire ne veut pas signer",
  },
  {
    id: SigningStatusEnum.CANNOT_SIGN,
    value: SigningStatusEnum.CANNOT_SIGN,
    label: "l'intérimaire ne peut pas signer",
  },
  {
    id: SigningStatusEnum.SIGNS,
    value: SigningStatusEnum.SIGNS,
    label: "l'intérimaire signe",
  },
];
