import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';
import { CompanyState } from './Types';

const getCompanyState = (state: RootState) => state.companies;

export const getCurrentCompany = createSelector(
  [getCompanyState],
  (state: CompanyState) => state.currentCompany
);

export const getFetchStatus = createSelector(
  [getCompanyState],
  (state: CompanyState) => state.fetchStatus
);

export const getCompanyRequestReasons = createSelector(
  [getCompanyState],
  (state: CompanyState) => state.requestReasons
);

export const getCompanyRequestReasonsFetchStatus = createSelector(
  [getCompanyState],
  (state: CompanyState) => state.requestReasonsFetchStatus
);
