import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';

import { YouplanCompaniesState } from './Types';

const getYouplanCompaniesState = (state: RootState) => state.youplanCompanies;

export const getYouplanCompanies = createSelector(
  [getYouplanCompaniesState],
  (state: YouplanCompaniesState) => state.youplanCompanies
);

export const getCurrentYouplanCompany = createSelector(
  [getYouplanCompaniesState],
  (state: YouplanCompaniesState) => state.currentYouplanCompany
);

export const getYouplanFetchStatus = createSelector(
  [getYouplanCompaniesState],
  (state: YouplanCompaniesState) => state.youplanCompaniesFetchStatus
);

export const getCanLoadMore = createSelector(
  [getYouplanCompaniesState],
  (state: YouplanCompaniesState) => state.canLoadMore
);

export const getYouplanSearchInput = createSelector(
  [getYouplanCompaniesState],
  (state: YouplanCompaniesState) => state.searchInput
);
