import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';
import { QualificationState } from './Types';

const getQualificationState = (state: RootState) => state.qualifications;

export const getCurrentQualification = createSelector(
  [getQualificationState],
  (state: QualificationState) => state.currentQualification
);
