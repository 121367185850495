import { useEffect } from 'react';

import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import { UseFormReturn } from 'react-hook-form';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { useFetchWorkAccidentByIds } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentByIds';
import { useUpdateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentAnalysis';
import { FETCH_STATUS } from 'src/Redux/Types';
import { ROUTES } from 'src/Routes/Routes.types';
import { EnumWorkAccidentAnalysisStatus, FirstAidPersonType } from 'src/Services/API';
import { toFetchStatus } from 'src/Utils/fetch-status';
import styles from './Context.module.scss';
import { contextSchema, RiskSchemaType } from './Context.schema';
import Accident from './SubSections/Accident.component';
import FirstAid from './SubSections/FirstAid.component';
import TemporaryWorker from './SubSections/TemporaryWorker.component';
import UserCompany from './SubSections/UserCompany.component';
import Witnesses from './SubSections/Witnesses.component';

export const ContextRoute = () => {
  const { analysisId } = useTypedParams(
    ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT
  );
  const currentAnalysis = useFetchWorkAccidentByIds({ analyseIds: [analysisId] });
  const isEditable =
    currentAnalysis.data?.[0].status === EnumWorkAccidentAnalysisStatus.IN_PROGRESS;
  const currentAnalysisFetchStatus = toFetchStatus(currentAnalysis);
  const updateWorkAnalysisMutation = useUpdateWorkAccidentAnalysis();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: contextSchema,
    defaultValues: {
      accidentLocation: undefined,
      causedByThirdParty: undefined,
      accidentDate: undefined,
      accidentTime: undefined,
      workingDayStartTime: undefined,
      workingDayEndTime: undefined,
      personLastName: undefined,
      personFirstName: undefined,
      companyName: undefined,
      victimPhoneNumber: undefined,
      daysOff: undefined,
      seniorityAtClient: undefined,
      seniorityAtRandstad: undefined,
      laterality: undefined,
      hasWitnesses: undefined,
      witnesses: undefined,
      welcomeDate: undefined,
      positionStudyDate: undefined,
      trainingDate: undefined,
      trainingEvaluation: undefined,
      referents: undefined,
      existingRisk: undefined,
      registeredRisk: undefined,
      firstAidPersonType: undefined,
      otherFirstAidPerson: undefined,
    },
  }) as UseFormReturn<RiskSchemaType>;

  const laterality = watch('laterality');
  const firstAidPersonType = watch('firstAidPersonType');
  const accidentLocation = watch('accidentLocation');
  const causedByThirdParty = watch('causedByThirdParty');
  const registeredRisk = watch('registeredRisk');
  const existingRisk = watch('existingRisk');
  const hasWitnesses = watch('hasWitnesses');
  const witnesses = watch('witnesses');
  const trainingEvaluation = watch('trainingEvaluation');
  const welcomeDate = watch('welcomeDate');
  const trainingDate = watch('trainingDate');
  const positionStudyDate = watch('positionStudyDate');
  const accidentDate = watch('accidentDate');
  const referents = watch('referents');

  useEffect(
    () => {
      if (currentAnalysis.isSuccess) {
        setValue('seniorityAtClient', currentAnalysis.data[0].context?.seniorityAtClient);
        setValue('seniorityAtRandstad', currentAnalysis.data[0].context?.seniorityAtRandstad);
        setValue('laterality', currentAnalysis.data[0].context?.laterality);
        setValue('firstAidPersonType', currentAnalysis.data[0].context?.firstAidPersonType);
        setValue('registeredRisk', currentAnalysis.data[0].context?.registeredRisk);
        setValue('existingRisk', currentAnalysis.data[0].context?.existingRisk);
        setValue('trainingEvaluation', currentAnalysis.data[0].context?.trainingEvaluation);
        setValue('referents', currentAnalysis.data[0].context?.referents);
        setValue(
          'accidentTime',
          currentAnalysis.data[0].context?.accidentTime ??
            currentAnalysis.data[0].accidentDateAndLocation?.accidentTime
        );
        setValue('otherFirstAidPerson', currentAnalysis.data[0].context?.otherFirstAidPerson);
        setValue('hasWitnesses', currentAnalysis.data[0].context?.hasWitnesses);
        setValue('witnesses', currentAnalysis.data[0].context?.witnesses);
        setValue('personLastName', currentAnalysis.data[0].context?.personLastName);
        setValue('personFirstName', currentAnalysis.data[0].context?.personFirstName);
        setValue('companyName', currentAnalysis.data[0].context?.companyName);
        setValue('victimPhoneNumber', currentAnalysis.data[0].context?.victimPhoneNumber);
        setValue(
          'daysOff',
          currentAnalysis.data[0].context?.daysOff ?? currentAnalysis.data[0].stoppageDuration
        );
        setValue(
          'workingDayStartTime',
          currentAnalysis.data[0].context?.workingDayStartTime ??
            currentAnalysis.data[0].accidentDateAndLocation?.workHours?.startTime1
        );
        setValue(
          'workingDayEndTime',
          currentAnalysis.data[0].context?.workingDayEndTime ??
            currentAnalysis.data[0].accidentDateAndLocation?.workHours?.endTime2
        );
        setValue('accidentLocation', currentAnalysis.data[0].context?.accidentLocation);
        setValue('causedByThirdParty', currentAnalysis.data[0].context?.causedByThirdParty);
        setValue(
          'welcomeDate',
          currentAnalysis.data[0].context?.welcomeDate
            ? new Date(currentAnalysis.data[0].context?.welcomeDate)
            : undefined
        );
        setValue(
          'positionStudyDate',
          currentAnalysis.data[0].context?.positionStudyDate
            ? new Date(currentAnalysis.data[0].context?.positionStudyDate)
            : undefined
        );
        setValue(
          'trainingDate',
          currentAnalysis.data[0].context?.trainingDate
            ? new Date(currentAnalysis.data[0].context?.trainingDate)
            : undefined
        );
        setValue(
          'accidentDate',
          currentAnalysis.data[0].context?.accidentDate
            ? new Date(currentAnalysis.data[0].context?.accidentDate)
            : new Date(
                currentAnalysis.data[0].accidentDateAndLocation?.accidentDate?.year ?? 0,
                (currentAnalysis.data[0].accidentDateAndLocation?.accidentDate?.month ?? 0) - 1,
                currentAnalysis.data[0].accidentDateAndLocation?.accidentDate?.day ?? 0
              )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAnalysisFetchStatus]
  );

  const contextHandleSubmit = () =>
    handleSubmit((values: RiskSchemaType) => {
      isEditable &&
        updateWorkAnalysisMutation.mutate({
          analyseId: analysisId,
          context: {
            ...values,
            otherFirstAidPerson:
              firstAidPersonType === FirstAidPersonType.OTHER
                ? values.otherFirstAidPerson
                : undefined,
          },
        });
    });

  useEffect(
    () => {
      if (currentAnalysisFetchStatus === FETCH_STATUS.FULFILLED && isDirty) contextHandleSubmit()();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      laterality,
      firstAidPersonType,
      accidentLocation,
      causedByThirdParty,
      registeredRisk,
      existingRisk,
      hasWitnesses,
      trainingEvaluation,
      welcomeDate,
      accidentDate,
      trainingDate,
      positionStudyDate,
      witnesses,
      referents,
    ]
  );

  return (
    <div className={styles.page}>
      <div className={styles.titleContainer}>1.</div>
      <div className={styles.content}>
        <div className={styles.title}>contexte</div>
        {currentAnalysis.isSuccess && (
          <>
            <TemporaryWorker
              isEditable={isEditable}
              candidateId={currentAnalysis.data?.[0].candidate?.id ?? ''}
              candidateFirstName={currentAnalysis.data?.[0].candidate?.firstName ?? ''}
              candidateLastName={currentAnalysis.data?.[0].candidate?.name ?? ''}
              control={control}
              handleSubmit={contextHandleSubmit}
            />
            <UserCompany
              isEditable={isEditable}
              companyId={currentAnalysis.data?.[0].company?.id ?? ''}
              name={currentAnalysis.data?.[0].company?.name ?? ''}
              siret={currentAnalysis.data?.[0].company?.siret ?? ''}
              control={control}
              handleSubmit={contextHandleSubmit}
            />
            <Accident
              isEditable={isEditable}
              control={control}
              handleSubmit={contextHandleSubmit}
              accidentAddress={
                currentAnalysis.data[0].context?.accidentAddress ?? 'adresse non renseignée'
              }
            />
            <Witnesses
              isEditable={isEditable}
              companyId={currentAnalysis.data?.[0].company?.id ?? ''}
              companyName={currentAnalysis.data?.[0].company?.name ?? ''}
              control={control}
              handleSubmit={contextHandleSubmit}
            />
            <FirstAid
              isEditable={isEditable}
              control={control}
              handleSubmit={contextHandleSubmit}
            />
          </>
        )}
      </div>
    </div>
  );
};
