import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKeys, QueryKeys } from '../types';
import { CandidateMemo, CandidatesService } from 'src/Services/API';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { useSelector } from 'react-redux';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import { toOSMDate } from 'src/Utils/toOsmDate';

interface EditMemoParams {
  memoId: string;
  candidateId: string;
  brandCode?: string;
  comment: string;
  isBehaviorMemo: boolean;
}

export const useEditCandidateMemo = (
  extraOptions?: UseMutationOptions<string, unknown, EditMemoParams, unknown>
) => {
  const queryClient = useQueryClient();
  const currentBrand = useSelector(getCurrentBrand);

  return useMutation(
    [MutationKeys.editCandidateMemo],
    async (params: EditMemoParams) => {
      return await CandidatesService.candidatesControllerEditCandidateMemo({
        body: {
          memoId: params.memoId,
          candidateId: params.candidateId,
          brandCode: params.brandCode ?? currentBrand?.brandCodeApiHeader ?? '',
          comment: params.comment,
          isBehaviorMemo: params.isBehaviorMemo,
        },
      });
    },
    {
      ...extraOptions,
      onMutate: async editedMemo => {
        await queryClient.cancelQueries({
          queryKey: [QueryKeys.fetchCandidateMemos, editedMemo.candidateId],
        });

        const previousMemos = queryClient.getQueryData<CandidateMemo[]>([
          QueryKeys.fetchCandidateMemos,
          editedMemo.candidateId,
        ]);

        const tempId = Math.random().toString(36).substr(2, 9);
        queryClient.setQueryData<CandidateMemo[]>(
          [QueryKeys.fetchCandidateMemos, editedMemo.candidateId],
          old =>
            old?.map(memo =>
              memo.id === editedMemo.memoId
                ? { ...memo, ...editedMemo, id: tempId, date: toOSMDate(moment()) }
                : memo
            )
        );

        return { previousMemos, tempId };
      },
      onError: (err, editedMemo, context) => {
        queryClient.setQueryData(
          [QueryKeys.fetchCandidateMemos, editedMemo.candidateId],
          context?.previousMemos
        );
        toast.error("Une erreur est survenue lors de l'édition du mémo, veuillez réessayer.");
      },
      onSettled: (data, error, editedMemo, context) => {
        if (data) {
          queryClient.setQueryData<CandidateMemo[]>(
            [QueryKeys.fetchCandidateMemos, editedMemo.candidateId],
            old => old?.map(memo => (memo.id === context?.tempId ? { ...memo, id: data } : memo))
          );
        }
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchCandidateMemos, editedMemo.candidateId],
        });
      },
    }
  );
};
