import styles from './TalentAddress.module.scss';
import { Props } from './TalentAddress.types';

export const TalentAddress = ({ address }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>adresse</div>
      <div className={styles.address}>{address}</div>
    </div>
  );
};

export default TalentAddress;
