import { Table } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useInView } from 'react-intersection-observer';
import { useMatch } from 'react-router-dom';
import Dot from 'src/Components/Dot';
import RepositioningModal from 'src/Routes/Talents/Candidates/RepositioningModal';
import { useFetchCandidatesPlanning } from 'src/Hooks/Candidate/useFetchCandidatesPlanning';
import { TopBar } from 'src/Components/TopBar';

import styles from './Candidates.module.scss';
import CandidatesActions from './CandidatesActions';
import CDITopBarComponent from 'src/Components/TopBar/CDITopBarComponent/CDITopBarComponent.component';

export const Candidates = () => {
  const { ref, inView } = useInView();
  const urlElements = useMatch<'candidateId', '/cdi/candidats/repositioning/:candidateId'>(
    '/cdi/candidats/repositioning/:candidateId'
  );
  const {
    data: candidatesPages,
    fetchNextPage,
    isSuccess,
    isFetchingNextPage,
    isLoading,
  } = useFetchCandidatesPlanning();

  const [repositioningCandidateId, setRepositioningCandidateId] = useState<string | undefined>();

  const candidates = (candidatesPages?.pages ?? []).flat();

  const getEndMissionStyle = (date: Date | undefined) => {
    switch (true) {
      case date === undefined:
        return styles.endMission;
      case date && date > moment().add(7, 'days').toDate():
        return styles.endMissionInMoreThan7Days;
      case date && date > moment().add(3, 'days').toDate():
        return styles.endMissionInMoreThan3Days;
      default:
        return styles.endMissionInLessThan3Days;
    }
  };

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, candidates.length]);

  return (
    <>
      <TopBar title="candidats">
        <CDITopBarComponent />
      </TopBar>
      <div className={styles.content}>
        <div className={styles.containerWithModal}>
          <div className={styles.globalContainer}>
            <div
              className={classnames(styles.container, {
                [styles.background]: urlElements?.params.candidateId !== undefined,
              })}
            >
              <div className={styles.candidateListContainer}>
                <div className={styles.candidatesContainerTopBar}>vision planning</div>
                {isLoading &&
                  candidates.length === 0 &&
                  Array.from({ length: 10 }).map((_, idx) => {
                    return (
                      <ContentLoader height="3.5rem" width="100%" uniqueKey="candidateCard">
                        <rect x="1%" y="10" rx="4" ry="4" width="80%" height="16" />
                        <rect x="1%" y="30" rx="4" ry="4" width="80%" height="16" />
                      </ContentLoader>
                    );
                  })}
                {(isSuccess || (isLoading && candidates.length > 0)) && (
                  <>
                    <Table
                      headers={[
                        { label: ' ' },
                        { label: 'nom / prénom' },
                        { label: 'qualification principale' },
                        { label: 'fin mission' },
                        { label: 'gmmr' },
                        { label: ' ' },
                      ]}
                      data={candidates.map(candidate => {
                        return [
                          <Dot className={styles.dot} />,
                          <p className={styles.tableElementName}>
                            {`${candidate.name} ${candidate.firstName} `}
                          </p>,
                          <p className={styles.tableElementQualif}>
                            {candidate.mainQualification?.label
                              ? candidate.mainQualification.label.toLowerCase()
                              : ''}
                          </p>,
                          <div className={getEndMissionStyle(candidate.lastContractEndDate)}>
                            <div className={styles.endMissionText}>
                              {candidate.lastContractEndDate?.toLocaleDateString('en-GB')}
                            </div>
                          </div>,
                          <div className={styles.gmmr}>{`${candidate.GMMR?.toString()} k€`}</div>,
                          <div className={styles.actionButton}>
                            <CandidatesActions
                              candidateId={candidate.id}
                              startRepositioning={() => setRepositioningCandidateId(candidate.id)}
                            />
                          </div>,
                        ];
                      })}
                      className={styles.table}
                    />
                    <div ref={ref}>
                      {isFetchingNextPage && (
                        <ContentLoader height="3.5rem" width="100%" uniqueKey={'candidateCard'}>
                          <rect x="1%" y="10" rx="4" ry="4" width="80%" height="16" />
                          <rect x="1%" y="30" rx="4" ry="4" width="80%" height="16" />
                        </ContentLoader>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className={styles.planningPart}></div>
            </div>
          </div>
          {repositioningCandidateId !== undefined && (
            <RepositioningModal
              candidateId={repositioningCandidateId}
              onClose={() => setRepositioningCandidateId(undefined)}
            />
          )}
        </div>
      </div>
    </>
  );
};
