import moment from 'moment';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { MissionsService } from 'src/Services/API';

import { QueryKeys } from '../types';

const GET_MISSIONS_SEARCH_LIMIT = 20;

export const useFetchMissionsForRepositioning = ({
  qualificationIds,
  departmentIds,
}: {
  qualificationIds: string[];
  departmentIds: string[];
}) => {
  const brandCode = useSelector(getCurrentBrandCode)!;
  return useInfiniteQuery(
    [QueryKeys.fetchMissionsForRepositioning, brandCode, qualificationIds, departmentIds],

    ({ pageParam: offset = 0 }) => {
      if (!brandCode) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return MissionsService.missionsControllerGetMissions({
        body: {
          offset,
          limit: GET_MISSIONS_SEARCH_LIMIT,
          startDate: moment(new Date()).toISOString(),
          endDate: moment(new Date()).add(3, 'years').toISOString(),
          brandCode,
          salesphases: ['AS', 'SP'],
          qualificationIds,
          departmentIds,
          withCount: true,
        },
      });
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.missions?.length === GET_MISSIONS_SEARCH_LIMIT
          ? (pages.length - 1) * GET_MISSIONS_SEARCH_LIMIT + lastPage.missions.length
          : undefined,
    }
  );
};
