import { capitalizeNames } from 'src/Utils/capitalizeNames';
import styles from './TalentZipCodeAndCity.module.scss';
import { Props } from './TalentZipCodeAndCity.types';

export const TalentZipCodeAndCity = ({ zipCode, city }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>code postal et ville</div>
      <div className={styles.text}>{`${zipCode} - ${capitalizeNames(city)}`}</div>
    </div>
  );
};

export default TalentZipCodeAndCity;
