import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CandidateMemo, CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCandidateMemos = (
  id: string,
  extraOptions?: UseQueryOptions<CandidateMemo[], unknown, CandidateMemo[], (string | QueryKeys)[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateMemos, id],
    async () => {
      return await CandidatesService.candidatesControllerGetCandidateMemos({
        id: id,
      });
    },
    { staleTime: Infinity, enabled: id !== undefined && id !== '', ...extraOptions }
  );
};
