import { Button, PopupMenuContext } from '@randstad-lean-mobile-factory/react-components-core';
import { useContext } from 'react';
import styles from './QualificationPopupValidateButton.module.scss';

const QualificationPopupValidateButton = () => {
  const context = useContext(PopupMenuContext);
  return (
    <Button.Primary
      className={styles.validateButton}
      onClick={() => {
        context?.().actions?.close();
      }}
    >
      valider
    </Button.Primary>
  );
};

export default QualificationPopupValidateButton;
