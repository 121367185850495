import { SkillMatchingMission } from 'src/Services/API';

export interface Props {
  missionId: string;
  candidateId?: string;
  isRepositioningTalent?: boolean;
  isSkillMatching?: boolean;
  SkillMatchingMission?: SkillMatchingMission;
}

export interface OwnProps {
  missionId?: string;
  candidateId?: string;
  isRepositioningTalent?: boolean;
  isSkillMatching?: boolean;
  SkillMatchingMission?: SkillMatchingMission;
}

export enum MISSION_TAB {
  TALENTS = 'intérimaires',
  INFORMATIONS = 'informations commande',
}
export enum SKILL_MATCHING_MISSION_TAB {
  TALENTS = 'intérimaires',
  INFORMATIONS = 'informations commande',
  SKILL_MATCHING = 'compatibilité des compétences',
}
