import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import SearchButton from '../SearchButton';
import { TypeAndEnter } from '@randstad-lean-mobile-factory/react-components-core';
import styles from './ExternalDemandsPreferencesTopBarComponent.module.scss';
import { ExternalDemandPreferencesActions } from 'src/Redux/ExternalDemandPreferences/Slice';

const ExternalDemandsPreferencesTopBarComponent = () => {
  const dispatch = useDispatch();

  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');

  const searchBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
        setSearchOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchBarRef]);

  useEffect(() => {
    dispatch(ExternalDemandPreferencesActions.setSearchInput(searchValue));
  }, [dispatch, searchValue]);

  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        {searchOpen || Boolean(searchValue) ? (
          <div className={styles.searchBar} ref={searchBarRef}>
            <TypeAndEnter
              selectedValue={searchValue}
              setSelectedValue={newValue => setSearchValue(newValue)}
              placeholder="rechercher"
              textInputContainerClassName={styles.textInputContainerClassName}
              hideReinitialisation={true}
            />
          </div>
        ) : (
          <SearchButton searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
        )}
      </div>
    </div>
  );
};
export default ExternalDemandsPreferencesTopBarComponent;
