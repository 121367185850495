import { GoBackArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import WorkAccidentAnalysisTopBarComponent from 'src/Components/TopBar/WorkAccidentAnalysisTopBarComponent/WorkAccidentAnalysisTopBarComponent.component';
import { useFetchWorkAccidentByIds } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentByIds';
import { ROUTES } from 'src/Routes/Routes.types';
import { SidebarAT } from './SidebarWorkAccidentAnalysis';
import styles from './WorkAccidentAnalysisDetail.module.scss';

export const ATComponentURLS = ['ATArchive', 'archivedAT'];

export const WorkAccidentAnalysisDetailRoute = () => {
  const navigate = useNavigate();
  const { analysisId } = useTypedParams(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL);

  // TODO changer cette logique
  const workAccident = useFetchWorkAccidentByIds({
    analyseIds: [ATComponentURLS.includes(analysisId) ? '' : analysisId],
  });

  return (
    <div className={styles.mainContainer}>
      <button
        className={styles.goBack}
        onClick={() => navigate(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.buildPath({}))}
      >
        <GoBackArrow />
        retour à la liste
      </button>
      <TopBar title="analyse AT">
        <WorkAccidentAnalysisTopBarComponent />
      </TopBar>
      <div className={classnames(styles.container, styles.page)}>
        <div className={styles.containerAT}>
          <div className={styles.sidebar}>
            <SidebarAT
              candidateId={workAccident.data?.[0]?.candidate?.id ?? ''}
              candidateFullName={`${workAccident.data?.[0]?.candidate?.firstName} ${workAccident.data?.[0]?.candidate?.name}`}
              qualification={workAccident.data?.[0]?.contractQualification?.label ?? ''}
            />
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
