import { useQuery } from '@tanstack/react-query';
import { RepositoryService } from 'src/Services/API';
import { QueryKeys } from 'src/Hooks/types';

const useFetchDepartments = () => {
  return useQuery([QueryKeys.fetchDepartments], async () => {
    return RepositoryService.repositoryControllerGetDepartments();
  });
};

export default useFetchDepartments;
