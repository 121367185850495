import {
  Button,
  ModalDeprecated,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import React, { useRef } from 'react';
import { Props } from './ClosingWorkAccidentAnalysisModal.types';
import moment from 'moment';
import { BDPDeleteWarning } from '@randstad-lean-mobile-factory/react-components-ui-shared';

const ClosingWorkAccidentAnalysisModal = ({ trigger, onValidate }: Props) => {
  const ref = useRef<PopupActions>(null);

  const onClose = () => {
    ref.current?.close();
  };

  return (
    <ModalDeprecated
      ref={ref}
      onClose={onClose}
      trigger={trigger}
      icon={<BDPDeleteWarning />}
      title={`attention`}
      footerActionsRight={[
        <Button.Secondary key="cancelButton" onClick={onClose}>
          annuler
        </Button.Secondary>,
        <Button.Primary
          key="validateButton"
          onClick={() => {
            onValidate(true);
            onClose();
          }}
        >
          confirmer
        </Button.Primary>,
      ]}
    >
      <WithLightTitle title={`le ${moment().format('L')} à ${moment().format('LT')}`}>
        Vous vous apprêtez à finaliser cette analyse AT, elle va maintenant être transmise au
        service at et une copie va être ajoutée au dossier osmose du client{' '}
      </WithLightTitle>
    </ModalDeprecated>
  );
};

export default ClosingWorkAccidentAnalysisModal;
