import React from 'react';
import classnames from 'classnames';
import { Props } from './YouplanMissionModalContent.types';
import styles from './YouplanMissionModalContent.module.scss';
import { redCloud, illusDetail } from 'src/Assets_DEPRECATED';
import KVLine from 'src/Components/KVLine';
import moment from 'moment';
import { FETCH_STATUS } from 'src/Redux/Types';
import YouplanMissionModalLoader from './YouplanMissionModalLoader';
import {
  Button,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useNavigate } from 'react-router-dom';
import { isRenewalEndorsement } from 'src/Utils/contracts';
import { OSMToTimeInput } from 'src/Utils/datetime';

const YouplanMissionModalContent = (props: Props) => {
  const navigate = useNavigate();
  const renewalContractCount = props.candidateCurrentContract?.endorsements?.filter(endorsement =>
    isRenewalEndorsement(endorsement.modalityType?.id ?? '')
  ).length;
  return (
    <ModalDeprecated
      open={props.open ?? true}
      onClose={() => navigate(-1)}
      icon={<img src={illusDetail} alt="illusDetail" />}
      key={'key'}
      nested
      title={
        props.fetchStatus === FETCH_STATUS.FULFILLED && props.youplanMission?.candidates
          ? `détails sur ${props.youplanMission.candidates[0].firstName} ${props.youplanMission?.candidates[0].name}`
          : 'Chargement'
      }
      footerActionsLeft={
        props.isPendingMission
          ? [
              <Button.Tertiary
                onClick={() => {
                  navigate(-1);
                }}
              >
                annuler
              </Button.Tertiary>,
            ]
          : []
      }
      footerActionsRight={
        props.isPendingMission
          ? [
              <Button.Primary
                disabled={props.fetchStatus !== FETCH_STATUS.FULFILLED}
                onClick={() => {
                  props.onCreateMission();
                }}
              >
                créer commande
              </Button.Primary>,
            ]
          : []
      }
    >
      <div className={styles.container}>
        {props.fetchStatus === FETCH_STATUS.FULFILLED && (
          <>
            <div className={styles.datesContainer}>
              <WithLightTitle title="missionné">
                <p
                  className={classnames(styles.dateValue, {
                    [styles.emptyDate]: props.youplanMission?.startDate === undefined,
                  })}
                  style={{ textAlign: 'left' }}
                >
                  {moment(props.youplanMission?.startDate).isSame(props.youplanMission?.endDate)
                    ? `le ${moment(props.youplanMission?.startDate).format('L')}`
                    : `du ${moment(props.youplanMission?.startDate).format('L')} au ${moment(
                        props.youplanMission?.endDate
                      ).format('L')}`}
                </p>
              </WithLightTitle>
              {props.candidateCurrentContract?.contractId &&
                moment(props.candidateCurrentContract.missionActualEndDate).isSameOrAfter(
                  moment()
                ) && (
                  <WithLightTitle
                    title={`du ${moment(props.candidateCurrentContract?.missionStartDate).format(
                      'L'
                    )} au ${moment(props.candidateCurrentContract?.missionActualEndDate).format(
                      'L'
                    )}`}
                  >
                    <p className={styles.dateValue} style={{ textAlign: 'right' }}>
                      {renewalContractCount
                        ? `${renewalContractCount.toString()}e${
                            renewalContractCount === 1 ? 'r' : ''
                          } renouvellement`
                        : 'contrat initial'}
                    </p>
                  </WithLightTitle>
                )}
            </div>
            {/* <div className={styles.serviceContainer}>
              <KVLine
                title="service client"
                value={props.youplanMission?.service?.name}
                emptyMessage="Pas de service renseigné"
              />
            </div> */}
            <div className={styles.qualificationContainer}>
              <KVLine
                title="qualification"
                value={props.youplanMission?.qualification?.label}
                emptyMessage="Pas de qualification renseignée"
              />
            </div>
            <div className={styles.horaires}>
              <KVLine
                title="heure de début"
                value={
                  props.youplanMission?.startHour
                    ? OSMToTimeInput(props.youplanMission.startHour)
                    : undefined
                }
                emptyMessage="Horaire non renseigné"
              />
            </div>
            <div className={styles.commentContainer}>
              <KVLine
                title="commentaire"
                value={props.youplanMission?.comment}
                emptyMessage="Aucun commentaire renseigné"
              />
            </div>
          </>
        )}
        {props.fetchStatus === FETCH_STATUS.PENDING && (
          <>
            <YouplanMissionModalLoader />
          </>
        )}
        {props.fetchStatus === FETCH_STATUS.REJECTED && (
          <>
            <div className={styles.noResult}>
              <img src={redCloud} alt="illusEscalier" />
              <div className={styles.noResultText}>Une erreur est survenue</div>
            </div>
          </>
        )}
      </div>
    </ModalDeprecated>
  );
};

export default YouplanMissionModalContent;
