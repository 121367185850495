import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { DayTimes } from 'src/Components/DayTimePicker/DayTimePicker.types';
import {
  getIsFromYouplanMission,
  getMissionDataFetchStatus,
  getNumberPositions,
  getProcessedMission,
  getSelectedCompany,
} from 'src/Redux/MissionCreation/Selectors';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { RootState } from 'src/Redux/RootReducer';
import {
  CompanyContact,
  CompanyRequestReason,
  CompanyService,
  MissionDetail,
} from 'src/Services/API';
import SecondContent from './SecondContent.component';

const MemoSecondContent = React.memo(SecondContent);

const EnhancedSecondContent = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const setProcessedMission = useCallback(
    (mission: MissionDetail) => dispatch(missionCreationActions.setProcessedMission(mission)),
    [dispatch]
  );
  const mission = useSelector(getProcessedMission);
  const missionDataFetchStatus = useSelector(getMissionDataFetchStatus);
  const selectedCompany = useSelector(getSelectedCompany);
  const branCode = useSelector(getCurrentBrandCode);
  const isFromYouplan = useSelector(getIsFromYouplanMission);
  const numberPositions = useSelector(getNumberPositions);

  const updateWorkingTime = useCallback(
    (workingTime: DayTimes, index: number) =>
      dispatch(missionCreationActions.updateSelectedWorkingTime({ workingTime, index })),
    [dispatch]
  );
  const addWorkingTime = useCallback(() => dispatch(missionCreationActions.addWorkingTime()), [
    dispatch,
  ]);
  const eraseWorkingTime = useCallback(
    (index: number) => dispatch(missionCreationActions.eraseWorkingTime({ index })),
    [dispatch]
  );
  const setSelectedContact = useCallback(
    (contact: CompanyContact) => {
      dispatch(missionCreationActions.setSelectedContact({ contact }));
    },
    [dispatch]
  );
  const setSelectedService = useCallback(
    (service?: CompanyService) => {
      if (service !== undefined) {
        dispatch(missionCreationActions.setSelectedService({ service }));
      }
    },
    [dispatch]
  );
  const setSelectedRequestReason = useCallback(
    (requestReason?: CompanyRequestReason) => {
      dispatch(missionCreationActions.setSelectedRequestReason({ requestReason }));
    },
    [dispatch]
  );
  const setNumberPositions = useCallback(
    (numberPositions: number) => {
      dispatch(missionCreationActions.setNumberPositions(numberPositions));
    },
    [dispatch]
  );

  return (
    <MemoSecondContent
      mission={mission}
      missionDataFetchStatus={missionDataFetchStatus}
      onMissionUpdate={setProcessedMission}
      selectedCompany={selectedCompany}
      brandCode={branCode}
      onWorkingTimesUpdate={updateWorkingTime}
      addWorkingTime={addWorkingTime}
      eraseWorkingTime={eraseWorkingTime}
      setSelectedContact={setSelectedContact}
      setSelectedService={setSelectedService}
      setSelectedRequestReason={setSelectedRequestReason}
      isFromYouplan={isFromYouplan}
      setNumberPositions={setNumberPositions}
      numberPositions={numberPositions}
    />
  );
};

export default EnhancedSecondContent;
