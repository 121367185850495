import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceState } from './Types';
import { fetchServices } from './Thunks';
import { FETCH_STATUS } from 'src/Redux/Types';

const initialState: ServiceState = {
  services: [],
  currentService: undefined,
  fetchStatus: FETCH_STATUS.FULFILLED,
  currentRequestId: undefined,
};

export const { reducer: servicesReducer, actions: servicesActions } = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setCurrentService: (state, action: PayloadAction<ServiceState['currentService']>) => {
      state.currentService = action.payload;
    },
    resetServices: state => {
      state.services = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchServices.pending, (state, action) => {
      state.fetchStatus = FETCH_STATUS.PENDING;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentRequestId === action.meta.requestId) {
        state.services = action.payload;
        state.fetchStatus = FETCH_STATUS.FULFILLED;
      }
    });
    builder.addCase(fetchServices.rejected, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentRequestId === action.meta.requestId) {
        state.fetchStatus = FETCH_STATUS.REJECTED;
      }
    });
  },
});
