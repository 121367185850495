import { Props } from './CalendarDayCube.types';
import styles from './CalendarDayCube.module.scss';
import classnames from 'classnames';
import { EnumActivityPlanningDayStatus } from 'src/Services/API';

const getColorForCube = (status: EnumActivityPlanningDayStatus): string => {
  switch (status) {
    case EnumActivityPlanningDayStatus.OutOfPermanentContract:
      return styles.noContract;
    case EnumActivityPlanningDayStatus.Intermission:
    case EnumActivityPlanningDayStatus.HrAbsence:
      return styles.intermission;
    case EnumActivityPlanningDayStatus.Weekend:
      return styles.weekEnd;
    case EnumActivityPlanningDayStatus.ValidatedAbsence:
    case EnumActivityPlanningDayStatus.UnvalidatedAbsence:
    case EnumActivityPlanningDayStatus.ValidatedAbsenceCompletedTimeTrackings:
    case EnumActivityPlanningDayStatus.UnvalidatedAbsenceCompletedTimeTrackings:
    case EnumActivityPlanningDayStatus.ValidatedAbsenceUncompletedTimeTrackings:
    case EnumActivityPlanningDayStatus.UnvalidatedAbsenceUncompletedTimeTrackings:
      return styles.absence;
    default:
      return styles.mission;
  }
};

const getTitleForCube = (status: EnumActivityPlanningDayStatus): string => {
  switch (status) {
    case EnumActivityPlanningDayStatus.OutOfPermanentContract:
      return 'Pas de contrat CDII';
    case EnumActivityPlanningDayStatus.Intermission:
      return 'Intermission';
    case EnumActivityPlanningDayStatus.HrAbsence:
    case EnumActivityPlanningDayStatus.UncompletedTimeTrackings:
      return "Absence de relevé d'heure";
    case EnumActivityPlanningDayStatus.Weekend:
      return 'Weekend';
    case EnumActivityPlanningDayStatus.ValidatedAbsence:
    case EnumActivityPlanningDayStatus.UnvalidatedAbsence:
    case EnumActivityPlanningDayStatus.ValidatedAbsenceCompletedTimeTrackings:
    case EnumActivityPlanningDayStatus.UnvalidatedAbsenceCompletedTimeTrackings:
    case EnumActivityPlanningDayStatus.ValidatedAbsenceUncompletedTimeTrackings:
    case EnumActivityPlanningDayStatus.UnvalidatedAbsenceUncompletedTimeTrackings:
      return 'Absence';
    case EnumActivityPlanningDayStatus.CompletedTimeTrackings:
      return 'Rh saisi';
    case EnumActivityPlanningDayStatus.BankHoliday:
      return 'Jour férié';
    case EnumActivityPlanningDayStatus.Day:
      return 'Jour';
    case EnumActivityPlanningDayStatus.Suspension:
      return 'Suspension';
    case EnumActivityPlanningDayStatus.Capped:
      return 'Plafonné';
  }
};

const CalendarDayCube = ({ status }: Props) => {
  const color = getColorForCube(status);
  const title = getTitleForCube(status);
  return <div title={title} className={classnames(styles.cubeShape, color)} />;
};

export default CalendarDayCube;
