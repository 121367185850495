import { useSelector } from 'react-redux';
import { redCloud } from 'src/Assets_DEPRECATED';
import CardList from 'src/Components/CardList';
import { FETCH_STATUS } from 'src/Redux/Types';
import { getCanLoadMore } from 'src/Redux/YouplanCompanies/Selectors';
import styles from './ListYouplanMissions.module.scss';
import { Props } from './ListYouplanMissions.types';

const ListYouplanMissions = (props: Props): JSX.Element => {
  const canLoadMore = useSelector(getCanLoadMore);

  const title =
    props.fetchStatus === FETCH_STATUS.PENDING
      ? `clients en cours de récupération`
      : `${props.cards.length} client${props.cards.length > 1 ? 's' : ''}`;

  if (props.fetchStatus === FETCH_STATUS.REJECTED) {
    return (
      <div className={styles.error}>
        <img src={redCloud} alt="error-img" />
        <p>Une erreur est survenue</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.total}>{title}</div>
      <div className={styles.cardsContainer}>
        <CardList
          cards={props.cards}
          fetchStatus={props.fetchStatus}
          selectedId={props.selectedId}
          canLoadMore={canLoadMore}
          onLoadMore={props.onLoadMore}
        />
      </div>
    </div>
  );
};

export default ListYouplanMissions;
