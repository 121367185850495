import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CandidateContract, CandidatesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCandidateContracts = (
  id: string,
  extraOptions?: UseQueryOptions<unknown, unknown, CandidateContract[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateContracts, id],
    async () => {
      const contracts = await CandidatesService.candidatesControllerGetCandidateContract({
        id: id,
      });
      return contracts;
    },
    { enabled: Boolean(id), ...extraOptions }
  );
};
