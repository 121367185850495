import { Endorsement } from 'src/Services/API';

export function isRenewalEndorsement(id: string): boolean {
  return ['02', '04'].includes(id);
}

export const renewalEndorsementReducer = (
  isRenewalEndorsementTable: boolean[],
  endorsement: Endorsement
) => {
  if (isRenewalEndorsement(endorsement.modalityType?.id ?? '')) {
    isRenewalEndorsementTable.push(true);
  }
  return isRenewalEndorsementTable;
};
