import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { Flux, ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useState } from 'react';
import CandidateDetailsModal from 'src/Routes/Talents/Candidates/CandidatesActions/CandidateDetailsModal';
import styles from './CandidatesActions.module.scss';
import { Props } from './CandidatesActions.types';

const CandidatesActions = ({ candidateId, startRepositioning }: Props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <PopupMenu
      position={'left bottom'}
      width={200}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpen })}>
          <ThreeDots className={styles.menuIcon} />
        </Button.Tertiary>
      }
    >
      <CandidateDetailsModal candidateId={candidateId ?? ''} />
      <PopupMenu.Item
        icon={<Flux />}
        text="repositionner le candidat"
        onClick={startRepositioning}
      />
    </PopupMenu>
  );
};

export default CandidatesActions;
