import { z } from 'zod';
import {
  AccidentLocation,
  CausedByThirdParty,
  FirstAidPersonType,
  Laterality,
} from 'src/Services/API';

export const contextSchema = z.object({
  laterality: z.nativeEnum(Laterality).optional(),
  firstAidPersonType: z.nativeEnum(FirstAidPersonType).optional(),
  personLastName: z.string().optional(),
  personFirstName: z.string().optional(),
  companyName: z.string().optional(),
  victimPhoneNumber: z.string().optional(),
  causedByThirdParty: z.nativeEnum(CausedByThirdParty).optional(),
  accidentLocation: z.nativeEnum(AccidentLocation).optional(),
  registeredRisk: z.boolean().optional(),
  existingRisk: z.boolean().optional(),
  hasWitnesses: z.boolean().optional(),
  witnesses: z
    .array(
      z.object({
        contactId: z.string().optional(),
        firstName: z.string().optional(),
        name: z.string(),
        position: z.string(),
        societyId: z.string().optional(),
        societyName: z.string(),
        phoneNumber: z.string().optional(),
      })
    )
    .optional(),
  trainingEvaluation: z.boolean().optional(),
  referents: z
    .array(
      z.object({
        contactId: z.string().optional(),
        firstName: z.string().optional(),
        name: z.string(),
        position: z.string(),
      })
    )
    .optional(),
  welcomeDate: z.date().optional(),
  positionStudyDate: z.date().optional(),
  trainingDate: z.date().optional(),
  accidentDate: z.date().optional(),
  accidentTime: z.string().optional(),
  workingDayStartTime: z.string().optional(),
  workingDayEndTime: z.string().optional(),
  seniorityAtRandstad: z.number().optional(),
  seniorityAtClient: z.number().optional(),
  daysOff: z.number().optional(),
  otherFirstAidPerson: z.string().optional(),
});

export type RiskSchemaType = z.infer<typeof contextSchema>;
