import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import ExternalDemandsInit from 'src/Components/ExternalDemandsInit';
import { useFetchAgencyBrandCode } from 'src/Hooks/ExternalDemands/useFetchAgencyBrandCode';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import styles from './ExternalDemands.module.scss';

export const ExternalDemandsRoute = () => {
  const agencyId = useSelector(getCurrentAgency);
  const { data, isLoading } = useFetchAgencyBrandCode(agencyId ?? '');

  return (
    <>
      {isLoading ? (
        <div className={styles.loadingScreen}>
          <Loader heightInRem={4} className={styles.loader} />
          <div>chargement des demandes externes, cela peut prendre du temps</div>
        </div>
      ) : data === '' ? (
        <ExternalDemandsInit agencyId={agencyId ?? ''} />
      ) : (
        <Outlet />
      )}
    </>
  );
};
