import {
  RadioButtonGroup,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusEscalier } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import { teaCup } from 'src/Assets_DEPRECATED';
import SelectCompany from 'src/Components/Selects/SelectCompany';
import SelectQualification from 'src/Components/Selects/SelectQualification';
import { FETCH_STATUS } from 'src/Redux/Types';
import MissionCreationLoader from '../MissionCreationLoader';
import styles from './PreviousPositionStudies.module.scss';
import { Props } from './PreviousPositionStudies.types';

const PreviousPositionStudies = (props: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.searchFieldCompany}>
          <WithLightTitle title="compte">
            <SelectCompany
              selectedCompany={props.selectedCompany}
              setSelectedCompany={selectedCompany => {
                props.setSelectedCompany(selectedCompany);
              }}
              agency={props.currentAgency ?? ''}
              brandCode={props.currentBrandcode?.brandCodeApiHeader ?? ''}
              canBeReset={false}
              defaultValue={props.selectedCompany?.companyName}
            />
          </WithLightTitle>
        </div>
        <div className={styles.searchFieldQualification}>
          <WithLightTitle title="qualification">
            <SelectQualification
              selectedQualification={props.selectedQualification}
              setSelectedQualification={selectedQualification => {
                props.setSelectedQualification(selectedQualification);
              }}
              // We specify no society / brand to have the same behavior as OSM
              canBeReset={false}
              defaultValue={props.selectedQualification?.label}
            />
          </WithLightTitle>
        </div>
      </div>
      {props.previousPositionStudiesFetchStatus === FETCH_STATUS.PENDING && (
        <MissionCreationLoader />
      )}
      {props.previousPositionStudiesFetchStatus === FETCH_STATUS.FULFILLED && (
        <>
          {props.previousPositionStudies?.length !== 0 ? (
            <>
              <div className={styles.title}>
                <p>nom de l'edp</p>
                <p>dernière modification</p>
              </div>
              {props.previousPositionStudies?.map((positionStudy, idx) => (
                <div
                  className={classnames(styles.previousPositionStudy, {
                    [styles.topPositionStudy]: idx === 0,
                  })}
                >
                  <RadioButtonGroup
                    name="previousPositionStudies"
                    radioButtonsInfo={[
                      {
                        id: positionStudy.positionStudyId ?? ' ',
                        value: positionStudy.positionStudyId ?? ' ',
                        label: (
                          <div className={styles.buttonContent}>
                            <div className={styles.positionStudyInfo}>
                              <p className={styles.positionStudyName}>
                                {positionStudy.positionStudyName}
                              </p>
                              <p className={styles.serviceName}>{positionStudy.service?.name}</p>
                            </div>
                            <div className={styles.modificationDate}>
                              <p>
                                {moment(positionStudy.modificationDate).format('DD/MM/Y') ??
                                  '--/--/--'}
                              </p>
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    onChange={() => {
                      props.setSelectedPreviousPositionStudy(positionStudy);
                    }}
                    selected={props.selectedPreviousPositionStudy?.positionStudyId}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className={styles.error}>
              <img src={teaCup} alt="teacup" />
              <p className={styles.noResultTitle}>aucune étude de poste pour :</p>
              <p className={styles.noResultText}>
                - le compte {props.selectedCompany?.companyName}
              </p>
              <p className={styles.noResultText}>
                - la qualification {props.selectedQualification?.label}
              </p>
            </div>
          )}
        </>
      )}
      {props.previousPositionStudiesFetchStatus === FETCH_STATUS.REJECTED && (
        <div className={styles.noResult}>
          <IllusEscalier />
          <div className={styles.noResultText}>Une erreur est survenue</div>
        </div>
      )}
    </div>
  );
};

export default PreviousPositionStudies;
