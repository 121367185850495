import ContentLoader from 'react-content-loader';

import PopOverContact from 'src/Components/PopOverContact';
import { EnumCreateActionBodyActionTarget } from 'src/Services/API';
import styles from './PotentialQualificationClientCard.module.scss';
import { Props } from './PotentialQualificationClientCard.types';

export const LoadingCard = () => (
  <ContentLoader uniqueKey="loadingCard" height="117px" width="100%">
    {[
      { width: '80%', height: '1.5rem', y: '0' },
      { width: '60%', height: '0.65rem', y: '1.8rem' },
      { width: '60%', height: '0.65rem', y: '2.7rem' },
      { width: '70%', height: '0.75rem', y: '4rem' },
      { width: '90%', height: '0.85rem', y: '5rem' },
      { width: '60%', height: '0.75rem', y: '6rem' },
    ].map((props, idx) => (
      <rect key={idx} x="0" rx="4" ry="4" {...props} />
    ))}
  </ContentLoader>
);

const PotentialQualificationClientCard = ({ potentialQualificationClient }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {potentialQualificationClient.client.companyName ?? 'client non renseigné'}
      </div>
      <p className={styles.subtitle}>
        {potentialQualificationClient.potentialQualification.label ??
          'qualification non renseignée'}
      </p>
      <p className={styles.subtitle}>
        {potentialQualificationClient.client.contact?.firstName &&
        potentialQualificationClient.client.contact?.name
          ? `${potentialQualificationClient.client.contact?.firstName} ${potentialQualificationClient.client.contact?.name}`
          : 'non du contact non renseigné'}
      </p>
      {potentialQualificationClient.client?.contact && (
        <PopOverContact
          target={EnumCreateActionBodyActionTarget.CONTACT}
          contact={{ ...potentialQualificationClient.client.contact, lastContact: undefined }}
          triggerButton={<button className={styles.contact}>contacter ce client</button>}
          position="right bottom"
          enableEditMissionStatus={false}
        />
      )}
    </div>
  );
};

export default PotentialQualificationClientCard;
