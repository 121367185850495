import { Props } from './TalentInformationHeader.types';
import styles from './TalentInformationHeader.module.scss';
import { FETCH_STATUS } from 'src/Redux/Types';
import RecrutLiveProfile from 'src/Components/RecrutLiveProfile/RecrutLiveProfile.component';
import MemoModal from 'src/Modals/MemoModal';

const TalentInformationHeader = ({ talent }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleInfoTalent}>informations talent</div>
      <div className={styles.buttonsContainer}>
        <div className={styles.profileButton}>
          <RecrutLiveProfile candidateId={talent?.talentId} text={'consulter dossier talent'} />
        </div>
        <MemoModal
          memos={talent?.memos ?? []}
          fetchStatus={FETCH_STATUS.FULFILLED}
          candidateId={talent?.talentId ?? ''}
        />
      </div>
    </div>
  );
};

export default TalentInformationHeader;
