import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { YouplanMissionsService } from 'src/Services/API';

export const useSetProcessingDateYouPlanMission = (
  opts?: UseMutationOptions<unknown, unknown, { id?: string }>
) => {
  return useMutation(async ({ id }: { id?: string }) => {
    if (!id) {
      throw new Error('Invalid values');
    }
    return YouplanMissionsService.youplanMissionsControllerSetProcessingDateYouPlanMission({
      id,
    });
  }, opts);
};
