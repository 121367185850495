import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AppelMedicalEventSource, ExternalDemandsService } from 'src/Services/API';
import { MutationKeys } from '../types';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';

interface ReplayExternalDemandsParams {
  agencyId: string;
  source: AppelMedicalEventSource;
  sourceIds: string[];
}

export const useReplayExternalDemands = (
  extraOptions?: UseMutationOptions<void, unknown, ReplayExternalDemandsParams, unknown>
) => {
  return useMutation(
    [MutationKeys.replayExternalDemands],
    async (params: ReplayExternalDemandsParams) => {
      return await ExternalDemandsService.externalDemandsControllerReplayCommandsError({
        body: params,
      });
    },
    {
      ...extraOptions,
      onSuccess: () => {
        toast.success(
          "la modification du roulement a bien été enregistrée, elle peut prendre jusqu'à 30 minutes à être prise en compte"
        );
      },
    }
  );
};
