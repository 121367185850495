import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  CandidateMemo,
  CandidatesService,
  ConsultantInfo,
  OSMConsultantModelDtoFullConsultantDto,
} from 'src/Services/API';
import { MutationKeys, QueryKeys } from '../types';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { toOSMDate } from 'src/Utils/toOsmDate';
import moment from 'moment';

interface MemoCreationParams {
  candidateId: string;
  comment: string;
  isBehaviorMemo: boolean;
  consultant: OSMConsultantModelDtoFullConsultantDto;
}

export const useCreateCandidateMemo = (
  extraOptions?: UseMutationOptions<string, unknown, MemoCreationParams, unknown>
) => {
  const queryClient = useQueryClient();
  const currentBrand = useSelector(getCurrentBrand);

  return useMutation(
    [MutationKeys.createCandidateMemo],
    async (params: MemoCreationParams) => {
      return await CandidatesService.candidatesControllerCreateCandidateMemo({
        id: params.candidateId,
        body: {
          brandCode: currentBrand?.brandCodeApiHeader ?? '',
          comment: params.comment,
          isBehaviorMemo: params.isBehaviorMemo,
        },
      });
    },
    {
      ...extraOptions,
      onMutate: async newMemo => {
        await queryClient.cancelQueries({
          queryKey: [QueryKeys.fetchCandidateMemos, newMemo.candidateId],
        });

        const previousMemos = queryClient.getQueryData<CandidateMemo[]>([
          QueryKeys.fetchCandidateMemos,
          newMemo.candidateId,
        ]);

        const tempId = Math.random().toString(36).substr(2, 9);
        queryClient.setQueryData<CandidateMemo[]>(
          [QueryKeys.fetchCandidateMemos, newMemo.candidateId],
          old => [
            {
              ...newMemo,
              id: tempId,
              date: toOSMDate(moment()),
              consultant: new ConsultantInfo({
                consultant: newMemo.consultant,
              }),
            },
            ...(old || []),
          ]
        );

        return { previousMemos, tempId };
      },
      onError: (err, newMemo, context) => {
        queryClient.setQueryData(
          [QueryKeys.fetchCandidateMemos, newMemo.candidateId],
          context?.previousMemos
        );
        toast.error('Une erreur est survenue lors de la création du mémo, veuillez réessayer.');
      },
      onSettled: (data, error, newMemo, context) => {
        if (data) {
          queryClient.setQueryData<CandidateMemo[]>(
            [QueryKeys.fetchCandidateMemos, newMemo.candidateId],
            old => old?.map(memo => (memo.id === context?.tempId ? { ...memo, id: data } : memo))
          );
        }
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.fetchCandidateMemos, newMemo.candidateId],
        });
      },
    }
  );
};
