import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import styles from './ErrorFormField.module.scss';
import { Props } from './ErrorFormField.types';

function ErrorFormField<TFieldValues extends FieldValues>({ name }: Props<TFieldValues>) {
  const {
    formState: { errors },
  } = useFormContext<TFieldValues>();

  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <p className={styles.error}>{message}</p>}
    />
  );
}

export default ErrorFormField;
