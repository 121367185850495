import { useQuery } from '@tanstack/react-query';
import { RepositoryService } from 'src/Services/API';
import { QueryKeys } from 'src/Hooks/types';

const useFetchCGC = () => {
  return useQuery([QueryKeys.fetchCGC], async () => {
    return RepositoryService.repositoryControllerGetCgcRepository();
  });
};

export default useFetchCGC;
