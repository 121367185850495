import React from 'react';
import { MenuItems, Props } from './YouplanMissionActions.types';
import { PopupMenu, toast } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Check,
  ThreeDots,
  AddCross,
  Info,
  Continue,
  Trashcan,
  Pencil,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useMarkYouplanMissionsAsRead } from 'src/Hooks/Youplan/useMarkYouplanMissionsAsRead';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/Hooks/types';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFetchCurrentContractOfCandidate } from 'src/Hooks/Contracts/useFetchCurrentContractOfCandidate';
import { useResolveYouplanRenewalConflict } from 'src/Hooks/Youplan/useResolveYouplanRenewalConflict';
import {
  EnumCoreMissionCandidateCandidateAdvancement,
  EnumCoreMissionCandidateCompanyAdvancement,
  EnumYouplanMissionActionSummaryAction,
  EnumYouplanMissionActionSummaryLastConflictCause,
} from 'src/Services/API';
import { useCancelYouplanRelatedMission } from 'src/Hooks/Youplan/useCancelYouplanRelatedMission';
import { useUpdateYouplanRelatedMission } from 'src/Hooks/Youplan/useUpdateYouplanRelatedMission';
import { useRefuseCandidateFromYouplanRelatedMission } from 'src/Hooks/Youplan/useRefuseCandidateFromYouplanRelatedMission';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';

const YouplanMissionActions = ({ mission, candidateId, onInfoClick, onCreateMission }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: currentContract } = useFetchCurrentContractOfCandidate({ candidateId });
  const { mutate: markMissionAsRead } = useMarkYouplanMissionsAsRead();
  const { mutate: refuseCandidate } = useRefuseCandidateFromYouplanRelatedMission();
  const { mutate: markMissionAsResolved } = useResolveYouplanRenewalConflict({
    onSuccess: () => {
      toast.success('le conflit a été correctement marqué comme résolu');
    },
    onError: () => {
      toast.error('impossible de marquer le conflit comme résolu');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchYouplanMissions] });
    },
  });
  const { mutate: cancelYouplanRelatedMission } = useCancelYouplanRelatedMission({
    onSuccess: () => {
      toast.success('le candidat a bien été retiré de la commande');
    },
    onError: () => {
      toast.error('impossible de retirer le candidat de cette commande');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchYouplanMissions] });
    },
  });
  const { mutate: updateYouplanRelatedMission } = useUpdateYouplanRelatedMission({
    onSuccess: () => {
      toast.success('la commande associée a été correctement mise à jour');
    },
    onError: () => {
      toast.error('impossible de mettre à jour la commande associée');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchYouplanMissions] });
    },
  });

  const isCancelled = mission.salesPhase?.id === 'AN';

  const didRenewContract =
    mission.youplanStatus?.action === EnumYouplanMissionActionSummaryAction.CONTRACT_RENEWAL;
  const didCreateMission =
    mission.youplanStatus?.action === EnumYouplanMissionActionSummaryAction.MISSION_CREATION;
  const wasMarkedAsRead =
    mission.youplanStatus?.action === EnumYouplanMissionActionSummaryAction.MARKED_AS_READ;

  const hasAnAction = mission.youplanStatus?.action !== undefined;
  const isInConflict = mission.youplanStatus?.isInConflict ?? false;

  const isUnhandledCancelation = isCancelled && hasAnAction && !wasMarkedAsRead;
  const isHandledCancelation = isCancelled && (!hasAnAction || wasMarkedAsRead);

  const isOkOk = mission.candidates?.some(
    candidate =>
      candidate.candidateAdvancement === EnumCoreMissionCandidateCandidateAdvancement.accepté &&
      candidate.companyAdvancement === EnumCoreMissionCandidateCompanyAdvancement.accepté
  );

  const shouldUpdateExistingMission =
    isInConflict &&
    didCreateMission &&
    isOkOk &&
    mission.youplanStatus?.lastConflictCause ===
      EnumYouplanMissionActionSummaryLastConflictCause.MODIFIED;

  return (
    <PopupMenu
      position={'left top'}
      width={260}
      trigger={<ThreeDots style={{ cursor: 'pointer' }} />}
    >
      {isUnhandledCancelation &&
        didCreateMission &&
        mission.relatedMissionCandidates !== undefined &&
        mission.relatedMissionCandidates?.length === 1 && (
          <PopupMenu.Item
            icon={<Trashcan />}
            text={MenuItems.MISSION_CANCELLATIION}
            onClick={() => {
              cancelYouplanRelatedMission({ id: mission.missionId });
            }}
          />
        )}
      {isUnhandledCancelation &&
        didCreateMission &&
        mission.relatedMissionCandidates !== undefined &&
        mission.relatedMissionCandidates?.length > 1 && (
          <PopupMenu.Item
            icon={<Trashcan />}
            text={MenuItems.REMOVE_TT_FROM_MISSION}
            onClick={() => {
              refuseCandidate({
                id: mission.missionId,
                candidateId: mission.candidates?.[0].candidateId ?? '',
              });
            }}
          />
        )}
      {shouldUpdateExistingMission && (
        <PopupMenu.Item
          icon={<Pencil />}
          text={MenuItems.MISSION_EDITION}
          onClick={() => {
            updateYouplanRelatedMission({ id: mission.missionId });
          }}
        />
      )}
      {(isInConflict || isUnhandledCancelation) && didRenewContract && (
        <PopupMenu.Item
          icon={<Check />}
          text={MenuItems.CONFLICT_RESOLUTION}
          onClick={() => {
            markMissionAsResolved({ id: mission.missionId });
          }}
        />
      )}
      {!isInConflict && !didCreateMission && !didRenewContract && !isHandledCancelation && (
        <>
          <PopupMenu.Item
            icon={<AddCross />}
            text={MenuItems.MISSIONCREATION}
            onClick={() => {
              onCreateMission();
            }}
          />
          <PopupMenu.Item
            icon={<Continue />}
            text={MenuItems.PROLONGATION}
            disabled={!currentContract?.contractId || !currentContract.isRenewable}
            onClick={() => {
              navigate(MODAL_ROUTES.CONTRACT_RENEWAL.route.buildPath({}), {
                state: {
                  background: location,
                  youplanMissionId: mission.missionId,
                  contractId: currentContract?.contractId,
                },
              });
            }}
          />
          <PopupMenu.Item
            icon={<Check />}
            text={MenuItems.TREATED}
            onClick={() => {
              markMissionAsRead({
                youplanMissionIds: [mission.missionId!],
                startDate: mission.startDate!,
                endDate: mission.endDate!,
                companyId: mission.company?.id!,
                dpaeAgencyId: mission.dPAEAgencyId!,
              });
            }}
          />
        </>
      )}
      <PopupMenu.Item
        icon={<Info />}
        text={MenuItems.DETAILS}
        onClick={() => {
          onInfoClick(mission.missionId!, candidateId);
        }}
      />
    </PopupMenu>
  );
};

export default YouplanMissionActions;
