import {
  SortDescending,
  SortDown,
  SortUp,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  ServerErrorYellow,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  Loader,
  PopupMenu,
  SegmentedControl,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedHash } from 'react-router-typesafe-routes/dom';
import { DistanceFilter } from 'src/Components/DistanceFilter';
import { TopBar } from 'src/Components/TopBar';
import { useFetchAgencyTemporaryWorkers } from 'src/Hooks/Candidate/useFetchAgencyTemporaryWorkers';
import { useFetchRegionalTemporaryWorkers } from 'src/Hooks/Candidate/useFetchRegionalTalents';
import { ROUTES } from 'src/Routes/Routes.types';
import { TemporaryWorkersSortingKey, TemporaryWorkersSortingMode } from 'src/Services/API';
import styles from './TemporaryWorkers.module.scss';
import { TemporaryWorkersSource } from './TemporaryWorkers.types';
import { TemporaryWorkersList } from './TemporaryWorkersList';
import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';

export const TemporaryWorkersRoute = () => {
  const navigate = useNavigate();
  const { isProd } = useIsProductionEnvironment();

  const [distanceFilter, setDistanceFilter] = useState(60);
  const [sortingKey, setSortingKey] = useState<TemporaryWorkersSortingKey>(
    TemporaryWorkersSortingKey.availability
  );
  const [sortingMode, setSortingMode] = useState<TemporaryWorkersSortingMode>(
    TemporaryWorkersSortingMode.asc
  );

  const {
    data: agencyData,
    isLoading: agencyIsLoading,
    isFetching: agencyIsFetching,
    hasNextPage: agencyHasNextPage,
    fetchNextPage: agencyFetchNextPage,
  } = useFetchAgencyTemporaryWorkers({ sortingKey, sortingMode });

  const {
    data: regionalData,
    isFetching: regionalIsFetching,
    isLoading: regionalIsLoading,
    hasNextPage: regionalHasNextPage,
    fetchNextPage: regionalFetchNextPage,
  } = useFetchRegionalTemporaryWorkers({ distanceInKm: distanceFilter, sortingKey, sortingMode });

  const [selectedSource, setSelectedSource] = useState(
    useTypedHash(ROUTES.TALENTS.TEMPORARY_WORKERS) ?? TemporaryWorkersSource.agency
  );

  useEffect(() => {
    navigate(ROUTES.TALENTS.TEMPORARY_WORKERS.buildPath({}, {}, selectedSource), { replace: true });
  }, [navigate, selectedSource]);

  const data = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyData?.pages.flatMap(page => page.temporaryWorkers);
      }
      case TemporaryWorkersSource.regional: {
        return regionalData?.pages.flatMap(page => page.temporaryWorkers);
      }
    }
  }, [agencyData, regionalData, selectedSource]);

  const fetchNextPage = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyFetchNextPage;
      }
      case TemporaryWorkersSource.regional: {
        return regionalFetchNextPage;
      }
    }
  }, [agencyFetchNextPage, regionalFetchNextPage, selectedSource]);

  const isLoading = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyIsLoading;
      }
      case TemporaryWorkersSource.regional: {
        return regionalIsLoading;
      }
    }
  }, [agencyIsLoading, regionalIsLoading, selectedSource]);

  const isFetching = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyIsFetching;
      }
      case TemporaryWorkersSource.regional: {
        return regionalIsFetching;
      }
    }
  }, [agencyIsFetching, regionalIsFetching, selectedSource]);

  const hasNextPage = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyHasNextPage;
      }
      case TemporaryWorkersSource.regional: {
        return regionalHasNextPage;
      }
    }
  }, [agencyHasNextPage, regionalHasNextPage, selectedSource]);

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (!scrollViewRef.current) return;
    const scrollBottom =
      scrollViewRef.current.scrollHeight -
      scrollViewRef.current.clientHeight -
      scrollViewRef.current.scrollTop;
    if (scrollBottom < 100 && !isFetching && hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);
  useEffect(handleScroll, [handleScroll]);

  return (
    <>
      <TopBar
        title={
          <SegmentedControl
            className={styles.segmentedControl}
            controls={Object.values(TemporaryWorkersSource)}
            selected={selectedSource}
            getValue={source =>
              ({
                [TemporaryWorkersSource.agency]: 'intérimaires de mon unité',
                [TemporaryWorkersSource.regional]: 'intérimaires de ma région',
              }[source])
            }
            onSelectionChange={setSelectedSource}
          />
        }
      >
        {isProd ? null : (
          <>
            {selectedSource === TemporaryWorkersSource.regional && (
              <DistanceFilter
                filteredDistance={distanceFilter}
                setFilteredDistance={setDistanceFilter}
              />
            )}

            <PopupMenu
              selectedIds={[sortingKey, sortingMode]}
              trigger={<Button.XSmall leftIcon={<SortDescending variant="line" />} />}
              position="left top"
            >
              <PopupMenu.Item
                id={TemporaryWorkersSortingMode.asc}
                icon={<SortUp size="xsmall" />}
                text="ascendant"
                onClick={() => setSortingMode(TemporaryWorkersSortingMode.asc)}
                keepOpenOnClick
              />
              <PopupMenu.Item
                id={TemporaryWorkersSortingMode.desc}
                icon={<SortDown size="xsmall" />}
                text="descendant"
                onClick={() => setSortingMode(TemporaryWorkersSortingMode.desc)}
                keepOpenOnClick
              />

              <div className={styles.menuGroup}>trier par</div>

              <PopupMenu.Item
                id={TemporaryWorkersSortingKey.agency}
                text="agence"
                onClick={() => setSortingKey(TemporaryWorkersSortingKey.agency)}
                keepOpenOnClick
              />
              <PopupMenu.Item
                id={TemporaryWorkersSortingKey.availability}
                text="prochaine dispo"
                onClick={() => setSortingKey(TemporaryWorkersSortingKey.availability)}
                keepOpenOnClick
              />
            </PopupMenu>
          </>
        )}
      </TopBar>
      <div className={styles.container}>
        {isLoading ? (
          <div className={styles.loadingScreen}>
            <Loader heightInRem={4} className={styles.loader} />
            chargement des intérimaires, cela peut prendre du temps
          </div>
        ) : !data ? (
          <div className={styles.error}>
            <ServerErrorYellow />
            Une erreur s'est produite lors du chargement des intérimaires
          </div>
        ) : data.length === 0 ? (
          <div className={styles.empty}>
            <VidePasteque />
            Aucun intérimaire ne correspond à votre recherche
          </div>
        ) : (
          <div
            style={{ flex: '1 1', minHeight: '0', overflowY: 'auto' }}
            onScroll={handleScroll}
            ref={scrollViewRef}
          >
            <TemporaryWorkersList source={selectedSource} temporaryWorkers={data} />
            {isFetching && <Loader heightInRem={2} className={styles.loader} />}
          </div>
        )}
      </div>
    </>
  );
};
