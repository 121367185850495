import React, { useCallback } from 'react';
import useAuth from 'src/Hooks/Authentication/useAuth';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';

import ParametersPopOverMenu from './ParametersPopOverMenu.component';

const MemoParametersPopOverMenu = React.memo(ParametersPopOverMenu);

const EnhancedParametersPopOverMenu = () => {
  const { logout } = useAuth();
  const user = useAuthenticatedUser();

  const onLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URI } });
  }, [logout]);

  return (
    <MemoParametersPopOverMenu
      firstName={user.givenName}
      lastName={user.familyName}
      email={user.email}
      onLogout={onLogout}
    />
  );
};

export default EnhancedParametersPopOverMenu;
