import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ExternalDemandsService, MailParameterToUpdateDto } from 'src/Services/API';
import { MutationKeys } from '../types';
import { toast } from '@randstad-lean-mobile-factory/react-components-core';

interface UpdateMailParametersParams {
  newMailParameter: MailParameterToUpdateDto;
}

export const useUpdateMailParameters = (
  extraOptions?: UseMutationOptions<void, unknown, UpdateMailParametersParams, unknown>
) => {
  return useMutation(
    [MutationKeys.updateMailParameters],
    async (params: UpdateMailParametersParams) => {
      return await ExternalDemandsService.externalDemandsControllerUpdateMailParameter({
        body: {
          agencyId: params.newMailParameter.agencyId,
          source: params.newMailParameter.source,
          mailParametersToUpdate: [params.newMailParameter],
        },
      });
    },
    {
      ...extraOptions,
      onSuccess: () => {
        toast.success('correspondance mise à jour');
      },
      onError: () => {
        toast.error('erreur lors de la mise à jour de la correspondance');
      },
      retry: 5,
    }
  );
};
