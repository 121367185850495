import React, { useState } from 'react';
import styles from './TalentDetailsQualifications.module.scss';
import { Props } from './TalentDetailsQualifications.types';
import { pluralFormat } from 'src/Utils/pluralFormat';

export const TalentDetailsQualifications = ({ qualifications, mainQualificationId }: Props) => {
  const [showAll, setShowAll] = useState(false);

  const mainQualification = qualifications?.filter(
    qualification => qualification.id === mainQualificationId
  );
  const secondaryQualifications = qualifications?.filter(
    qualification => qualification.id !== mainQualificationId
  );

  const displayedSecondaryQualifications = showAll
    ? secondaryQualifications
    : secondaryQualifications?.slice(0, 3);

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>qualifications principale et secondaires</div>
      <p key="main" className={styles.qualificationsText}>
        {mainQualification !== undefined && mainQualification.length > 0
          ? `# principale :`
          : `# pas de qualification principale`}
      </p>
      <div>
        {mainQualification?.map(qualification => (
          <p
            key={qualification.id}
            className={styles.qualificationsText}
          >{`• ${qualification.label?.toLocaleLowerCase()}`}</p>
        ))}
      </div>
      <br />
      <p key="secondary" className={styles.qualificationsText}>
        {displayedSecondaryQualifications !== undefined &&
        displayedSecondaryQualifications?.length > 0
          ? `# ` +
            pluralFormat(displayedSecondaryQualifications.length, 'secondaire', false) +
            ` : `
          : `# pas de qualifications secondaires`}
      </p>
      {displayedSecondaryQualifications?.map(qualification => (
        <p
          key={qualification.id}
          className={styles.qualificationsText}
        >{`• ${qualification.label?.toLocaleLowerCase()}`}</p>
      ))}
      {secondaryQualifications && secondaryQualifications.length > 3 && (
        <p onClick={handleToggleShowAll} className={styles.toggleShowAll}>
          {showAll ? 'montrer moins' : 'tout voir...'}
        </p>
      )}
    </div>
  );
};

export default TalentDetailsQualifications;
