import { Button, ModalDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';
import styles from './WrongAgencyFromEDPModal.module.scss';

const WrongAgencyFromEDPModal = () => {
  const navigate = useNavigate();
  const onClose = () => navigate(ROUTES.ROOT.buildPath({}));
  return (
    <ModalDeprecated
      open
      onClose={onClose}
      footerActionsRight={[<Button.Primary onClick={onClose}>valider</Button.Primary>]}
      title="L'agence rattachée à l'EDP ne figure pas dans votre périmètre"
    >
      <p className={styles.text}>Vous allez être redirigé vers l'accueil de Flash</p>
    </ModalDeprecated>
  );
};
export default WrongAgencyFromEDPModal;
