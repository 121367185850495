import { useQuery } from '@tanstack/react-query';

import { CompaniesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchCompanyServices = (companyId: string, brandCode: string) => {
  return useQuery(
    [QueryKeys.fetchCompanyServices, companyId, brandCode],
    async () => {
      const services = await CompaniesService.companiesControllerGetServices({
        id: companyId,
        brandCode: brandCode,
      });
      return services.sort((a, b) =>
        a.name === b.name ? 0 : (a.name ?? '') < (b.name ?? '') ? -1 : 1
      );
    },
    { enabled: !!companyId }
  );
};
