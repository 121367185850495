import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from 'src/Hooks/types';
import { ContractsService } from 'src/Services/API';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import invariant from 'invariant';

export const useFetchContractDetail = ({ contractId }: { contractId?: string }) => {
  const brandCode = useSelector(getCurrentBrandCode)!;
  return useQuery(
    [QueryKeys.fetchContractDetail, contractId, brandCode],
    () => {
      invariant(contractId, 'contractId must be defined');
      return ContractsService.contractsControllerGetContract({ id: contractId, brandCode });
    },
    { enabled: !!contractId }
  );
};
