import { useCallback } from 'react';

import { Cross, LightArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import Select from 'react-select';

import styles from './SearchableDropDown.module.scss';
import CustomDropdownOption from './CustomDropdownOption';
import { OptionType } from './CustomDropdownOption/CustomDropdownOption.types';
import { AlwaysDefined, SearchableDropDownProps } from './SearchableDropDown.types';

const SearchableDropDown = <T,>({
  className,
  items,
  disabled,
  placeholder,
  keyValueExtractor,
  onSelectItem,
  selectedItem,
  canBeReset,
  triggerIcon,
  error,
}: SearchableDropDownProps<T>) => {
  const toOptionType = useCallback(
    (item: AlwaysDefined<T>) => {
      return keyValueExtractor
        ? keyValueExtractor(item)
        : { item, value: item?.toString() ?? '', label: item?.toString() ?? '' };
    },
    [keyValueExtractor]
  );

  return (
    <Select<OptionType<AlwaysDefined<T>>>
      options={items.map(toOptionType)}
      value={selectedItem !== undefined ? toOptionType(selectedItem) : null}
      isDisabled={disabled}
      isClearable={canBeReset}
      getOptionValue={({ value }) => value}
      getOptionLabel={({ label }) => label}
      classNames={{
        container: () => classnames(className),
        control: state =>
          classnames(styles.select, {
            [styles.focused]: state.isFocused,
            [styles.error]: error,
            [styles.open]: state.menuIsOpen,
            [styles.disabled]: state.isDisabled,
          }),
        valueContainer: () => styles.valueContainer,
        placeholder: () => styles.placeholder,
        indicatorSeparator: () => styles.indicatorSeparator,
        indicatorsContainer: () => styles.indicatorContainer,
        menu: () => styles.menu,
        menuList: () => styles.menuList,
      }}
      onChange={value => onSelectItem?.(value?.item)}
      placeholder={placeholder}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      components={{
        ValueContainer: ({ children }) => (
          <div className={styles.valueContainer}>
            {triggerIcon}
            <div className={styles.valueContainerChildren}>{children}</div>
          </div>
        ),
        DropdownIndicator: () => <LightArrow className={styles.arrow} />,
        Option: CustomDropdownOption,
        ClearIndicator: ({ clearValue }) => (
          <Cross className={styles.crossIcon} onClick={clearValue} />
        ),
        NoOptionsMessage: () => <div className={styles.noOptionsMessage}>pas de résultats</div>,
      }}
    />
  );
};

export default SearchableDropDown;
