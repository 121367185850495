import { Props } from './TalentFirstContractStartDate.types';
import styles from './TalentFirstContractStartDate.module.scss';
import moment from 'moment';

const TalentFirstContractStartDate = ({ firstContractStartDate }: Props) => {
  const formattedDate = moment(firstContractStartDate).format('DD/MM/YYYY');
  return (
    <div className={styles.container}>
      <div className={styles.title}>date de premier contrat</div>
      <div className={styles.date}>
        {firstContractStartDate !== undefined ? formattedDate : 'inconnue'}
      </div>
    </div>
  );
};

export default TalentFirstContractStartDate;
