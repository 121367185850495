import {
  Button,
  FetchButton,
  ModalDeprecated,
  PopupActions,
  TextArea,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BinocularFullCut } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFetchCandidateLastMedicalVisit } from 'src/Hooks/Candidate/useFetchCandidateLastMedicalVisit';
import { useSendSuggestionToAgencies } from 'src/Hooks/Candidate/useSendSuggestionToAgencies';
import { getAnalyticsUserInfo, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { toFetchStatus } from 'src/Services/Async';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import { isInternEmails } from 'src/Utils/emails';
import { parseEmails } from 'src/Utils/parseEmails';
import { pluralFormat } from 'src/Utils/pluralFormat';
import { OSMCommonModelDateDtoToDate } from '../../utils';
import styles from './CGCMissionPropositionModal.module.scss';
import { Props } from './CGCMissionPropositionModal.types';

const CGCMissionPropositionModal = ({ candidateData, selectedMissions, statut }: Props) => {
  const ref = useRef<PopupActions>(null);
  const navigate = useNavigate();
  const cgcId = useSelector(getCurrentAgency) ?? '';
  const userInfo = useSelector(getAnalyticsUserInfo);
  const { data: lastMedicalVisit } = useFetchCandidateLastMedicalVisit(candidateData.id);

  const sendSuggestionToAgencies = useSendSuggestionToAgencies();
  const sendSuggestionToAgenciesFetchStatus = toFetchStatus(sendSuggestionToAgencies);

  const [cc, setCc] = useState<string>('');
  const [ccMailsAreValid, setCCMailsAreValid] = useState(true);

  const [message, setMessage] = useState<string>(
    candidateData.sosComment
      ? `message de l'agence ${candidateData.sosAgencyId} lors du sos :
${candidateData.sosComment}`
      : ''
  );

  useEffect(() => {
    const emails = parseEmails(cc);
    if (emails[0] !== '') {
      setCCMailsAreValid(isInternEmails(emails));
    } else {
      setCCMailsAreValid(true);
    }
  }, [cc]);

  return (
    <ModalDeprecated
      ref={ref}
      title="suggestions aux agences"
      footerActionsRight={[
        <Button.Secondary onClick={() => ref.current?.close()}>annuler</Button.Secondary>,
        <FetchButton
          fetchStatus={sendSuggestionToAgenciesFetchStatus}
          title="envoyer"
          onClick={() => {
            return sendSuggestionToAgencies.mutate({
              cgcId: cgcId ?? '',
              cc: parseEmails(cc),
              comment: message,
              candidateId: candidateData.id ?? '',
              candidateFullName: `${candidateData.firstName} ${candidateData.name}`,
              candidateMainQualification: candidateData.mainQualification?.label ?? 'inconnue',
              lastContractEndDate: OSMCommonModelDateDtoToDate(candidateData.lastContract?.endDate),
              lastMedicalVisit: lastMedicalVisit
                ? moment(lastMedicalVisit).format('DD/MM/YYYY')
                : 'inconnue',
              suggestedMissions: selectedMissions.map(mission => {
                return {
                  clientName: mission.company?.name ?? 'inconnu',
                  agencyId: mission.agencyId ?? 'inconnu',
                  missionNumber: mission.missionId ?? 'inconnu',
                  qualificationName: mission.qualification?.label ?? 'inconnue',
                  startDate: mission?.startDate
                    ? moment(mission.startDate).format('DD/MM/YYYY')
                    : 'inconnue',
                };
              }),
            });
          }}
          disabled={message.length < 10 || !ccMailsAreValid}
          onSuccess={() => {
            ReactGA.event(ANALYTICS_EVENT.COUNT_ORDER_SUGGEST, {
              ...userInfo,
              suggestedOrderCount: selectedMissions.length,
              statut,
            });
            ref.current?.close();
            navigate(ROUTES.TALENTS.CDII.buildPath({}));
          }}
        />,
      ]}
      trigger={
        <Button.Secondary
          text={selectedMissions.length > 1 ? 'suggérer aux commandes' : 'suggérer à la commande'}
          className={styles.cgcButton}
          disabled={selectedMissions.length === 0}
          onClick={() =>
            ReactGA.event(ANALYTICS_EVENT.CLICK_ORDER_SUGGEST, {
              ...userInfo,
              suggestedOrderCount: selectedMissions.length,
              statut,
            })
          }
        />
      }
      icon={<BinocularFullCut />}
    >
      <p className={styles.text}>
        Vous avez suggéré le talent sur {pluralFormat(selectedMissions.length, 'commande')}.
        Veuillez saisir un message, des remarques à propos de ce cdii à destination des agences.
      </p>
      <WithLightTitle
        title="cc (mails séparés d'une virgule)"
        className={styles.checkText}
        children={
          <TextInput
            className={styles.reportTextArea}
            value={cc}
            containerClassName={classnames(styles.textInput, {
              [styles.warningEmails]: !ccMailsAreValid,
            })}
            onChange={(event: any) => setCc((event.target as HTMLTextAreaElement).value)}
          />
        }
      />
      {!ccMailsAreValid && (
        <div className={styles.warningMessage}>une ou plusieurs adresses mails sont invalides</div>
      )}
      <WithLightTitle
        title="message (min 10 caractères)"
        className={styles.checkText}
        children={
          <TextArea
            className={styles.reportTextArea}
            value={message}
            onChange={event => setMessage((event.target as HTMLTextAreaElement).value)}
            maxLength={REPORT_MAX_LENGTH}
            placeholder={
              selectedMissions.length > 1
                ? 'les raisons de ces suggestions'
                : 'les raisons de cette suggestion'
            }
          />
        }
      />
    </ModalDeprecated>
  );
};

export default CGCMissionPropositionModal;
