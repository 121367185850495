export interface CDICandidatesState {
  planningStartDate: Date;
  sortBy: CDISortBy;
  sortOrder: SortOrder;
}

export enum CDISortBy {
  AGENCY,
  CONTRACT_END_DATE,
  STATUT,
  NEXT_AVAILABILITY,
}

export enum SortOrder {
  ASC,
  DESC,
}
