import classnames from 'classnames';
import { TopBar } from 'src/Components/TopBar';
import styles from './ActionPlan.module.scss';
import ActionPlansListComponent from './ActionPlansList/ActionPlansList.component';

export const ActionPlanRoute = () => {
  return (
    <div className={styles.mainContainer}>
      <TopBar title="plans d'action" />
      <div className={classnames(styles.container, styles.page)}>
        <ActionPlansListComponent />
      </div>
    </div>
  );
};
