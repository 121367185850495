import { Eye } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { eye } from 'src/Assets_DEPRECATED';
import PopOverContact from 'src/Components/PopOverContact';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { ROUTES } from 'src/Routes/Routes.types';
import { EnumCreateActionBodyActionTarget } from 'src/Services/API';
import SuggestedCandidateActions from '../SuggestedCandidateActions';
import styles from './CandidateCard.module.scss';
import { Props } from './CandidateCard.types';

const CandidateCard = ({
  candidate,
  hideAcceptance,
  cdiContractId,
  positionStudyId,
  suggestedCandidate,
  missionId,
  companyName,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={classnames(styles.leftSide)}>
          <div className={styles.nameAndContract}>
            <p className={styles.fullName}>
              {candidate?.name} {candidate?.firstName}
            </p>
            {cdiContractId && <p className={styles.contract}>CDI TT</p>}
          </div>
        </div>
      </div>
      {hideAcceptance || (
        <div className={styles.item}>
          <span className={styles.subtitle}>TT : </span>
          <span
            className={classnames(styles.advancementStatus, {
              [styles.undefinedAdvancement]: candidate?.candidateAdvancement === undefined,
            })}
          >
            {candidate?.candidateAdvancement || '--'}
          </span>
          <br />
          <span className={styles.subtitle}>compte : </span>
          <span
            className={classnames(styles.advancementStatus, {
              [styles.undefinedAdvancement]: candidate?.companyAdvancement === undefined,
            })}
          >
            {candidate?.companyAdvancement || '--'}
          </span>
        </div>
      )}
      {!suggestedCandidate && (
        <div className={styles.item}>
          <span className={styles.subtitle}>
            {candidate?.lastContact?.at.format('[le] DD/MM/Y à HH[h]mm') ?? '--'}
          </span>
          {candidate?.lastContact && (
            <button
              className={styles.lastCRButton}
              onClick={() => {
                navigate(MODAL_ROUTES.ACTION_PREVIEW_MODAL.route.buildPath({}), {
                  state: {
                    modalParameters: {
                      target: {
                        firstName: candidate.firstName,
                        lastName: candidate.name,
                      },
                      actionId: candidate.lastContact?.actionId,
                    },
                    background: location,
                  },
                });
              }}
            >
              <img src={eye} alt="eye" />
              <span className={styles.lastCRButtonText}>dernier CR</span>
            </button>
          )}
        </div>
      )}
      {candidate && (
        <Button.Tertiary.XSmall
          onClick={() =>
            navigate(
              candidate.statusCode === 'CDI'
                ? ROUTES.TALENTS.CDII.CDII_DETAIL.buildPath({ candidateId: candidate.candidateId })
                : ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.buildPath({
                    talentId: candidate.candidateId,
                  })
            )
          }
        >
          <Eye />
        </Button.Tertiary.XSmall>
      )}
      {candidate &&
        (suggestedCandidate && missionId !== undefined ? (
          <SuggestedCandidateActions
            candidate={candidate}
            missionId={missionId}
            companyName={companyName ?? ''}
          />
        ) : (
          <div className={styles.contactButton}>
            <PopOverContact
              target={EnumCreateActionBodyActionTarget.CANDIDATE}
              contact={candidate}
              positionStudyId={positionStudyId}
              enableEditMissionStatus={!!missionId}
            />
          </div>
        ))}
    </div>
  );
};

export default CandidateCard;
