import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { City, RepositoryService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useFetchCityList = (extraOptions?: UseMutationOptions<City[], unknown, string>) => {
  return useMutation(
    [MutationKeys.fetchCities],
    async (keyword: string) => {
      if (!keyword) {
        return [];
      }
      return await RepositoryService.repositoryControllerGetCities({ keyword });
    },
    { ...extraOptions }
  );
};
