import { useEffect, useState } from 'react';

import {
  Button,
  FetchButton,
  LetterBadge,
  SegmentedMenu,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  CloseOld,
  RadioButtonCheck,
  RadioButtonEmpty,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import ContentLoader from 'react-content-loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchCandidateDetails } from 'src/Hooks/Candidate/useFetchCandidateDetails';
import { useFetchCandidateQualifications } from 'src/Hooks/Candidate/useFetchCandidateQualifications';
import useFetchDepartments from 'src/Hooks/Departments/useFetchDepartments';
import { useAddCandidateToMissions } from 'src/Hooks/Missions/useAddCandidateToMissions';
import { FETCH_STATUS } from 'src/Redux/Types';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import {
  Department,
  Mission,
  PotentialQualificationClient,
  SkillMatchingMission,
} from 'src/Services/API';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Utils/fetch-status';
import { pluralFormat } from 'src/Utils/pluralFormat';
import Missions from './Missions';
import MissionsBySkillMatching from './MissionsBySkillMatching';
import PotentialQualificationsClients from './PotentialQualificationsClients';
import styles from './RepositioningModal.module.scss';
import { Props, REPOSITIONING_TAB } from './RepositioningModal.types';

const SelectedItemsNumber = ({
  label,
  hasSelectedElement,
}: {
  label: string;
  hasSelectedElement: boolean;
}) => (
  <div className={styles.bottomLeftContainer}>
    {hasSelectedElement ? <RadioButtonCheck /> : <RadioButtonEmpty />}
    <p className={styles.bottomLeftLabel}>{label}</p>
  </div>
);

const RepositioningModal = ({ candidateId, onClose }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const departmentsQuery = useFetchDepartments();
  const departmentsFetchStatus = toFetchStatus(departmentsQuery);
  const candidateQualifications = useFetchCandidateQualifications(candidateId);
  const candidateQualificationsFetchStatus = toFetchStatus(candidateQualifications);
  const candidateQuery = useFetchCandidateDetails(candidateId);
  const candidateFetchStatus = toFetchStatus(candidateQuery);
  const fetchStatus = mergeSeveralFetchStatus([
    candidateFetchStatus,
    candidateQualificationsFetchStatus,
    departmentsFetchStatus,
  ]);
  const addToMissionMutation = useAddCandidateToMissions();
  const addToMissionFetchStatus = toFetchStatus(addToMissionMutation);

  const [scrollTop, setScrollTop] = useState<number>(0);

  const [selectedTab, setSelectedTab] = useState(REPOSITIONING_TAB.COMMANDES);
  const [selectedMissions, setSelectedMissions] = useState<Mission[]>([]);
  const [
    selectedPotentialQualificationsClients,
    setSelectedPotentialQualificationsClients,
  ] = useState<PotentialQualificationClient[]>([]);
  const [selectedMissionsBySkillMatching, setSelectedMissionsBySkillMatching] = useState<
    SkillMatchingMission[]
  >([]);
  const [selectedQualifications, setSelectedQualifications] = useState<string[]>(
    candidateQualifications.data?.map(qualification => qualification.id ?? '') ?? []
  );
  const [selectedDepartments, setSelectedDepartments] = useState<Department[]>([]);

  useEffect(() => {
    if (candidateQualificationsFetchStatus === FETCH_STATUS.FULFILLED) {
      setSelectedQualifications(
        candidateQualifications.data?.map(qualification => qualification.id ?? '') ?? []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateQualificationsFetchStatus]);

  useEffect(() => {
    if (
      departmentsFetchStatus === FETCH_STATUS.FULFILLED &&
      candidateFetchStatus === FETCH_STATUS.FULFILLED
    ) {
      const candidateDepartment = departmentsQuery.data?.find(
        item => item.id === candidateQuery.data?.zipCode.slice(0, 2)
      );
      if (candidateDepartment) {
        setSelectedDepartments([candidateDepartment]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentsFetchStatus, candidateFetchStatus]);

  const displayedSection = () => {
    switch (selectedTab) {
      case REPOSITIONING_TAB.COMMANDES:
        return {
          component: (
            <Missions
              selectedMissions={selectedMissions}
              onMissionClick={setSelectedMissions}
              selectedQualifications={selectedQualifications}
              setSelectedQualifications={setSelectedQualifications}
              allQualifications={candidateQualifications.data ?? []}
              allDepartments={departmentsQuery.data ?? []}
              departmentsFetchStatus={departmentsFetchStatus}
              selectedDepartments={selectedDepartments}
              setSelectedDepartments={setSelectedDepartments}
              setScrollTop={setScrollTop}
            />
          ),
          bottomBar: (
            <div className={styles.bottomBar}>
              <SelectedItemsNumber
                label={pluralFormat(selectedMissions.length, 'commande sélectionnée')}
                hasSelectedElement={selectedMissions.length > 0}
              />
              <div className={styles.buttons}>
                <Button.Secondary
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  annuler
                </Button.Secondary>
                <FetchButton
                  disabled={selectedMissions.length === 0}
                  fetchStatus={addToMissionFetchStatus}
                  title="rattacher à une commande"
                  className={styles.validationButton}
                  onSuccess={() => {
                    navigate(MODAL_ROUTES.REPOSITIONING_VALIDATION.route.buildPath({}), {
                      state: {
                        background: location,
                        modalParameters: {
                          selectedMissionNumber: selectedMissions.length,
                          missionId: selectedMissions[0].missionId,
                          missionAgencyId: selectedMissions[0].agencyId,
                        },
                      },
                    });
                  }}
                  onClick={() =>
                    addToMissionMutation.mutate({
                      body: {
                        candidateId,
                        missionIds: selectedMissions.map(mission => mission.missionId ?? ''),
                      },
                    })
                  }
                />
              </div>
              <div></div>
            </div>
          ),
        };
      case REPOSITIONING_TAB.QUALIFICATION:
        return {
          component: (
            <PotentialQualificationsClients
              candidateId={candidateQuery.data?.candidateId}
              candidateQualifications={candidateQualifications.data ?? []}
              selectedPotentialQualificationsClients={selectedPotentialQualificationsClients}
              setSelectedPotentialQualificationsClients={setSelectedPotentialQualificationsClients}
              selectedQualifications={selectedQualifications}
              setSelectedQualifications={setSelectedQualifications}
              allDepartments={departmentsQuery.data ?? []}
              departmentsFetchStatus={departmentsFetchStatus}
              selectedDepartments={selectedDepartments}
              setSelectedDepartments={setSelectedDepartments}
              setScrollTop={setScrollTop}
            />
          ),
          bottomBar: (
            <div className={styles.bottomBar}>
              <SelectedItemsNumber
                label={pluralFormat(
                  selectedPotentialQualificationsClients.length,
                  'qualification sélectionnée'
                )}
                hasSelectedElement={selectedPotentialQualificationsClients.length > 0}
              />
              <div className={styles.buttons}>
                <Button.Secondary
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  annuler
                </Button.Secondary>
                <FetchButton
                  disabled={true}
                  fetchStatus={addToMissionFetchStatus}
                  title="créer un PPA"
                  className={styles.validationButton}
                />
              </div>
              <div></div>
            </div>
          ),
        };
      case REPOSITIONING_TAB.MATCHING:
        return {
          component: (
            <MissionsBySkillMatching
              candidateId={candidateQuery.data?.candidateId}
              candidateQualifications={selectedQualifications}
              allDepartments={departmentsQuery.data ?? []}
              departmentsFetchStatus={departmentsFetchStatus}
              selectedDepartments={selectedDepartments}
              setSelectedDepartments={setSelectedDepartments}
              selectedMissionsBySkillMatching={selectedMissionsBySkillMatching}
              setSelectedMissionsBySkillMatching={setSelectedMissionsBySkillMatching}
              setScrollTop={setScrollTop}
            />
          ),
          bottomBar: (
            <div className={styles.bottomBar}>
              <SelectedItemsNumber
                label={pluralFormat(
                  selectedMissionsBySkillMatching.length,
                  'commande sélectionnée'
                )}
                hasSelectedElement={selectedMissionsBySkillMatching.length > 0}
              />
              <div className={styles.buttons}>
                <Button.Secondary
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  annuler
                </Button.Secondary>
                <FetchButton
                  disabled={selectedMissionsBySkillMatching.length === 0}
                  fetchStatus={addToMissionFetchStatus}
                  title="rattacher à une mission"
                  className={styles.validationButton}
                  onSuccess={() => {
                    navigate(MODAL_ROUTES.REPOSITIONING_VALIDATION.route.buildPath({}), {
                      state: {
                        background: location,
                        modalParameters: {
                          selectedMissionNumber: selectedMissionsBySkillMatching.length,
                          missionId: selectedMissionsBySkillMatching[0].missionId,
                          missionAgencyId: selectedMissionsBySkillMatching[0].agencyId,
                        },
                      },
                    });
                  }}
                  onClick={() =>
                    addToMissionMutation.mutate({
                      body: {
                        candidateId,
                        missionIds: selectedMissionsBySkillMatching.map(
                          mission => mission.missionId ?? ''
                        ),
                      },
                    })
                  }
                />
              </div>
              <div></div>
            </div>
          ),
        };
      default:
        break;
    }
  };
  return (
    <div className={styles.screenContainer}>
      <div className={styles.container}>
        <div className={styles.topBar}>
          <p className={styles.title}>repositionner le candidat</p>
          <Button.Tertiary onClick={onClose} className={styles.closeButton}>
            <CloseOld className={styles.crossIcon} />
          </Button.Tertiary>
        </div>
        {fetchStatus === FETCH_STATUS.FULFILLED && (
          <>
            <div className={styles.candidateInfo}>
              <LetterBadge
                text={`${candidateQuery.data?.firstName[0]}${candidateQuery.data?.name[0]}`}
                className={styles.letterBadge}
              />
              <div>
                <p
                  className={styles.name}
                >{`${candidateQuery.data?.firstName} ${candidateQuery.data?.name}`}</p>
                <p
                  className={styles.qualification}
                >{`qualification principale : ${candidateQuery.data?.qualification.label}`}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: `${Math.max(-scrollTop / 3, -28)}px`,
                marginBottom: `${Math.max(16 - scrollTop / 9, 0)}px`,
              }}
            >
              <SegmentedMenu
                selected={selectedTab}
                controls={Object.values(REPOSITIONING_TAB)}
                onSelectionChange={setSelectedTab}
                keyValueExtractor={tab => ({ key: tab, value: tab })}
                controlItemClassName={styles.controlsLabel}
                bottomBarClassName={styles.bottomMenuBar}
                className={styles.menuContainer}
              />
            </div>
            {displayedSection()?.component}
          </>
        )}
        {fetchStatus === FETCH_STATUS.PENDING && (
          <ContentLoader height="8rem" width="90%" uniqueKey={'repositioning'}>
            <rect x="1%" y="10" rx="4" ry="4" width="50%" height="18" />
            <rect x="1%" y="30" rx="4" ry="4" width="80%" height="18" />
            <rect x="1%" y="50" rx="4" ry="4" width="80%" height="18" />
            <rect x="1%" y="70" rx="4" ry="4" width="80%" height="18" />
          </ContentLoader>
        )}
        {displayedSection()?.bottomBar}
      </div>
    </div>
  );
};

export default RepositioningModal;
