import {
  Button,
  FetchButton,
  ModalDeprecated,
  PopupMenu,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Continue, Eye, ThreeDots } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment/moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderDeleteAlert, Pen } from 'src/Assets_DEPRECATED';
import { useCloseWorkAccidentActionPlan } from 'src/Hooks/WorkAccidentAnalysis/useCloseWorkAccidentActionPlan';
import { MODAL_ROUTES, useBackgroundLocation } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { WhyActionsStatusEnum } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import styles from './ActionPlansListMenu.module.scss';
import { Props } from './ActionPlansListMenu.types';

const ActionPlansListMenuComponent = ({ actionInfos, isEditable }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useBackgroundLocation();

  const closeWorkAccidentActionPlanMutation = useCloseWorkAccidentActionPlan();
  const closeWorkAccidentActionPlanMutationFetchStatus = toFetchStatus(
    closeWorkAccidentActionPlanMutation
  );

  const closeAction = ({
    workAccidentId,
    actionId,
  }: {
    workAccidentId: string;
    actionId: string;
  }) => {
    closeWorkAccidentActionPlanMutation.mutate({
      actionId,
      workAccidentId,
    });
  };
  return (
    <div className={styles.menuContainer}>
      {!isEditable && (
        <Button.Tertiary
          onClick={() =>
            navigate(MODAL_ROUTES.ENHANCED_ACTION_PLANS_LIST.route.buildPath({}), {
              state: {
                background: location,
                modalParameters: {
                  actionId: actionInfos?.action?.osmoseActionId,
                  workAccidentId: actionInfos?.workAccidentId,
                  isEditable: isEditable,
                },
              },
            })
          }
          className={styles.iconButton}
        >
          <Eye className={styles.icon} />
        </Button.Tertiary>
      )}
      {isEditable && actionInfos?.action?.status === WhyActionsStatusEnum.DONE && (
        <Button.Tertiary
          onClick={() => {
            setOpen(prevState => !prevState);
            navigate(MODAL_ROUTES.ENHANCED_ACTION_PLANS_LIST.route.buildPath({}), {
              state: {
                background: location,
                modalParameters: {
                  actionId: actionInfos?.action?.osmoseActionId,
                  workAccidentId: actionInfos?.workAccidentId,
                  isEditable: !isEditable,
                },
              },
            });
          }}
          className={styles.iconButton}
        >
          <Eye className={styles.icon} />
        </Button.Tertiary>
      )}
      {isEditable && actionInfos?.action?.status === WhyActionsStatusEnum.IN_PROGRESS && (
        <PopupMenu
          position="left bottom"
          openTriggerClassName={styles.menuContainer}
          width={160}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false);
          }}
          trigger={
            <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: open })}>
              <ThreeDots className={styles.menuIcon} />
            </Button.Tertiary>
          }
        >
          <>
            {actionInfos?.action && (
              <PopupMenu.Item
                text="détail"
                icon={<Pen />}
                onClick={() =>
                  navigate(MODAL_ROUTES.ENHANCED_ACTION_PLANS_LIST.route.buildPath({}), {
                    state: {
                      background: location,
                      modalParameters: {
                        actionId: actionInfos?.action?.osmoseActionId,
                        workAccidentId: actionInfos?.workAccidentId,
                        isEditable: isEditable,
                      },
                    },
                  })
                }
              />
            )}
            {actionInfos?.action &&
              isEditable &&
              actionInfos.action.status === WhyActionsStatusEnum.IN_PROGRESS && (
                <PopupMenu.Item
                  icon={<Continue />}
                  text="clôturer"
                  onClick={() => setOpenModal(true)}
                />
              )}
          </>
        </PopupMenu>
      )}

      {openModal && (
        <ModalDeprecated
          onClose={() => setOpenModal(false)}
          className={styles.ModalDeprecated}
          title="clôturer une action"
          children={
            <WithLightTitle
              title={`le ${moment().format('DD/MM/YYYY à HH:mm')}`}
              children={
                <div>
                  Souhaitez-vous clôturer cette action ? Elle ne sera plus modifiable par la suite.
                </div>
              }
            />
          }
          icon={<HeaderDeleteAlert />}
          iconClassName={styles.modalHeader}
          open={openModal}
          footerActionsRight={[
            <Button.Secondary
              onClick={() => {
                setOpenModal(false);
              }}
            >
              annuler
            </Button.Secondary>,

            <FetchButton
              fetchStatus={closeWorkAccidentActionPlanMutationFetchStatus}
              title="valider"
              onClick={() => {
                actionInfos &&
                  closeAction({
                    actionId: actionInfos.action?.osmoseActionId ?? '',
                    workAccidentId: actionInfos.workAccidentId ?? '',
                  });
              }}
              onSuccess={() => setOpenModal(false)}
            />,
          ]}
        />
      )}
    </div>
  );
};
export default ActionPlansListMenuComponent;
