import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { DayPickerRangeControllerShape, DayPickerSingleDateControllerShape } from 'react-dates';
import { contractsActions } from '../Contracts/Slice';
import { searchContracts } from '../Contracts/Thunks';
import { missionsActions } from '../Missions/Slice';
import { searchMissions } from '../Missions/Thunks';
import { RootState } from '../RootReducer';
import { searchYouplanCompanies } from '../YouplanCompanies/Thunks';
import { getEndDate, getSingleDate, getStartDate } from './Selectors';
import { searchActions } from './Slice';

export function useDatePicker(): [Moment, Moment, DayPickerRangeControllerShape['onDatesChange']] {
  const startDate = useSelector(getStartDate);
  const endDate = useSelector(getEndDate);

  const dispatch = useDispatch();
  const onDatesChange = useCallback<DayPickerRangeControllerShape['onDatesChange']>(
    ({ startDate, endDate }) => {
      if (startDate && endDate) {
        dispatch(searchActions.setStartDate(startDate.toISOString()));
        dispatch(searchActions.setEndDate(endDate.toISOString()));
      }
    },
    [dispatch]
  );

  return [startDate, endDate, onDatesChange];
}

export function useSingleDatePicker(): [
  Moment,
  DayPickerSingleDateControllerShape['onDateChange']
] {
  const date = useSelector(getSingleDate);

  const dispatch = useDispatch();
  const onDateChange = useCallback<DayPickerSingleDateControllerShape['onDateChange']>(
    date => {
      if (date) {
        dispatch(searchActions.setStartDate(date.toISOString()));
      }
    },
    [dispatch]
  );

  return [date, onDateChange];
}

export const useSearchMissionsWithReset = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const searchWithReset = useCallback(() => {
    dispatch(missionsActions.reset());
    dispatch(contractsActions.reset());
    dispatch(searchActions.resetOffset());
    dispatch(searchMissions());
  }, [dispatch]);

  return searchWithReset;
};

export const useSearchYouplanCompaniesWithReset = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const searchWithReset = useCallback(() => {
    dispatch(missionsActions.reset());
    dispatch(contractsActions.reset());
    dispatch(searchActions.resetOffset());
    dispatch(searchYouplanCompanies());
  }, [dispatch]);

  return searchWithReset;
};

export const useSearchContractsWithReset = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const searchWithReset = useCallback(() => {
    dispatch(missionsActions.reset());
    dispatch(contractsActions.reset());
    dispatch(searchActions.resetOffset());
    dispatch(searchContracts());
  }, [dispatch]);

  return searchWithReset;
};
