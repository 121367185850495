import { FETCH_STATUS } from 'src/Redux/Types';
import styles from './../VariableSimulator.module.scss';
import { Props } from './ComponentResult.types';

const C_USE_K_EURO = false;
const ComponentResult = (props: Props) => (
  <div className={styles.result}>
    <div className={styles.resultTitle}>variable estimée</div>

    {props.displayResult && (
      <>
        {props.fetchStatus === FETCH_STATUS.PENDING && <div className={styles.dotElastic}></div>}

        {props.fetchStatus === FETCH_STATUS.REJECTED && (
          <p className={styles.error}>
            Une erreur est survenue, merci de ressayer ou contacter l'administrateur
          </p>
        )}

        {props.fetchStatus === FETCH_STATUS.FULFILLED && (
          <div className={styles.blockResultVariable}>
            <div className={styles.resultVariable}>
              {props.mutation?.data !== undefined ? props.mutation?.data.toFixed(0) : '--'}
            </div>
            <div className={styles.uniteBig}> {C_USE_K_EURO ? 'k' : ''}€</div>
          </div>
        )}
      </>
    )}
    {!props.displayResult && (
      <>
        <div className={styles.blockResultVariable}>
          <div className={styles.resultVariable}>{'--'}</div>
          <div className={styles.uniteBig}> {C_USE_K_EURO ? 'k' : ''}€</div>
        </div>
      </>
    )}
  </div>
);

export default ComponentResult;
