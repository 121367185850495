import styles from './../Context.module.scss';
import { useEffect } from 'react';
import { ToggleSwitch, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { WitnessesProps } from '../Context.types';
import { useController } from 'react-hook-form';
import SelectCompanyContacts from './SelectCompanyContacts/SelectCompanyContacts.component';
import { yesNoUnknownFromBoolean } from 'src/Utils/yesNoUnknownText';

const Witnesses = ({
  isEditable,
  companyId,
  companyName,
  control,
  handleSubmit,
}: WitnessesProps) => {
  const hasWitnesses = useController({ control, name: 'hasWitnesses' });
  const witnesses = useController({ control, name: 'witnesses' });

  useEffect(() => {
    window.addEventListener('beforeunload', handleSubmit);
    return () => {
      window.removeEventListener('beforeunload', handleSubmit);
    };
  }, [handleSubmit]);

  useEffect(() => {
    if (witnesses.formState.isDirty) handleSubmit()();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [witnesses.field.value]);

  return (
    <div>
      <div className={styles.subTitle}>témoins ou 1re personne avisée</div>

      {isEditable ? (
        <div className={styles.switches}>
          des personnes ont été témoins sur la scène de l’accident ?
          <ToggleSwitch
            checked={hasWitnesses.field.value}
            onCheckStatusChange={value => {
              hasWitnesses.field.onChange(value);
              if (!value) witnesses.field.onChange(undefined);
            }}
          />
        </div>
      ) : (
        <WithLightTitle
          title="des personnes ont été témoins sur la scène de l’accident ?"
          className={styles.lightTitleWithFullWidth}
          children={<div>{yesNoUnknownFromBoolean(hasWitnesses.field.value)}</div>}
        />
      )}

      <SelectCompanyContacts
        isEditable={isEditable}
        control={control}
        name="witnesses"
        show={hasWitnesses.field.value ?? false}
        title="témoins présents lors de l’analyse AT"
        contactLabel="témoin"
        companyId={companyId}
        companyName={companyName}
        handleSubmit={handleSubmit}
      />

      <div className={styles.divider} />
    </div>
  );
};

export default Witnesses;
