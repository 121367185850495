import React from 'react';
import { Provider } from 'react-redux';

import store, { persistor } from 'src/Redux/store';
import { PersistGate } from 'redux-persist/integration/react';

const EnhancedProvider = ({ children }: { children: React.ReactElement }) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>{children}</PersistGate>
  </Provider>
);

export default EnhancedProvider;
