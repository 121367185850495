import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { WorkAccidentAnalysisService } from './../../Services/API/index';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { MutationKeys, QueryKeys } from '../types';
import { Signatory } from 'src/Routes/Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Conclusion/Conclusion.types';

interface WorkAccidentUpdateParams {
  analyseId: string;
  signatory: Signatory;
  file: any;
}

export const useUpdateWorkAccidentConclusionSignatory = (
  extraOptions?: UseMutationOptions<boolean, unknown, WorkAccidentUpdateParams, unknown>
) => {
  const queryClient = useQueryClient();

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgencyId = useSelector(getCurrentAgency);

  return useMutation(
    [MutationKeys.updateWorkAccidentAnalysis, currentBrand, currentAgencyId],
    async (params: WorkAccidentUpdateParams) => {
      if (!currentBrand?.brandCodeApiHeader || !currentAgencyId) {
        throw new Error('No current brand or has an invalid current brand');
      }
      return await WorkAccidentAnalysisService.workAccidentAnalysisControllerUpdateWorkAccidentConclusionSignatory(
        {
          id: params.analyseId,
          file: params.file,
          signatory: params.signatory,
        }
      );
    },
    {
      ...extraOptions,
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchWorkAccidentById] }),
    }
  );
};
