import moment from 'moment';
import styles from './TalentAvailabilityAndPlacementHeader.module.scss';
import { Props } from './TalentAvailabilityAndPlacementHeader.types';

export const TalentAvailabilityAndPlacementHeader = ({ availabilityDate }: Props) => {
  return (
    <div className={styles.row}>
      <div className={styles.informationCard}>
        <div className={styles.informationCardOverLabel}>prochaine disponibilité</div>
        <div className={styles.informationCardLabel}>
          {availabilityDate ? moment(availabilityDate).format('L') : 'inconnue'}
        </div>
      </div>
      <div className={styles.informationCard}>
        <div className={styles.informationCardOverLabel}>dernier placement pro-actif</div>
        <div className={styles.informationCardLabel}>JJ/MM/AAAA</div>
      </div>
    </div>
  );
};

export default TalentAvailabilityAndPlacementHeader;
