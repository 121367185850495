import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from 'src/Redux/RootReducer';
import { VariableSimulatorState } from './Types';

const getVariableSimulatorState = (state: RootState) => state.variableSimulator;

export const getFetchStatus = createSelector(
  [getVariableSimulatorState],
  (state: VariableSimulatorState) => state.fetchStatus
);

export const getConsultantInformation = createSelector(
  [getVariableSimulatorState],
  (state: VariableSimulatorState) => state.consultantInformation
);

export const getLastSyncDate = createSelector(
  [getVariableSimulatorState],
  (state: VariableSimulatorState) =>
    state.lastSyncDate === undefined ? undefined : moment(state.lastSyncDate)
);
