import { WhyActionDelayEnum } from 'src/Services/API';
import { z } from 'zod';

export const actionSchema = z.object({
  selected: z.boolean().optional(),
  description: z.string().min(1),
  actor: z.string().optional(),
  actorName: z.string().optional(),
  delay: z.nativeEnum(WhyActionDelayEnum).optional(),
});

export type ActionSchemaType = z.infer<typeof actionSchema>;
