import { Control, UseFormWatch } from 'react-hook-form';
import { descriptionSchemaType } from '../Description.schema';
import {
  BarragePhotovoltaique,
  Barriere,
  BarriereLateraleCamion,
  Baudrier,
  BipRecul,
  Bouchons,
  CarterProtection,
  Casque,
  Chaussures,
  ClasseIIIHumide,
  DoubleCommande,
  EncoffrementMachineBruyante,
  FiletProtection,
  Gants,
  GardeCorps,
  GyrophareEngin,
  HotteAspirante,
  InsonorisationLocal,
  Lunettes,
  Masque,
  PoigneeLateraleCamion,
  PoigneeVibratile,
  SolAntiderapant,
  StopRoll,
  TapisSensible,
  Ventilation,
  VoieCirculation,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';

import styles from './ProtectionEquipments.module.scss';

export interface Props {
  isEditable: boolean;
  control: Control<descriptionSchemaType>;
  watch: UseFormWatch<descriptionSchemaType>;
  submit: () => void;
  isDirty: boolean;
}

export const iconMapping: { [id: string]: JSX.Element } = {
  gants: <Gants className={styles.icon} />,
  baudrier: <Baudrier className={styles.icon} />,
  chaussures: <Chaussures className={styles.icon} />,
  casque: <Casque className={styles.icon} />,
  lunettes: <Lunettes className={styles.icon} />,
  masque: <Masque className={styles.icon} />,
  bouchons: <Bouchons className={styles.bouchonsIcon} />,
  barriereProtection: <Barriere className={styles.icon} />,
  voie: <VoieCirculation className={styles.icon} />,
  sol: <SolAntiderapant className={styles.icon} />,
  gardeCorps: <GardeCorps className={styles.icon} />,
  filetProtection: <FiletProtection className={styles.icon} />,
  stopRolls: <StopRoll className={styles.icon} />,
  barriereLaterale: <BarriereLateraleCamion className={styles.icon} />,
  poigneeLaterale: <PoigneeLateraleCamion className={styles.icon} />,
  carterProtection: <CarterProtection className={styles.icon} />,
  tapis: <TapisSensible className={styles.icon} />,
  doubleCommande: <DoubleCommande className={styles.icon} />,
  barrage: <BarragePhotovoltaique className={styles.icon} />,
  bipRecule: <BipRecul className={styles.icon} />,
  gyrophare: <GyrophareEngin className={styles.icon} />,
  poignee: <PoigneeVibratile className={styles.icon} />,
  materiel: <ClasseIIIHumide className={styles.icon} />,
  hotte: <HotteAspirante className={styles.icon} />,
  ventilation: <Ventilation className={styles.icon} />,
  insonorisation: <InsonorisationLocal className={styles.icon} />,
  encoffrement: <EncoffrementMachineBruyante className={styles.icon} />,
};
