import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SEARCH_LIMIT } from 'src/Redux/Search/Types';
import { FETCH_STATUS } from 'src/Redux/Types';
import { searchMissions } from './Thunks';
import { MissionSortBy, MissionsState, SortOrder } from './Types';

const initialState: MissionsState = {
  missions: [],
  currentMissionId: '',
  missionsFetchStatus: FETCH_STATUS.FULFILLED,
  currentMissionFetchStatus: FETCH_STATUS.FULFILLED,
  currentRequestId: undefined,
  canLoadMore: false,
  sortOrder: SortOrder.DESC,
  sortBy: MissionSortBy.START_DATE,
};

export const { reducer: missionsReducer, actions: missionsActions } = createSlice({
  name: 'missions',
  initialState,
  reducers: {
    setCurrentMissionId: (state, action: PayloadAction<string>) => {
      state.currentMissionId = action.payload;
    },
    setMissionSortBy: (state, action: PayloadAction<MissionSortBy>) => {
      state.sortBy = action.payload;
    },
    setMissionSortOrder: (state, action: PayloadAction<SortOrder>) => {
      state.sortOrder = action.payload;
    },
    reset: state => {
      state.missions = [];
      state.currentMissionId = '';
      state.currentRequestId = undefined;
    },
    resetMissionList: state => {
      state.missions = [];
    },
  },
  extraReducers: builder => {
    // Search missions
    builder.addCase(searchMissions.pending, (state, action) => {
      state.missionsFetchStatus = FETCH_STATUS.PENDING;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(searchMissions.fulfilled, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentRequestId === action.meta.requestId) {
        state.missions = state.missions.concat(action.payload);
        state.canLoadMore = action.payload.length === SEARCH_LIMIT;
        state.missionsFetchStatus = FETCH_STATUS.FULFILLED;
      }
    });
    builder.addCase(searchMissions.rejected, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.currentRequestId === action.meta.requestId) {
        state.missionsFetchStatus = FETCH_STATUS.REJECTED;
      }
    });
  },
});
