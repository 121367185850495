import { useState } from 'react';

import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  DocumentFile,
  DocumentPDF,
  Eye,
  LightPencil,
  Refresh,
  ThreeDots,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGenerateATPdf } from 'src/Hooks/WorkAccidentAnalysis/useGenerateATPdf';
import { FETCH_STATUS } from 'src/Redux/Types';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { toFetchStatus } from 'src/Utils/fetch-status';
import { onFileOpen } from 'src/Utils/onFileOpen';
import { AnalysisStatus } from '../utils';
import styles from './WorkAccidentAnalysisActionButton.module.scss';
import { Props } from './WorkAccidentAnalysisActionButton.types';

const WorkAccidentAnalysisActionButton = ({ analysis, onAnalyseOpen, status }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const generateATPdf = useGenerateATPdf();
  return (
    <PopupMenu
      position={'left top'}
      width={260}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpen })}>
          <ThreeDots className={styles.menuIcon} />
        </Button.Tertiary>
      }
    >
      <PopupMenu.Item
        icon={AnalysisStatus.DONE ? <Eye key={analysis.id} /> : <LightPencil key={analysis.id} />}
        text={
          status === AnalysisStatus.TODO
            ? "créer l'analyse AT"
            : status === AnalysisStatus.IN_PROGRESS
            ? "reprendre l'analyse AT"
            : "consulter l'analyse AT"
        }
        onClick={onAnalyseOpen}
      />
      {status !== AnalysisStatus.DONE && (
        <PopupMenu.Item
          icon={<DocumentFile />}
          text="classer l'analyse AT"
          onClick={() => {
            navigate(MODAL_ROUTES.ARCHIVE_AT.route.buildPath({}), {
              state: {
                modalParameters: {
                  status,
                  analysis,
                },
                background: location,
              },
            });
          }}
        />
      )}

      {status !== AnalysisStatus.TODO && (
        <PopupMenu.Item
          icon={
            toFetchStatus(generateATPdf) === FETCH_STATUS.PENDING ? (
              <Refresh className={styles.loading} />
            ) : (
              <DocumentPDF />
            )
          }
          text="PDF analyse AT"
          keepOpenOnClick
          onClick={() => {
            generateATPdf.mutate(analysis.id ?? '', {
              onSuccess: buffer => onFileOpen(buffer, 'application/pdf'),
            });
          }}
          disabled={analysis.id === undefined}
        />
      )}
    </PopupMenu>
  );
};

export default WorkAccidentAnalysisActionButton;
