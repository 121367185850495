import moment from 'moment';
import styles from './TalentLastContractEndDate.module.scss';
import { Props } from './TalentLastContractEndDate.types';

export const TalentLastContractEndDate = ({ lastContractEndDate }: Props) => {
  const formattedDate = moment(lastContractEndDate).format('DD/MM/YYYY');
  return (
    <div>
      <div className={styles.title}>date de fin du dernier contrat</div>
      <div className={styles.date}>
        {lastContractEndDate !== undefined ? 'inconnue' : formattedDate}
      </div>
    </div>
  );
};

export default TalentLastContractEndDate;
