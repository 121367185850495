import { Box, Slider } from '@mui/material';
import {
  Button,
  PopupMenuContext,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useContext, useState } from 'react';
import { Props } from './DistanceFilterContent.types';
import styles from './DistanceFIlterContent.module.scss';
import { VerticalBar } from '@randstad-lean-mobile-factory/react-components-ui-shared';
function Label(value: string) {
  return (
    <div className={styles.label}>
      <div>
        <VerticalBar className={styles.bar} />{' '}
      </div>
      <div className={styles.text}>{value}</div>
    </div>
  );
}
const DistanceFilterContent = ({ filteredDistance, setFilteredDistance, data }: Props) => {
  const practiceChoices = () => {
    return [
      { value: 0, label: '0' },
      { value: 10, label: '10' },
      { value: 15, label: '15' },
      { value: 20, label: '20' },
      { value: 25, label: '25' },
      { value: 30, label: '30' },
      { value: 35, label: '35' },
      { value: 40, label: '40' },
      { value: 45, label: '45' },
      { value: 50, label: '50' },
      { value: 55, label: '65' },
      { value: 60, label: '80' },
      { value: 65, label: '90' },
      { value: 70, label: '100' },
      { value: 80, label: '110' },
    ];
  };
  const practiceChoicesLabel = () => {
    return [
      { value: 10, label: '10' },
      { value: 20, label: '20' },
      { value: 30, label: '30' },
      { value: 40, label: '40' },
      { value: 50, label: '50' },
      { value: 60, label: '80' },
      { value: 70, label: '100' },
      { value: 80, label: '100+' },
    ];
  };
  const context = useContext(PopupMenuContext);
  const [value, setValue] = useState(
    practiceChoices().find(mark => parseInt(mark.label) === filteredDistance)?.value ?? 0
  );

  /*
  #2175D9 is $main-blue-full
  rgba(33, 117, 217, 0.16) is $main-blue-full wiht transparency = 0.16 
  rgba(199, 209, 220, 0.7) is $contrast-grey-light
  */
  const style = {
    '& .MuiSlider-thumb': {
      backgroundColor: '#2175D9',
      '&:hover': {
        boxShadow: '0px 0px 0px 8px rgba(33, 117, 217, 0.16)',
      },
      '&.Mui-focusVisible': {
        boxShadow: '0px 0px 0px 8px rgba(33, 117, 217,0.16)',
      },
    },
    '& .MuiSlider-track': {
      backgroundColor: '#2175D9',
      borderColor: '#2175D9',
    },
    '& .MuiSlider-rail': {
      backgroundColor: 'rgba(199, 209, 220, 0.7)',
      borderColor: 'rgba(199, 209, 220, 0.7)',
    },
    '& .MuiSlider-mark': {
      backgroundColor: 'rgba(199, 209, 220, 0.7)',
      borderColor: '#rgba(199, 209, 220, 0.7)',
    },
    '& .MuiSlider-markActive': {
      backgroundColor: '#2175D9',
      borderColor: '#2175D9',
    },
  };

  return (
    <div className={styles.container}>
      <WithLightTitle title="dans un rayon de..." className={styles.title}>
        <Box
          sx={{
            width: '100%',
            ...style,
          }}
        >
          <Slider
            min={0}
            max={90}
            defaultValue={80}
            value={value}
            onChange={(event, item) => {
              if ((item as number) >= 10 && (item as number) <= 80) {
                setValue(item as number);
              }
            }}
            step={5}
            aria-label="Restricted values"
            marks={practiceChoicesLabel().map(item => {
              return { value: item.value, label: Label(item.label) };
            })}
          />
        </Box>
      </WithLightTitle>
      <div className={styles.button}>
        <Button.Tertiary
          onClick={() => {
            context?.().actions?.close();
          }}
        >
          annuler
        </Button.Tertiary>
        <Button
          onClick={() => {
            setFilteredDistance(
              parseInt(practiceChoices().find(mark => mark.value === value)?.label ?? '0')
            );

            context?.().actions?.close();
          }}
        >
          {data
            ? `valider (${
                value >= 80 ? data.length : data.filter(mission => mission <= value).length
              } ${
                data.filter(mission => mission <= value).length === 1 ? 'résultat' : 'résultats'
              })`
            : 'valider'}
        </Button>
      </div>
    </div>
  );
};

export default DistanceFilterContent;
