import {
  CompanySearchResult,
  OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDtoBase,
} from 'src/Services/API';

export const positionStudyToMissionCompany = (
  positionStudyCompany?: OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDtoBase
): CompanySearchResult => {
  return {
    companyName: positionStudyCompany?.name,
    companyAddress: {
      city: positionStudyCompany?.location?.cityName,
      postalCode: positionStudyCompany?.location?.zipCode,
      address1: positionStudyCompany?.location?.address,
    },
    companyId: positionStudyCompany?.companyId,
  };
};
