import { useQuery } from '@tanstack/react-query';
import { RepositoryService } from 'src/Services/API';

import { QueryKeys } from '../types';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

export const useFetchInjuryLocationDetails = ({
  injuryLocationId,
}: {
  injuryLocationId: string;
}) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useQuery(
    [QueryKeys.fetchInjuryLocationDetails, injuryLocationId],
    async () => {
      return await RepositoryService.repositoryControllerGetInjuryLocationDetails({
        brandCode,
        injuryLocationId,
      });
    },
    {
      enabled: brandCode !== '' && injuryLocationId !== '',
    }
  );
};
