import { createAsyncThunk } from '@reduxjs/toolkit';
import { CompanyState } from './Types';
import { ThunkAPI } from 'src/Redux/RootReducer';
import { CompaniesService } from 'src/Services/API';

export interface FetchCompanyInfoParams {
  id: string;
  brandCode: string;
}

export const getCompanyRequestReasons = createAsyncThunk<
  CompanyState['requestReasons'],
  FetchCompanyInfoParams,
  ThunkAPI
>('companies/requestreasons', async (params: FetchCompanyInfoParams, thunkAPI) => {
  return CompaniesService.companiesControllerGetRequestReasons(params);
});
