import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppelMedicalEventSource } from 'src/Services/API';
import { ExternalDemandPreferencesState } from './Types';

const initialState: ExternalDemandPreferencesState = {
  selectedSource: AppelMedicalEventSource.HUBLO,
  searchInput: '',
};

export const {
  reducer: ExternalDemandPreferencesReducer,
  actions: ExternalDemandPreferencesActions,
} = createSlice({
  name: 'ExternalDemandPreferences',
  initialState,
  reducers: {
    setSearchInput: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
    setSelectedSource: (state, action: PayloadAction<AppelMedicalEventSource>) => {
      state.selectedSource = action.payload;
    },
  },
});
