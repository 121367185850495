import { useQuery } from '@tanstack/react-query';
import { AgenciesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchAgencyConsultants = (agencyId: string, brandCode?: string) => {
  return useQuery(
    [QueryKeys.fetchAgencyConsultants, agencyId, brandCode],
    () => {
      if (!agencyId) {
        throw new Error('no agency id');
      }
      return AgenciesService.agenciesControllerGetAgencyConsultants({ agencyId, brandCode });
    },
    {
      enabled: !!agencyId,
      refetchOnWindowFocus: false,
    }
  );
};
