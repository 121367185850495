import { TopBar } from 'src/Components/TopBar';
import TemporaryWorkersTopBarComponent from 'src/Components/TopBar/TemporaryWorkersTopBarComponent';
import ListYouplanMissions from './ListYouplanMissions';
import YouplanMissions from './YouplanMissionDetail';
import styles from './YouplanMissions.module.scss';

export const YouplanMissionsRoute = () => {
  return (
    <>
      <TopBar title="youplan">
        <TemporaryWorkersTopBarComponent />
      </TopBar>
      <div className={styles.content}>
        <ListYouplanMissions />
        <YouplanMissions />
      </div>
    </>
  );
};
