import { useSearchMissionsWithReset } from 'src/Redux/Search/Hooks';
import SingleDatePickerPopOver from '../SingleDatePickerPopOver';
import styles from './MissionsTopBarComponent.module.scss';

export const MissionsTopBarComponent = () => {
  const searchMissionsWithReset = useSearchMissionsWithReset();

  return (
    <div className={styles.container}>
      de mon agence en cours le
      <div className={styles.datePickerContainer}>
        <SingleDatePickerPopOver calendar={false} onChange={searchMissionsWithReset} />
      </div>
    </div>
  );
};
