import React, { useRef, useState } from 'react';
import moment from 'moment';

import styles from './SingleDatePickerPopOver.module.scss';
import SingleDatePicker from 'src/Components/SingleDatePicker';
import { Button, Popup, PopupActions } from '@randstad-lean-mobile-factory/react-components-core';
import SingleDatePickerButton from '../SingleDatePickerButton';
import { useSingleDatePicker } from 'src/Redux/Search/Hooks';
import { Props } from './SingleDatePickerPopOver.types';

const SingleDatePickerPopOver = ({ calendar, onChange }: Props) => {
  const ref = useRef<PopupActions | null>(null);
  const [activeStartDate, onDateChange] = useSingleDatePicker();
  const [startDate, setStartDate] = useState<moment.Moment | null>(activeStartDate);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  return (
    <Popup
      ref={ref}
      open={isDatePickerOpen}
      trigger={
        <span>
          <SingleDatePickerButton
            icon={calendar}
            isDatePickerOpen={isDatePickerOpen}
            setIsDatePickerOpen={setIsDatePickerOpen}
          />
        </span>
      }
      onOpen={() => {
        setStartDate(activeStartDate);
      }}
      position="bottom center"
    >
      <div className={styles.container}>
        <SingleDatePicker
          startDate={startDate}
          onDateChange={startDate => {
            setStartDate(startDate.startDate);
          }}
        />
        <div className={styles.footer}>
          <Button.Secondary onClick={() => ref.current?.close()}>annuler</Button.Secondary>
          <Button
            disabled={startDate === null}
            onClick={() => {
              onDateChange(startDate);
              startDate && onChange?.(startDate);
              ref.current?.close();
            }}
          >
            appliquer
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default SingleDatePickerPopOver;
