import { Folder } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import { useLocation, useNavigate } from 'react-router-dom';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';

const ArchivedAccidentAnalysisButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Button.Secondary.XSmall
      onClick={() =>
        navigate(MODAL_ROUTES.ARCHIVED_AT.route.buildPath({}), {
          state: {
            background: location,
          },
        })
      }
      leftIcon={<Folder />}
      text="AT classées"
    />
  );
};

export default ArchivedAccidentAnalysisButton;
