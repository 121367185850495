import { Liste } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import { Drawer } from '@randstad-lean-mobile-factory/react-components-core';

import { useFetchWorkAccidents } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidents';
import { toFetchStatus } from 'src/Services/Async';
import styles from './ArchivedATDrawer.module.scss';

import { useFetchWorkAccidentByIds } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentByIds';

import { useNavigate } from 'react-router-dom';
import { LoadingCard } from 'src/Components/Card';
import { FETCH_STATUS } from 'src/Redux/Types';
import { ROUTES } from 'src/Routes/Routes.types';
import CardArchivedAT from './CardArchivedAT/CardArchivedAT.component';

const ArchivedATDrawer = () => {
  const navigate = useNavigate();
  const fetchWorkAccidents = useFetchWorkAccidents();
  const fetchWorkAccidentsFetchStatus = toFetchStatus(fetchWorkAccidents);
  const archivedAT = fetchWorkAccidents.data?.archived;
  const analysisIds = archivedAT?.map(AT => AT.id);
  const { data: archivedAnalysis } = useFetchWorkAccidentByIds({ analyseIds: analysisIds });

  return (
    <Drawer
      open
      onClose={() => {
        navigate(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.buildPath({}));
      }}
    >
      <Liste className={styles.headerIllustration} />
      <div className={styles.header}>
        <h2 className={styles.title}>liste des AT classées</h2>
      </div>
      {archivedAT?.map((AT, idx) => {
        return (
          <div key={idx}>
            {fetchWorkAccidentsFetchStatus !== FETCH_STATUS.FULFILLED ? (
              <div className={styles.loadingContainer}>
                {Array.from({ length: 2 }).map((_, idx) => (
                  <div className={styles.loadingCard} key={`${idx} memoCard`}>
                    <LoadingCard />
                  </div>
                ))}
              </div>
            ) : (
              <CardArchivedAT AT={AT} idx={idx} archivedAnalysis={archivedAnalysis} />
            )}
          </div>
        );
      })}
    </Drawer>
  );
};

export default ArchivedATDrawer;
