import {
  RadioButtonGroup,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusEscalier } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import SelectCompany from 'src/Components/Selects/SelectCompany';
import SelectQualification from 'src/Components/Selects/SelectQualification';
import { FETCH_STATUS } from 'src/Redux/Types';
import MissionCreationLoader from '../MissionCreationLoader';
import styles from './PreviousMissions.module.scss';
import { Props } from './PreviousMissions.types';

const PreviousMissions = (props: Props) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.searchFieldCompany}>
        <WithLightTitle title="compte">
          <SelectCompany
            selectedCompany={props.selectedCompany}
            setSelectedCompany={selectedCompany => {
              props.setSelectedCompany(selectedCompany);
            }}
            agency={props.currentAgency ?? ''}
            brandCode={props.currentBrandcode?.brandCodeApiHeader ?? ''}
            canBeReset={false}
            defaultValue={props.selectedCompany?.companyName}
          />
        </WithLightTitle>
      </div>
      <div className={styles.searchFieldQualification}>
        <WithLightTitle title="qualification">
          <SelectQualification
            selectedQualification={props.selectedQualification}
            setSelectedQualification={selectedQualification => {
              props.setSelectedQualification(selectedQualification);
            }}
            // We specify no society / brand to have the same behavior as OSM
            canBeReset={false}
            defaultValue={props.selectedQualification?.label}
          />
        </WithLightTitle>
      </div>
    </div>
    {props.previousMissionsFetchStatus === FETCH_STATUS.PENDING && <MissionCreationLoader />}
    {props.previousMissionsFetchStatus === FETCH_STATUS.FULFILLED && (
      <>
        <div className={styles.title}>
          <p>nom de la commande</p>
          <p>dernière modification</p>
        </div>
        {props.previousMissions?.map((mission, idx) => (
          <div className={classnames(styles.previousMission, { [styles.topMission]: idx === 0 })}>
            <RadioButtonGroup
              name="previousMissions"
              radioButtonsInfo={[
                {
                  id: mission.missionId ?? ' ',
                  value: mission.missionId ?? ' ',
                  label: (
                    <div className={styles.buttonContent}>
                      <div className={styles.missionInfo}>
                        <p className={styles.missionName}>{mission.qualification?.label}</p>
                        <p className={styles.serviceName}>{mission.service?.name}</p>
                      </div>
                      <div className={styles.modificationDate}>
                        <p>{moment(mission.modificationDate).format('DD/MM/Y') ?? '--/--/--'}</p>
                      </div>
                    </div>
                  ),
                },
              ]}
              onChange={() => props.setSelectedPreviousMission(mission)}
              selected={props.selectedPreviousMission?.missionId}
            />
          </div>
        ))}
      </>
    )}
    {props.previousMissionsFetchStatus === FETCH_STATUS.REJECTED && (
      <div className={styles.noResult}>
        <IllusEscalier />
        <div className={styles.noResultText}>Une erreur est survenue</div>
      </div>
    )}
  </div>
);

export default PreviousMissions;
