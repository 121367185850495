import React from 'react';
import moment from 'moment';

import './DateRangePicker.scss';
import { Props } from './DateRangePicker.types';
import { DateRangePickerDeprecated as LibDateRangePicker } from '@randstad-lean-mobile-factory/react-components-core';

const DateRangePicker = (props: Props) => {
  return (
    <LibDateRangePicker
      startDate={props.startDate?.toDate()}
      endDate={props.endDate?.isValid() ? props.endDate.toDate() : undefined}
      onChange={(range: [Date | undefined, Date | undefined]) => {
        props.onDatesChange({
          startDate: range[0] ? moment(range[0]) : null,
          endDate: range[1] ? moment(range[1]) : null,
        });
      }}
      monthsShown={props.monthsShown ?? 2}
    />
  );
};

export default DateRangePicker;
