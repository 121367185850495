import { RandstadCircle } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Button,
  ParametersPopup,
  PopoverActions,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useRef, useState } from 'react';
import PerimeterSelectionModal from 'src/Modals/PerimeterSelectionModal';
import { Props } from './ParametersPopOverMenu.types';
import ParametersPopOverMenuButton from './ParametersPopOverMenuButton';
import styles from './ParametersPopOverMenu.module.scss';

const ParametersPopOverMenu = (props: Props) => {
  const innerRef = useRef<PopoverActions>(null);
  const [isPerimeterModalOpen, setIsPerimeterModalOpen] = useState(false);

  return (
    <>
      <ParametersPopup
        trigger={<ParametersPopOverMenuButton />}
        onClose={() => setIsPerimeterModalOpen(false)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
        {...props}
        ref={innerRef}
      >
        <Button.Tertiary
          className={styles.button}
          onClick={() => {
            innerRef.current?.close();
            setIsPerimeterModalOpen(true);
          }}
        >
          <RandstadCircle variant="fill" />
          choix de vos unités
        </Button.Tertiary>
      </ParametersPopup>
      <PerimeterSelectionModal
        open={isPerimeterModalOpen}
        onClose={() => setIsPerimeterModalOpen(false)}
      />
    </>
  );
};

export default ParametersPopOverMenu;
