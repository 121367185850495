import { useMutation } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrand,
} from 'src/Redux/Perimeter/Selectors';
import { CandidatesService } from 'src/Services/API';

import { MutationKeys } from '../types';

export const useOpenOSMResume = () => {
  const brandCode = useSelector(getCurrentBrand);
  const UserInfo = useSelector(getAnalyticsUserInfo);
  const agency = useSelector(getCurrentAgency);
  return useMutation(
    [MutationKeys.openOSMResume],
    async ({ candidateId, candidateName }: { candidateId: string; candidateName: string }) => {
      const base64 = await CandidatesService.candidatesControllerGetCvCompany({
        id: candidateId,
        brandCode: brandCode?.brand?.code,
      });
      ReactGA.event('Consultation de CV Osmose', {
        brandCode,
        agency: agency,
        regionId: UserInfo?.regionId,
        zoneId: UserInfo?.zoneId,
      });
      const win = window.open();
      win?.document.write(
        `<iframe src="data:application/pdf;base64,${base64}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
      );
      if (win) {
        win.document.title = `CV ${candidateName}`;
      }
    }
  );
};
