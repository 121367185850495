import { useEffect, useRef, useState } from 'react';

import { Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import ContentLoader from 'react-content-loader';
import { useInView } from 'react-intersection-observer';
import { redCloud } from 'src/Assets_DEPRECATED';
import { useFetchCandidateLastContractEndDate } from 'src/Hooks/Candidate/useFetchCandidateLastContractEndDate';
import { useFetchMissionsBySkillMatchingForRepositioning } from 'src/Hooks/Missions/useFetchMissionsBySkillMatchingForRepositioning';
import DatesPopupMenu from '../DatesPopupMenu';
import DepartmentsPopupMenu from '../DepartmentsPopupMenu';
import MissionBySkillMatchingCard, { LoadingCard } from './MissionBySkillMatchingCard';
import styles from './MissionsBySkillMatching.module.scss';
import { Props } from './MissionsBySkillMatching.types';

const MissionsBySkillMatching = ({
  candidateId,
  candidateQualifications,
  allDepartments,
  departmentsFetchStatus,
  selectedDepartments,
  setSelectedDepartments,
  selectedMissionsBySkillMatching,
  setSelectedMissionsBySkillMatching,
  setScrollTop,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView();
  const { data: candidateLastContractEndDate } =
    useFetchCandidateLastContractEndDate(candidateId) ?? moment();
  const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment | null>(
    moment(candidateLastContractEndDate) ?? moment()
  );
  const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment | null>(
    moment(candidateLastContractEndDate ?? '').add(6, 'months')
  );
  const {
    data: missionsPages,
    fetchNextPage,
    isSuccess,
    isError,
    isFetchingNextPage,
    isLoading,
  } = useFetchMissionsBySkillMatchingForRepositioning({
    mainQualifications: candidateQualifications,
    departmentIds: selectedDepartments.map(item => item.id ?? ''),
    startDate: selectedStartDate ?? moment(),
    endDate: selectedEndDate ?? moment(),
  });
  const missions = (missionsPages?.pages ?? []).flat(1);

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, missions.length]);

  useEffect(() => {
    const startDate = moment(candidateLastContractEndDate).isAfter(moment())
      ? moment(candidateLastContractEndDate)
      : moment();
    setSelectedStartDate(startDate.clone());
    setSelectedEndDate(startDate.clone().add(2, 'months'));
  }, [candidateLastContractEndDate]);
  return (
    <div
      className={styles.container}
      ref={containerRef}
      onScroll={() => setScrollTop(containerRef?.current?.scrollTop ?? 0)}
    >
      <div className={styles.filters}>
        <div className={styles.filter}>
          <DatesPopupMenu
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedStartDate={setSelectedStartDate}
            setSelectedEndDate={setSelectedEndDate}
          />
        </div>
        <div className={styles.filter}>
          <DepartmentsPopupMenu
            allDepartments={allDepartments}
            departmentsFetchStatus={departmentsFetchStatus}
            selectedDepartments={selectedDepartments}
            setSelectedDepartments={setSelectedDepartments}
          />
        </div>
      </div>
      {isSuccess && (
        <>
          <p className={styles.title}>
            {missions.length > 0
              ? 'commandes pour lesquelles le candidat a au moins 50% des compétences'
              : 'aucune commande dont le candidat a au moins 50% des compétences'}
          </p>
          <div className={styles.checkboxesContainer}>
            {missions.map(mission => {
              const selectedMissionIds = selectedMissionsBySkillMatching.map(
                mission => mission.missionId
              );
              const isSelected = selectedMissionIds.includes(mission.missionId ?? '');
              return (
                <div className={styles.checkboxContainer}>
                  <Checkbox
                    children={<MissionBySkillMatchingCard mission={mission} />}
                    onClick={() =>
                      isSelected
                        ? setSelectedMissionsBySkillMatching(
                            selectedMissionsBySkillMatching.filter(
                              missionItem => mission.missionId !== missionItem.missionId
                            )
                          )
                        : setSelectedMissionsBySkillMatching([
                            ...selectedMissionsBySkillMatching,
                            mission,
                          ])
                    }
                    checked={isSelected}
                  />
                </div>
              );
            })}
            <div ref={ref}>
              {isFetchingNextPage && (
                <ContentLoader height="3.5rem" width="100%" uniqueKey={'missionCard'}>
                  <rect x="1%" y="10" rx="4" ry="4" width="80%" height="16" />
                  <rect x="1%" y="30" rx="4" ry="4" width="80%" height="16" />
                </ContentLoader>
              )}
            </div>
          </div>
        </>
      )}
      {isLoading && (
        <>
          <div className={styles.title}></div>
          {Array.from({ length: 2 }).map((_, idx) => (
            <div className={styles.checkboxContainer} key={`${idx} missionCard`}>
              <LoadingCard />
            </div>
          ))}
        </>
      )}
      {isError && (
        <div className={styles.title}>
          <img src={redCloud} alt="illusEscalier" />
          <div>Une erreur est survenue</div>
        </div>
      )}
    </div>
  );
};

export default MissionsBySkillMatching;
