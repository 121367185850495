import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyState } from './Types';
import { getCompanyRequestReasons } from './Thunks';
import { FETCH_STATUS } from 'src/Redux/Types';

const initialState: CompanyState = {
  requestReasons: [],
  requestReasonsFetchStatus: FETCH_STATUS.FULFILLED,
  currentCompany: undefined,
  fetchStatus: FETCH_STATUS.FULFILLED,
  companiesRequestId: undefined,
  contactsRequestId: undefined,
  requestReasonsRequestId: undefined,
};

export const { reducer: companiesReducer, actions: companiesActions } = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCurrentCompany: (state, action: PayloadAction<CompanyState['currentCompany']>) => {
      state.currentCompany = action.payload;
    },

    resetCompanyRequestReasons: state => {
      state.requestReasons = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getCompanyRequestReasons.pending, (state, action) => {
      state.requestReasonsFetchStatus = FETCH_STATUS.PENDING;
      state.requestReasonsRequestId = action.meta.requestId;
    });
    builder.addCase(getCompanyRequestReasons.fulfilled, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.requestReasonsRequestId === action.meta.requestId) {
        state.requestReasons = action.payload;
        state.requestReasonsFetchStatus = FETCH_STATUS.FULFILLED;
      }
    });
    builder.addCase(getCompanyRequestReasons.rejected, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.requestReasonsRequestId === action.meta.requestId) {
        state.requestReasonsFetchStatus = FETCH_STATUS.REJECTED;
      }
    });
  },
});
