import { YesNoUnknown } from 'src/Services/API';

export const yesNoUnknownFromBoolean = (boolean: boolean | undefined) => {
  if (boolean) return 'oui';
  if (boolean === false) return 'non';
  return 'non renseigné';
};

export const yesNoUnknownTextFromYesNoUnknown = {
  [YesNoUnknown.Yes]: 'oui',
  [YesNoUnknown.No]: 'non',
  [YesNoUnknown.Unknown]: 'non renseigné',
};
