import {
  Button,
  Popup,
  PopupMenuContext,
} from '@randstad-lean-mobile-factory/react-components-core';
import { InfoOutline } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useContext } from 'react';
import { Colors } from 'src/Utils/constants';
import styles from './LegendPopup.module.scss';

const LegendPopup = () => {
  const popupContext = useContext(PopupMenuContext);
  return (
    <Popup
      key="legend"
      contentStyle={{
        width: 160,
        height: 130,
        textAlign: 'left',
        backgroundColor: Colors.white,
        borderWidth: 1,
        borderRadius: 8,
      }}
      arrowStyle={{ backgroundColor: Colors.darkBlue }}
      keepTooltipInside
      position="bottom left"
      trigger={
        <div>
          <Button.Secondary.XSmall className={styles.legendButton}>
            <InfoOutline className={styles.iconClassName} />
            légende
          </Button.Secondary.XSmall>
        </div>
      }
      onClose={() => {
        popupContext?.().actions?.close();
      }}
      on="hover"
    >
      <div className={styles.legendText}>
        statut général
        <div className={styles.legendColors}>
          <div className={styles.legendContainer}>
            <div title={'absent'} className={classnames(styles.cubeShapeAbsence)} />
            <div className={styles.legendColorsText}>absent</div>
          </div>
          <div className={styles.legendContainer}>
            <div title={'intermission'} className={classnames(styles.cubeShapeIntermission)} />
            <div className={styles.legendColorsText}>intermission</div>
          </div>
          <div className={styles.legendContainer}>
            <div title={'mission'} className={classnames(styles.cubeShapeMission)} />
            <div className={styles.legendColorsText}>en mission</div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default LegendPopup;
