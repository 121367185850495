import { Animation, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { TextArea, TripleChoiceButton } from '@randstad-lean-mobile-factory/react-form-fields';
import { useEffect } from 'react';
import { useController } from 'react-hook-form';

import { Epi, MaseItem, YesNoUnknown } from 'src/Services/API';
import { addOrRemove } from 'src/Utils/addOrRemove';

import styles from './ProtectionEquipments.module.scss';
import { Props } from './ProtectionEquipments.types';
import { useFetchProtectionEquipments } from 'src/Hooks/WorkAccidentAnalysis/useFetchProtectionEquipments';
import EpisSelection from './EpisSelection/EpisSelection.component';
import EpcsSelection from './EpcsSelection/EpcsSelection.component';
import { yesNoUnknownTextFromYesNoUnknown } from 'src/Utils/yesNoUnknownText';

const ProtectionEquipments = ({ isEditable, control, watch, submit, isDirty }: Props) => {
  const fetchProtectionEquipments = useFetchProtectionEquipments();
  const protectionEquipments = fetchProtectionEquipments.data;

  const hasEpis = watch('hasEpis');
  const hasEpcs = watch('hasEpcs');
  const otherEPCS = watch('otherEpcs');

  const EPIsController = useController({
    name: 'epis',
    control,
  });

  const mainEPCsController = useController({
    name: 'mainEpcs',
    control,
  });

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasEpis, EPIsController.field.value, hasEpcs, mainEPCsController.field.value]);

  return (
    <div className={styles.container}>
      <div className={styles.separator} />
      <h2 className={styles.title}>EPI sur le poste</h2>

      {isEditable ? (
        <div className={styles.subsection}>
          <p className={styles.label}>
            y a-t-il des équipements de protection individuels pour ce poste ?
          </p>
          <TripleChoiceButton name="hasEpis" control={control} />
        </div>
      ) : (
        <WithLightTitle
          className={styles.lightTitle}
          title="y a-t-il des équipements de protection individuels pour ce poste ?"
        >
          {yesNoUnknownTextFromYesNoUnknown[hasEpis]}
        </WithLightTitle>
      )}

      <Animation.Unroll visible={hasEpis === YesNoUnknown.Yes}>
        <EpisSelection
          isEditable={isEditable}
          allEPIs={(protectionEquipments?.epis ?? []) as MaseItem[]}
          isError={fetchProtectionEquipments.isError}
          isSuccess={fetchProtectionEquipments.isSuccess}
          selectedEpis={EPIsController.field.value}
          onEpiUpdate={(epi: Epi) => {
            isEditable &&
              EPIsController.field.onChange([
                ...EPIsController.field.value.filter(previous => previous.id !== epi.id),
                epi,
              ]);
          }}
          onEpiDelete={(epi: Epi) => {
            isEditable &&
              EPIsController.field.onChange(
                EPIsController.field.value.filter(selectedEpi => selectedEpi.id !== epi.id)
              );
          }}
        />
      </Animation.Unroll>

      <div className={styles.separator} />
      <h2 className={styles.title}>EPC sur le poste</h2>

      {isEditable ? (
        <div className={styles.subsection}>
          <p className={styles.label}>
            y a-t-il des équipements de protection collectifs sur ce poste ?
          </p>
          <TripleChoiceButton name="hasEpcs" control={control} />
        </div>
      ) : (
        <WithLightTitle
          className={styles.lightTitle}
          title="y a-t-il des équipements de protection collectifs sur ce poste ?"
        >
          {yesNoUnknownTextFromYesNoUnknown[hasEpcs]}
        </WithLightTitle>
      )}

      <Animation.Unroll visible={hasEpcs === YesNoUnknown.Yes}>
        {isEditable ? (
          <EpcsSelection
            allEpcs={(protectionEquipments?.epcs ?? []) as MaseItem[]}
            isLoading={fetchProtectionEquipments.isLoading}
            isError={fetchProtectionEquipments.isError}
            isSuccess={fetchProtectionEquipments.isSuccess}
            selectedEpcs={mainEPCsController.field.value}
            onEpcClick={(epc: MaseItem) =>
              mainEPCsController.field.onChange(addOrRemove(mainEPCsController.field.value, epc))
            }
          />
        ) : (
          <WithLightTitle title="EPC sélectionnés">
            {mainEPCsController.field.value.map(epc => (
              <div>{epc.label}</div>
            ))}
            {mainEPCsController.field.value.length === 0 && 'aucun renseigné'}
          </WithLightTitle>
        )}

        <WithLightTitle
          className={styles.lightTitle}
          title="autres EPC"
          rightTitleComponent={
            isEditable ? (
              <span className={styles.charCountLabel}>
                {otherEPCS?.length ?? 0}/3999 caractères
              </span>
            ) : (
              <></>
            )
          }
        >
          {isEditable ? (
            <div className={styles.otherEpcsContainer}>
              <TextArea
                name="otherEpcs"
                control={control}
                maxLength={3999}
                placeholder="veuillez lister les autres EPC nécessaires"
                onBlurCapture={submit}
              />
            </div>
          ) : (
            <div className={styles.otherEpcsContainerNonEditable}>
              {otherEPCS ?? 'non renseigné'}
            </div>
          )}
        </WithLightTitle>
      </Animation.Unroll>
    </div>
  );
};

export default ProtectionEquipments;
