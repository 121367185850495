import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { useFetchTemporaryWorkerDetail } from 'src/Hooks/Candidate/useFetchTemporaryWorkerDetail';
import { ROUTES } from 'src/Routes/Routes.types';
import RepositioningTalent from 'src/Routes/Talents/TemporaryWorkers/TalentRepositioning/Repositioning';
import { getTalentStatus } from 'src/Routes/Talents/TemporaryWorkers/utils';
import styles from './TalentRepositioning.module.scss';

export const TalentRepositioningRoute = () => {
  const { talentId } = useTypedParams(
    ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.REPOSITIONING
  );
  const {
    data: talent,
    isSuccess: talentSuccess,
    isLoading: talentLoading,
    isError: talentError,
  } = useFetchTemporaryWorkerDetail(talentId);

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        {talentLoading ? (
          <div className={styles.loadingScreen}>
            <Loader heightInRem={4} className={styles.loader} />
            <p>chargement des détails du talent, cela peut prendre du temps</p>
          </div>
        ) : talentSuccess && talentId && talentId !== '' ? (
          <div className={styles.repositioningCDII}>
            <RepositioningTalent
              talentId={talentId}
              talentData={talent ?? {}}
              statut={getTalentStatus(
                talent?.lastContract?.endDate,
                talent?.availabilityForConsultantDate
              )}
            />
          </div>
        ) : (
          talentError && <div className={styles.noResult}>ce talent n'existe pas</div>
        )}
      </div>
    </div>
  );
};
