import {
  Badge,
  DataTable,
  Menu,
  MenuItem,
  ThreeDotsButton,
} from '@randstad-lean-mobile-factory/react-components-core';
import { ColumnDef } from '@tanstack/react-table';
import classnames from 'classnames';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/Routes/Routes.types';
import { TemporaryWorkerWithoutDetail } from 'src/Services/API';
import { capitalizeNames } from 'src/Utils/capitalizeNames';
import { TALENTS_STATUS } from '../TemporaryWorkers.types';
import { getTalentStatus } from '../utils';
import styles from './TemporaryWorkersList.module.scss';
import { TemporaryWorkersListProps } from './TemporaryWorkersList.types';
import { Eye } from '@randstad-lean-mobile-factory/react-assets/dist/icons';

export const TemporaryWorkersList = ({ source, temporaryWorkers }: TemporaryWorkersListProps) => {
  const navigate = useNavigate();

  const columns = useMemo((): ColumnDef<TemporaryWorkerWithoutDetail>[] => {
    const columns: (ColumnDef<TemporaryWorkerWithoutDetail> | false)[] = [
      {
        id: 'name',
        header: 'nom',
        accessorFn: temporaryWorker =>
          [temporaryWorker.firstName, temporaryWorker.name]
            .filter(Boolean)
            .map(capitalizeNames)
            .join(' '),
        cell: ({ getValue }) => <span className={styles.name}>{getValue() as string}</span>,
      },
      {
        id: 'agency',
        header: 'unité du dernier contrat',
        accessorFn: temporaryWorker => temporaryWorker.agencyId,
      },
      {
        id: 'qualification',
        header: 'qualification dernier contrat',
        accessorFn: temporaryWorker => temporaryWorker.qualification?.label?.toLowerCase(),
      },
      {
        id: 'address',
        header: 'lieu de résidence',
        accessorFn: temporaryWorker =>
          [temporaryWorker.zipCode, temporaryWorker.city].filter(Boolean).join(' '),
      },
      {
        id: 'lastContractEndDate',
        header: 'date fin contrat',
        accessorFn: temporaryWorker =>
          temporaryWorker.lastContractEndDate &&
          moment(temporaryWorker.lastContractEndDate).format('L'),
      },
      {
        id: 'availability',
        header: 'prochaine dispo',
        accessorFn: temporaryWorker =>
          temporaryWorker.availabilityForConsultantDate &&
          moment(temporaryWorker.availabilityForConsultantDate).format('L'),
      },
      {
        id: 'status',
        header: () => <div className={styles.statusHeader}>statut</div>,
        cell: ({ row }) => {
          const talentStatus = getTalentStatus(
            undefined, // TODO add last contract end date
            row.original.availabilityForConsultantDate
          );
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isOpen, setIsOpen] = useState(false);
          return (
            <div className={styles.statusColumn} data-no-padding-left>
              <Badge
                className={classnames({
                  [styles.badgeInMission]: talentStatus === TALENTS_STATUS.IN_MISSION,
                  [styles.badgeIntermission]: talentStatus === TALENTS_STATUS.INTERMISSION,
                  [styles.badgeSoonAvailable]: talentStatus === TALENTS_STATUS.SOON_AVAILABLE,
                })}
                value={talentStatus}
              />
              <Menu
                onOpen={() => setIsOpen(!isOpen)}
                onClose={() => setIsOpen(!isOpen)}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                trigger={<ThreeDotsButton className={styles.button} triggered={isOpen} />}
                enableStopPropagation
              >
                <MenuItem
                  onClick={() =>
                    navigate(
                      ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.buildPath({
                        talentId: row.original.candidateId,
                      })
                    )
                  }
                >
                  <Eye />
                  détail talent
                </MenuItem>
              </Menu>
            </div>
          );
        },
      },
    ];
    return columns.filter(Boolean);
  }, [navigate]);

  return (
    <DataTable
      size="small"
      columns={columns}
      data={temporaryWorkers}
      rowStyle="cards"
      manualSorting
      manualFiltering
      enableSorting={false}
      onRowClick={row =>
        navigate(
          ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.buildPath({
            talentId: row.original.candidateId,
          })
        )
      }
    />
  );
};
