import {
  ActionPlan,
  AnalyseAT,
  Filters,
  FlashIcon,
  ListFill,
  WhiteDash,
  WhiteRightArrow,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';

import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring, useTransition } from 'react-spring';
import { CDIWorkers, Contract, Mission, Talents, tools, Youplan } from 'src/Assets_DEPRECATED';
import ParametersPopOverMenu from 'src/Components/PopOverMenus/ParametersPopOverMenu';
import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { MODAL_ROUTES, useBackgroundLocation } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { FeatureSwitchManager } from 'src/Services/FeatureSwitch';
import Apps from './Apps';
import styles from './SideBar.module.scss';
import { firstSectionTitle, secondSectionTitle } from './SideBar.types';

const SubMenuIcon = ({ text, minified }: { text: string; minified: boolean }) => {
  const transitions = useTransition(minified, null, {
    from: { position: 'fixed', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return (
    <div className={styles.menuTitle}>
      {transitions.map(({ item, key, props }) =>
        item ? (
          <animated.div key={key} style={props}>
            <WhiteDash className={styles.whitedash} />
          </animated.div>
        ) : (
          <animated.div key={key} style={props}>
            {text}
          </animated.div>
        )
      )}
    </div>
  );
};

const authorizedAgenciesCDIIRepositioning = [
  'AR3',
  'BE4',
  'DVO',
  'VSF',
  '804',
  'MIM',
  'ZD',
  'SMI',
  'HN',
  'GDX',
  'PA4',
  'PB',
  'ACR',
  'ELA',
  '698',
  'MG',
  'DY',
  'FIG',
  'CW',
  'MCF',
  'AT',
  'FCV',
  'NVM',
  'IO',
  'JZW',
  'ZW',
  'CBO',
  'QB',
  'MM',
  'LBA',
  'AR',
  'OR8',
  'NB',
  'V94',
  '854',
  'USS',
  'MAR',
  'V79',
  'ORT',
  'VC6',
  'JI',
  'VIS',
  'OB3',
  'HAU',
  'CCW',
  'CCH',
  'GH',
  'JT',
  'PAU',
  'NU2',
  'OI1',
  'SJ',
  'FCE',
  'AJO',
  'VS6',
  'THI',
  'MY',
  'VLU',
  'CLU',
  'ACT',
  'TCF',
  'RBX',
  'ZG',
  'TUL',
  'ZQ',
  'ICF',
  'NV2',
  'FVA',
  'TGH',
  'CAU',
  'CF',
  'TAR',
  'ZB',
  'V95',
  'AT2',
  'KPO',
  'LA',
  'ZN',
  'AUR',
  '849',
  'ZJ',
  'JSB',
  'LPC',
  'IZN',
  'BC',
  'XV',
  'LOU',
  'AE1',
  'VY',
  '693',
  'BIG',
  'OCF',
  'ZQ2',
  'LG',
  'BRD',
  'AE',
  'BAC',
  'PS9',
  'FSA',
  'CMM',
  'LPA',
  'LPC',
  'PAX',
  'MG',
  'XV',
  'FJU',
  'DCM',
  'AIA',
  'AAY',
  'CCN',
  'CLO',
  'CGR',
  'CCA',
  '306',
  '308',
  '310',
  '362',
  '376',
  '379',
  'BAN',
  'BSN',
  'DAG',
  'IRZ',
  'KN',
  'LIG',
  'MA6',
  'MA7',
  'MA8',
  'MHF',
  'NAM',
  'NAX',
  'NEB',
  'ONA',
  'QP',
  'SKC',
  'SPG',
  'T36',
  'TW',
  'V58',
  'V97',
  'VAH',
  'VAK',
  'ANP',
  'BS',
  'BS1',
  'BSB',
  'CAP',
  'CJ',
  'GLO',
  'GO',
  'GP',
  'GU2',
  'GUI',
  'HLO',
  'IZO',
  'LNO',
  'LT',
  'NW',
  'OL3',
  'OL4',
  'PDO',
  'PLO',
  'PST',
  'QO',
  'QY',
  'RW',
  'SB',
  'V38',
  'VAG',
  'VAI',
  'VAL',
  'ZI',
  'ZK',
  'ZO',
  'ZR',
  'ZRI',
  '385',
  '386',
  'BDB',
  'BRE',
  'DBR',
  'DPL',
  'FBO',
  'FCH',
  'FMO',
  'IZL',
  'IZX',
  'MBG',
  'MDB',
  'OC3',
  'OSR',
  'RS',
  'RSX',
  'STI',
  'TRS',
  'V59',
  'VAD',
  'ZL',
  'ZU',
  'ZX',
  'ZXA',
  'TIL',
  'BOT',
  'AGS',
  'AGT',
  'ARY',
  'BBA',
  'BLV',
  'CHG',
  'CHI',
  'CNC',
  'CSY',
  'E12',
  'ECH',
  'ERY',
  'ESR',
  'ESY',
  'EVR',
  'FCG',
  'GSL',
  'IAG',
  'IBE',
  'ILL',
  'JS',
  'LEA',
  'LVJ',
  'MFC',
  'OLR',
  'OLS',
  'OY',
  'PIL',
  'QX',
  'RCC',
  'RMA',
  'RO',
  'RSY',
  'SAN',
  'SAO',
  'SLR',
  'SMY',
  'TSA',
  'TU',
  'V35',
  'VEC',
  'VL6',
  'ZF',
  'DA',
  'BRO',
  '300',
  '301',
  '344',
  '741',
  'COC',
  'DI2',
  'E27',
  'EPC',
  'FLO',
  'GPH',
  'IB',
  'JVR',
  'KCS',
  'LH',
  'LHX',
  'MFM',
  'OC2',
  'OS3',
  'PR',
  'PSP',
  'QG',
  'RC',
  'RG',
  'RY',
  'SCE',
  'SV1',
  'VEO',
  'VNE',
  'VPK',
  'XSV',
  'YG',
  'YIE',
  'YO',
  'YX',
  'YZ',
  '327',
  '332',
  '333',
  '953',
  'CG',
  'CGM',
  'CN2',
  'CNX',
  'CO',
  'DOV',
  'E52',
  'OA1',
  'FMT',
  'FVI',
  'KE',
  'LFA',
  'LFM',
  'LSA',
  'LX',
  'MVI',
  'NKG',
  'NZA',
  'OL2',
  'QK',
  'RB',
  'VMO',
  'YP',
  '108',
  '136',
  '536',
  '817',
  '860',
  '921',
  '960',
  'BG',
  'BL',
  'CHL',
  'CSC',
  'CT',
  'CT3',
  'CTY',
  'E29',
  'EDX',
  'FTH',
  'GCH',
  'GOR',
  'HMO',
  'IDR',
  'IE',
  'IF',
  'IOR',
  'JR',
  'LLB',
  'LTO',
  'MK',
  'PVZ',
  'SGS',
  'SUL',
  'SUN',
  'TJS',
  'TO',
  'TOG',
  'TSB',
  'V32',
  'VBL',
  'VIE',
  'VV',
  'AS6',
  'BSA',
  'ERO',
  'FBR',
  'FR',
  'GAR',
  'GVP',
  'IJA',
  'JA',
  'LM1',
  'LME',
  'NAL',
  'PAR',
  'QJ',
  'QU',
  'SOC',
  'TKT',
  'VNR',
  'VSU',
];

const authorizedAgenciesTTRepositioning = [
  'ACT',
  'CW',
  'FCV',
  'KPO',
  'PB',
  'SMI',
  'V95',
  'VC6',
  '693',
  '698',
  'CF',
  'DVO',
  'ICF',
  'JSB',
  'MCF',
  'OCF',
  'OI1',
  'OR8',
  'TCF',
  'VIS',
  'VS6',
  'THI',
  'JI',
  '804',
  '849',
  '854',
  'BIG',
  'BRD',
  'CBO',
  'CLU',
  'FVA',
  'GH',
  'LBA',
  'LPC',
  'RBX',
  'TGH',
  'ZG',
  'GDX',
];

// TODO simplifier la sidebar
const SideBar = ({ isCurrentBrandAppelMedical }: { isCurrentBrandAppelMedical: boolean }) => {
  const navigate = useNavigate();
  const location = useBackgroundLocation();
  const currentSection = location.pathname.split('/').slice(0, 3).join('/');

  const openedWidth = 176;
  const closedWidth = 64;
  const { isProd } = useIsProductionEnvironment();

  const currentAgency = useSelector(getCurrentAgency);

  const isCurrentAgencyAuthorizedToConsultCDIIRepositioning = useMemo(
    () => authorizedAgenciesCDIIRepositioning.includes(currentAgency ?? ''),
    [currentAgency]
  );

  const isCurrentAgencyAuthorizedToConsultTTRepositioning = useMemo(
    () => authorizedAgenciesTTRepositioning.includes(currentAgency ?? ''),
    [currentAgency]
  );

  const [opened, setOpened] = useState(true);

  const expand = useSpring({
    minWidth: opened ? `${openedWidth}px` : `${closedWidth}px`,
    maxWidth: opened ? `${openedWidth}px` : `${closedWidth}px`,
  });

  const spin = useSpring({
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  });

  const fade = useSpring({
    opacity: opened ? 1 : 0,
  });

  const menu = useMemo(() => {
    return [
      !isCurrentBrandAppelMedical
        ? {
            route: ROUTES.ACTIVITY,
            subMenu: [
              {
                icon: <Mission />,
                route: ROUTES.ACTIVITY.MISSIONS,
              },
              {
                icon: <Contract />,
                route: ROUTES.ACTIVITY.CONTRACTS,
              },
              {
                icon: <Youplan />,
                route: ROUTES.ACTIVITY.YOUPLAN,
              },
            ],
          }
        : {
            route: ROUTES.EXTERNAL_DEMANDS,
            subMenu: [
              {
                icon: (
                  <div className={styles.icons}>
                    <ListFill />
                  </div>
                ),
                route: ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST,
              },
              {
                icon: (
                  <div className={styles.icons}>
                    <Filters />
                  </div>
                ),
                route: ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES,
              },
            ],
          },
      ...(FeatureSwitchManager.canUseRepositioning &&
      !isCurrentBrandAppelMedical &&
      (isProd
        ? isCurrentAgencyAuthorizedToConsultCDIIRepositioning ||
          isCurrentAgencyAuthorizedToConsultTTRepositioning
        : true)
        ? [
            {
              route: ROUTES.TALENTS,
              subMenu: [
                ...((isProd ? isCurrentAgencyAuthorizedToConsultCDIIRepositioning : true)
                  ? [
                      {
                        icon: <CDIWorkers />,
                        route: ROUTES.TALENTS.CDII,
                      },
                    ]
                  : []),
                ...(FeatureSwitchManager.canUseTalents &&
                (isProd ? isCurrentAgencyAuthorizedToConsultTTRepositioning : true)
                  ? [
                      {
                        icon: <Talents className={styles.talentsIcon} />,
                        route: ROUTES.TALENTS.TEMPORARY_WORKERS,
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
      ...(FeatureSwitchManager.canUseAccidentologie && !isCurrentBrandAppelMedical
        ? [
            {
              route: ROUTES.ACCIDENTOLOGY,
              subMenu: [
                {
                  icon: <AnalyseAT />,
                  route: ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT,
                },
                {
                  icon: <ActionPlan />,
                  route: ROUTES.ACCIDENTOLOGY.ACTION_PLAN,
                },
              ],
            },
          ]
        : []),
    ] as const;
  }, [
    isCurrentBrandAppelMedical,
    isProd,
    isCurrentAgencyAuthorizedToConsultCDIIRepositioning,
    isCurrentAgencyAuthorizedToConsultTTRepositioning,
  ]);

  return (
    <animated.div style={expand}>
      <div className={styles.container}>
        {/* <AutomnEasterEgg /> */}
        {/* <WinterEasterEgg /> */}
        <div className={styles.logo}>
          <FlashIcon />
          <animated.div style={fade} className={styles.menuItemContainer}>
            <div className={styles.verticalSeparator} />
            <div className={styles.appTitle}>flash</div>
          </animated.div>
        </div>
        {menu.map(menuItem => (
          <div key={menuItem.route.path} className={styles.menuContainer}>
            <SubMenuIcon text={firstSectionTitle[menuItem.route.path]} minified={!opened} />
            {menuItem.subMenu.map(item => {
              const isSelected = item.route.path === currentSection;
              return (
                <div className={styles.menuItemContainer} key={item.route.path}>
                  <div className={isSelected ? styles.whiteBorder : styles.transparentBorder} />
                  <div className={classnames(styles.item, { [styles.selected]: isSelected })}>
                    <animated.div
                      className={styles.mainContainer}
                      onClick={() => navigate(item.route.buildPath({}))}
                    >
                      {item.icon}
                      <animated.div className={styles.text} style={fade}>
                        {secondSectionTitle[item.route.path]}
                      </animated.div>
                    </animated.div>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
        <div className={styles.bottomButtons}>
          {FeatureSwitchManager.canUseVariableSimulator && !isCurrentBrandAppelMedical && (
            <Button.Tertiary
              className={styles.toolsButton}
              onClick={() =>
                navigate(MODAL_ROUTES.VARIABLE_SIMULATOR.route.buildPath({}), {
                  state: { background: location },
                })
              }
            >
              <img src={tools} alt="tools" />
            </Button.Tertiary>
          )}
          <div className={styles.appsButton}>
            <Apps />
          </div>
          <div className={styles.parameterButton}>
            <ParametersPopOverMenu />
          </div>
        </div>
        <div className={styles.retractButton} onClick={() => setOpened(!opened)}>
          <animated.div style={spin} className={styles.retractIcon}>
            <WhiteRightArrow />
          </animated.div>
          <animated.div className={styles.text} style={fade}>
            refermer
          </animated.div>
        </div>
      </div>
    </animated.div>
  );
};

export default SideBar;
