import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { QueryKeys } from '../types';
import { DisplayStatus, ExternalDemandsService } from 'src/Services/API';

export const useFetchExternalDemands = (displayStatus: DisplayStatus) => {
  const agencyId = useSelector(getCurrentAgency) ?? '';
  return useQuery(
    [QueryKeys.fetchExternalDemands, agencyId, displayStatus],
    async () => {
      return ExternalDemandsService.externalDemandsControllerGetExternalDemand({
        body: {
          agencyId,
          displayStatus,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
