import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchCandidateLastContractEndDate = (
  candidateId?: string,
  extraOptions?: UseQueryOptions<Date, unknown, Date, (string | undefined)[]>
) => {
  return useQuery(
    [QueryKeys.fetchCandidateLastContractEndDate, candidateId],
    async () => {
      return await CandidatesService.candidatesControllerGetCandidateLastContractEndDate({
        id: candidateId ?? '',
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: candidateId !== undefined && candidateId !== '',
      staleTime: 3600000,
      ...extraOptions,
    }
  );
};
