import {
  ExternalDemandElement,
  isDemandItem,
} from './ExternalDemandsList/ExternalDemandsList.types';
import { AppelMedicalEventStatus, ExternalDemandStatus } from '../../../Services/API';
import {
  ROULEMENT_ERROR,
  TECHNICAL_ERROR,
  CANCEL_ERROR,
  ANNULATION_ERROR,
} from './ExternalDemandsListMenu/ExternalDemandsListMenu.types';

const causeTransformer = (text?: string) => {
  if (!text) return "Contacter l'assistance";
  if (text.includes(ROULEMENT_ERROR)) return 'Un roulement est déja positionné pour ce jour';
  if (text.includes(TECHNICAL_ERROR)) return 'Problème Osmose';
  if (text.includes(CANCEL_ERROR)) return CANCEL_ERROR;
  if (text.length > 40) return "Contacter l'assistance";
  if (text.includes(ANNULATION_ERROR))
    return "erreur d'annulation: candidats non M présents, merci de les supprimer depuis osmose";
  return text;
};

export const formatError = (element: ExternalDemandElement) => {
  if (element.status === AppelMedicalEventStatus.Ignorée) return 'Ignorée';
  if (isDemandItem(element)) {
    if (
      element.status === AppelMedicalEventStatus['Commande créée'] &&
      element.cause !== CANCEL_ERROR
    )
      return 'Roulement créé';
    if (element.status === AppelMedicalEventStatus['Commande mise à jour'])
      return 'Roulement mis à jour ';
    return causeTransformer(element.cause);
  } else {
    if (element.status === ExternalDemandStatus.COMPLETE) return 'Commande créée';
    if (element.status === ExternalDemandStatus.MIXED) return 'Des erreurs se sont produites';
    return element.items.length > 1
      ? 'Consulter les roulements'
      : causeTransformer(element.items[0].cause);
  }
};
