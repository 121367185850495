import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../RootReducer';
import { ExternalDemandPreferencesState } from './Types';

const getExternalDemandPreferencesState = (state: RootState) => state.ExternalDemandPreferences;

export const getSearchInput = createSelector(
  [getExternalDemandPreferencesState],
  (state: ExternalDemandPreferencesState) => state.searchInput
);

export const getSelectedSource = createSelector(
  [getExternalDemandPreferencesState],
  (state: ExternalDemandPreferencesState) => state.selectedSource
);
