import { Table } from '@randstad-lean-mobile-factory/react-components-core';
import { LineData } from '@randstad-lean-mobile-factory/react-components-core/dist/Table/Table.types';
import { WhiteCheck } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import ContentLoader from 'react-content-loader';
import { ActionsPlanDot } from 'src/Assets_DEPRECATED';
import {
  WhyActionsActorsTranslation,
  WhyActionsCategoriesTranslation,
  WhyActionTranslation,
} from 'src/Routes/Accidentology/WorkAccidentAnalysis/WorkAccidentAnalysisDetail/Actions/Actions.types';
import { useFetchWorkAccidentActionPlans } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentActionPlans';
import { FETCH_STATUS } from 'src/Redux/Types';
import { WhyAction, WhyActionsStatusEnum } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import ActionPlansListMenuComponent from '../ActionPlanListMenu';
import styles from './ActionPlansList.module.scss';
import { sortWorkAccidentAction } from './utils';

const ActionPlansListComponent = () => {
  const fetchWorkAccidentActionPlans = useFetchWorkAccidentActionPlans();
  const fetchWorkAccidentActionPlansFetchStatus = toFetchStatus(fetchWorkAccidentActionPlans);
  const actionsExists =
    fetchWorkAccidentActionPlans.data?.some(
      workAccident =>
        workAccident.whyActions?.length !== 0 &&
        workAccident.whyActions?.some(action => action.status === WhyActionsStatusEnum.IN_PROGRESS)
    ) ?? false;

  const actionsArchivedExists =
    fetchWorkAccidentActionPlans.data?.some(
      workAccident =>
        workAccident.whyActions?.every(action => action.status === WhyActionsStatusEnum.DONE) &&
        moment().subtract(12, 'month').isSameOrBefore(workAccident.dateOfSignature)
    ) ?? false;

  const advancementLevel = (action: WhyAction) => {
    return (
      <>
        {action.status === WhyActionsStatusEnum.DONE && (
          <div key={`nested-filler-1-${action.osmoseActionId}`} className={styles.actionStatusDone}>
            <WhiteCheck />
          </div>
        )}
        {action.status === WhyActionsStatusEnum.IN_PROGRESS &&
          moment(action.realisationDate).isAfter(moment()) && (
            <div
              key={`nested-filler-1-${action.osmoseActionId}`}
              className={styles.actionStatusInProgress}
            >
              en cours
            </div>
          )}
        {action.status === WhyActionsStatusEnum.IN_PROGRESS &&
          moment(action.realisationDate).isSameOrBefore(moment()) && (
            <div
              key={`nested-filler-1-${action.osmoseActionId}`}
              className={styles.actionStatusLate}
            >
              en retard
            </div>
          )}
      </>
    );
  };
  return (
    <>
      {fetchWorkAccidentActionPlansFetchStatus === FETCH_STATUS.PENDING && (
        <div>
          {Array.from({ length: 10 }).map((_, idx) => {
            return (
              <ContentLoader
                key={`action-plans-loader-${idx}`}
                height={70}
                width="100%"
                uniqueKey={`candidateCard_${idx}`}
              >
                <rect x="1%" y="10" rx="4" ry="4" width="80%" height="16" />
                <rect x="1%" y="30" rx="4" ry="4" width="80%" height="16" />
              </ContentLoader>
            );
          })}
        </div>
      )}

      {fetchWorkAccidentActionPlansFetchStatus === FETCH_STATUS.FULFILLED &&
        fetchWorkAccidentActionPlans.data && (
          <div className={styles.containerWithModal}>
            <div className={styles.globalContainer}>
              <div className={classnames(styles.container)}>
                <div className={styles.actionsListContainer}>
                  <div className={styles.actionsContainerTopBar}>{`${
                    actionsExists ? '' : 'pas de '
                  }plans d'action en cours`}</div>

                  {actionsExists && (
                    <Table
                      nestedLineClassName={styles.nestedLine}
                      headers={[
                        { label: 'nom du TT' },
                        { label: 'actions identifiées par analyse AT' },
                        { label: 'interlocuteurs' },
                        { label: 'date cible de réalisation' },
                        { label: "date de l'accident" },
                        { label: '' },
                      ]}
                      data={
                        sortWorkAccidentAction(fetchWorkAccidentActionPlans.data)
                          .map(workAccidentAction => {
                            if (
                              !workAccidentAction.whyActions?.every(
                                action => action.status === WhyActionsStatusEnum.DONE
                              )
                            ) {
                              return {
                                key: `workAccident-${workAccidentAction.workAccidentId}`,
                                line: [
                                  <div
                                    key={`name-${workAccidentAction.workAccidentId}`}
                                    className={styles.name}
                                  >
                                    <ActionsPlanDot
                                      key={`dot-${workAccidentAction.workAccidentId}`}
                                    />
                                    <div
                                      title={`${
                                        workAccidentAction.candidate?.name !== undefined
                                          ? workAccidentAction.candidate?.name
                                          : ''
                                      } ${
                                        workAccidentAction.candidate?.lastName !== undefined
                                          ? workAccidentAction.candidate.lastName
                                          : ''
                                      }`}
                                    >
                                      <p>{`${
                                        workAccidentAction.candidate?.name !== undefined
                                          ? workAccidentAction.candidate?.name
                                          : ''
                                      } ${
                                        workAccidentAction.candidate?.lastName !== undefined
                                          ? workAccidentAction.candidate.lastName
                                          : ''
                                      }`}</p>
                                    </div>
                                  </div>,
                                  <div
                                    key={`number-of-actions-${workAccidentAction.workAccidentId}`}
                                    className={styles.numberOfActions}
                                  >
                                    {workAccidentAction.whyActions &&
                                      workAccidentAction.whyActions.length > 1 &&
                                      `${workAccidentAction.whyActions.length} actions`}
                                  </div>,
                                  <div
                                    key={`number-of-actors-${workAccidentAction.workAccidentId}`}
                                    className={styles.numberOfActors}
                                  >
                                    {workAccidentAction.whyActions?.filter(
                                      action => action.actorName !== ''
                                    ).length === 1
                                      ? workAccidentAction.whyActions.find(
                                          action => action.actorName !== ''
                                        )?.actorName
                                      : `${
                                          workAccidentAction.whyActions?.filter(
                                            action => action.actorName !== ''
                                          ).length
                                        } personnes`}
                                  </div>,
                                  <div
                                    key={`realisation-date-${workAccidentAction.workAccidentId}`}
                                    className={styles.realisationDate}
                                  >
                                    {workAccidentAction?.whyActions !== undefined &&
                                    workAccidentAction?.whyActions.length === 1
                                      ? moment(
                                          workAccidentAction.whyActions[0].realisationDate
                                        ).format('DD/MM/YYYY')
                                      : ''}
                                  </div>,
                                  <div
                                    key={`accident-date-${workAccidentAction.workAccidentId}`}
                                    className={styles.accidentDate}
                                  >
                                    {`${moment(workAccidentAction.accidentDate).format(
                                      'DD/MM/YYYY'
                                    )} à ${moment(workAccidentAction.accidentDate).format(
                                      'HH:mm'
                                    )}`}
                                  </div>,
                                  <div
                                    key={`empty-realisation-date-${workAccidentAction.workAccidentId}`}
                                    className={styles.actionStatus}
                                  >
                                    {workAccidentAction.whyActions?.length === 1 &&
                                      advancementLevel(workAccidentAction.whyActions[0])}
                                  </div>,
                                ],

                                nested:
                                  workAccidentAction?.whyActions !== undefined &&
                                  workAccidentAction?.whyActions.length > 1
                                    ? workAccidentAction?.whyActions
                                        ?.sort((actionA, actionB) => {
                                          const nameComparison = actionA.actorName?.localeCompare(
                                            actionB.actorName ?? 'z'
                                          );
                                          if (nameComparison === 0) {
                                            return moment(actionA.realisationDate).isBefore(
                                              actionB.realisationDate
                                            )
                                              ? -1
                                              : 1;
                                          }
                                          return nameComparison;
                                        })
                                        .map(action => {
                                          return [
                                            <p
                                              key={`nested-name-${action.osmoseActionId}`}
                                              className={styles.name}
                                            >
                                              {''}
                                            </p>,
                                            <p
                                              key={`nested-numberOfActions-${action.osmoseActionId}`}
                                              className={styles.numberOfActions}
                                            >
                                              • {WhyActionsActorsTranslation[action.actorType]}
                                              <br />•{' '}
                                              {WhyActionsCategoriesTranslation[action.category]}
                                              <br />• {WhyActionTranslation[action.name]}
                                            </p>,
                                            <p
                                              key={`nested-numberOfActors-${action.osmoseActionId}`}
                                              className={styles.numberOfActors}
                                            >
                                              {action.actorName}
                                            </p>,
                                            <p
                                              key={`nested-realisationDate-${action.osmoseActionId}`}
                                              className={styles.realisationDate}
                                            >
                                              {moment(action.realisationDate).format(
                                                'DD/MM/YYYY'
                                              ) ?? ''}
                                            </p>,
                                            <p
                                              key={`nested-filler-1-${action.osmoseActionId}`}
                                            ></p>,
                                            <div
                                              key={`nested-advancement-${action.osmoseActionId}`}
                                              className={styles.actionStatus}
                                            >
                                              {advancementLevel(action)}
                                            </div>,
                                            <ActionPlansListMenuComponent
                                              isEditable={true}
                                              key={`nested-menu-component-${action.osmoseActionId}`}
                                              actionInfos={{
                                                action,
                                                agencyId: workAccidentAction.agencyId,
                                                workAccidentId: workAccidentAction.workAccidentId,
                                                companyId: workAccidentAction.companyId,
                                              }}
                                            />,
                                          ];
                                        })
                                    : undefined,
                                parentRowWithNoChildrenRightComponent: (
                                  <ActionPlansListMenuComponent
                                    isEditable={true}
                                    key={`action-plans-list-${workAccidentAction.workAccidentId}`}
                                    actionInfos={
                                      workAccidentAction &&
                                      workAccidentAction.whyActions && {
                                        action: workAccidentAction.whyActions[0],
                                        agencyId: workAccidentAction.agencyId,
                                        workAccidentId: workAccidentAction.workAccidentId,
                                        companyId: workAccidentAction.companyId,
                                      }
                                    }
                                  />
                                ),
                              };
                            }
                            return undefined;
                          })
                          .filter(item => item !== undefined) as LineData[]
                      }
                      expandHeaderLabel="actions"
                      className={styles.table}
                    />
                  )}
                </div>
                <div className={styles.actionsListContainer}>
                  <div className={styles.actionsContainerTopBar}>{`${
                    actionsArchivedExists ? '' : 'pas de '
                  }plans d'action archivés`}</div>

                  {actionsArchivedExists && (
                    <Table
                      nestedLineClassName={styles.nestedLine}
                      headers={[
                        { label: 'nom du TT' },
                        { label: 'actions identifiées par analyse AT' },
                        { label: 'interlocuteurs' },
                        { label: 'date cible de réalisation' },
                        { label: "date de l'accident" },
                        { label: '' },
                      ]}
                      data={
                        sortWorkAccidentAction(fetchWorkAccidentActionPlans.data)
                          .filter(
                            workAccidentAction =>
                              moment(moment()).diff(
                                moment(workAccidentAction.dateOfSignature),
                                'month'
                              ) <= 12
                          )
                          .map(workAccidentAction => {
                            if (
                              workAccidentAction.whyActions?.every(
                                action => action.status === WhyActionsStatusEnum.DONE
                              )
                            ) {
                              return {
                                key: `workAccident-${workAccidentAction.workAccidentId}`,
                                line: [
                                  <div
                                    key={`name-${workAccidentAction.workAccidentId}`}
                                    className={styles.name}
                                  >
                                    <ActionsPlanDot
                                      key={`dot-${workAccidentAction.workAccidentId}`}
                                    />
                                    <div
                                      title={`${
                                        workAccidentAction.candidate?.name !== undefined
                                          ? workAccidentAction.candidate?.name
                                          : ''
                                      } ${
                                        workAccidentAction.candidate?.lastName !== undefined
                                          ? workAccidentAction.candidate.lastName
                                          : ''
                                      }`}
                                    >
                                      <p>{`${
                                        workAccidentAction.candidate?.name !== undefined
                                          ? workAccidentAction.candidate?.name
                                          : ''
                                      } ${
                                        workAccidentAction.candidate?.lastName !== undefined
                                          ? workAccidentAction.candidate.lastName
                                          : ''
                                      }`}</p>
                                    </div>
                                  </div>,
                                  <div
                                    key={`number-of-actions-${workAccidentAction.workAccidentId}`}
                                    className={styles.numberOfActions}
                                  >
                                    {workAccidentAction.whyActions &&
                                      workAccidentAction.whyActions.length > 1 &&
                                      `${workAccidentAction.whyActions.length} actions`}
                                  </div>,
                                  <div
                                    key={`number-of-actors-${workAccidentAction.workAccidentId}`}
                                    className={styles.numberOfActors}
                                  >
                                    {workAccidentAction.whyActions?.filter(
                                      action => action.actorName !== ''
                                    ).length === 1
                                      ? workAccidentAction.whyActions.find(
                                          action => action.actorName !== ''
                                        )?.actorName
                                      : `${
                                          workAccidentAction.whyActions?.filter(
                                            action => action.actorName !== ''
                                          ).length
                                        } personnes`}
                                  </div>,
                                  <div
                                    key={`realisation-date-${workAccidentAction.workAccidentId}`}
                                    className={styles.realisationDate}
                                  >
                                    {workAccidentAction?.whyActions !== undefined &&
                                    workAccidentAction?.whyActions.length === 1
                                      ? moment(
                                          workAccidentAction.whyActions[0].realisationDate
                                        ).format('DD/MM/YYYY')
                                      : ''}
                                  </div>,
                                  <div
                                    key={`accident-date-${workAccidentAction.workAccidentId}`}
                                    className={styles.accidentDate}
                                  >
                                    {`${moment(workAccidentAction.accidentDate).format(
                                      'DD/MM/YYYY'
                                    )} à ${moment(workAccidentAction.accidentDate).format(
                                      'HH:mm'
                                    )}`}
                                  </div>,
                                  <div
                                    key={`empty-realisation-date-${workAccidentAction.workAccidentId}`}
                                    className={styles.actionStatus}
                                  >
                                    {workAccidentAction.whyActions?.length === 1 &&
                                      advancementLevel(workAccidentAction.whyActions[0])}
                                  </div>,
                                ],
                                nested:
                                  workAccidentAction?.whyActions !== undefined &&
                                  workAccidentAction?.whyActions.length > 1
                                    ? workAccidentAction?.whyActions
                                        ?.sort((actionA, actionB) => {
                                          const nameComparison = actionA.actorName?.localeCompare(
                                            actionB.actorName ?? 'z'
                                          );
                                          if (nameComparison === 0) {
                                            return moment(actionA.realisationDate).isBefore(
                                              actionB.realisationDate
                                            )
                                              ? -1
                                              : 1;
                                          }
                                          return nameComparison;
                                        })
                                        .map(action => {
                                          return [
                                            <p
                                              key={`nested-name-${action.osmoseActionId}`}
                                              className={styles.name}
                                            ></p>,
                                            <p
                                              key={`nested-numberOfActions-${action.osmoseActionId}`}
                                              className={styles.numberOfActions}
                                            >
                                              • {WhyActionsActorsTranslation[action.actorType]}
                                              <br />•{' '}
                                              {WhyActionsCategoriesTranslation[action.category]}
                                              <br />• {WhyActionTranslation[action.name]}
                                            </p>,
                                            <p
                                              key={`nested-numberOfActors-${action.osmoseActionId}`}
                                              className={styles.numberOfActors}
                                            >
                                              {action.actorName}
                                            </p>,
                                            <p
                                              key={`nested-realisationDate-${action.osmoseActionId}`}
                                              className={styles.realisationDate}
                                            >
                                              {moment(action.realisationDate).format(
                                                'DD/MM/YYYY'
                                              ) ?? ''}
                                            </p>,
                                            <p
                                              key={`nested-filler-1-${action.osmoseActionId}`}
                                            ></p>,
                                            <div
                                              key={`nested-advancement-${action.osmoseActionId}`}
                                              className={styles.actionStatus}
                                            >
                                              {advancementLevel(action)}
                                            </div>,
                                            <ActionPlansListMenuComponent
                                              isEditable={false}
                                              key={`nested-menu-component-${action.osmoseActionId}`}
                                              actionInfos={{
                                                action,
                                                agencyId: workAccidentAction.agencyId,
                                                workAccidentId: workAccidentAction.workAccidentId,
                                                companyId: workAccidentAction.companyId,
                                              }}
                                            />,
                                          ];
                                        })
                                    : undefined,
                                parentRowWithNoChildrenRightComponent: (
                                  <ActionPlansListMenuComponent
                                    isEditable={false}
                                    key={`action-plans-list-${workAccidentAction.workAccidentId}`}
                                    actionInfos={
                                      workAccidentAction &&
                                      workAccidentAction.whyActions && {
                                        action: workAccidentAction.whyActions[0],
                                        agencyId: workAccidentAction.agencyId,
                                        workAccidentId: workAccidentAction.workAccidentId,
                                        companyId: workAccidentAction.companyId,
                                      }
                                    }
                                  />
                                ),
                              };
                            }
                            return undefined;
                          })
                          .filter(item => item !== undefined) as LineData[]
                      }
                      expandHeaderLabel="actions"
                      className={styles.table}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default ActionPlansListComponent;
