import React from 'react';
import { Props } from './MissionCard.types';
import styles from './MissionCard.module.scss';
import moment from 'moment';
import classnames from 'classnames';
import YouplanMissionActions from 'src/Routes/Activity/YouplanMissions/YouplanMissionDetail/YouplanMissionActions';
import YouplanMissionBadge from './YouplanMissionBadge';
import {
  EnumCoreMissionCandidateCandidateAdvancement,
  EnumCoreMissionCandidateCompanyAdvancement,
  EnumYouplanMissionActionSummaryAction,
} from 'src/Services/API';
import { Checkbox } from '@randstad-lean-mobile-factory/react-components-core';

const MissionDates = ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => {
  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);
  return momentStartDate.isSame(momentEndDate) ? (
    <div className={styles.date}>{`le ${momentStartDate.format('L')}`}</div>
  ) : (
    <>
      <div className={styles.date}>{`du ${momentStartDate.format('L')}`}</div>
      <div className={styles.date}> {`au ${momentEndDate.format('L')}`}</div>
    </>
  );
};

const MissionCard = ({ mission, selected, onChange, firstSelectedMission }: Props) => {
  const isCancelled = mission?.salesPhase?.id === 'AN';
  const isUnhandledCancel = isCancelled && mission.youplanStatus?.action !== undefined;
  const isConflictingRenewal =
    (mission?.youplanStatus?.isInConflict || isUnhandledCancel) &&
    mission?.youplanStatus?.action === EnumYouplanMissionActionSummaryAction.CONTRACT_RENEWAL;
  const isOkOk =
    mission?.candidates?.some(
      candidate =>
        candidate.candidateAdvancement === EnumCoreMissionCandidateCandidateAdvancement.accepté &&
        candidate.companyAdvancement === EnumCoreMissionCandidateCompanyAdvancement.accepté
    ) ?? false;
  const disabled =
    firstSelectedMission &&
    (firstSelectedMission?.qualification?.id !== mission?.qualification?.id ||
      !moment(mission?.startDate)
        .startOf('day')
        .isSame(moment(firstSelectedMission?.startDate).startOf('day')) ||
      !moment(mission?.endDate)
        .startOf('day')
        .isSame(moment(firstSelectedMission?.endDate).startOf('day')));

  return (
    <div
      className={classnames(styles.container, {
        [styles.selected]: selected,
        [styles.pointer]: !disabled,
      })}
    >
      <Checkbox checked={selected} onClick={onChange} disabled={disabled} />
      <div className={styles.name}>
        <div>
          {mission?.candidates?.[0]
            ? `${mission?.candidates?.[0].firstName} ${mission?.candidates?.[0].lastName}`
            : "pas d'intérimaire sur cette mission"}
        </div>
        <YouplanMissionBadge
          status={mission?.youplanStatus}
          isCancelled={isCancelled}
          isOkOk={isOkOk}
        />
      </div>

      <div className={styles.item}>
        <div className={styles.durationContainer}>
          <MissionDates startDate={mission?.startDate as Date} endDate={mission?.endDate as Date} />
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.qualification}>{mission?.qualification?.label}</div>
      </div>
      <div className={styles.item}>
        <div className={classnames(styles.service, { [styles.warning]: isConflictingRenewal })}>
          {isConflictingRenewal
            ? 'assurez vous que la prolongation de contrat initiale a été supprimée avant de marquer le besoin comme résolu'
            : mission?.service?.name}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.qualification}>
          {mission?.processingDate
            ? moment(mission?.processingDate as Date).format('DD/MM - HH:mm')
            : ''}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <YouplanMissionActions
          mission={mission!}
          candidateId={mission?.candidates?.[0]?.candidateId!}
        />
      </div>
    </div>
  );
};

export default MissionCard;
