import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ExternalDemandsService } from 'src/Services/API';
import { MutationKeys } from '../types';
import { useSelector } from 'react-redux';
import { getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';

export const useCreateExternalDemandsJournal = (
  extraOptions?: UseMutationOptions<void, unknown, string, unknown>
) => {
  const brandCode = useSelector(getCurrentBrandCode);

  return useMutation(
    [MutationKeys.createExternalDemandsJournal],
    async (agencyId: string) => {
      await ExternalDemandsService.externalDemandsControllerCreateAgencyJournal({
        agencyId,
        body: { brandCode: brandCode ?? '' },
      });
    },
    { ...extraOptions }
  );
};
