import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanySearchResult } from 'src/Services/API';
import { FETCH_STATUS } from '../Types';
import { searchYouplanCompanies } from './Thunks';
import { SEARCH_LIMIT } from 'src/Redux/Search/Types';

import { YouplanCompaniesState } from './Types';

export const initialState: YouplanCompaniesState = {
  youplanCompanies: [],
  currentYouplanCompany: undefined,
  youplanCompaniesFetchStatus: FETCH_STATUS.FULFILLED,
  searchCompaniesCurrentRequestId: undefined,
  canLoadMore: false,
  searchInput: '',
};

export const { reducer: youplanCompaniesReducer, actions: youplanCompaniesActions } = createSlice({
  name: 'youplanCompanies',
  initialState,
  reducers: {
    setCurrentYouplanCompany: (state, action: PayloadAction<CompanySearchResult>) => {
      state.currentYouplanCompany = action.payload;
    },
    setYouplanSearchInput: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
  },
  extraReducers: builder => {
    // Search youplanCompanies
    builder.addCase(searchYouplanCompanies.pending, (state, action) => {
      state.youplanCompaniesFetchStatus = FETCH_STATUS.PENDING;
      state.searchCompaniesCurrentRequestId = action.meta.requestId;
    });
    builder.addCase(searchYouplanCompanies.fulfilled, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.searchCompaniesCurrentRequestId === action.meta.requestId) {
        state.youplanCompanies = action.payload;
        if (
          !state.currentYouplanCompany ||
          !action.payload.find(item => item.companyId === state.currentYouplanCompany?.companyId)
        ) {
          state.currentYouplanCompany = action.payload.length > 0 ? action.payload[0] : undefined;
        }
        state.canLoadMore = action.payload.length === SEARCH_LIMIT;
        state.youplanCompaniesFetchStatus = FETCH_STATUS.FULFILLED;
      }
    });
    builder.addCase(searchYouplanCompanies.rejected, (state, action) => {
      // we only consider the results of the last emitted request
      if (state.searchCompaniesCurrentRequestId === action.meta.requestId) {
        state.youplanCompaniesFetchStatus = FETCH_STATUS.REJECTED;
      }
    });
  },
});
