import { Kanban, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { Calendrier, PersonSearch } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useNavigate } from 'react-router-dom';
import { LoadingCard } from 'src/Components/Card';
import { TopBar } from 'src/Components/TopBar';
import WorkAccidentAnalysisTopBarComponent from 'src/Components/TopBar/WorkAccidentAnalysisTopBarComponent/WorkAccidentAnalysisTopBarComponent.component';
import { useCreateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useCreateWorkAccidentAnalysis';
import { useFetchWorkAccidents } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidents';
import { FETCH_STATUS } from 'src/Redux/Types';
import { ROUTES } from 'src/Routes/Routes.types';
import { CandidatesWorkAccidents } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import styles from './KanbanWorkAccidentAnalysis.module.scss';
import { AnalysisStatus, sortAccidents, SortAccidentsBy } from './utils';
import WorkAccidentAnalysisActionButton from './WorkAccidentAnalysisActionButton/WorkAccidentAnalysisActionButton.component';

export const KanbanWorkAccidentAnalysisRoute = (): JSX.Element => {
  const navigate = useNavigate();
  const fetchWorkAccidents = useFetchWorkAccidents();
  const fetchWorkAccidentsFetchStatus = toFetchStatus(fetchWorkAccidents);
  const createWorkAccidentAnalysis = useCreateWorkAccidentAnalysis();
  const createWorkAccidentAnalysisFetchStatus = toFetchStatus(createWorkAccidentAnalysis);

  // Todos
  const [sortNameToDo, setSortNameToDo] = useState<'asc' | 'desc' | null>(null);
  const [sortDateToDo, setSortDateToDo] = useState<'asc' | 'desc' | null>('desc');

  // In Progress
  const [sortNameInProgress, setSortNameInProgress] = useState<'asc' | 'desc' | null>(null);
  const [sortDateInProgress, setSortDateInProgress] = useState<'asc' | 'desc' | null>('desc');

  // Done
  const [sortNameDone, setSortNameDone] = useState<'asc' | 'desc' | null>(null);
  const [sortDateDone, setSortDateDone] = useState<'asc' | 'desc' | null>('desc');

  const analysis: {
    status: AnalysisStatus;
    data: CandidatesWorkAccidents[] | undefined;
    sortBy: SortAccidentsBy;
    sortOrder: 'asc' | 'desc';
    onClick: (item: CandidatesWorkAccidents) => void;
  }[] = useMemo(() => {
    if (fetchWorkAccidentsFetchStatus === FETCH_STATUS.FULFILLED && fetchWorkAccidents.data) {
      return [
        {
          status: AnalysisStatus.TODO,
          data: fetchWorkAccidents.data.todo,
          sortBy: sortNameToDo ? SortAccidentsBy.Name : SortAccidentsBy.StartDate,
          sortOrder: sortNameToDo ?? sortDateToDo ?? 'asc',
          onClick: item => {
            createWorkAccidentAnalysis.mutate(
              {
                accidentId: item?.workAccidentId ?? '',
                candidateId: item.candidate?.id ?? '',
                firstName: item.candidate?.name ?? '',
                lastName: item.candidate?.lastName ?? '',
                analyseId: item?.id ?? '',
                startDate: item?.startDate,
                endDate: item?.endDate,
                stoppageDuration: item?.stoppageDuration ?? 0,
              },
              {
                onSuccess: data =>
                  navigate(
                    ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({
                      analysisId: data,
                    })
                  ),
              }
            );
          },
        },
        {
          status: AnalysisStatus.IN_PROGRESS,
          data: fetchWorkAccidents.data.inProgress,
          sortBy: sortNameInProgress ? SortAccidentsBy.Name : SortAccidentsBy.StartDate,
          sortOrder: sortNameInProgress ?? sortDateInProgress ?? 'asc',
          onClick: item =>
            item.id &&
            navigate(
              ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({
                analysisId: item.id,
              })
            ),
        },
        {
          status: AnalysisStatus.DONE,
          data: fetchWorkAccidents.data.done,
          sortBy: sortNameDone ? SortAccidentsBy.Name : SortAccidentsBy.StartDate,
          sortOrder: sortNameDone ?? sortDateDone ?? 'asc',
          onClick: item =>
            item.id &&
            navigate(
              ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONTEXT.buildPath({
                analysisId: item.id,
              })
            ),
        },
      ];
    } else return [];
  }, [
    createWorkAccidentAnalysis,
    fetchWorkAccidents.data,
    fetchWorkAccidentsFetchStatus,
    navigate,
    sortDateDone,
    sortDateInProgress,
    sortDateToDo,
    sortNameDone,
    sortNameInProgress,
    sortNameToDo,
  ]);

  const renderAnalysis = useCallback(
    (status: AnalysisStatus) => {
      const analysisByStatus = analysis.find(analysis => analysis.status === status);

      return analysisByStatus?.data?.length
        ? sortAccidents({
            accidents: analysisByStatus.data,
            sortBy: analysisByStatus.sortBy,
            sortOrder: analysisByStatus.sortOrder,
          }).map((item, idx) => (
            <Kanban.Item
              title={`${item.candidate?.name} ${item.candidate?.lastName}`}
              subtitle={item.startDate ? moment(item.startDate).format('L') : ''}
              actionButton={
                <WorkAccidentAnalysisActionButton
                  onAnalyseOpen={() => analysisByStatus.onClick(item)}
                  status={analysisByStatus.status}
                  analysis={item}
                />
              }
              key={`${item.workAccidentId}-${idx}`}
            />
          ))
        : [];
    },
    [analysis]
  );

  const loadingCard = useCallback(() => {
    const random = Math.floor(Math.random() * 2) + 2;
    const loadingElems = [];

    for (let i = 0; i <= random; i++)
      loadingElems.push(<LoadingCard key={`loading-${i}-${random}`} />);

    return loadingElems;
  }, []);

  const sortItemsByNameOnClick = useCallback(
    (status: AnalysisStatus) => {
      if (status === AnalysisStatus.TODO) {
        sortNameToDo
          ? sortNameToDo === 'asc'
            ? setSortNameToDo('desc')
            : setSortNameToDo('asc')
          : setSortNameToDo('asc');

        setSortDateToDo(null);
      } else if (status === AnalysisStatus.IN_PROGRESS) {
        sortNameInProgress
          ? sortNameInProgress === 'asc'
            ? setSortNameInProgress('desc')
            : setSortNameInProgress('asc')
          : setSortNameInProgress('asc');

        setSortDateInProgress(null);
      } else {
        sortNameDone
          ? sortNameDone === 'asc'
            ? setSortNameDone('desc')
            : setSortNameDone('asc')
          : setSortNameDone('asc');

        setSortDateDone(null);
      }
    },
    [sortNameDone, sortNameInProgress, sortNameToDo]
  );

  const sortItemsByDateOnClick = useCallback(
    (status: AnalysisStatus) => {
      if (status === AnalysisStatus.TODO) {
        sortDateToDo
          ? sortDateToDo === 'asc'
            ? setSortDateToDo('desc')
            : setSortDateToDo('asc')
          : setSortDateToDo('asc');

        setSortNameToDo(null);
      } else if (status === AnalysisStatus.IN_PROGRESS) {
        sortDateInProgress
          ? sortDateInProgress === 'asc'
            ? setSortDateInProgress('desc')
            : setSortDateInProgress('asc')
          : setSortDateInProgress('asc');

        setSortNameInProgress(null);
      } else {
        sortDateDone
          ? sortDateDone === 'asc'
            ? setSortDateDone('desc')
            : setSortDateDone('asc')
          : setSortDateDone('asc');

        setSortNameDone(null);
      }
    },
    [sortDateDone, sortDateInProgress, sortDateToDo]
  );

  return (
    <div className={styles.mainContainer}>
      <TopBar title="analyses AT">
        <WorkAccidentAnalysisTopBarComponent />
      </TopBar>
      <div className={classnames(styles.container, styles.page)}>
        <>
          {createWorkAccidentAnalysisFetchStatus === FETCH_STATUS.PENDING ? (
            <div className={styles.loading}>
              {Array.from({ length: 10 }, (_, index) => (
                <ContentLoader height="3.5rem" width="100%" key={`loader-${index}`}>
                  <rect x="1%" y="10" rx="4" ry="4" width="80%" height="16" />
                  <rect x="1%" y="30" rx="4" ry="4" width="80%" height="16" />
                </ContentLoader>
              ))}
            </div>
          ) : (
            <div className={styles.content}>
              {Object.values(AnalysisStatus).map(status => {
                return (
                  <div className={styles.kanban} key={status}>
                    <Kanban
                      title={
                        status === AnalysisStatus.TODO
                          ? 'à faire'
                          : status === AnalysisStatus.IN_PROGRESS
                          ? 'en cours'
                          : 'fait'
                      }
                      key={status}
                      className={styles.kanban}
                      children={
                        fetchWorkAccidentsFetchStatus === FETCH_STATUS.PENDING
                          ? loadingCard()
                          : renderAnalysis(status)
                      }
                      filterPopupContent={
                        <div className={styles.popupWidth}>
                          <PopupMenu.Item
                            text={'intérimaire'}
                            key={'candidate' + status}
                            icon={<PersonSearch />}
                            onClick={() => sortItemsByNameOnClick(status)}
                          />
                          <PopupMenu.Item
                            text={'date de déclaration'}
                            key={'declaration date' + status}
                            icon={<Calendrier />}
                            onClick={() => sortItemsByDateOnClick(status)}
                          />
                        </div>
                      }
                    />
                  </div>
                );
              })}
            </div>
          )}
        </>
      </div>
    </div>
  );
};
