import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../types';
import { useSelector } from 'react-redux';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { CandidatesService } from 'src/Services/API';

export const useFetchCandidateCurrentCDIContract = (candidateId?: string) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchCandidateCurrentCDIContract, candidateId, currentBrand],
    async () => {
      if (!currentBrand) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return await CandidatesService.candidatesControllerGetCdiContractDetails({
        id: candidateId ?? '',
        brandCode: currentBrand.brand?.code ?? '',
      });
    },
    { enabled: candidateId !== '' }
  );
};
