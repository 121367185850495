export interface Props {}

export enum UploadStatus {
  None,
  InProgress,
  Failed,
  Done,
}

export interface DropzoneItem {
  id?: string;
  file: File;
  subtitle?: string;
  status: UploadStatus;
}
