import { Badge, Button } from '@randstad-lean-mobile-factory/react-components-core';
import { Filters } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { redCloud, whiteAdd } from 'src/Assets_DEPRECATED';
import CardList from 'src/Components/CardList';
import SortMissionsPopOverMenu from 'src/Components/PopOverMenus/SortMissionsPopOverMenu';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { getCanLoadMore } from 'src/Redux/Missions/Selectors';
import { getCurrentQualification } from 'src/Redux/Qualifications/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import styles from './ListMissions.module.scss';
import { Props } from './ListMissions.types';
import { useState } from 'react';
import FilterMissionsModal from 'src/Modals/FilterMissionsModal';

const ListMissions = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const canLoadMore = useSelector(getCanLoadMore);
  const qualification = useSelector(getCurrentQualification);
  const company = useSelector(getCurrentCompany);
  const [isFilterMissionsModalOpen, setIsFilterMissionsModalOpen] = useState(false);
  const title =
    props.fetchStatus === FETCH_STATUS.PENDING
      ? `commandes en cours de récupération`
      : `${props.cards.length} commande${props.cards.length > 1 ? 's' : ''}`;

  let numberOfFilters = 2; // there is always a date and a unit
  if (qualification) {
    numberOfFilters += 1;
  }
  if (company) {
    numberOfFilters += 1;
  }

  if (props.fetchStatus === FETCH_STATUS.REJECTED) {
    return (
      <div className={styles.error}>
        <img src={redCloud} alt="error-img" />
        <p>Une erreur est survenue</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <Button.Secondary
          leftIcon={<Filters />}
          text="filtres"
          rightIcon={<Badge value={numberOfFilters.toString()} className={styles.dot} />}
          className={styles.filterButton}
          onClick={() => setIsFilterMissionsModalOpen(true)}
        />
        <SortMissionsPopOverMenu />
      </div>

      <div className={styles.total}>{title}</div>
      <div className={styles.cardsContainer}>
        <CardList
          cards={props.cards}
          fetchStatus={props.fetchStatus}
          selectedId={props.selectedId}
          canLoadMore={canLoadMore}
          onLoadMore={props.onLoadMore}
        />
      </div>
      <div
        className={styles.addMissionButton}
        onClick={() =>
          navigate(MODAL_ROUTES.MISSION_CREATION.route.buildPath({}), {
            state: {
              background: location,
            },
          })
        }
      >
        <img src={whiteAdd} alt="add" />
      </div>
      <FilterMissionsModal
        isOpen={isFilterMissionsModalOpen}
        setIsOpen={setIsFilterMissionsModalOpen}
      />
    </div>
  );
};

export default ListMissions;
