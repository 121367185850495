import { useQuery } from '@tanstack/react-query';
import { AgenciesService } from 'src/Services/API';
import { QueryKeys } from '../types';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';

export const useFetchRelatedCGC = () => {
  const agencyId = useSelector(getCurrentAgency);
  return useQuery(
    [QueryKeys.fetchRelatedCGC, agencyId],
    () => {
      if (!agencyId) {
        throw new Error('no agency id');
      }
      return AgenciesService.agenciesControllerGetRelatedCgc({ id: agencyId });
    },
    {
      enabled: !!agencyId,
      refetchOnWindowFocus: false,
    }
  );
};
