import styles from './RepositioningInternPotentialOffers.module.scss';
import { LoadingCard } from 'src/Components/Card';
import { FETCH_STATUS } from 'src/Redux/Types';
import RepositioningCardWithCheckBox from '../RepositioningCardWithCheckbox/RepositioningCardWithCheckbox.component';
import { Props } from './RepositioningInternPotentialOffers.types';
import { useFetchAgenciesDistances } from 'src/Hooks/Agencies/useFetchAgenciesDistances';
import { toFetchStatus } from 'src/Services/Async';
import { AgencyDetails } from 'src/Services/API';

const RepositioningInternPotentialOffers = ({
  candidateData,
  selectedAgencies,
  setSelectedAgencies,
  agenciesDetails,
  fetchStatus,
}: Props) => {
  const { data: agenciesDistances, isSuccess, isLoading, isError } = useFetchAgenciesDistances({
    zipCode: candidateData.candidateAddress?.candidatePostalCode ?? '',
    agencies:
      agenciesDetails?.map((agencyDetail: AgencyDetails) => ({
        agencyId: agencyDetail.id,
        zipCode: agencyDetail.postalCode,
      })) ?? [],
  });
  const distancesFetchStatus = toFetchStatus({ isSuccess, isLoading, isError });

  return distancesFetchStatus === FETCH_STATUS.PENDING ? (
    <div className={styles.loadingContainer}>
      {Array.from({ length: 10 }).map((_, idx) => (
        <div className={styles.loadingCard} key={`${idx} repositioningCard`}>
          <LoadingCard />
        </div>
      ))}
    </div>
  ) : fetchStatus === FETCH_STATUS.FULFILLED ? (
    <RepositioningCardWithCheckBox
      agenciesDetails={agenciesDetails}
      candidateData={candidateData}
      selectedAgencies={selectedAgencies}
      setSelectedAgencies={setSelectedAgencies}
      agenciesDistances={agenciesDistances ?? []}
    />
  ) : (
    <div>
      <div className={styles.noMission}>
        Il n'y a aucun poste potentiel correspondant aux critères
      </div>
    </div>
  );
};

export default RepositioningInternPotentialOffers;
