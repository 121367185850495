import { useQuery } from '@tanstack/react-query';
import { AgenciesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchAgenciesDetails = (agenciesIds: string[]) => {
  return useQuery(
    [QueryKeys.fetchAgenciesDetails],
    async () => {
      const agenciesDetails = await AgenciesService.agenciesControllerGetAgenciesDetails({
        agenciesIds: agenciesIds,
      });

      return agenciesDetails;
    },
    { enabled: agenciesIds.length > 0 }
  );
};
