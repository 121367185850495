import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QueryKeys } from 'src/Hooks/types';
import { EditActionState } from 'src/Services/Routing';
import ActionPlansListModal from './ActionPlansListModal.component';

const EnhancedActionPlansListModal = () => {
  const navigate = useNavigate();
  const modalParameters = (useLocation().state as EditActionState).modalParameters;
  const queryClient = useQueryClient();

  const onClose: () => void = useCallback(() => {
    queryClient.invalidateQueries([
      QueryKeys.fetchWorkAccidentActionPlanById,
      modalParameters?.workAccidentId,
    ]);
    navigate(-1);
  }, [navigate, modalParameters?.workAccidentId, queryClient]);
  return (
    <ActionPlansListModal
      workAccidentId={modalParameters?.workAccidentId}
      actionId={modalParameters?.actionId}
      onClose={onClose}
      isEditable={modalParameters?.isEditable}
    />
  );
};

export default EnhancedActionPlansListModal;
