import { useMemo, useState } from 'react';

import {
  Badge,
  Loader,
  SegmentedControl,
} from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useFetchExternalDemands } from 'src/Hooks/ExternalDemands/useFetchExternalDemands';
import { getSearchInput } from 'src/Redux/ExternalDemandList/Selectors';
import { getSelectedSource } from 'src/Redux/ExternalDemandPreferences/Selectors';
import { ExternalDemandPreferencesActions } from 'src/Redux/ExternalDemandPreferences/Slice';
import { ROUTES } from 'src/Routes/Routes.types';
import { AppelMedicalEventSource, DisplayStatus, ExternalDemandDto } from 'src/Services/API';
import { TopBar } from 'src/Components/TopBar';
import styles from './DemandsList.module.scss';
import ExternalDemandsList from './ExternalDemandsList';
import ExternalDemandsTopBarComponent from 'src/Components/TopBar/ExternalDemandsTopBarComponent/ExternalDemandsTopBarComponent.component';

export const DemandsListRoute = () => {
  const showArchivedDemands = !!useMatch(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.ARCHIVED.path);
  const initialSource = useSelector(getSelectedSource);
  const searchInput = useSelector(getSearchInput);
  const dispatch = useDispatch();

  const displayStatus = showArchivedDemands ? DisplayStatus.archived : DisplayStatus.displayable;
  const { data, isLoading, isFetching } = useFetchExternalDemands(displayStatus);
  const [selectedSource, setSelectedSource] = useState<AppelMedicalEventSource>(initialSource);

  const filteredData = useMemo(
    () =>
      Object.values(AppelMedicalEventSource).reduce((acc, source) => {
        acc[source] =
          data?.filter((externalDemand: ExternalDemandDto) => externalDemand.source === source) ||
          [];
        return acc;
      }, {} as Record<AppelMedicalEventSource, ExternalDemandDto[]>),
    [data]
  );

  return (
    <>
      <div className={styles.container}>
        <TopBar title={showArchivedDemands ? 'demandes archivées' : 'demandes'}>
          <ExternalDemandsTopBarComponent />
        </TopBar>
        <div className={styles.segmentedControlContainer}>
          <SegmentedControl
            className={styles.segmentedControl}
            controls={Object.keys(filteredData) as AppelMedicalEventSource[]}
            selected={selectedSource}
            getValue={(dashboardMenuItem: AppelMedicalEventSource) => {
              return (
                <div className={styles.segmentContent}>
                  {dashboardMenuItem.toLowerCase()}
                  <Badge
                    value={`${filteredData[dashboardMenuItem].length}`}
                    className={classnames(
                      styles.badge,
                      dashboardMenuItem === selectedSource && styles.selectedBadge
                    )}
                  />
                </div>
              );
            }}
            onSelectionChange={dashboardMenuItem => {
              setSelectedSource(dashboardMenuItem);
              dispatch(ExternalDemandPreferencesActions.setSelectedSource(dashboardMenuItem));
            }}
          />
        </div>
        {isLoading ? (
          <div className={styles.loadingScreen}>
            <Loader heightInRem={4} className={styles.loader} />
            <div>chargement des demandes externes, cela peut prendre du temps</div>
          </div>
        ) : !filteredData[selectedSource] ? (
          <div className={styles.error}>
            Une erreur s'est produite pendant la recherche de commandes
          </div>
        ) : filteredData[selectedSource].length === 0 ? (
          <div className={styles.noResult}>aucun résultat pour cette agence</div>
        ) : (
          <ExternalDemandsList
            data={filteredData[selectedSource]}
            isFetching={isFetching}
            searchInput={searchInput}
            source={selectedSource}
          />
        )}
      </div>
    </>
  );
};
