import { Animation } from '@randstad-lean-mobile-factory/react-components-core';
import { MinusClose, PlusOpen } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from './HideableSection.module.scss';
import { Props } from './HideableSection.types';

const HideableSection = ({
  title,
  children,
  defaultOpenState,
  dataReady,
  titleIcon,
  onToggle,
  isOpen,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(defaultOpenState);

  useEffect(() => {
    setOpen(defaultOpenState);
  }, [defaultOpenState]);

  useEffect(() => {
    if (open) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [open]);

  return (
    <>
      <div
        className={classnames(styles.trigger, { [styles.open]: isOpen })}
        onClick={() => {
          onToggle();
          setOpen(!open);
        }}
        ref={ref}
      >
        <div className={styles.title}>
          {title}
          {titleIcon}
        </div>
        {isOpen ? <MinusClose /> : <PlusOpen />}
      </div>
      <Animation.Unroll visible={isOpen} noOpacityFade immediate={!dataReady}>
        {children}
      </Animation.Unroll>
    </>
  );
};

export default HideableSection;
