import { useEffect, useState } from 'react';

import {
  Button,
  DatePickerInput,
  DropDown,
  RadioButtonGroup,
  SignatureWithChoice,
  TextArea,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  PlusCircle,
  TickCircleGreen,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import moment from 'moment/moment';
import { useController, UseFormReturn } from 'react-hook-form';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { useFetchAgencyConsultants } from 'src/Hooks/Agencies/useFetchAgencyConsultants';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import { useFetchCompanyContacts } from 'src/Hooks/Companies/useFetchCompanyContacts';
import { useFetchWorkAccidentByIds } from 'src/Hooks/WorkAccidentAnalysis/useFetchWorkAccidentByIds';
import { useFinaliseWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useFinaliseWorkAccidentAnalysis';
import { useUpdateWorkAccidentAnalysis } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentAnalysis';
import { useUpdateWorkAccidentConclusionSignatory } from 'src/Hooks/WorkAccidentAnalysis/useUpdateWorkAccidentConclusionSignatory';
import { FETCH_STATUS } from 'src/Redux/Types';
import { ROUTES } from 'src/Routes/Routes.types';
import {
  AnalysisStatus,
  EnumWorkAccidentAnalysisStatus,
  SigningStatusEnum,
} from 'src/Services/API';
import { toFetchStatus } from 'src/Services/Async';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import ClosingWorkAccidentAnalysisModal from './ClosingWorkAccidentAnalysisModal';
import styles from './Conclusion.module.scss';
import { conclusionSchema, ConclusionSchemaType } from './Conclusion.schema';
import {
  ClientSigningStatusRadioButtons,
  Signatory,
  VictimSigningStatusRadioButtons,
} from './Conclusion.types';

const ENCODING = 'base64';

export const ConclusionRoute = () => {
  const { analysisId } = useTypedParams(
    ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.WORK_ACCIDENT_DETAIL.CONCLUSION
  );
  const currentAnalysis = useFetchWorkAccidentByIds({ analyseIds: [analysisId] });
  const isEditable =
    currentAnalysis.data?.[0].status === EnumWorkAccidentAnalysisStatus.IN_PROGRESS;
  const currentAnalysisFetchStatus = toFetchStatus(currentAnalysis);
  const updateWorkAnalysisMutation = useUpdateWorkAccidentAnalysis();
  const updateWorkAccidentConclusionSignatory = useUpdateWorkAccidentConclusionSignatory();
  const [hasAdditionalSigningPerson, sethasAdditionalSigningPerson] = useState(false);
  const [additionalSignataireName, setAdditionalSignataireName] = useState('');
  const [additionalSignataireRole, setAdditionalSignataireRole] = useState('');

  const user = useAuthenticatedUser();

  const randstadContacts = useFetchAgencyConsultants(
    currentAnalysis.data?.[0].agencyId ?? '',
    currentAnalysis.data?.[0].brandCode
  );
  const clientContacts = useFetchCompanyContacts(currentAnalysis.data?.[0].company?.id ?? '');

  const [closeAnalysis, setCloseAnalysis] = useState<AnalysisStatus.DONE | undefined>(undefined);
  const finaliseWorkAccidentAnalysisMutation = useFinaliseWorkAccidentAnalysis(setCloseAnalysis);

  const { control, setValue, handleSubmit } = useFormWithZodResolver({
    schema: conclusionSchema,
    defaultValues: {
      comments: undefined,
      dateOfSignature: undefined,
      victimSigningPerson: undefined,
      randstadSigningPerson: undefined,
      clientSigningPerson: undefined,
      randstadConsultantSignature: undefined,
      clientSignature: undefined,
      victimSignature: undefined,
      additionalSigningPerson: undefined,
      additionalSignature: undefined,
    },
  }) as UseFormReturn<ConclusionSchemaType>;

  const comments = useController({ control, name: 'comments' });
  const dateOfSignature = useController({ control, name: 'dateOfSignature' });
  const victimSigningPerson = useController({ control, name: 'victimSigningPerson' });
  const randstadSigningPerson = useController({ control, name: 'randstadSigningPerson' });
  const clientSigningPerson = useController({ control, name: 'clientSigningPerson' });
  const additionalSigningPerson = useController({ control, name: 'additionalSigningPerson' });
  const randstadConsultantSignature = useController({
    control,
    name: 'randstadConsultantSignature',
  });
  const clientSignature = useController({ control, name: 'clientSignature' });
  const victimSignature = useController({ control, name: 'victimSignature' });
  const additionalSignature = useController({ control, name: 'additionalSignature' });

  const signaturesSize = { width: 834, height: 210 };

  const hasDateOfSignature = !!dateOfSignature.field.value;
  const hasValidAdditionalSignature =
    !hasAdditionalSigningPerson ||
    (additionalSigningPerson.field.value?.signed && additionalSignature.field.value?.length);
  const hasValidVictimSignature =
    victimSigningPerson.field.value?.signed && !!victimSignature.field.value?.length;
  const hasValidConsultantSignature =
    randstadSigningPerson.field.value?.signed && !!randstadConsultantSignature.field.value?.length;
  const hasValidClientSignature =
    clientSigningPerson.field.value?.signed && !!clientSignature.field.value?.length;
  const hasUnsigningClient =
    clientSigningPerson.field.value?.clientSigningStatus === SigningStatusEnum.CANNOT_SIGN ||
    clientSigningPerson.field.value?.clientSigningStatus ===
      SigningStatusEnum.DOES_NOT_WANT_TO_SIGN;
  const hasUnsigningVictim =
    victimSigningPerson.field.value?.victimSigningStatus === SigningStatusEnum.CANNOT_SIGN ||
    victimSigningPerson.field.value?.victimSigningStatus ===
      SigningStatusEnum.DOES_NOT_WANT_TO_SIGN;
  const isDisabled = !(
    hasDateOfSignature &&
    hasValidAdditionalSignature &&
    (hasValidVictimSignature || hasUnsigningVictim) &&
    hasValidConsultantSignature &&
    (hasValidClientSignature || hasUnsigningClient)
  );

  useEffect(
    () => {
      if (
        currentAnalysisFetchStatus === FETCH_STATUS.FULFILLED &&
        currentAnalysis.data?.[0].conclusion !== undefined
      ) {
        if (currentAnalysis.data[0].randstadConsultantSignature) {
          const randstadSignMime = currentAnalysis.data[0].randstadConsultantSignature?.mimetype;
          const randstadSignData = currentAnalysis.data[0].randstadConsultantSignature?.buffer;
          const randstadSignUri =
            'data:' + randstadSignMime + ';' + ENCODING + ',' + randstadSignData;
          setValue('randstadConsultantSignature', randstadSignUri);
        }

        if (currentAnalysis.data[0].victimSignature) {
          const victimSignMime = currentAnalysis.data[0].victimSignature?.mimetype;
          const victimSignData = currentAnalysis.data[0].victimSignature?.buffer;
          const victimSignUri = 'data:' + victimSignMime + ';' + ENCODING + ',' + victimSignData;
          setValue('victimSignature', victimSignUri);
        }
        if (currentAnalysis.data[0].additionalSignature) {
          const additionalSignMime = currentAnalysis.data[0].additionalSignature?.mimetype;
          const additionalSignData = currentAnalysis.data[0].additionalSignature?.buffer;
          const additionalSignUri =
            'data:' + additionalSignMime + ';' + ENCODING + ',' + additionalSignData;
          setValue('additionalSignature', additionalSignUri);
        }

        if (currentAnalysis.data[0].clientSignature) {
          const clientSignMime = currentAnalysis.data[0].clientSignature?.mimetype;
          const clientSignData = currentAnalysis.data[0].clientSignature?.buffer;
          const clientSignUri = 'data:' + clientSignMime + ';' + ENCODING + ',' + clientSignData;
          setValue('clientSignature', clientSignUri);
        }

        setValue('comments', currentAnalysis.data?.[0].conclusion.comments);
        setValue(
          'dateOfSignature',
          currentAnalysis.data?.[0].conclusion?.dateOfSignature
            ? new Date(currentAnalysis.data?.[0].conclusion.dateOfSignature)
            : new Date()
        );

        setValue('randstadSigningPerson', {
          personName:
            currentAnalysis.data?.[0].conclusion?.randstadSigningPerson?.personName ?? undefined,
          id: currentAnalysis.data?.[0].conclusion?.randstadSigningPerson?.id ?? undefined,
          moment: currentAnalysis.data?.[0].conclusion?.randstadSigningPerson?.moment
            ? new Date(currentAnalysis.data?.[0].conclusion.randstadSigningPerson.moment)
            : undefined,
          signed: !!currentAnalysis.data?.[0].conclusion?.randstadSigningPerson?.moment,
        });
        setValue('additionalSigningPerson', {
          personName:
            currentAnalysis.data?.[0].conclusion?.additionalSigningPerson?.personName ?? undefined,
          moment: currentAnalysis.data?.[0].conclusion?.additionalSigningPerson?.moment
            ? new Date(currentAnalysis.data?.[0].conclusion.additionalSigningPerson.moment)
            : undefined,
          signed: !!currentAnalysis.data?.[0].conclusion?.additionalSigningPerson?.moment,
        });
        setValue('clientSigningPerson', {
          personName:
            currentAnalysis.data?.[0].conclusion?.clientSigningPerson?.personName ?? undefined,
          id: currentAnalysis.data?.[0].conclusion?.clientSigningPerson?.id ?? undefined,
          moment: currentAnalysis.data?.[0].conclusion?.clientSigningPerson?.moment
            ? new Date(currentAnalysis.data?.[0].conclusion.clientSigningPerson.moment)
            : undefined,
          signed: !!currentAnalysis.data?.[0].conclusion?.clientSigningPerson?.moment,
          clientSigningStatus:
            currentAnalysis.data[0].conclusion.clientSigningPerson?.clientSigningStatus,
        });
        setValue('victimSigningPerson', {
          moment: currentAnalysis.data?.[0].conclusion?.victimSigningPerson?.moment
            ? new Date(currentAnalysis.data?.[0].conclusion.victimSigningPerson.moment)
            : undefined,
          signed: !!currentAnalysis.data?.[0].conclusion?.victimSigningPerson?.moment,
          victimSigningStatus:
            currentAnalysis.data[0].conclusion.victimSigningPerson?.victimSigningStatus,
        });
      } else if (
        currentAnalysisFetchStatus === FETCH_STATUS.FULFILLED &&
        currentAnalysis.data?.[0].conclusion === undefined
      ) {
        setValue('dateOfSignature', new Date());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAnalysisFetchStatus]
  );

  const conclusionHandleSubmit = () =>
    handleSubmit(async (values: ConclusionSchemaType) => {
      if (isEditable) {
        if (
          values.randstadSigningPerson?.personName &&
          values.randstadConsultantSignature !== undefined
        ) {
          const res: Response = await fetch(values.randstadConsultantSignature);
          const blob: Blob = await res.blob();
          const randstadSigningPersonSignature = new File(
            [blob],
            'randstad_signing_person_signature.png',
            {
              type: 'image/png',
            }
          );

          updateWorkAccidentConclusionSignatory.mutate({
            file: randstadSigningPersonSignature,
            signatory: Signatory.CONSULTANT,
            analyseId: analysisId,
          });
        } else {
          updateWorkAccidentConclusionSignatory.mutate({
            file: null,
            signatory: Signatory.CONSULTANT,
            analyseId: analysisId,
          });
        }

        if (values.clientSigningPerson && values.clientSignature !== undefined) {
          const res: Response = await fetch(values.clientSignature);
          const blob: Blob = await res.blob();
          const clientSigningPersonSignature = new File(
            [blob],
            'client_signing_person_signature.png',
            {
              type: 'image/png',
            }
          );

          updateWorkAccidentConclusionSignatory.mutate({
            file: clientSigningPersonSignature,
            signatory: Signatory.CLIENT,
            analyseId: analysisId,
          });
        } else {
          updateWorkAccidentConclusionSignatory.mutate({
            file: null,
            signatory: Signatory.CLIENT,
            analyseId: analysisId,
          });
        }

        if (values.victimSigningPerson && values.victimSignature !== undefined) {
          const res: Response = await fetch(values.victimSignature);
          const blob: Blob = await res.blob();
          const victimSigningPersonSignature = new File(
            [blob],
            'victim_signing_person_signature.png',
            {
              type: 'image/png',
            }
          );

          updateWorkAccidentConclusionSignatory.mutate({
            file: victimSigningPersonSignature,
            signatory: Signatory.VICTIM,
            analyseId: analysisId,
          });
        } else {
          updateWorkAccidentConclusionSignatory.mutate({
            file: null,
            signatory: Signatory.VICTIM,
            analyseId: analysisId,
          });
        }

        if (
          values.additionalSigningPerson?.personName &&
          values.additionalSignature !== undefined
        ) {
          const res: Response = await fetch(values.additionalSignature);
          const blob: Blob = await res.blob();
          const additionalSigningPersonSignature = new File(
            [blob],
            'additional_signing_person_signature.png',
            {
              type: 'image/png',
            }
          );

          updateWorkAccidentConclusionSignatory.mutate({
            file: additionalSigningPersonSignature,
            signatory: Signatory.ADDITIONAL,
            analyseId: analysisId,
          });
        } else {
          updateWorkAccidentConclusionSignatory.mutate({
            file: null,
            signatory: Signatory.ADDITIONAL,
            analyseId: analysisId,
          });
        }

        updateWorkAnalysisMutation.mutate({
          analyseId: analysisId,
          conclusion: {
            comments: values.comments,
            dateOfSignature: values.dateOfSignature,
            victimSigningPerson: values.victimSigningPerson,
            randstadSigningPerson: values.randstadSigningPerson,
            clientSigningPerson: values.clientSigningPerson,
            additionalSigningPerson: values.additionalSigningPerson,
          },
          status: closeAnalysis,
        });
      }
    });
  useEffect(
    () => {
      window.addEventListener('beforeunload', conclusionHandleSubmit);
      return () => {
        window.removeEventListener('beforeunload', conclusionHandleSubmit);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (currentAnalysisFetchStatus === FETCH_STATUS.FULFILLED) conclusionHandleSubmit()();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dateOfSignature.field.value,
      victimSigningPerson.field.value,
      randstadSigningPerson.field.value,
      clientSigningPerson.field.value,
      additionalSigningPerson.field.value,
      closeAnalysis,
    ]
  );

  return (
    <div className={styles.page}>
      <div className={styles.titleContainer}>5.</div>
      <div className={styles.content}>
        <div className={styles.title}>conclusion</div>
        <div className={styles.subTitle}>observations, commentaires (EU, TT, agence)</div>
        <WithLightTitle
          title="ajouter toutes observations ou commentaires nécessaires des différentes parties"
          className={styles.lightTitle}
          rightTitleComponent={
            isEditable ? (
              <div className={styles.numberOfChar}>
                {comments.field.value?.length ?? 0}/{REPORT_MAX_LENGTH} caractères
              </div>
            ) : (
              <></>
            )
          }
          children={
            isEditable ? (
              <TextArea
                value={comments.field.value}
                className={styles.textArea}
                placeholder={'en quelques mots…'}
                onChange={(event: any) =>
                  comments.field.onChange((event.target as HTMLTextAreaElement).value)
                }
                onBlurCapture={conclusionHandleSubmit()}
              />
            ) : (
              <div>{comments.field.value ?? 'non renseigné'}</div>
            )
          }
        />
        <div className={styles.divider} />
        <div className={styles.subTitle}>signatures</div>
        <WithLightTitle
          title="date"
          className={styles.lightTitle}
          children={
            isEditable ? (
              <DatePickerInput
                date={dateOfSignature.field.value}
                onSelectDate={date => dateOfSignature.field.onChange(date)}
                minDate={moment().subtract(1, 'month').toDate()}
                maxDate={moment().add(1, 'month').toDate()}
              />
            ) : (
              <div>
                {dateOfSignature.field.value
                  ? moment(dateOfSignature.field.value).format('DD/MM/YYYY')
                  : 'non renseigné'}
              </div>
            )
          }
        />

        <WithLightTitle
          title="signataire randstad"
          className={styles.lightTitle}
          children={
            <>
              {randstadSigningPerson?.field?.value &&
              randstadSigningPerson.field.value?.moment &&
              randstadSigningPerson.field.value?.personName &&
              randstadSigningPerson.field.value?.signed &&
              randstadSigningPerson.field.value.moment.getTime() <
                moment().subtract(1, 'second').toDate().getTime() ? (
                <div className={styles.signatureValidated}>
                  <div className={styles.greenTick}>
                    <TickCircleGreen />
                    {`signé le ${moment(randstadSigningPerson.field.value.moment).format(
                      'DD/MM/YYYY'
                    )} par ${randstadSigningPerson.field.value.personName}`}
                  </div>
                  {isEditable && (
                    <Trashcan
                      onClick={() => {
                        randstadSigningPerson.field.onChange({
                          personName: undefined,
                          id: undefined,
                          moment: undefined,
                          signed: false,
                        });
                        randstadConsultantSignature.field.onChange(undefined);
                      }}
                    />
                  )}
                </div>
              ) : (
                <>
                  <DropDown
                    items={
                      randstadContacts.data?.map(
                        contact =>
                          `${contact.firstName !== undefined ? contact.firstName : ''} ${
                            contact.name !== undefined ? contact.name : ''
                          }`
                      ) ?? []
                    }
                    placeholder="nom d'un consultant Randstad"
                    canBeReset={true}
                    className={styles.dropdown}
                    childrenClassName={styles.childDropdown}
                    keyValueExtractor={(item: string) => {
                      return { key: item, value: item };
                    }}
                    selectedItem={
                      randstadSigningPerson.field.value?.personName
                        ? randstadSigningPerson.field.value?.personName
                        : "nom d'un consultant Randstad"
                    }
                    onSelectItem={(item?: string) =>
                      randstadSigningPerson.field.onChange({
                        ...randstadSigningPerson.field.value,
                        personName: item,
                        id: randstadContacts.data?.find(
                          contact =>
                            `${contact.firstName !== undefined ? contact.firstName : ''} ${
                              contact.name !== undefined ? contact.name : ''
                            }` === item
                        )?.id,
                      })
                    }
                  />
                  <SignatureWithChoice
                    containerClassName={classnames(styles.signature, {
                      [styles.disableSignature]: !randstadSigningPerson.field.value?.personName,
                    })}
                    size={signaturesSize}
                    onSignChange={image => {
                      if (image) {
                        randstadSigningPerson.field.onChange({
                          ...randstadSigningPerson.field.value,
                          moment: new Date(),
                          signed: true,
                        });
                        randstadConsultantSignature.field.onChange(image ?? undefined);
                      }
                    }}
                    onClear={() => {
                      randstadSigningPerson.field.onChange({
                        ...randstadSigningPerson.field.value,
                        moment: undefined,
                        signed: false,
                      });
                      randstadConsultantSignature.field.onChange(undefined);
                    }}
                    disabled={!randstadSigningPerson.field.value?.personName}
                  />
                </>
              )}
            </>
          }
        />
        <WithLightTitle
          title="signataire côté client"
          className={styles.lightTitle}
          children={
            <>
              {clientSigningPerson?.field?.value &&
              clientSigningPerson.field.value?.moment &&
              clientSigningPerson.field.value?.personName &&
              clientSigningPerson.field.value?.signed &&
              clientSigningPerson.field.value.moment.getTime() <
                moment().subtract(1, 'second').toDate().getTime() ? (
                <div className={styles.signatureValidated}>
                  <div className={styles.greenTick}>
                    <TickCircleGreen />
                    {`signé le ${moment(clientSigningPerson.field.value.moment).format(
                      'DD/MM/YYYY'
                    )} par ${clientSigningPerson.field.value.personName}`}
                  </div>
                  {isEditable && (
                    <Trashcan
                      onClick={() => {
                        clientSigningPerson.field.onChange({
                          personName: undefined,
                          id: undefined,
                          moment: undefined,
                          signed: false,
                        });
                        clientSignature.field.onChange(undefined);
                      }}
                    />
                  )}
                </div>
              ) : clientSigningPerson.field.value?.clientSigningStatus ===
                  SigningStatusEnum.CANNOT_SIGN && !isEditable ? (
                <div>Le client ne peut pas signer.</div>
              ) : clientSigningPerson.field.value?.clientSigningStatus ===
                  SigningStatusEnum.DOES_NOT_WANT_TO_SIGN && !isEditable ? (
                <div>Le client ne veut pas signer.</div>
              ) : (
                <>
                  <RadioButtonGroup
                    name="clientSigningStatus"
                    radioButtonsInfo={ClientSigningStatusRadioButtons}
                    onChange={(_, id) =>
                      clientSigningPerson.field.onChange({
                        ...clientSigningPerson.field.value,
                        clientSigningStatus:
                          ClientSigningStatusRadioButtons.find(button => button.id === id)?.value ??
                          undefined,
                      })
                    }
                    selected={clientSigningPerson.field.value?.clientSigningStatus}
                  />
                  {clientSigningPerson.field.value?.clientSigningStatus ===
                    SigningStatusEnum.SIGNS && (
                    <>
                      <DropDown
                        items={
                          clientContacts.data?.map(
                            contact =>
                              `${contact.firstName !== undefined ? contact.firstName : ''} ${
                                contact.name !== undefined ? contact.name : ''
                              }`
                          ) ?? []
                        }
                        placeholder="nom d'un interlocuteur"
                        canBeReset={true}
                        className={styles.dropdown}
                        childrenClassName={styles.childDropdown}
                        keyValueExtractor={(item: string) => {
                          return { key: item, value: item };
                        }}
                        selectedItem={
                          clientSigningPerson.field.value?.personName
                            ? clientSigningPerson.field.value?.personName
                            : "nom d'un interlocuteur"
                        }
                        onSelectItem={(item?: string) =>
                          clientSigningPerson.field.onChange({
                            ...clientSigningPerson.field.value,
                            personName: item,
                            id: clientContacts.data?.find(
                              contact =>
                                `${contact.firstName !== undefined ? contact.firstName : ''} ${
                                  contact.name !== undefined ? contact.name : ''
                                }` === item
                            )?.contactId,
                          })
                        }
                      />
                      <SignatureWithChoice
                        containerClassName={classnames(styles.signature, {
                          [styles.disableSignature]: !clientSigningPerson.field.value?.personName,
                        })}
                        size={signaturesSize}
                        onSignChange={image => {
                          if (image) {
                            clientSigningPerson.field.onChange({
                              ...clientSigningPerson.field.value,
                              moment: new Date(),
                              signed: true,
                            });
                            clientSignature.field.onChange(image ?? undefined);
                          }
                        }}
                        onClear={() => {
                          clientSigningPerson.field.onChange({
                            ...clientSigningPerson.field.value,
                            moment: undefined,
                            signed: false,
                          });
                          clientSignature.field.onChange(undefined);
                        }}
                        disabled={!clientSigningPerson.field.value?.personName}
                      />
                    </>
                  )}
                </>
              )}
            </>
          }
        />

        <WithLightTitle
          title="signataire intérimaire"
          className={styles.lightTitle}
          children={
            <>
              {victimSigningPerson?.field?.value &&
              victimSigningPerson.field.value?.moment &&
              victimSigningPerson.field.value?.signed &&
              victimSigningPerson.field.value.moment.getTime() <
                moment().subtract(1, 'second').toDate().getTime() ? (
                <div className={styles.signatureValidated}>
                  <div className={styles.greenTick}>
                    <TickCircleGreen />
                    {`signé le ${moment(victimSigningPerson.field.value.moment).format(
                      'DD/MM/YYYY'
                    )} par ${currentAnalysis.data?.[0].candidate?.firstName} ${
                      currentAnalysis.data?.[0].candidate?.name
                    }`}
                    {isEditable && (
                      <Trashcan
                        onClick={() => {
                          victimSigningPerson.field.onChange({
                            personName: undefined,
                            id: undefined,
                            moment: undefined,
                            signed: false,
                          });
                          victimSignature.field.onChange(undefined);
                        }}
                      />
                    )}
                  </div>
                </div>
              ) : victimSigningPerson.field.value?.victimSigningStatus ===
                  SigningStatusEnum.CANNOT_SIGN && !isEditable ? (
                <div>L'intérimaire ne peut pas signer.</div>
              ) : victimSigningPerson.field.value?.victimSigningStatus ===
                  SigningStatusEnum.DOES_NOT_WANT_TO_SIGN && !isEditable ? (
                <div>L'intérimaire ne veut pas signer.</div>
              ) : (
                <>
                  <div>
                    <RadioButtonGroup
                      name="victimSigningStatus"
                      radioButtonsInfo={VictimSigningStatusRadioButtons}
                      onChange={(_, id) =>
                        victimSigningPerson.field.onChange({
                          ...victimSigningPerson.field.value,
                          victimSigningStatus:
                            VictimSigningStatusRadioButtons.find(button => button.id === id)
                              ?.value ?? undefined,
                        })
                      }
                      selected={victimSigningPerson.field.value?.victimSigningStatus}
                    />
                  </div>
                  {isEditable &&
                    victimSigningPerson.field.value?.victimSigningStatus ===
                      SigningStatusEnum.SIGNS && (
                      <SignatureWithChoice
                        size={signaturesSize}
                        onSignChange={image => {
                          if (image) {
                            victimSigningPerson.field.onChange({
                              ...victimSigningPerson.field.value,
                              moment: new Date(),
                              signed: true,
                            });
                            victimSignature.field.onChange(image ?? undefined);
                          }
                        }}
                        onClear={() => {
                          victimSigningPerson.field.onChange({
                            ...victimSigningPerson.field.value,
                            moment: undefined,
                            signed: false,
                          });
                          victimSignature.field.onChange(undefined);
                        }}
                      />
                    )}
                </>
              )}
            </>
          }
        />
        <WithLightTitle
          title="signataire supplémentaire"
          className={styles.additionalSigningPerson}
          children={
            <>
              {additionalSigningPerson?.field?.value &&
              additionalSigningPerson.field.value.moment &&
              additionalSigningPerson.field.value.personName &&
              additionalSigningPerson.field.value.signed &&
              additionalSigningPerson.field.value.moment.getTime() <
                moment().subtract(1, 'second').toDate().getTime() ? (
                <div className={styles.signatureValidated}>
                  <div className={styles.greenTick}>
                    <TickCircleGreen />
                    {`signé le ${moment(additionalSigningPerson.field.value.moment).format(
                      'DD/MM/YYYY'
                    )} par ${additionalSigningPerson.field.value.personName}`}
                  </div>
                  {isEditable && (
                    <Trashcan
                      onClick={() => {
                        additionalSigningPerson.field.onChange({
                          personName: undefined,
                          moment: undefined,
                          signed: false,
                        });
                        additionalSignature.field.onChange(undefined);
                      }}
                    />
                  )}
                </div>
              ) : (
                <>
                  {isEditable && !hasAdditionalSigningPerson && (
                    <Button.Tertiary
                      className={styles.addSigningPerson}
                      onClick={() => {
                        sethasAdditionalSigningPerson(true);
                      }}
                    >
                      <PlusCircle className={styles.plusCircle} /> ajouter une signature
                    </Button.Tertiary>
                  )}
                  {isEditable && hasAdditionalSigningPerson && (
                    <>
                      <div className={styles.additionalSigningPersonTextInputContainer}>
                        <TextInput
                          type="text"
                          value={additionalSignataireName}
                          onChange={(event: any) => {
                            setAdditionalSignataireName(
                              (event.target as HTMLTextAreaElement).value
                            );
                            additionalSigningPerson.field.onChange({
                              ...additionalSigningPerson.field.value,
                              personName: additionalSignataireName,
                            });
                          }}
                          placeholder="nom du signataire supplémentaire"
                          containerClassName={styles.additionalSigningPersonTextInput}
                        />
                        <TextInput
                          type="text"
                          value={additionalSignataireRole}
                          onChange={(event: any) => {
                            setAdditionalSignataireRole(
                              (event.target as HTMLTextAreaElement).value
                            );
                            additionalSigningPerson.field.onChange({
                              ...additionalSigningPerson.field.value,
                              personRole: additionalSignataireRole,
                            });
                          }}
                          placeholder="rôle du signataire supplémentaire"
                          containerClassName={styles.additionalSigningPersonTextInput}
                        />
                      </div>
                      <SignatureWithChoice
                        containerClassName={classnames(styles.signature, {
                          [styles.disableSignature]: !hasAdditionalSigningPerson,
                        })}
                        size={signaturesSize}
                        onSignChange={image => {
                          if (image) {
                            additionalSigningPerson.field.onChange({
                              ...additionalSigningPerson.field.value,
                              moment: new Date(),
                              signed: true,
                            });
                            additionalSignature.field.onChange(image ?? undefined);
                          }
                        }}
                        onClear={() => {
                          additionalSigningPerson.field.onChange({
                            ...additionalSigningPerson.field.value,
                            moment: undefined,
                            signed: false,
                          });
                          additionalSignature.field.onChange(undefined);
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </>
          }
        />

        {isEditable && (
          <ClosingWorkAccidentAnalysisModal
            trigger={
              <Button.Secondary className={styles.lightTitle} disabled={isDisabled}>
                clôturer l'analyse AT
              </Button.Secondary>
            }
            onValidate={closeAnalysis => {
              if (closeAnalysis) {
                finaliseWorkAccidentAnalysisMutation.mutate({
                  analyseId: analysisId,
                  candidateName: `${currentAnalysis.data?.[0].candidate?.firstName} ${currentAnalysis.data?.[0].candidate?.name}`,
                  companyId: currentAnalysis.data?.[0].company?.id ?? '',
                  createdAt: moment(new Date()).toISOString(),
                  user,
                  agencyId: currentAnalysis.data?.[0].agencyId ?? '',
                  randstadSignatory:
                    currentAnalysis.data?.[0].conclusion?.randstadSigningPerson?.id ?? '',
                  clientSignatory:
                    currentAnalysis.data?.[0].conclusion?.clientSigningPerson?.id ?? '',
                });
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
