import {
  Button,
  FetchButton,
  ModalDeprecated,
  PopupActions,
  TextArea,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BinocularFullCut } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFetchCandidateLastMedicalVisit } from 'src/Hooks/Candidate/useFetchCandidateLastMedicalVisit';
import { useSuggestCandidateToAgencies } from 'src/Hooks/Candidate/useSuggestCandidateToAgencies';
import { getAnalyticsUserInfo, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { toFetchStatus } from 'src/Services/Async';
import { REPORT_MAX_LENGTH } from 'src/Utils/constants';
import { isInternEmails } from 'src/Utils/emails';
import { parseEmails } from 'src/Utils/parseEmails';
import { OSMCommonModelDateDtoToDate } from '../../utils';
import styles from './CGCAgencyPropositionModal.module.scss';
import { Props } from './CGCAgencyPropositionModal.types';

const CGCAgencyPropositionModal = ({ candidateData, selectedAgencies, buttonOn }: Props) => {
  const ref = useRef<PopupActions>(null);
  const userInfo = useSelector(getAnalyticsUserInfo);
  const navigate = useNavigate();
  const cgcId = useSelector(getCurrentAgency) ?? '';
  const { data: lastMedicalVisit } = useFetchCandidateLastMedicalVisit(candidateData.id);

  const suggestCandidateToAgencies = useSuggestCandidateToAgencies();
  const suggestCandidateToAgenciesFetchStatus = toFetchStatus(suggestCandidateToAgencies);

  const [cc, setCc] = useState<string>('');
  const [ccMailsAreValid, setCCMailsAreValid] = useState(true);

  const [message, setMessage] = useState<string>(
    candidateData.sosComment
      ? `message de l'agence ${candidateData.sosAgencyId} lors du sos :
  ${candidateData.sosComment}`
      : ''
  );

  useEffect(() => {
    const emails = parseEmails(cc);
    if (emails[0] !== '') {
      setCCMailsAreValid(isInternEmails(emails));
    } else {
      setCCMailsAreValid(true);
    }
  }, [cc]);

  return (
    <ModalDeprecated
      ref={ref}
      title={selectedAgencies.length > 1 ? 'suggestions aux agences' : `suggestion à l'agence`}
      footerActionsRight={[
        <Button.Secondary key="cancelButton" onClick={() => ref.current?.close()}>
          annuler
        </Button.Secondary>,
        <FetchButton
          fetchStatus={suggestCandidateToAgenciesFetchStatus}
          key="sendButton"
          title="envoyer"
          onClick={() => {
            return suggestCandidateToAgencies.mutate({
              candidateId: candidateData.id ?? '',
              cc: parseEmails(cc),
              suggestedAgencies: selectedAgencies,
              cgcId: cgcId ?? '',
              candidateFullName: `${candidateData.firstName} ${candidateData.name}`,
              candidateMainQualification: candidateData.mainQualification?.label ?? 'inconnue',
              comment: message,
              lastContractEndDate: OSMCommonModelDateDtoToDate(candidateData.lastContract?.endDate),
              lastMedicalVisit: lastMedicalVisit
                ? moment(lastMedicalVisit).format('DD/MM/YYYY')
                : 'inconnue',
            });
          }}
          disabled={message.length < 10 || !ccMailsAreValid}
          onSuccess={() => {
            ref.current?.close();
            ReactGA.event(ANALYTICS_EVENT.PPA_INTERN_SUGGESTION, {
              ...userInfo,
              suggestionCount: selectedAgencies.length,
            });
            navigate(ROUTES.TALENTS.CDII.buildPath({}));
          }}
        />,
      ]}
      trigger={
        buttonOn ? (
          <Button.Secondary
            text={selectedAgencies.length > 1 ? 'contacter les agences' : `contacter l'agence`}
            className={styles.cgcButton}
            disabled={selectedAgencies.length === 0}
          />
        ) : (
          <div className={styles.contact}>contacter agence</div>
        )
      }
      icon={<BinocularFullCut />}
    >
      <p className={styles.text}>
        Je m'apprête à transmettre le talent aux agences suivantes : <br />
        {selectedAgencies.map(agency => (
          <p className={styles.agencyText} key={agency.idDecrypted}>
            • {agency.name} <br />
          </p>
        ))}
      </p>
      <div>
        <WithLightTitle
          title="cc (mails séparés d'une virgule)"
          className={styles.checkText}
          children={
            <TextInput
              className={styles.reportTextArea}
              value={cc}
              containerClassName={classnames(styles.textInput, {
                [styles.warningEmails]: !ccMailsAreValid,
              })}
              onChange={(event: any) => setCc((event.target as HTMLTextAreaElement).value)}
            />
          }
        />
        {!ccMailsAreValid && (
          <div className={styles.warningMessage}>
            une ou plusieurs adresses mails sont invalides
          </div>
        )}
      </div>
      <WithLightTitle
        title="votre message"
        className={styles.checkText}
        children={
          <TextArea
            className={styles.reportTextArea}
            value={message}
            onChange={event => setMessage((event.target as HTMLTextAreaElement).value)}
            maxLength={REPORT_MAX_LENGTH}
            placeholder={'en quelques mots...'}
          />
        }
      />
    </ModalDeprecated>
  );
};

export default CGCAgencyPropositionModal;
